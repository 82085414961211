import React from 'react';
import StyledTypography from '../styledTypography';

const ListOption = ({ fcid, name, site_name }) => (
    <>
        <div>
            <StyledTypography variant="subtitle1" noWrap>
                {fcid}
            </StyledTypography>
            <StyledTypography variant="subtitle2" noWrap>
                {name || site_name}
            </StyledTypography>
        </div>
    </>
)

export default ListOption;