import React from "react";
import { useHistory } from "react-router-dom";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import { useTranslation } from "react-i18next";

const EmptyCheckoutModal = ({ open, onCancel }) => {
  const { t } = useTranslation();
  const history = useHistory();

  function goToCatalog() {
    onCancel();
    history.push("/catalog");
  }

  const actions = [
    {
      text: t("modals.gotIt"),
      autoFocus: true,
      primary: true,
      onClick: goToCatalog
    }
  ];

  return (
    <MuiDialog
      open={open}
      onClose={onCancel}
      actions={actions}
      title={t("modals.emptyCheckoutTitle")}
      content={t("modals.emptyCheckoutContent")}
      {...settings}
    />
  );
};

export default EmptyCheckoutModal;
