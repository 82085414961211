import React from "react";
import clsx from "clsx";
import { inject, observer } from "mobx-react";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Header from "../header";
import MenuDrawer from "../MenuDrawer";
import SkipToMainContentBtn from "../skipToMainContentBtn";
import { useStyles } from "./styles";

import "./styles.scss";

const Layout = ({ menuStore, children }) => {
  const classes = useStyles();

  function skipToMain() {
    const mainContent = document.getElementById("main-content");
    if (!mainContent) return;
    mainContent.focus();
  }

  return (
    <div className={classes.root}>
      <AppBar color="secondary" position="fixed" className={classes.appBar}>
        <SkipToMainContentBtn onClick={skipToMain} />
        <Toolbar className={classes.toolbar}>
          <Header />
        </Toolbar>
      </AppBar>
      <Hidden smDown>
        <MenuDrawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: !menuStore.menuState.collapsed,
            [classes.drawerClose]: menuStore.menuState.collapsed
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: !menuStore.menuState.collapsed,
              [classes.drawerClose]: menuStore.menuState.collapsed
            })
          }}
        />
      </Hidden>
      <Hidden mdUp>
        <MenuDrawer
          variant="temporary"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper
          }}
        />
      </Hidden>
      <main id="main-content" tabIndex="-1" className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  menuStore: rootStore.menuStore
}))(observer(Layout));
