import React, { forwardRef, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { CaretDownOutlined } from "@ant-design/icons";

import Label from "../label";
import ValidationError from "../validationError";

import "./styles.scss";

const customStyles = {
  menu: provided => ({
    ...provided,
    position: "absolute",
    margin: "6px 0"
  }),
  menuList: provided => ({
    ...provided,
    padding: "2px 0"
  }),
  option: (provided, state) => {
    return {
      ...provided,
      height: 32,
      padding: "5px 12px",
      background: state.isSelected
        ? "#3586D6"
        : state.isFocused
        ? "rgba(212, 239, 252, 0.51)"
        : "white",
      transition: "background 0.3s ease",
      cursor: "pointer",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: state.isSelected && !state.isFocused ? "white" : "#4a4a4a",
      "&:hover": {
        background: state.isSelected ? "#3586D6" : "rgba(212, 239, 252, 0.51)",
        color: "#4a4a4a"
      }
    };
  },
  placeholder: (provided, _) => {
    return {
      ...provided,
      width: "calc(100% - 10px)",
      color: "#595959",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      height: 34,
      minHeight: 34,
      boxShadow: "none",
      borderColor: state.isFocused ? "#0073CF80" : "#EDEDED",
      background: state.isDisabled ? "#f5f5f5" : "white",
      cursor: "pointer",
      "&:hover": {
        borderColor: "#0073CF80"
      }
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.65)"
  }),
  indicatorSeparator: () => ({
    display: "none"
  })
};

const customArrow = props => {
  return (
    <div
      className={`rc-select__custom-arrow ${
        props.selectProps.menuIsOpen ? "open" : ""
      }`}
    >
      <CaretDownOutlined rotate={props.selectProps.menuIsOpen ? 180 : 0} />
    </div>
  );
};

const RCSelect = forwardRef(
  (
    {
      options = [],
      name,
      onChange,
      defaultValue,
      label,
      disabled,
      isSearchable = false,
      onMenuClose,
      onMenuOpen,
      components,
      styles,
      ...rest
    },
    ref
  ) => {
    const [activedescendant, setActivedescendant] = useState("");
    const [isOpen, setIsOpen] = useState(Boolean(rest.isModalOpen));

    const selectOptions = useMemo(
      () =>
        options.map(item => {
          if (!item.label) {
            item.label = item.value;
          }
          return item;
        }),
      [options]
    );

    const getOptionHtmlId = (options, id) => {
      const optionIndex = options.findIndex(item => item.id === id);
      return `react-select-${name}-option-${optionIndex}`;
    };

    useEffect(() => {
      const el = document.querySelector(`#react-select-${name}-input`);
      if (!el) return;
      if (isOpen) {
        el.setAttribute("aria-activedescendant", activedescendant);
      } else {
        el.removeAttribute("aria-activedescendant");
      }
    }, [activedescendant, isOpen, name]);

    useEffect(() => {
      const el = document.querySelector(`#react-select-${name}-input`);
      if (!el) return;
      el.setAttribute("aria-expanded", isOpen);
    }, [isOpen, name]);

    useEffect(() => {
      const el = document.querySelector(`#react-select-${name}-input`);
      if (!el) return;
      el.setAttribute("role", "combobox");
    }, [name]);

    useEffect(() => {
      if (
        name &&
        ((rest.value && Object.keys(rest.value).length) ||
          (defaultValue && Object.keys(defaultValue).length)) &&
        options &&
        options.length
      ) {
        const { id } = rest.value || defaultValue;
        const optionHtmlId = getOptionHtmlId(options, id);
        setActivedescendant(optionHtmlId);
      }
    }, [rest.value, defaultValue]);

    return (
      <>
        {label && (
          <Label
            htmlFor={`react-select-${name}-input`}
            text={label.text}
            antLabel={label.antLabel}
            required={
              rest["data-__meta"] &&
              rest["data-__meta"].rules &&
              rest["data-__meta"].rules.length &&
              rest["data-__meta"].rules.find(item => item.required)
            }
          />
        )}
        <Select
          {...rest}
          ref={ref}
          styles={{
            ...customStyles,
            ...styles
          }}
          options={selectOptions}
          components={{
            DropdownIndicator: customArrow,
            ...components
          }}
          isDisabled={disabled}
          instanceId={name}
          onChange={onChange}
          aria-label={name}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          onMenuOpen={() => {
            setIsOpen(true);
            if (onMenuOpen) {
              onMenuOpen();
            }
          }}
          onMenuClose={() => {
            setIsOpen(false);
            if (onMenuClose) {
              onMenuClose();
            }
          }}
          aria-describedby={
            rest["data-__field"] &&
            rest["data-__field"].errors &&
            rest["data-__field"].errors
              .map((_, i) => `${name}-error-${i}`)
              .join(" ")
          }
        />
        {rest["data-__field"] &&
          rest["data-__field"].errors &&
          rest["data-__field"].errors.map((err, i) => (
            <ValidationError
              key={`${err.message}-${i}`}
              id={`${name}-error-${i}`}
              errorText={err.message}
            />
          ))}
      </>
    );
  }
);

export default RCSelect;
