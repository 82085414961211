import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import Affix from "../../components/affix";
import Button from "../../components/button";
import MuiTabs from "../../components/muiTabs";
import FiltersMenu from "../../components/filtersMenu";
import CatalogList from "../../components/catalogList";
import FilterBlock from "../../components/filterBlock";
import PrintOnDemandCatalogList from "../../components/printOnDemandCatalogList/PrintOnDemandCatalogList";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const Catalog = ({
  catalogStore,
  authorizationStore,
  locationStore,
  departmentsStore,
  languagesStore,
  colorProfilesStore,
  tagsStore,
  menuStore,
  vendorsStore
}) => {
  const { t } = useTranslation();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [tabsFixed, setTabsFixed] = useState(false);

  const history = useHistory();
  const shouldShowTabs = !catalogStore.activeCatalog.anotherFCIncluded;
  const printOnDemand = catalogStore.activeCatalog.catalogName === "printOnDemand";

  useEffect(() => {
    tagsStore.getTags();
  }, [tagsStore]);

  useEffect(() => {
    vendorsStore.getVendors();
  }, [vendorsStore]);

  useEffect(() => {
    languagesStore.getLanguages();
  }, [languagesStore]);

  useEffect(() => {
    departmentsStore.getDepartments();
  }, [departmentsStore]);

  useEffect(() => {
    colorProfilesStore.getColorProfiles();
  }, [colorProfilesStore]);

  useEffect(() => {
    catalogStore.getCatalogMaterials();
  }, [catalogStore, catalogStore.activeCatalogFilters, locationStore.activeLocation]);

  useEffect(() => {
    catalogStore.getFilters();
    return catalogStore.clearFilters;
  }, [catalogStore, catalogStore.activeCatalog.locationFilter, locationStore.activeLocation]);

  function toggleFilters() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function onApplyHandler() {
    toggleFilters();
    catalogStore.applyFilters();
  }

  function catalogToggleHandler({ target }) {
    catalogStore.setActiveCatalog(target.value);
    menuStore.resetMenuState();
  }

  const onTabsBecameFixed = affixed => {
    setTabsFixed(affixed);
  };

  const onCreateTemplateHandler = () => {
    history.push("/templates/create");
  };

  return (
    <div className="catalog">
      <div className="catalog__header">
        <div className={shouldShowTabs ? "flex-between" : "heading-no-tabs"}>
          <Typography variant="h1">{catalogStore.activeCatalog.catalogShowedName}</Typography>
          <div className="flex box-buttons">
            {catalogStore.activeCatalog.catalogName === "printOnDemand" && authorizationStore.user.isAdmin && (
              <Button type="primary" onClick={onCreateTemplateHandler}>
                {t("pages.catalog.createNewTemplate")}
              </Button>
            )}
            <FilterBlock onCatalogToggle={catalogToggleHandler} onFilterClick={toggleFilters} />
          </div>
        </div>
        {shouldShowTabs && (
          <Affix style={{ background: "#fff" }} onChange={onTabsBecameFixed} offsetTop={70}>
            <div style={{ width: tabsFixed ? "calc(100% - 80px)" : "100%" }}>
              {!catalogStore.activeCatalog.isLoading && (
                <MuiTabs
                  ariaLabel={t("pages.catalog.zoneFilterLabel")}
                  activeKey={Number(catalogStore.activeCatalog.zoneFilter)}
                  onChange={catalogStore.setZoneFilter}
                  options={catalogStore.activeCatalogZoneFilters}
                />
              )}
            </div>
          </Affix>
        )}
      </div>
      <div className="catalog__content">
        {printOnDemand && (
          <PrintOnDemandCatalogList
            loading={catalogStore.activeCatalog.isLoading || departmentsStore.isLoading}
            templates={catalogStore.activeCatalog.materials}
          />
        )}
        {!printOnDemand && <CatalogList loading={catalogStore.activeCatalog.isLoading} dataSource={catalogStore.activeCatalog.filteredMaterials} />}
      </div>
      <FiltersMenu isOpen={isFiltersOpen} onReset={catalogStore.clearFilters} onApply={onApplyHandler} onClose={toggleFilters} />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  menuStore: rootStore.menuStore,
  authorizationStore: rootStore.authorizationStore,
  tagsStore: rootStore.tagsStore,
  vendorsStore: rootStore.vendorsStore,
  catalogStore: rootStore.catalogStore,
  locationStore: rootStore.locationStore,
  languagesStore: rootStore.languagesStore,
  departmentsStore: rootStore.departmentsStore,
  colorProfilesStore: rootStore.colorProfilesStore
}))(observer(Catalog));
