import React from "react";

import Spin from "../spin";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FullPageLoader = () => {
  const { t } = useTranslation();

  return (
    <Spin tip={t("loading")} size="large" role="alert" aria-busy="true">
      <div className="loading-wrapper" />
    </Spin>
  );
};

export default FullPageLoader;
