import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const MuiTypography = withStyles({
  subtitle1: ({ styleoverrides }) => {
    const overrides = styleoverrides && styleoverrides.subtitle1 ? styleoverrides.subtitle1 : {};
    return {
      fontSize: 14,
      fontWeight: "bold",
      color: "#4A4A4A",
      ...overrides
    };
  },
  subtitle2: ({ styleoverrides }) => {
    const overrides = styleoverrides && styleoverrides.subtitle2 ? styleoverrides.subtitle2 : {};

    return {
      fontSize: 12,
      color: "#4A4A4A",
      opacity: 0.5,
      ...overrides
    };
  },
  body1: {
    display: "block",
    fontSize: 16,
    color: "#4A4A4A",
    opacity: 0.5
  }
})(Typography);

const StyledTypography = props => <MuiTypography {...props} />;

export default StyledTypography;
