import React from "react";
import { inject, observer } from "mobx-react";
import { InputNumber } from "antd";

import MaterialSelect from "../../../../components/formComponents/materialSelect";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const ColumnTitle = ({ filter, filterName }) => {
  const { t } = useTranslation();
  const options = filter.values
    .map(({ value, title }) => ({
      id: value,
      value,
      label: title
    }))
    .filter(({ label }) => label);

  return (
    <div className="column-title">
      {filter.isIdFilter && (
        <>
          <label style={{ display: "none" }} htmlFor="order-id-filter">
            {t("pages.ordersHistory.orderId")}
          </label>
          <InputNumber
            id="order-id-filter"
            type={"number"}
            placeholder={t("pages.ordersHistory.all")}
            aria-label={t("pages.ordersHistory.orderIdLabel")}
            value={filter.selectedValues}
            onChange={filter.onChangeHandler}
          />
        </>
      )}
      {!filter.isIdFilter && (
        <MaterialSelect
          multiple
          name={filterName}
          placeholder={t("pages.ordersHistory.all")}
          onChange={values => {
            filter.onChangeHandler(values.map(item => item.toString()));
          }}
          value={[
            ...filter.selectedValues.map(id => {
              return options.find(item => item.id.toString() === id.toString());
            })
          ]}
          options={options}
          styles={{
            root: {
              maxWidth: 110
            }
          }}
        />
      )}
    </div>
  );
};

export default inject(({ rootStore }) => ({
  ordersHistoryStore: rootStore.ordersHistoryStore
}))(observer(ColumnTitle));
