import { action, observable } from "mobx";

import { styles } from "./settings";
import { tagNameTransformer, generateId } from "../../utils";

export default class FinancialCenterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable map = {};
  @observable mapMarker = {};
  @observable mapID = generateId();
  @observable mapSettings = {
    zoom: 12,
    mapTypeControl: false,
    styles
  };

  @action.bound initMap(coords) {
    this.map = new window.google.maps.Map(document.getElementById(this.mapID), {
      center: coords,
      ...this.mapSettings
    });
    this.setMapMarker(coords);

    window.google.maps.event.addListener(this.map, "tilesloaded", function(e) {
      //ada
      setTimeout(() => {
        const map = document.querySelector(".map-wrapper");
        if (map) {
          const mapImgs = map.querySelectorAll("img");
          mapImgs.forEach((img, index) => {
            if (!img.alt || img.alt === "") {
              img.alt = `Google Maps Image - ${index}`;
              img.removeAttribute("role");
            }
          });
          const maps = document.querySelectorAll("map");
          maps.forEach((map, index) => {
            const mapAreas = map.querySelectorAll("area");
            mapAreas.forEach((area, indexArea) => {
              area.alt = `map-${index}-area-${indexArea}`;
            });
          });
          const emptyP = document.querySelector(".gm-style-pbt");

          if (emptyP) {
            tagNameTransformer(emptyP, "span");
          }
        }
      }, 100);
    });
  }

  @action.bound setMapMarker(coords) {
    this.mapMarker = new window.google.maps.Marker({
      position: coords,
      map: this.map
    });
  }
}
