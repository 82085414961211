import React, { useState } from "react";
import { Card as AntCard } from "antd";
import { useHistory } from "react-router-dom";

import { templateStates } from "../../constants";
import { PrintOnDemandOrderModal } from "../modals";
import { Title, Description, Cover } from "./components";
import DeleteTemplateModal from "../modals/deleteTemplateModal";

const PrintOnDemandMaterialCard = ({ template }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { id, name, photo } = template;
  const alt = photo && photo.description ? photo.description : `${name}`;

  const toggleModalVisibility = async () => {
    await template.getPdfFields();
    setVisible(!visible);
  };

  const onRestoreHandler = () => {
    template.updateState(templateStates.active);
  };

  const onDeleteHandler = () => {
    setConfirmDeleteVisible(true);
  };

  const onEditHandler = () => {
    history.push(`/templates/${template.id}/edit`);
  };

  const onConfirmDeleteHandler = () => {
    template.updateState(templateStates.deleted);
    setConfirmDeleteVisible(false);
  };

  const onCancelDeleteHandler = () => {
    setConfirmDeleteVisible(false);
  };

  return (
    <>
      <AntCard
        className="material-card"
        hoverable
        bordered={false}
        style={{ borderRadius: 4 }}
        cover={<Cover id={id} url={template.mediumPhoto} alt={alt} deleted={template.isDeleted} onRestore={onRestoreHandler} />}
        onClick={toggleModalVisibility}
      >
        <AntCard.Meta title={<Title title={name} />} description={<Description department={template.departmentName} language={template.languageName} />} />
      </AntCard>
      {visible && (
        <PrintOnDemandOrderModal
          template={template}
          visible={visible}
          onCloseModal={toggleModalVisibility}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          onRestore={onRestoreHandler}
        />
      )}
      <DeleteTemplateModal visible={confirmDeleteVisible} materialName={template.name} onCancel={onCancelDeleteHandler} onSubmit={onConfirmDeleteHandler} />
    </>
  );
};

export default PrintOnDemandMaterialCard;
