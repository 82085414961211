import React, { Component } from "react";
import { Form } from "antd";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";

import Icon from "../../icon";
import Avatar from "../../avatar";
import Button from "../../button";
import { ReasonsSelect } from "../../selects";
import { proxy } from "../../../common/apiUrls";
import WarningNotification from "../../warningNotification";
import TextareaWithCounter from "../../formComponents/textareaWithCounter";
import AssociatedMaterials from "../../associatedMaterials";
import { materialOrderForm, buttonWidth } from "./settings";
import { getMaterialNameByType } from "../../../common/models/catalog/settings";
import InputNumberAccessibility from "../../formComponents/inputNumberAccessibility";
import PreviewModal from "../../modals/previewModal";
import ProgressButton from "../../progressButton";
import { numberMinPositiveValuePromise } from "../../../utils";

import "./styles.scss";
import { withTranslation } from "react-i18next";

const { formName, layout, quantity, reason, comment } = materialOrderForm;

@inject("rootStore")
@observer
class CheckoutOrderForm extends Component {
  constructor() {
    super();
    this.state = { showPreview: false };
  }

  onDeleteHandler = () => {
    this.props.onDelete(this.props.material.id);
  };

  checkIfZoneAndSubZoneExist = () => {
    const { zone, subzone } = this.props.material;
    return zone && zone.name && subzone && subzone.name;
  };

  onQuantityChangeHandler = quantity => {
    this.props.material.resetReason();
    this.props.form.setFieldsValue({ [reason.name]: null });
    this.props.material.onQuantityChange(quantity);
  };

  onShowPreviewHandler = async () => {
    // await this.props.material.downloadPdf();
    this.setState({ showPreview: true });
  };

  onPreviewCancelHandler = () => {
    this.setState({ showPreview: false });
  };

  render() {
    const { rootStore, material, form, formId, t } = this.props;
    const {
      item: { code, merch_name, name, item_type, fixture_category, photo, content, generatedPdfForm, departmentId }
    } = material;
    const isPodTemplate = item_type === "PodTemplate";
    const isQuantityDisabled = item_type === "MerchItemOccurence";
    const alt = photo && photo.description ? photo.description : `${merch_name}-${code}`;
    const department = rootStore.departmentsStore.findById(departmentId);
    const templatePreview =
      generatedPdfForm && generatedPdfForm.previewImageMedium.url ? generatedPdfForm.previewImageMedium.url : content && content.sample.thumbnailPath;
    const photoPreviewUrl = (photo && `${proxy}${photo.medium_thumb}`) || templatePreview;
    return (
      <div className="checkout-materials-list-item">
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <div className="left-block">
              <Button onClick={this.onDeleteHandler} className="btn-delete" type="link" aria-label={t("checkoutOrderForm.deleteItemLabel")}>
                <Icon type="cancel" />
              </Button>
              <Avatar size={184} src={photoPreviewUrl} alt={alt} />
              {isPodTemplate && (
                <div className="zoom-out-wrapper">
                  <ProgressButton variant="contained" color="secondary" aria-label={t("checkoutOrderForm.zoomOutLabel")} onClick={this.onShowPreviewHandler}>
                    <Icon type="arrows-alt" />
                  </ProgressButton>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={8} lg={9}>
            <Form layout={layout} className="right-block">
              <span className="right-block__category block bold">{merch_name || name}</span>
              <span className="right-block__code block">{code || (department && department.name)}</span>
              {this.checkIfZoneAndSubZoneExist() && (
                <span className="right-block__subcategories block bold">{`${material.zone.name}: ${material.subzone.name}`}</span>
              )}
              <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={4} lg={3} xl={2} item>
                  <Form.Item help={false}>
                    {form.getFieldDecorator(quantity.name, {
                      initialValue: material.currentQuantity,
                      rules: [{ required: true, message: t("checkoutOrderForm.quantity.rules.required") }, numberMinPositiveValuePromise]
                    })(
                      <InputNumberAccessibility
                        className="quantity"
                        label={t("checkoutOrderForm.quantity.label")}
                        onChange={material.isTemplateItem ? null : this.onQuantityChangeHandler}
                        disabled={isQuantityDisabled}
                        min={1}
                        max={999}
                        _id={`${formName}_${quantity.name}_${formId}`}
                        antLabel
                      />
                    )}
                  </Form.Item>
                </Grid>
                <Grid xs={12} sm={6} md={8} lg={9} xl={10} item>
                  <Form.Item help={false}>
                    {form.getFieldDecorator(reason.name, {
                      rules: [{ required: true, message: t("checkoutOrderForm.reason.rules.required") }],
                      initialValue: {
                        value: JSON.stringify(material.selectedReason),
                        label: material.selectedReason.body,
                        id: material.selectedReason.id
                      }
                    })(
                      <ReasonsSelect
                        placeholder={t("checkoutOrderForm.reason.placeholder")}
                        onChange={material.setSelectedReason}
                        filters={[getMaterialNameByType(fixture_category || item_type), material.filterKey]}
                        label={{
                          text: t("checkoutOrderForm.reason.label"),
                          antLabel: true
                        }}
                        name={`${formId}-${reason.name}`}
                      />
                    )}
                  </Form.Item>
                </Grid>
              </Grid>
              <Form.Item help={false}>
                {form.getFieldDecorator(comment.name, {
                  initialValue: material.comments,
                  rules: [
                    {
                      required: material.selectedReason.comments_required,
                      message: t("checkoutOrderForm.comment.rules.required")
                    }
                  ]
                })(
                  <TextareaWithCounter
                    maxLength={2000}
                    placeholder={material.selectedReason.comments_placeholder}
                    _id={`${formName}_${comment.name}_${formId}`}
                    label={{ text: t("checkoutOrderForm.comment.label") }}
                  />
                )}
              </Form.Item>
            </Form>
          </Grid>
        </Grid>
        <div style={{ paddingLeft: buttonWidth }} className="associated-materials-wrapper">
          <AssociatedMaterials
            editable
            onDelete={this.props.onDelete}
            setOrderFormRef={this.props.setOrderFormRef}
            data={material.automatically_ordered_marketing_items}
          />
        </div>
        <div style={{ paddingLeft: buttonWidth, marginBottom: 23 }}>
          {material.warningNotifications.map((warning, i) => (
            <WarningNotification key={i} {...warning} onDelete={this.onDeleteHandler} />
          ))}
        </div>
        {isPodTemplate && (
          <PreviewModal
            visible={this.state.showPreview}
            useFrame
            onCancel={this.onPreviewCancelHandler}
            previewLink={content.sample.path}
            base64={material.item.generatedPdfForm.previewImageBig.url}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(
  Form.create({
    name: formName
  })(CheckoutOrderForm)
);
