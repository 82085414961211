import React from "react";
import { Button as AntButton } from "antd";
import "./styles.scss";

const Button = React.forwardRef(({
  type = "default",
  icon = "",
  children,
  onClick,
  ...restProps
}, ref) => (
  <AntButton ref={ref} type={type} icon={icon} onClick={onClick} {...restProps}>
    {children}
  </AntButton>
));

export default Button;
