import { action, observable, computed } from "mobx";
import pick from "lodash/pick";

import { DateService } from "../../services";
import { paginationConfig } from "./settings";
import { urls, proxy } from "../../common/apiUrls";
import { HistoryFilter, Order } from "../../common/models";
import { downloadFile } from "../../utils";

export default class OrdersHistoryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable pagination = paginationConfig;
  @observable orders = [];
  @observable datesToDisable = [];
  @observable isLoading = false;
  @observable tableFilters = [];
  @observable activeTabKey = "all";
  @observable downloadLinks = {
    csv_download_url: "",
    xlsx_download_url: ""
  };
  @observable reportFormats = [{ id: "xlsx_download_url", value: "xlsx" }, { id: "csv_download_url", value: "csv" }];

  @observable activeFilters = {
    finalization_time_lt: DateService.getUnixStringFromDate(DateService.getEndOfDay()),
    finalization_time_gt: DateService.getUnixStringFromDate(DateService.subtractFromDate(1, "year").startOf("day")),
    order_status_in: null
  };

  @action.bound async getOrdersHistory() {
    const { method, url } = urls.orders.history;
    await this.rootStore.makeRequest(this.saveOrders, method, url, {}, this.historyFilterQuery);
  }

  @computed get historyFilterQuery() {
    const activeLocation = this.rootStore.locationStore.activeLocation;
    const location_fcid_in = [activeLocation.fcid];
    const { pageSize, current } = this.pagination;
    const filters = this.shouldIncludeDateFilters ? this.activeFilters : pick(this.activeFilters, "order_status_in");
    return { q: { ...filters, location_fcid_in }, page: current, per_page: pageSize };
  }

  @computed get shouldIncludeDateFilters() {
    return this.activeTabKey !== "0";
  }

  @action.bound saveOrders({ orders, total, ...restProps }) {
    this.downloadLinks = restProps;
    this.pagination.total = total;
    this.orders = orders.map((order, index) => new Order({ store: this, ...order, index }));
  }

  @action.bound async onReportFormatChangeHandler({ id: key, value: fileExtension }) {
    const method = "get";
    const url = `${proxy}${this.downloadLinks[key]}`;

    await this.rootStore.makeRequest(file => downloadFile(file, "report", fileExtension), method, url, {});
  }

  @action.bound hasActiveFilters() {
    return this.tableFilters.some(filter => filter.hasActiveFilters);
  }

  @action.bound onTableChangeHandler(_, current) {
    this.pagination.current = current;
  }

  @action.bound async getOrdersHistoryFilters() {
    const { method, url } = urls.orders.filters;
    await this.rootStore.makeRequest(this.saveFilterValues, method, url);
  }

  @action.bound saveFilterValues({ filters }) {
    this.tableFilters = filters.map(this.createFilterByName);
  }

  @action.bound createFilterByName(filter) {
    switch (filter.name) {
      case "user_id": {
        return new HistoryFilter({
          store: this,
          ...filter,
          type: "requestor",
          searchFilterKey: "full_name_cont_any"
        });
      }
      case "receiver_id": {
        return new HistoryFilter({
          store: this,
          ...filter,
          type: "receiver",
          searchFilterKey: "full_name_cont_any"
        });
      }
      case "location_fcid": {
        return new HistoryFilter({
          store: this,
          ...filter,
          type: "site_name",
          isLocationFilter: true,
          searchFilterKey: "site_name_cont_any"
        });
      }
      case "location_market": {
        return new HistoryFilter({
          store: this,
          ...filter,
          type: "market",
          isLocationFilter: true,
          searchFilterKey: "market_cont_any"
        });
      }
      default: {
        return new HistoryFilter({ store: this, ...filter });
      }
    }
  }

  @action.bound getFilterByName(filterName) {
    return this.tableFilters.find(({ name }) => name === filterName);
  }

  @action.bound setOrderStatusFilter(filter) {
    this.activeTabKey = filter;
    this.pagination.current = 1;
    this.activeFilters.order_status_in = filter === "all" ? null : filter.split(",");
  }

  @action.bound onChangeHandler(dates) {
    if (!dates.length) {
      this.resetDateFilters();
      return;
    }
    if (dates[1]) {
      this.activeFilters.finalization_time_lt = DateService.getUnixStringFromDate(dates[1].endOf("day"));
    }
    if (dates[0]) {
      this.activeFilters.finalization_time_gt = DateService.getUnixStringFromDate(dates[0].endOf("day"));
    }
  }

  @action.bound resetDateFilters() {
    this.activeFilters.finalization_time_gt = "";
    this.activeFilters.finalization_time_lt = "";
  }

  @action.bound onCalendarOpenChangeHandler(status) {
    if (!status) this.datesToDisable = [];
  }

  @action.bound onCalendarChangeHandler(dates) {
    this.datesToDisable = dates;
  }

  @action.bound clearFilters() {
    this.activeFilters = {
      finalization_time_lt: this.finalization_time_lt,
      finalization_time_gt: this.finalization_time_gt,
      order_status_in: this.order_status_in
    };
    this.tableFilters.map(filter => filter.setDefaultSelectedValues());
  }
}
