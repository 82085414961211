import { action, computed, observable } from "mobx";

import { urls } from "../../apiUrls";
import { blobToBase64 } from "../../../utils";
import { profileAssignments } from "../catalog/settings";

export default class CheckoutMaterial {
  constructor(props) {
    const autoMarketing = {
      automatically_ordered_marketing_items: props.automatically_ordered_marketing_items.map(material => {
        const reason = props.store.rootStore.reasonsStore.findReasonById(material.order_reason_id);
        return new CheckoutMaterial({ store: props.store.rootStore.checkoutStore, filterKey: reason.ordering_type, selectedReason: reason, ...material });
      })
    };
    Object.assign(this, props, autoMarketing, { currentQuantity: props.quantity });
  }

  @observable id = null;
  @observable zone = {};
  @observable subzone = {};
  @observable comments = "";
  @observable quantity = 0;
  @observable currentQuantity = 0;
  @observable item = {
    content: {
      main: { mimeType: "", origFileName: "", path: "" },
      sample: { mimeType: "", optimizedPath: "", path: "", thumbnailPath: "" }
    },
    generatedPdfForm: {
      id: null,
      pdf: {
        relativePath: "",
        originalFileName: "",
        url: ""
      },
      previewImageSmall: {
        relativePath: "",
        originalFileName: "",
        url: ""
      },
      previewImageMedium: {
        relativePath: "",
        originalFileName: "",
        url: ""
      },
      previewImageBig: {
        relativePath: "",
        originalFileName: "",
        url: ""
      }
    }
  };
  @observable warnings = {};
  @observable base64 = "";
  @observable isLoading = false;
  @observable selectedReason = {};
  @observable filterKey = "";

  @computed get warningNotifications() {
    return this.warnings.length ? this.warnings : [];
  }

  @action.bound onQuantityChange(quantity) {
    const { inProfile, notInProfile } = profileAssignments;

    this.currentQuantity = quantity;
    this.filterKey = this.currentQuantity <= this.quantity ? inProfile : notInProfile;
  }

  @action.bound setSelectedReason({ value }) {
    const { id } = JSON.parse(value);
    this.selectedReason = this.store.rootStore.reasonsStore.findReasonById(id);
  }

  @action.bound async downloadPdf() {
    const { url } = urls.downloadPdf;
    const { idToken } = await this.rootStore.authorizationStore.acquireTokenResponse();
    this.isLoading = true;
    fetch(`${url}/${this.item.id}?fileName=${this.item.content.main.origFileName}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(res => res.blob())
      .then(this.onLoadSuccess)
      .catch(e => this.store.rootStore.errorService.handleError(e));
    this.isLoading = false;
  }

  @action.bound async onLoadSuccess(data) {
    this.base64 = await blobToBase64(data);
  }

  @action.bound resetReason() {
    this.selectedReason = {};
  }

  @computed get isTemplateItem() {
    return this.filterKey === "pod_template";
  }

  @computed get hasPdfForm() {
    return Boolean(this.item.pdfFormFields && this.item.pdfFormFields.length);
  }
}
