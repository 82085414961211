import { isNumber } from "lodash";

export const transformData = data => {
  const { downloadable, orderable, editable, departmentId, languageId, colorProfileId, vendors, quantityMin, quantityMax, ...rest } = data;

  return {
    ...rest,
    downloadable: toBoolean(downloadable),
    orderable: toBoolean(orderable),
    editable: toBoolean(editable),
    departmentId: isNumber(departmentId) ? departmentId : departmentId.id,
    languageId: isNumber(languageId) ? languageId : languageId.id,
    colorProfileId: isNumber(colorProfileId) ? colorProfileId : colorProfileId.id,
    vendors: vendors ? { [isNumber(vendors) ? vendors : vendors.id]: { quantity: { min: quantityMin, max: quantityMax } } } : {},
  };
};

const toBoolean = val => {
  return val.value ? val.value === "YES" : val === "YES";
};
