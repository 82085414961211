import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import { Row, Col } from "antd";

import Map from "../../components/map";
import Button from "../../components/button";
import Sidebar from "../../components/sidebar";
import UserCard from "../../components/userCard";
import { ChangeRequestModal, ChangeRequestSent } from "../../components/modals";
import FinancialCenterCard from "../../components/financialCenterCard";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FinancialCenter = ({ supportStore, locationStore }) => {
  const { t } = useTranslation();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [changeRequestSentVisible, setChangeRequestSentVisible] = useState(false);

  function toggleModalVisibility() {
    setModalVisibility(!modalVisibility);
  }

  const onChangeRequestSubmitHandler = async data => {
    supportStore.sendSupportOrder({
      data,
      location_fcid: locationStore.fullLocationData.fcid,
      order_type: "change_fc_info"
    });
    setChangeRequestSentVisible(true);
  };

  return (
    <>
      <Row className="location-page">
        <div className="heading flex-between">
          <Typography variant="h1">{t("pages.financialCenter.title")}</Typography>
          <Button type="primary" size="large" onClick={toggleModalVisibility}>
            {t("pages.financialCenter.changeRequest")}
          </Button>
        </div>
        <Col span={5}>
          <Sidebar className="sidebar-cards-shadow">
            <FinancialCenterCard showDownloadButtons />
            <UserCard />
          </Sidebar>
        </Col>
        <Col span={19}>
          <Map />
        </Col>
      </Row>
      {modalVisibility && <ChangeRequestModal visible={modalVisibility} onCancel={toggleModalVisibility} onSubmitCb={onChangeRequestSubmitHandler} />}
      <ChangeRequestSent visible={changeRequestSentVisible} onCancel={() => setChangeRequestSentVisible(false)} />
    </>
  );
};

export default inject(({ rootStore }) => ({
  supportStore: rootStore.supportStore,
  locationStore: rootStore.locationStore
}))(observer(FinancialCenter));
