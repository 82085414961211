import React from "react";
import { inject, observer } from "mobx-react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";

import MaterialMenu from "../materialMenu";

const MenuDrawer = ({ menuStore, variant, classes, className }) => {
  const withToolbar = variant === "permanent";

  return (
    <Drawer className={className} classes={classes} variant={variant} anchor="left" open={!menuStore.menuState.collapsed} onClose={menuStore.callbackCollapse}>
      {withToolbar && <Toolbar />}
      <MaterialMenu />
    </Drawer>
  );
};

export default inject(({ rootStore }) => ({
  menuStore: rootStore.menuStore,
}))(observer(MenuDrawer));
