import React from "react";
import { Affix as AntAffix } from "antd";

const Affix = ({ offsetTop = 0, style, children, onChange }) => (
  <AntAffix offsetTop={offsetTop} style={style} onChange={onChange}>
    {children}
  </AntAffix>
);

export default Affix;
