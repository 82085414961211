import React from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";

import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const MuiDownLoadBtn = withStyles(({ breakpoints }) => ({
  root: {
    height: 48,
    padding: "0 15px",
    background: "#DC1431",
    color: "white",
    [breakpoints.down("sm")]: {
      height: "auto"
    },
    "&:hover, &:focus": {
      color: "#DC1431",
      border: "1px solid #DC1431",
      background: "white"
    }
  }
}))(Button);

const DownloadReport = ({ ordersHistoryStore }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuOption = (id, value) => {
    ordersHistoryStore.onReportFormatChangeHandler({ id, value });
  };

  return (
    <>
      <MuiDownLoadBtn
        startIcon={<GetAppIcon />}
        aria-controls="simple-menu"
        aria-expanded={Boolean(anchorEl)}
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
      >
        <span>{t("downloadReport.buttonText")}</span>
      </MuiDownLoadBtn>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        MenuListProps={{
          style: {
            minWidth: 200
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {ordersHistoryStore.reportFormats.map(({ id, value }) => (
          <MenuItem key={id} onClick={() => handleClickMenuOption(id, value)}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default inject(({ rootStore }) => ({
  ordersHistoryStore: rootStore.ordersHistoryStore
}))(observer(DownloadReport));
