import { action, computed, observable } from "mobx";

import { urls } from "../../apiUrls";
import axios from "axios";
import of from "await-of";

export default class ServerStatusStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable status = null;

  @action.bound async getServerStatus() {
    const mockResponseReject = () => {
      return new Promise((resolve, reject) => {
        setTimeout(function() {
          reject({ response: { status: 400, data: { code: "404", message: "Web application is down" } } });
        }, 2000);
      });
    };
    const mockResponseResolve = () => {
      return new Promise(resolve => {
        setTimeout(function() {
          resolve({ response: { status: 200, data: { code: "200", message: "Web application is okey" } } });
        }, 2000);
      });
    };

    const { url } = urls.status;

    if (!this.isServerMaintenance) {
      const [response, error] = process.env.REACT_APP_ENV === "development" ? await of(mockResponseResolve()) : await of(axios.create().get(url));
      if (error) {
        this.status = (error.response && error.response.status) || 200;
      }
      if (response) {
        this.status = response.status;
      }
    }
  }

  @computed get isServerStatusOk() {
    return this.status === 200;
  }

  @computed get isServerMaintenance() {
    return this.status === 400;
  }
}
