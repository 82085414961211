import { observable, action } from "mobx";

import { CustomOrderForm } from "../../common/models";
import { urls } from "../../common/apiUrls";

export default class CustomOrderStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.customOrders.push(new CustomOrderForm({ store: this }));
  }

  @observable customOrders = [];
  @observable groups = [];
  @observable zones = [];
  @observable subzones = [];
  @observable isLoading = false;

  @action.bound async cleanCustomOrders() {
    this.customOrders = [new CustomOrderForm({ store: this })];
  }

  @action.bound async getCustomDetails() {
    const { method, url } = urls.orders.getCustomDetails;
    return await this.rootStore.makeRequest(this.saveData, method, url);
  }

  @action.bound async saveData(customDetails) {
    Object.keys(customDetails).forEach(this.saveDetailsByGroups(customDetails));
  }

  @action.bound async sendCustomOrder(data, cb) {
    const { method, url } = urls.orders.sendCustomOrders;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onSendCustomOrderSuccess, method, url, {}, { custom_orders: [data] });
    this.isLoading = false;
    if (cb) cb();
  }

  @action.bound async onSendCustomOrderSuccess(data) {
    this.rootStore.feedbackStore.setActiveFeedbackExist(data.orders[0].active_feedback_exists);
    this.rootStore.feedbackStore.setOrderId(data.orders[0].id);
  }

  @action.bound saveDetailsByGroups(array) {
    return key => (this[key] = array[key].map(item => (item.id ? { ...item, value: item.name } : { id: item, value: item })));
  }

  @action.bound onDeleteCustomOrderForm(formId) {
    this.customOrders = this.customOrders.filter(({ id }) => id !== formId);
  }

  @action.bound addCustomOrderForm() {
    this.customOrders.push(new CustomOrderForm({ store: this }));
  }
}
