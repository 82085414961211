import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useIdleTimer } from "react-idle-timer";

import FullPageLoader from "../fullPageLoader";
import PickLocation from "../../pages/pickLocation";
import UnauthorizedError from "../../pages/unauthorizedError";
import NoPrimaryLocationError from "../../pages/noPrimaryLocationError";
import Maintenance from "../../pages/maintenance/maintenance";

const ProtectedRouteWrapper = ({ authorizationStore, locationStore, errorService, children, serverStatusStore }) => {
  const { locationInitialized } = locationStore;

  const hasActiveLocation = Boolean(locationStore.activeLocation && locationStore.activeLocation.fcid);

  const hasPrimaryLocation = Boolean(authorizationStore.user.primaryLocation);

  const hasLocations = hasActiveLocation || hasPrimaryLocation;

  const handleOnIdle = () => {
    if (authorizationStore.user.id && !(process.env.REACT_APP_ENV === "development")) {
      authorizationStore.logout();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, //15 === min
    onIdle: handleOnIdle,
    debounce: 500
  });

  useEffect(() => {
    const getUserData = async () => {
      await serverStatusStore.getServerStatus();
      await authorizationStore.getUserProfile();
      await locationStore.getActiveLocation();
    };

    getUserData();
  }, [authorizationStore, locationStore]);

  if (serverStatusStore.isServerMaintenance) {
    return <Maintenance />;
  }

  if (!authorizationStore.user.id && !errorService.unauthorized) {
    return <FullPageLoader />;
  }
  if (errorService.unauthorized) {
    return <UnauthorizedError />;
  }
  if (errorService.noPrimaryLocation) {
    return <NoPrimaryLocationError />;
  }
  if (!locationInitialized || !hasLocations) {
    return <PickLocation />;
  }
  return locationStore.activeLocation && children;
};

export default inject(({ rootStore }) => ({
  errorService: rootStore.errorService,
  locationStore: rootStore.locationStore,
  authorizationStore: rootStore.authorizationStore,
  serverStatusStore: rootStore.serverStatusStore
}))(observer(ProtectedRouteWrapper));
