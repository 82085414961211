import React from "react";
import "./styles.scss";
import Icon from "../../../components/icon";
import PrimaryButton from "../../../components/formComponents/primaryButton";
import Button from "../../../components/button";
import { useTranslation } from "react-i18next";

const DraftAlert = ({ onDiscard, onContinue, fc }) => {
  const { t } = useTranslation();
  return (
    <div className="draft-alert">
      <div className="draft-alert__content-wrapper">
        <div className="draft-alert__icon-wrapper">
          <Icon style={{ fontSize: 24, color: "#fff" }} type="cart" />
        </div>
        <div className="draft-alert__message-wrapper">
          {fc && fc.name && <b>{fc.name}</b>}
          <span>{t("pages.pickLocation.draftOrder")}</span>
        </div>
      </div>
      <div className="draft-alert__buttons-wrapper">
        <Button text="add to cart" color="secondary" variant="text" onClick={onDiscard}>
          {t("pages.pickLocation.discard")}
        </Button>
        <PrimaryButton
          style={{
            marginLeft: 12,
            height: 36,
            width: 184,
            fontSize: 14
          }}
          onClick={onContinue}
        >
          {t("pages.pickLocation.continue")}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default DraftAlert;
