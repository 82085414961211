import React from "react";
import { Spin as AntSpin } from "antd";

const Spin = ({ spinning, children, ...restProps }) => (
  <AntSpin spinning={spinning} {...restProps}>
    {children}
  </AntSpin>
);

export default Spin;
