import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Icon from "../../icon";
import Input from "../../input";
import { getURLSearchParam } from "../../../utils";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const GlobalSearch = ({ onPressEnter, searchInputRef }) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const searchStr = getURLSearchParam("search", search);
  const [value, setValue] = useState(searchStr);

  useEffect(() => {
    setValue(searchStr);
  }, [search, searchStr]);

  const onChangeHandler = e => {
    setValue(e.target.value);
  };

  const onPressEnterHandler = () => {
    onPressEnter(value);
  };

  return (
    <Input
      ref={searchInputRef}
      className="global-search"
      placeholder={t("globalSearch.placeholder")}
      value={value}
      aria-label={t("globalSearch.globalSearchLabel")}
      prefix={<Icon type="search" size={21} />}
      onChange={onChangeHandler}
      onPressEnter={onPressEnterHandler}
    />
  );
};

export default GlobalSearch;
