import React from "react";

import { DateService } from "../../services";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const OrderDate = () => {
  const { t } = useTranslation();
  return (
    <div className="wrapper flex-between">
      <span className="wrapper__title">{t("orderDate.title")}</span>
      <span className="wrapper__value">{DateService.getFormattedDate(new Date(), "MM/DD/YYYY")}</span>
    </div>
  );
};

export default OrderDate;
