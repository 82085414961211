import React, { forwardRef, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { components } from "react-select";

import Icon from "../../icon";

import RCSelect from "../../formComponents/rc-select";

import "./styles.scss";

const CustomInput = props => <components.Input {...props} aria-label={t("selects.locationSelectLabel")}></components.Input>;

const customControl = ({ children, ...props }) => (
  <components.Control {...props}>
    <Icon type="location" style={{ marginLeft: 5, fontSize: 24, color: "white" }} />
    {children}
  </components.Control>
);

const customSingleValue = ({ children, ...props }) => {
  const { value } = props.data;
  const parsedValue = JSON.parse(value);
  if (!parsedValue) return null;
  const { fcid } = parsedValue;

  return (
    <components.SingleValue {...props}>
      {fcid} ({children})
    </components.SingleValue>
  );
};

const customDropdownIndicator = props => {
  const { isLoading } = props.selectProps;
  const iconStyle = {
    marginRight: 8,
    fontSize: 24,
    color: "white"
  };
  return <Icon style={iconStyle} type={isLoading ? "loading" : "arrowDown"} />;
};

const CustomOption = ({ children, ...props }) => {
  const { value } = props.data;
  const { fcid, name } = JSON.parse(value);

  const innerOptionStyle = {
    display: "flex",
    flexDirection: "column"
  };

  const rowSpanStyle = {
    height: 20,
    lineHeight: "20px"
  };

  return (
    <components.Option {...props}>
      <div style={innerOptionStyle}>
        <span style={rowSpanStyle}>{name}</span>
        <span style={rowSpanStyle}>{fcid}</span>
      </div>
    </components.Option>
  );
};

const LocationSelect = forwardRef(({ locationStore, onChange, ...props }, ref) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(3);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState(locationStore.defaultLocations);
  const [loading, setLoading] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    if (!dropdownVisibility || locations.length >= total) return;
    setLoading(true);
    locationStore.getLocations({ page, search }, onSuccess);
  }, [dropdownVisibility, search]);

  const onSuccess = ({ data, total }) => {
    if (locations.length >= total) setAllDataLoaded(true);
    setLocations([...locations, ...data.filter(deleteDefaultLocations)]);
    setTotal(total);
    setPage(page + 1);
    setLoading(false);
  };

  const deleteDefaultLocations = location => {
    return !locationStore.defaultLocationsIds.includes(location.id);
  };

  const options = locations.map(({ id, fcid, name, site_name }) => {
    return {
      label: name || site_name,
      value: JSON.stringify({ fcid, id, name: name || site_name }),
      id
    };
  });

  const onScrollHandler = () => {
    if (allDataLoaded) return;
    setLoading(true);
    locationStore.getLocations({ page, search }, onSuccess);
  };

  const onSearchHandler = val => {
    setLocations(locationStore.defaultLocations);
    setPage(1);
    setAllDataLoaded(false);
    setTotal(3);
    setSearch(val);
  };

  const onVisibilityChangeHandler = visibility => {
    setDropdownVisibility(visibility);
  };

  return (
    <>
      <RCSelect
        {...props}
        ref={ref}
        onMenuOpen={() => onVisibilityChangeHandler(true)}
        onMenuClose={() => onVisibilityChangeHandler(false)}
        onInputChange={onSearchHandler}
        options={options}
        isLoading={loading}
        isSearchable={true}
        onChange={onChange}
        onMenuScrollToBottom={onScrollHandler}
        optionLabelProp="location"
        components={{
          Control: customControl,
          SingleValue: customSingleValue,
          LoadingIndicator: null,
          DropdownIndicator: customDropdownIndicator,
          Option: CustomOption,
          Input: CustomInput
        }}
        styles={{
          control: (p, s) => {
            return {
              ...p,
              height: 44,
              backgroundColor: "transparent",
              border: "1px solid rgba(255, 255, 255, .4) !important",
              boxShadow: s.isFocused ? "0 0 6px #D4EFFC !important" : "none"
            };
          },
          valueContainer: (p, _) => {
            return {
              ...p,
              height: "100%",
              padding: "0 8px",
              paddingLeft: 0
            };
          },
          singleValue: (p, _) => {
            return {
              ...p,
              display: "flex",
              alignItems: "center",
              color: "white"
            };
          },
          input: (p, _) => {
            return {
              ...p,
              color: "white",
              margin: 0
            };
          },
          option: (provided, state) => {
            return {
              ...provided,
              height: 54,
              background: state.isSelected ? "#3586D6" : state.isFocused ? "rgba(212, 239, 252, 0.51)" : "white",
              cursor: "pointer",
              color: state.isSelected && !state.isFocused ? "white" : "#4a4a4a",
              "&:hover": {
                background: state.isSelected ? "#3586D6" : "rgba(212, 239, 252, 0.51)",
                color: "#4a4a4a"
              }
            };
          }
        }}
      />
    </>
  );
});

export default inject(({ rootStore }) => ({
  locationStore: rootStore.locationStore
}))(observer(LocationSelect));
