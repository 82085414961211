import React, { useEffect, useState } from "react";
import { List } from "antd";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import OrderDetails from "../../orderDetails";
import { useTranslation } from "react-i18next";

const PlaceOrderModal = ({ onCancel, visible, checkoutStore, feedbackStore }) => {
  const { t } = useTranslation();
  const defaultActions = [
    {
      text: t("modals.openHistory"),
      color: "secondary",
      onClick: goToOrdersHistory
    },
    {
      text: t("modals.gotIt"),
      autoFocus: true,
      primary: true,
      onClick: goToCatalog
    }
  ];
  const [actions, setActions] = useState(defaultActions);
  const history = useHistory();

  useEffect(() => {
    if (feedbackStore.activeFeedbackExist) {
      setActions([
        {
          text: t("modals.survey"),
          color: "secondary",
          onClick: goToFeedback
        },
        ...defaultActions
      ]);
    }
  }, [feedbackStore.activeFeedbackExist]);

  function goToCatalog() {
    checkoutStore.getDraftOrder();
    onCancel();
    history.push("/catalog");
  }

  function goToOrdersHistory() {
    checkoutStore.getDraftOrder();
    onCancel();
    history.push("/orders-history");
  }

  function goToFeedback() {
    checkoutStore.getDraftOrder();
    onCancel();
    history.push("/survey");
  }

  const content = (
    <div>
      {t("modals.placeOrderContent")}
      <List
        className="checkout-materials-list"
        dataSource={checkoutStore.draftOrder.orders}
        renderItem={order => <OrderDetails key={order.id} order={order} />}
      />
      {feedbackStore.activeFeedbackExist && <span>{t("pages.feedback.prompt")}</span>}
    </div>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.placeOrderTitle")} {...settings} />;
};

export default inject(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore,
  feedbackStore: rootStore.feedbackStore
}))(observer(PlaceOrderModal));
