import React, { Component } from "react";
import { Form } from "antd";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";

import Icon from "../../icon";
import Button from "../../button";
import { ReasonsSelect } from "../../selects";
import { customRequestForm } from "./settings";
import TextareaWithCounter from "../../formComponents/textareaWithCounter";
import MaterialSelect from "../../formComponents/materialSelect";
import MaterialRadioGroup from "../../formComponents/materialRadioGroup";
import InputNumberAccessibility from "../../formComponents/inputNumberAccessibility";
import { numberMinPositiveValuePromise } from "../../../utils";

import "./styles.scss";
import { withTranslation } from "react-i18next";

const { layout, materialType, reasonSelect, quantityNumber, commentField, categorySelect, zoneSelect, subZoneSelect } = customRequestForm;

@inject("rootStore")
@observer
class CustomOrderItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedDetails: false
    };
  }

  onDeleteFormHandler = () => {
    this.props.onDeleteCustomOrder(this.props.formId);
  };

  onMaterialTypeChangeHandler = e => {
    this.props.customOrder.onMaterialTypeChange(e);
    this.props.form.resetFields([reasonSelect.name]);
  };

  toggleDetails = () => {
    this.setState({
      collapsedDetails: !this.state.collapsedDetails
    });
  };

  onZoneChangeHandler = ({ id: zoneId }) => {
    this.props.customOrder.setSelectedZoneId(zoneId);
    this.props.form.resetFields([subZoneSelect.name]);
  };

  filteredSubzonesByZone = () => {
    return this.props.subzones.filter(subzone => subzone.zone_id === this.props.customOrder.selectedZoneId);
  };

  render() {
    const { collapsedDetails } = this.state;
    const { formNumber, form, customOrder, shouldShowDeleteBtn, zones, groups, t } = this.props;
    const { materialTypes, selectedZoneId, activeMaterialType } = customOrder;

    const materialTypesRules = [{ required: true, message: t("customOrderItemForm.materialType.rules.required") }];
    const reasonSelectRules = [{ required: true, message: t("customOrderItemForm.reasonSelect.rules.required") }];
    const commentFieldRules = [{ required: true, message: t("customOrderItemForm.commentField.rules.required") }];

    return (
      <div className="material-details">
        <div className="flex flex-between">
          <h2>{t("customOrderItemForm.materialDetails", { formNumber: formNumber })}</h2>
          {shouldShowDeleteBtn && (
            <Button autoFocus aria-label={t("customOrderItemForm.deleteItemLabel")} type="link" onClick={this.onDeleteFormHandler}>
              <Icon className="red" type="cancel" />
            </Button>
          )}
        </div>
        <Form layout={layout}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form.Item help={false}>
                {form.getFieldDecorator(materialType.name, {
                  rules: materialTypesRules,
                  initialValue: activeMaterialType && activeMaterialType.id ? activeMaterialType.id : null
                })(
                  <MaterialRadioGroup
                    onChange={this.onMaterialTypeChangeHandler}
                    options={materialTypes}
                    {...materialType}
                    label={t("customOrderItemForm.materialType.label")}
                    rules={materialTypesRules}
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={7} md={6} sm={12}>
                  <Form.Item help={false}>
                    {form.getFieldDecorator(reasonSelect.name, {
                      rules: reasonSelectRules
                    })(
                      <ReasonsSelect
                        placeholder={t("customOrderItemForm.reasonSelect.placeholder")}
                        disabled={!customOrder.activeMaterialType.title}
                        onChange={customOrder.setSelectedReason}
                        filters={[customOrder.activeMaterialType.value, "not_in_profile"]}
                        label={{ text: t("customOrderItemForm.reasonSelect.label") }}
                        name={`form_${formNumber}_${reasonSelect.name}`}
                      />
                    )}
                  </Form.Item>
                </Grid>
                <Grid item lg={5} md={6} sm={12}>
                  <Form.Item help={false}>
                    {form.getFieldDecorator(quantityNumber.name, {
                      initialValue: 1,
                      rules: [{ required: true, message: "quantity is required" }, numberMinPositiveValuePromise]
                    })(
                      <InputNumberAccessibility
                        _id={`form_${formNumber}_${quantityNumber.name}`}
                        label={t("customOrderItemForm.quantityNumber.label")}
                        min={1}
                        max={999}
                      />
                    )}
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Form.Item help={false}>
            {form.getFieldDecorator(commentField.name, {
              rules: commentFieldRules
            })(
              <TextareaWithCounter
                _id={`form_${formNumber}_${commentField.name}`}
                maxLength={2000}
                placeholder={customOrder.selectedReason.comments_placeholder}
                label={{ text: t("customOrderItemForm.commentField.label") }}
              />
            )}
          </Form.Item>
          <Button onClick={this.toggleDetails} aria-expanded={collapsedDetails} size="small" type="link">
            {collapsedDetails ? t("customOrderItemForm.lessDetails") : t("customOrderItemForm.moreDetails")}
          </Button>
          {collapsedDetails && (
            <div className="form__details">
              <Grid container spacing={2}>
                <Grid item lg={6} md={4} sm={12}>
                  <Form.Item>
                    {form.getFieldDecorator(categorySelect.name, { preserve: true })(
                      <MaterialSelect
                        autoFocus
                        name={`form_${formNumber}_${categorySelect.name}`}
                        valueKey="value"
                        label={{
                          text: t("customOrderItemForm.categorySelect.label")
                        }}
                        options={groups.map(option => {
                          return {
                            id: option.id,
                            label: option.value,
                            value: option.value
                          };
                        })}
                        placeholder={t("customOrderItemForm.categorySelect.placeholder")}
                      />
                    )}
                  </Form.Item>
                </Grid>
                <Grid item lg={2} md={4} sm={12}>
                  <Form.Item>
                    {form.getFieldDecorator(zoneSelect.name, { preserve: true })(
                      <MaterialSelect
                        name={`form_${formNumber}_${zoneSelect.name}`}
                        valueKey="value"
                        label={{
                          text: t("customOrderItemForm.zoneSelect.label")
                        }}
                        options={zones.map(option => {
                          return {
                            id: option.id,
                            label: option.value,
                            value: option.id
                          };
                        })}
                        placeholder={t("customOrderItemForm.zoneSelect.placeholder")}
                        onChange={this.onZoneChangeHandler}
                      />
                    )}
                  </Form.Item>
                </Grid>
                <Grid item lg={2} md={4} sm={12}>
                  <Form.Item>
                    {form.getFieldDecorator(subZoneSelect.name, { preserve: true })(
                      <MaterialSelect
                        name={`form_${formNumber}_${subZoneSelect.name}`}
                        valueKey="value"
                        label={{
                          text: t("customOrderItemForm.subZoneSelect.label")
                        }}
                        disabled={!selectedZoneId}
                        options={this.filteredSubzonesByZone().map(option => {
                          return {
                            id: option.id,
                            label: option.value,
                            value: option.id
                          };
                        })}
                        placeholder={t("customOrderItemForm.subZoneSelect.placeholder")}
                      />
                    )}
                  </Form.Item>
                </Grid>
              </Grid>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default withTranslation()(
  Form.create({
    name: "request"
  })(CustomOrderItemForm)
);
