import axios from "axios";

export default class InterceptorsService {
  constructor(rootStore) {
    this.rootStore = rootStore;

    axios.interceptors.request.use(this.addAuthHeaders, this.error);
    axios.interceptors.response.use(data => data, this.error);
  }

  addAuthHeaders = async data => {
    const { idToken } = await this.rootStore.authorizationStore.acquireTokenResponse();

    data.headers = {
      Authorization: `Bearer ${idToken}`,
      ...data.headers
    };

    return data;
  };

  error(error) {
    return Promise.reject(error);
  }
}
