import React, { Component } from "react";
import { Spin } from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { inject, observer } from "mobx-react";

import Button from "../../components/button";
import Sidebar from "../../components/sidebar";
import UserCard from "../../components/userCard";
import OrderDate from "../../components/orderDate";
import { PlaceCustomOrderModal } from "../../components/modals";
import RouteLeavingGuard from "../../components/routeLeavingGuard";
import FinancialCenterCard from "../../components/financialCenterCard";
import DeliveryInformationTitle from "../../components/deliveryInformationTitle";
import { CustomOrderItemForm } from "../../components/forms";
import { validateForms } from "../../utils";

import "./styles.scss";
import { withTranslation } from "react-i18next";

@inject("rootStore")
@observer
class CustomOrder extends Component {
  constructor(props) {
    super(props);
    this.customOrderForms = {};
    this.locationListener = null;
    this.state = {
      visible: false,
      orderWasSent: false
    };
  }

  componentDidMount() {
    this.props.rootStore.customOrderStore.getCustomDetails();
    this.locationListener = this.props.history.subscribe(location => {
      if (location.pathname !== "/custom-order") this.props.rootStore.customOrderStore.cleanCustomOrders();
    });
  }

  componentWillUnmount() {
    this.locationListener();
  }

  setRequestFormRef = form => {
    if (!form) return;
    this.customOrderForms[form.props.formId] = form;
  };

  onAddCustomOrder = customOrder => {
    this.props.rootStore.customOrderStore.addCustomOrderForm(customOrder);
  };

  onPlaceOrderHandler = async () => {
    const t = this.props.t;
    let isFormsValid = await validateForms(this.customOrderForms);
    if (!isFormsValid) {
      this.props.rootStore.alertsStore.createAlert({
        severity: "error",
        message: t("pages.customOrder.formValidationError")
      });
      return;
    }
    await this.props.rootStore.supportStore.sendCustomOrders(this.prepareDataForSending(), this.onOrderSentSuccess, this.customOrderForms);
  };

  prepareDataForSending = () => {
    const location_fcid = this.props.rootStore.locationStore.activeLocation.fcid;
    return Object.keys(this.customOrderForms).map(key => {
      const { form } = this.customOrderForms[key].props;
      const formValues = form.getFieldsValue(["order_type", "reason", "quantity", "comments", "group_description", "zone_id", "subzone_id"]);
      const { id, body } = JSON.parse(formValues.reason.value);
      formValues.reason = body;
      formValues.order_reason_id = id;
      formValues.subzone_id = formValues.subzone_id && Object.keys(formValues.subzone_id).length ? formValues.subzone_id.id : formValues.subzone_id;
      formValues.zone_id = formValues.zone_id && Object.keys(formValues.zone_id).length ? formValues.zone_id.id : formValues.zone_id;
      formValues.group_description =
        formValues.group_description && Object.keys(formValues.group_description).length ? formValues.group_description.value : formValues.group_description;
      return { ...formValues, location_fcid };
    });
  };

  onOrderSentSuccess = () => {
    this.setState({ orderWasSent: true, visible: !this.state.visible });
  };

  toggleModalVisibility = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  onOrderFormDelete = formId => {
    delete this.customOrderForms[formId];
    this.props.rootStore.customOrderStore.onDeleteCustomOrderForm(formId);
  };

  renderCustomOrderItem = (order, i) => {
    const { groups, zones, subzones, customOrders } = this.props.rootStore.customOrderStore;
    return (
      <CustomOrderItemForm
        customOrder={order}
        groups={groups}
        zones={zones}
        subzones={subzones}
        shouldShowDeleteBtn={customOrders.length > 1}
        formId={order.id}
        formNumber={i + 1}
        wrappedComponentRef={this.setRequestFormRef}
        onDeleteCustomOrder={this.onOrderFormDelete}
        key={order.id}
      />
    );
  };

  render() {
    const t = this.props.t;
    const { customOrderStore, supportStore, reasonsStore } = this.props.rootStore;

    const submitButton = {
      type: "primary",
      htmlType: "submit",
      title: t("pages.customOrder.placeOrder")
    };

    const addMaterialButton = {
      type: "default",
      htmlType: "button",
      title: t("pages.customOrder.addNewMaterial")
    };

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <div className="heading flex-between">
              <div>
                <RouteLeavingGuard when={!this.state.orderWasSent} />
                <Typography variant="h1">{t("pages.customOrder.title")}</Typography>
                <p className="heading__subtitle">{t("pages.customOrder.subTitle")}</p>
              </div>
              <Button
                size="large"
                type={submitButton.type}
                htmlType={submitButton.htmlType}
                onClick={this.onPlaceOrderHandler}
                disabled={this.props.rootStore.supportStore.pending}
              >
                {submitButton.title}
              </Button>
            </div>
            <div style={{ minHeight: 200 }} className="spinning-wrapper">
              {(customOrderStore.pending || supportStore.pending || reasonsStore.isLoading) && (
                <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />
              )}
              {!(customOrderStore.pending || supportStore.pending || reasonsStore.isLoading) && (
                <div className="list--materials">
                  {!customOrderStore.pending && !reasonsStore.isLoading && customOrderStore.customOrders.map(this.renderCustomOrderItem)}
                </div>
              )}
            </div>
            <div className="flex flex-between">
              <Button
                size="large"
                type={addMaterialButton.type}
                htmlType={addMaterialButton.htmlType}
                onClick={this.onAddCustomOrder}
                disabled={this.props.rootStore.supportStore.pending}
              >
                {addMaterialButton.title}
              </Button>
              <Button
                size="large"
                type={submitButton.type}
                htmlType={submitButton.htmlType}
                onClick={this.onPlaceOrderHandler}
                disabled={this.props.rootStore.supportStore.pending}
              >
                {submitButton.title}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Sidebar>
              <DeliveryInformationTitle />
              <OrderDate />
              <FinancialCenterCard editable={true} />
              <UserCard />
            </Sidebar>
          </Grid>
        </Grid>
        <PlaceCustomOrderModal visible={this.state.visible} onCancel={this.toggleModalVisibility} />
      </>
    );
  }
}

export default withTranslation()(CustomOrder);
