import React from "react";
import { useEffect } from "react";
import { Table as AntTable } from "antd";

import "./styles.scss";
import Icon from "../icon";
import Pagination from "../pagination";
import { useTranslation } from "react-i18next";

const Table = ({ columns, onChange, dataSource, expandedRowRender, pagination, rowKey, loading, size = "small", ...respProps }) => {
  const { t } = useTranslation();
  const emptyThToTd = () => {
    const firstTh = document.querySelector("th");
    const emptyThs = [firstTh];
    const filtersTr = document.querySelectorAll("thead tr")[1];
    if (filtersTr) {
      const filtersThs = filtersTr.querySelectorAll("th");
      emptyThs.push(filtersThs[2]);
    }

    emptyThs.forEach(th => {
      const td = document.createElement("td");
      for (let attr of th.attributes) {
        td.setAttribute(attr.name, attr.value);
      }
      td.innerHTML = th.innerHTML;
      td.classList.add("table-header__td");
      th.parentNode.insertBefore(td, th);
      th.parentNode.removeChild(th);
    });
  };

  const keyboardNavigation = ({ keyCode, target }) => {
    if (keyCode === 13 && target && target.click) {
      target.click();
    }
  };

  const paginationAda = () => {
    const pageBtns = document.querySelectorAll(".ant-pagination-item");
    pageBtns.forEach(btn => {
      const title = +btn.getAttribute("title");
      if (typeof title === "number" && !isNaN(title)) {
        btn.setAttribute("title", t("table.title", { title }));
      }
    });
  };

  const sortThsToBtns = () => {
    const sortThs = document.querySelectorAll(".ant-table-column-has-actions");
    sortThs.forEach(btn => {
      btn.setAttribute("tabindex", "0");
    });
  };

  useEffect(() => {
    paginationAda();
    const paginationContainer = document.querySelector(".ant-table-pagination");
    if (paginationContainer) {
      paginationContainer.addEventListener("keydown", keyboardNavigation);
      return () => paginationContainer.addEventListener("keydown", keyboardNavigation);
    }
  }, [pagination]);

  useEffect(() => {
    emptyThToTd();
    sortThsToBtns();
  }, []);

  useEffect(() => {
    const table = document.querySelector("table");
    if (!table) return;
    table.setAttribute("aria-describedby", "table-caption");
  }, []);

  useEffect(() => {
    const caretsUp = document.querySelectorAll(".anticon-caret-up");
    const caretsDown = document.querySelectorAll(".anticon-caret-down");
    if (!caretsUp || !caretsDown) return;
    [...caretsUp, ...caretsDown].forEach(caret => caret.setAttribute("aria-hidden", "true"));
  }, []);

  useEffect(() => {
    const columns = document.querySelectorAll(".ant-table-column-has-sorters");
    if (!columns) return;

    columns.forEach(col => {
      const columnName = col.classList[0].split("-").join(" ");
      col.setAttribute("aria-label", t("table.columnSortLabel", { columnName }));
    });
  }, []);

  const removeAriaSort = () => {
    const columns = document.querySelectorAll(".ant-table-column-has-sorters");
    columns.forEach(col => col.setAttribute("aria-sort", "none"));
  };

  const onTableChangeHandler = (...[, , sorters]) => {
    const { column, order } = sorters;
    removeAriaSort();
    if (!column || !order) return;
    const currentColumn = document.querySelector(`.${column.className}`);
    const caption = document.querySelector("#table-caption");
    caption.textContent = t("table.textContent", { title: column.title, order });
    currentColumn.setAttribute("aria-sort", `${order}ing`);
  };

  return (
    <>
      <caption style={{ position: "absolute", left: 9999, width: 1, height: 1 }} id="table-caption" aria-live="polite" />
      <AntTable
        columns={columns}
        size={size}
        onChange={onTableChangeHandler}
        loading={loading}
        rowKey={rowKey}
        pagination={pagination}
        dataSource={dataSource}
        expandedRowRender={expandedRowRender}
        expandIcon={({ expanded, onExpand, record }) => (
          <button
            aria-label={`${expanded ? t("table.collapseRowLabel") : t("table.expandRowLabel")} ${record.index + 1}`}
            className="table-expand-btn"
            aria-expanded={expanded}
            onClick={e => onExpand(record, e)}
          >
            <Icon type={expanded ? "minus" : "plus"} size={12} />
          </button>
        )}
        expandIconAsCell
        onHeaderRow={() => {
          return {
            onKeyDown: e => {
              const { key, target } = e;
              if (key === "Enter" && target && target.click) {
                target.click();
              }
            }
          };
        }}
        {...respProps}
      />
      <Pagination {...pagination} onChange={onChange} />
    </>
  );
};

export default Table;
