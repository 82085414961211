import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      alignItems: "flex-start"
    },
    image: { width: 150 },
    body: { marginLeft: 10 }
  })
);
