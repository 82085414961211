import React from "react";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

import Icon from "../../../../icon";
import { settings } from "../../settings";

import { useStyles } from "../../styles";

const DialogTitle = ({ onCloseModal, title, onDelete, onEdit, deleted, isAdmin }) => {
  const classes = useStyles();

  const onCloseHandler = () => {
    if (!onCloseModal) return;
    onCloseModal();
  };

  const onDeleteHandler = () => {
    if (!onDelete) return;
    onDelete();
  };

  const onEditHandler = () => {
    if (!onEdit) return;
    onEdit();
  };

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {deleted && <span className={classes.deleted}>deleted</span>}
      <h2 id={settings.titleId} className="dialog-title">
        {title}
      </h2>
      <div>
        {!deleted && isAdmin && (
          <IconButton aria-label="delete" className={clsx(classes.blueIconBtn)} onClick={onDeleteHandler}>
            <Icon style={{ fontSize: 20 }} type="delete" />
          </IconButton>
        )}
        {isAdmin && (
          <IconButton aria-label="edit" className={clsx(classes.blueIconBtn)} onClick={onEditHandler}>
            <Icon style={{ fontSize: 20 }} type="edit" />
          </IconButton>
        )}
        <IconButton aria-label="close" className={clsx(classes.greyIconBtn)} onClick={onCloseHandler} autoFocus>
          <Icon style={{ fontSize: 20 }} type="cancel" />
        </IconButton>
      </div>
    </MuiDialogTitle>
  );
};

export default DialogTitle;
