import React from "react";
import { inject, observer } from "mobx-react";

import MuiSnackbar from "../muiSnackbar";

const Notifier = ({ alertsStore, duration = 4000 }) => {
  const onCloseHandler = id => {
    alertsStore.deleteAlert(id);
  };

  const hasAlerts = Boolean(alertsStore.alerts.length);

  if (!hasAlerts) return null;

  return (
    <div role="alert">
      {alertsStore.alerts.map(({ id, ...data }) => (
        <MuiSnackbar key={id} visible={true} duration={duration} onClose={() => onCloseHandler(id)} {...data} />
      ))}
    </div>
  );
};

export default inject(({ rootStore }) => ({
  alertsStore: rootStore.alertsStore,
}))(observer(Notifier));
