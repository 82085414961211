import React from "react";
import { Redirect, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

const Login = ({ rootStore, ...route }) => {
  const { component, ...routeProps } = route;
  return <Route {...routeProps} render={props => (rootStore.authorizationStore.user.id ? <Redirect to="/" /> : <route.component {...props} />)} />;
};

export default inject("rootStore")(observer(Login));
