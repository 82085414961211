import React from "react";
import { Collapse } from "antd";
import { inject, observer } from "mobx-react";

import { getIcon, customPanelStyle, panelHeader, getPanelContent } from "./settings";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FiltersList = ({ bordered = false, expandIconPosition, expandIcon = getIcon, catalogStore: { activeCatalog } }) => {
  const { t } = useTranslation();

  return (
    <Collapse bordered={bordered} defaultActiveKey={["subzone_id", "campaign_id"]} expandIcon={expandIcon} expandIconPosition={expandIconPosition}>
      {activeCatalog.filters.map(filter => (
        <Collapse.Panel forceRender={true} style={customPanelStyle} header={panelHeader(filter)} key={filter.name}>
          {getPanelContent(filter, t)}
        </Collapse.Panel>
      ))}
      {!Boolean(activeCatalog.filters.length) && <span className="filters__empty-placeholder">{t("filtersMenu.noFiltersAvailable")}</span>}
    </Collapse>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore
}))(observer(FiltersList));
