import React, { useEffect, useState } from "react";
import { inject, observer, Provider } from "mobx-react";
import { Router } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import stores from "./stores";
import theme from "./assets/themes";
import Notifier from "./components/notifier";
import { ProtectedRoute } from "./components/routes";
import FullPageLoader from "./components/fullPageLoader";
import ProtectedRouteWrapper from "./components/protectedRouteWrapper";

const Pages = ({ authorizationStore, catalogStore }) => {
  const [catalogsLoaded, setCatalogsLoaded] = useState(false);

  useEffect(() => {
    setCatalogsLoaded(!!catalogStore.catalogs.length);
  }, [catalogStore.catalogs]);

  if (!authorizationStore.isAuthenticated) {
    return <FullPageLoader />;
  }

  return (
    <>
      {catalogsLoaded ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Notifier />
          <ProtectedRouteWrapper>
            <ProtectedRoute />
          </ProtectedRouteWrapper>
        </ThemeProvider>
      ) : (
        <FullPageLoader />
      )}
    </>
  );
};

const InjectedPages = inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  catalogStore: rootStore.catalogStore
}))(observer(Pages));

const App = () => {
  return (
    <Provider {...stores}>
      <Router history={stores.rootStore.navigationStore.history}>
        <InjectedPages />
      </Router>
    </Provider>
  );
};

export default App;
