import { observable, action, computed } from "mobx";
import { proxy } from "../../apiUrls";

export default class Location {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable id = null;
  @observable address = "";
  @observable cig = "";
  @observable cost_center = "";
  @observable fcid = null;
  @observable floor_plan = "";
  @observable language = "";
  @observable latitude = 0;
  @observable longitude = 0;
  @observable mail_code = "";
  @observable name = "";
  @observable phone = "";

  @computed get coordinates() {
    return { lat: this.latitude || 0, lng: this.longitude || 0 };
  }

  @computed get hasCig() {
    return Boolean(this.cig);
  }

  @computed get hasFloorPlans() {
    // return Boolean(this.floor_plan);
    return false;
  }

  @action.bound downloadCIG() {
    window.location.href = `${proxy}/${this.cig}`;
  }

  @action.bound downloadFloorPlan() {
    window.location.href = `${proxy}${this.floor_plan}`;
  }
}
