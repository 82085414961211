import React from "react";
import LogoType from "../../assets/images/BofA_rgb.svg";

import "./styles.scss";
import { Trans, useTranslation } from "react-i18next";

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <div className="maintenance">
      <div className="maintenance__content-wrap">
        <LogoType />
        <span className="maintenance__title">{t("pages.maintenance.title")}</span>
        <span className="maintenance__sub-title">
          <Trans
            i18nKey="pages.maintenance.content"
            components={{
              a: <a href={`mailto:aimhelp@envisioncreates.com`} />
            }}
          />
        </span>
        <span className="maintenance__sub-title">{t("pages.maintenance.thankYou")}</span>
      </div>
    </div>
  );
};

export default Maintenance;
