import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" },
    text: {
      fontSize: 16,
    },
    bold: {
      fontWeight: 600,
    },
  })
);
