import React from "react";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import { useTranslation } from "react-i18next";

const ConfirmNavigation = ({ visible, onCancel, onOk }) => {
  const { t } = useTranslation();
  const actions = [
    {
      text: t("modals.cancel"),
      color: "secondary",
      onClick: onCancel
    },
    {
      text: t("modals.yesLeave"),
      primary: true,
      autoFocus: true,
      onClick: onOk
    }
  ];

  return (
    <MuiDialog
      open={visible}
      onClose={onCancel}
      actions={actions}
      title={t("modals.confirmNavigationTitle")}
      content={t("modals.confirmNavigationContent")}
      {...settings}
    />
  );
};

export default ConfirmNavigation;
