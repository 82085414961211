import React from "react";
import startCase from "lodash/startCase";
import capitalize from "lodash/capitalize";

import Tag from "../../components/tag";
import List from "../../components/list";
import { DateService } from "../../services";
import OrdersHistoryItem from "../../components/ordersHistoryItem";
import OrdersHistoryCustomItem from "../../components/ordersHistoryCustomItem";

export const tableDateFormat = "MM/DD/YYYY";
export const tableTimeFormat = "hh:mm A";

export const paginationConfig = {
  total: 0,
  pageSize: 10,
  current: 1,
  style: { display: "none" }
};

export const renderStatusColumn = (text, order) => <Tag className={`order-table order-table--${order.status}`}>{startCase(order.status)}</Tag>;

export const renderDateColumn = date => (
  <span className="custom-column-value">
    {date && (
      <>
        <span className="block">{DateService.getFormattedDate(date, tableDateFormat)}</span>
        {DateService.getFormattedDate(date, tableTimeFormat)}
      </>
    )}
  </span>
);

export const getRowClassName = record => {
  return record.ordered_items.length ? "orders-history__table__row" : "orders-history__table__row hide-expand-icon";
};

export const getTransformedOrderType = (text, t) => {
  const historyOrderTypes = {
    pod_template: t("pages.ordersHistory.historyOrderTypes.pod_template"),
    remote_atm: t("pages.ordersHistory.historyOrderTypes.remote_atm"),
    full_kit: t("pages.ordersHistory.historyOrderTypes.full_kit"),
    marketing_cling: t("pages.ordersHistory.historyOrderTypes.marketing_cling")
  };

  const orderType = historyOrderTypes[text];
  if (text.startsWith("custom")) {
    const [customText, ...splitText] = text.split("_");
    return capitalize(customText) + ` (${splitText.map(el => capitalize(el)).join(" ")})`;
  }
  return orderType ? orderType : capitalize(text);
};

export const expandedRowRender = ({ ordered_items, type }) => (
  <List
    dataSource={ordered_items}
    renderItem={material => {
      return material.item ? <OrdersHistoryItem material={material} /> : <OrdersHistoryCustomItem orderType={type} material={material} />;
    }}
  />
);
