import { observable, action, computed } from "mobx";

import { urls } from "../../common/apiUrls";

export default class CustomAtmStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable pending = false;
  @observable selectedReason = null;

  @action.bound async getAtms({ page, search }, onSuccess) {
    const { method, url } = urls.getAtms;
    this.pending = true;
    await this.rootStore.makeRequestWithDebounce(onSuccess, method, url, {
      page,
      q: {
        ...this.query(search)
      }
    });
    this.pending = false;
  }

  @action.bound async sendCustomAtmOrder(custom_orders, cb) {
    const { method, url } = urls.orders.sendCustomOrders;
    const onSuccess = data => {
      if (cb) {
        cb();
      }
      this.rootStore.feedbackStore.setActiveFeedbackExist(data.orders[0].active_feedback_exists);
      this.rootStore.feedbackStore.setOrderId(data.orders[0].id);
    };

    this.pending = true;
    await this.rootStore.makeRequest(onSuccess, method, url, {}, { custom_orders });
    this.pending = false;
  }

  @action.bound async setSelectedReason(reason) {
    this.selectedReason = reason;
  }

  @computed get commentsPlaceHolder() {
    if (this.selectedReason && this.selectedReason.value) {
      const { comments_placeholder } = JSON.parse(this.selectedReason.value);
      return comments_placeholder;
    } else {
      return "Comments...";
    }
  }

  @action.bound query(search) {
    const isString = isNaN(parseInt(search));
    return isString ? { site_name_cont: search } : { fcid_cont: search };
  }
}
