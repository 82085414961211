import React, { useState, useEffect } from "react";
import { Prompt, useHistory } from "react-router-dom";

import { ConfirmNavigation } from "../modals";

const RouteLeavingGuard = ({ when, onLeave, forward }) => {
  const history = useHistory();
  const [state, setState] = useState(() => ({
    modalVisible: false,
    lastLocationPathname: null,
    confirmedNavigation: false
  }));

  useEffect(() => {
    if (state.confirmedNavigation) {
      if (forward && state.lastLocationPathname) {
        history.push(state.lastLocationPathname);
      } else {
        history.goBack();
      }
    }
  }, [state.confirmedNavigation, history, state.lastLocationPathname]);

  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [when]);

  function updateState(newState) {
    setState({ ...state, ...newState });
  }

  function showModal(location) {
    updateState({
      modalVisible: true,
      lastLocationPathname: location.pathname + location.search
    });
  }

  function closeModal() {
    updateState({
      modalVisible: false
    });
  }

  function handleBlockedNavigation(nextLocation) {
    if (!state.confirmedNavigation) {
      showModal(nextLocation);
      return false;
    }

    return true;
  }

  function handleConfirmNavigationClick() {
    if (onLeave) onLeave();
    if (!state.lastLocationPathname) return;
    updateState({
      modalVisible: false,
      confirmedNavigation: true
    });
  }

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmNavigation visible={state.modalVisible} onCancel={closeModal} onOk={handleConfirmNavigationClick} />
    </>
  );
};

export default RouteLeavingGuard;
