import React from "react";
import Box from "@material-ui/core/Box";

import Button from "../../../../components/button";

import { useStyles } from "./styles";
import { Trans, useTranslation } from "react-i18next";

const PdfFormErrorsNotification = ({ pdfFormsErrors, onGenerateHandler, onCancelHandler }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <p className={classes.text}>
        <Trans
          i18nKey="pages.checkout.pleaseGeneratePdfFormsForMaterialsWithIds"
          values={{
            pdfFormsErrors: Object.keys(pdfFormsErrors).toString
          }}
          components={{
            s: <span className={classes.bold} />
          }}
        />
      </p>
      <div>
        <Box component="span" mr={1}>
          <Button onClick={onGenerateHandler}>{t("pages.checkout.generate")}</Button>
        </Box>
        <Button onClick={onCancelHandler}>{t("pages.checkout.cancel")}</Button>
      </div>
    </div>
  );
};

export default PdfFormErrorsNotification;
