import { observable, action } from "mobx";

import { urls } from "../../apiUrls";
import { validateForm, handleArrayFormsErrors } from "../../../utils";

export default class SupportStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable isLoading = false;
  @observable lastResponse = {};

  @action.bound async sendCustomOrders(custom_orders, cb, forms) {
    const { method, url } = urls.orders.sendCustomOrders;

    const cbWrapper = (data, body) => {
      const firstOrder = data.orders[0];
      this.rootStore.feedbackStore.setActiveFeedbackExist(firstOrder.active_feedback_exists);
      this.rootStore.feedbackStore.setOrderId(firstOrder.id);
      this.lastResponse = firstOrder;
      if (cb) {
        cb(data, body);
      }
    };

    this.isLoading = true;
    await this.rootStore.makeRequest(cbWrapper, method, url, {}, { custom_orders }, err => this.onFormsError(err, forms));
    this.isLoading = false;
  }

  @action.bound async sendSupportOrder({ data, ...rest }, cb) {
    const { method, url } = urls.orders.sendSupportOrder;

    const cbWrapper = (data, body) => {
      this.rootStore.feedbackStore.setActiveFeedbackExist(data.order.active_feedback_exists);
      this.rootStore.feedbackStore.setOrderId(data.order.id);
      this.lastResponse = data.order;
      if (cb) {
        cb(data, body);
      }
    };
    this.isLoading = true;

    await this.rootStore.makeRequest(cbWrapper, method, url, {}, { ...rest, comments: data.comments });
    this.isLoading = false;
  }

  onFormsError(err, forms) {
    if (err) {
      const { error } = err.response ? err.response.data : null;
      if (error) {
        const { failed_ordered_items, failed_orders } = error;
        const fieldSchema = {
          order_reason: "reason"
        };
        if (failed_ordered_items) {
          handleArrayFormsErrors(failed_ordered_items, forms, fieldSchema);
        }
        if (failed_orders) {
          handleArrayFormsErrors(failed_orders, forms, fieldSchema);
        }
      }
    }
  }

  @action.bound async prepareDataForSending(form) {
    let validationResult = await validateForm(form);
    if (validationResult.errors) return;
    return validationResult;
  }
}
