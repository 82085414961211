import React from "react";
import { forwardRef } from "react";
import { InputBase } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const MuiInputBase = withStyles({
    root: {
        width: "100%",
        height: 34,
        border: "1px solid #EDEDED",
        borderRadius: 4,
        padding: "0 20px 0 12px",
    },
    input: {
        "&.transparent": {
            opacity: 0
        },
    },
    focused: {
        boxShadow: "0 0 6px #D4EFFC !important",
        border: "1px solid #0073CF"
    }
})(InputBase);

const PaginatedStyledInput = forwardRef(({ ...props }, ref) => (
    <MuiInputBase ref={ref} {...props} />
));

export default PaginatedStyledInput;