import React from "react";
import { Row, Col } from "antd";
import { inject, observer } from "mobx-react";

import TextBlock from "../textBlock";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FinancialCenterDetails = ({ locationStore }) => {
  const { t } = useTranslation();

  const { name, mail_code, fcid, address, phone, cost_center, language } = locationStore.fullLocationData;

  return (
    <div className="location-details">
      <TextBlock title={t("financialCenterCard.locationName")} content={name} contentSize={20} />
      <Row gutter={16} type="flex">
        <Col span={8}>
          <TextBlock title={t("financialCenterCard.mailCode")} content={mail_code} />
        </Col>
        <Col span={8}>
          <TextBlock title={t("financialCenterCard.fcid")} content={fcid} />
        </Col>
      </Row>
      <TextBlock title={t("financialCenterCard.locationAddress")} content={address} />
      <Row gutter={16} type="flex">
        <Col span={8}>
          <TextBlock title={t("financialCenterCard.phone")} content={phone} />
        </Col>
        <Col span={8}>
          <TextBlock title={t("financialCenterCard.costCenter")} content={cost_center} />
        </Col>
        <Col span={8}>
          <TextBlock title={t("financialCenterCard.language")} content={language} />
        </Col>
      </Row>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  locationStore: rootStore.locationStore
}))(observer(FinancialCenterDetails));
