import React from "react";
import { useState, forwardRef, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import uniqBy from "lodash/uniqBy";
import PaginatedAutocompleteInput from "./paginatedAutocompleteInput";

import "./styles.scss";
import PaginatedListOption from "./paginatedListOption";
import Label from "../label";
import ValidationError from "../validationError";

const PaginatedAutocomplete = forwardRef(({ id, onChange, getOptions, optionProps = { id: "id", title: "title" }, disabled, label, rules, error, ...rest }, ref) => {
    const [focusCount, setFocusCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dropdownVisibility, setDropdownVisibility] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(3);
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([])
    const [searchTouched, setSearchTouched] = useState(false);
    const [allDataLoaded, setAllDataLoaded] = useState(false);
    const required = rules && rules.required && rules.required.value;
    const handleChange = (_, data) => {
        setSearchTouched(false);
        onChange(data);
    }

    const handleInputChange = (e, newInputValue) => {
        setSearch(newInputValue);
        if(e){
            setPage(0);
            setSearchTouched(true);
        }
    }

    const onSuccessGetOptions = ({ data, total }) => {
        if (options.length >= total) setAllDataLoaded(true);
        const newOptions = uniqBy(
            [...options, ...data],
            optionProps.id
        ).filter(s => s);
        setOptions(newOptions)
        setTotal(total);
        setPage(page + 1);
        setLoading(false);
    }

    const handleScrollListBox = event => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            onScrollToBottomHandler();
        }
    }

    const onScrollToBottomHandler = () => {
        if (allDataLoaded) return;
        setLoading(true);
        getOptions({ page, search: searchTouched ? search : '' }, onSuccessGetOptions);
    };

    const onInputKeyDown = e => {
        const { keyCode } = e;
        if(keyCode === 40 || keyCode === 38){
            const listboxNode = document.querySelector(`${rest.name}-paginated-autocomplete__listbox`);
            if(listboxNode){
                if (listboxNode.scrollTop + listboxNode.clientHeight + 58 >= listboxNode.scrollHeight) {
                    onScrollToBottomHandler();
                }
            }
        }
    }

    const handleFocus = () => setFocusCount(prev => prev + 1)

    useEffect(() => {
        setAllDataLoaded(false);
        if(options.length){
            setTotal(options.length);
        }
    }, [search])

    useEffect(() => {
        if (!dropdownVisibility || options.length >= total) return;
        setLoading(true);
        getOptions({ page, search: searchTouched ? search : '' }, onSuccessGetOptions)
    }, [dropdownVisibility, search, total, searchTouched]);

    return (
        <div>
            {label && (
                <Label
                    htmlFor={`${id}__input`}
                    id={`${id}__label`}
                    text={label}
                    required={required}
                />
            )}
            <Autocomplete
                ref={ref}
                {...rest}
                autoHighlight
                onFocus={handleFocus}
                disabled={disabled}
                open={dropdownVisibility}
                onOpen={() => setDropdownVisibility(true)}
                onClose={() => setDropdownVisibility(false)}
                onChange={handleChange}
                onInputChange={handleInputChange}
                getOptionSelected={(option, value) => option[optionProps.id] === value[optionProps.id]}
                getOptionLabel={params => `${String(params[optionProps.id])} ${params[optionProps.title]}`}
                getOptionLabel={(option) => option[optionProps.title] ? `${String(option[optionProps.id])} ${option[optionProps.title]}` : ""}
                options={options}
                loading={loading}
                disableClearable
                inputValue={search}
                renderOption={params => <PaginatedListOption { ...params } optionProps={optionProps} />}
                className="paginated-autocomplete"
                ListboxProps={{
                    onScroll: handleScrollListBox,
                    className: `${rest.name}-paginated-autocomplete__listbox paginated-autocomplete__listbox`
                }}
                renderInput={params => (
                    <PaginatedAutocompleteInput
                        { ...params }
                        inputProps={{
                            ...params.inputProps,
                            id: `${id}__input`,
                            "aria-describedby": `${id}-error`,
                            "aria-required": required
                        }}
                        dropdownVisibility={dropdownVisibility}
                        placeholder={rest.placeholder}
                        value={rest.value}
                        onInputKeyDown={onInputKeyDown}
                        loading={loading}
                        optionProps={optionProps}
                        disabled={disabled}
                    />
                )}
            />
            {error && error.message && (
                <ValidationError
                    key={focusCount}
                    id={`${id}-error`}
                    errorText={error.message}
                />
            )}
        </div>
    )
});

export default PaginatedAutocomplete;