import { parse, stringify } from "qs";

class QueryStringService {
  parse(string, options) {
    return parse(string, { ...options, ignoreQueryPrefix: true });
  }

  stringify(str, options = {}) {
    return stringify(str, options);
  }
}

export default new QueryStringService();
