import React from "react";
import { inject, observer } from "mobx-react";

import Icon from "../icon";
import Button from "../button";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FilterBlock = ({ catalogStore, onFilterClick }) => {
  const { t } = useTranslation();
  const amountAllActiveFilters = catalogStore.activeCatalog.amountAllActiveFilters();

  function onTagClose(e) {
    e.preventDefault();
    catalogStore.clearFilters();
  }

  return (
    <>
      {!!amountAllActiveFilters && (
        <div className="selected-filters">
          <span>{`selected filters: ${amountAllActiveFilters}`}</span>
          <Button
            aria-label={t("filterBlock.resetSelectedFiltersLabel")}
            className="selected-filters__close-btn"
            type="link"
            onClick={onTagClose}
            icon="close"
          />
        </div>
      )}
      <Button aria-label={t("filterBlock.openFiltersDrawerLabel")} className="btn--filters" type="link" onClick={onFilterClick}>
        {t("filterBlock.filters")}
        <Icon type="filter" />
      </Button>
    </>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore
}))(observer(FilterBlock));
