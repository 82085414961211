import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import Box from "@material-ui/core/Box";
import { inject, observer } from "mobx-react";

import DraftAlert from "./draftAlert";
import { AlertModal } from "../../components/modals";
import LocationAutocomplete from "./locationAutocomplete";
import LogoType from "../../../src/assets/images/BofA_rgb.svg";
import PrimaryButton from "../../components/formComponents/primaryButton";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const PickLocation = ({ locationStore, checkoutStore, authorizationStore, alertsStore }) => {
  const { t } = useTranslation();
  const [fc, setFc] = useState({});

  const { user } = authorizationStore;
  const { setLocationInitialized, setActiveLocation } = locationStore;
  const { draftOrderIsPending, draftNotEmpty, draftOrder } = checkoutStore;

  const handleOnDiscard = () => {
    draftOrder.clearOrder();
  };

  const initLocationLs = async () => await setLocationInitialized(true);

  const handleOnContinue = async e => {
    if (e.target) {
      await setActiveLocation(fc);
      await initLocationLs();
    }
  };

  useEffect(() => {
    checkoutStore.getDraftOrder();
  }, [checkoutStore]);

  useEffect(() => {
    authorizationStore.getConfig();
  }, [authorizationStore]);

  useEffect(() => {
    setFc(prev => (locationStore.activeLocation && locationStore.activeLocation.fcid ? locationStore.activeLocation : user.primaryLocation) || prev);
  }, [locationStore.activeLocation, user.primaryLocation]);

  useEffect(() => {
    if (!fc || !fc.fcid) return;
    alertsStore.getAIMAlerts(fc.fcid);
  }, [alertsStore, fc.fcid]);

  const onLoginModalCancelHandler = id => {
    alertsStore.deleteAIMAlert(id);
  };

  return (
    <div className="pick-location">
      <div className="pick-location__content-wrap">
        <LogoType />
        <span className="pick-location__title">{t("pages.pickLocation.title")}</span>
        <span className="pick-location__sub-title">{t("pages.pickLocation.subTitle")}</span>
        {draftOrderIsPending && <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />}
        {!draftOrderIsPending && (
          <>
            <Box mb={3}>
              <LocationAutocomplete value={fc} setValue={setFc} disabled={draftNotEmpty} />
              {draftNotEmpty && <DraftAlert fc={fc} onDiscard={handleOnDiscard} onContinue={handleOnContinue} />}
            </Box>
            {!draftNotEmpty && (
              <PrimaryButton
                disabled={!fc}
                size="medium"
                style={{
                  minWidth: 220
                }}
                onClick={handleOnContinue}
              >
                {t("pages.pickLocation.continue")}
              </PrimaryButton>
            )}
            {alertsStore.hasAIMAlerts && (
              <AlertModal alert={alertsStore.AIMAlert} visible onCancel={() => onLoginModalCancelHandler(alertsStore.AIMAlert.id)} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  alertsStore: rootStore.alertsStore,
  locationStore: rootStore.locationStore,
  checkoutStore: rootStore.checkoutStore,
  navigationStore: rootStore.navigationStore
}))(observer(PickLocation));
