import { observable, action, computed } from "mobx";
import { User } from "../../models";
import { LocalStorageService } from "../../../services";

const mockConfig = {
  title: "Title",
  description: "Description",
  language: "en",
  customer_support_email: "dmghelpdesk@diversifiedus.com",
  technical_support_email: "customercare@envisioncreates.com",
  support_phone_number: "(855) 445-2585",
  time_zone: "Central Time (US & Canada)",
  vault_link: "https://thevault.bankofamerica.com/",
  vault_link_new_tab: false,
  logo_url: null,
  config_color_scheme: [{ name: "Light" }]
};

const mockUserProfile = {
  id: 14886,
  full_name: "Ivan Iguaran",
  email: "iiguaran@onedoor.com",
  role: ["Marketing and Merchandising", "CSP User"],
  language: "",
  phone: "",
  contact_email: "iiguaran@onedoor.com",
  assigned_locations: [
    {
      id: 14414,
      primary: true,
      fcid: 14414,
      name: "Harvard Square",
      mail_code: "MA5-157-01-01",
      address: "Cambridge, 1414 Massachusetts Ave, MA, Middlesex, 02138",
      phone: "617.349.7410",
      cost_center: "82633",
      language: "Chinese",
      cig: "/boxroom/files/835861",
      floor_plan: ["/boxroom/files/270796"],
      latitude: 42.373881,
      longitude: -71.119329
    }
  ]
};

export default class AuthorizationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable user = new User({ store: this });
  @observable config = null;
  @observable isAuthenticated = false;

  @action.bound async login() {
    // Mock login
    this.isAuthenticated = true;
    await this.getInfo();
  }

  @action.bound async logout() {
    // Mock logout
    this.isAuthenticated = false;
    this.user = new User({ store: this });
    this.config = null;
    await LocalStorageService.remove("locationInitialized");
  }

  @action.bound setUser(user) {
    if (!user) return;
    this.user = new User({ store: this, ...user });
  }

  @action.bound async saveUser(data) {
    this.setUser(data);
    await LocalStorageService.save("user", data);
  }

  @action.bound async setConfig(config) {
    if (!config) return;
    this.config = config;
  }

  @action.bound async saveConfig(data) {
    this.setConfig(data);
    await LocalStorageService.save("config", data);
  }

  @action.bound async getUserProfile() {
    // Mock user profile request
    return this.saveUser(mockUserProfile);
  }

  @action.bound async getConfig() {
    // Mock config request
    return this.saveConfig(mockConfig);
  }

  @action.bound async getInfo() {
    return Promise.all([this.getUserProfile(), this.getConfig()]);
  }

  @computed get vaultLink() {
    return this.config && this.config.vault_link;
  }

  @computed get vaultLinkSettings() {
    return this.config && this.config.vault_link_new_tab ? "_blank" : "_self";
  }

  // Mock token acquisition
  @action.bound async acquireTokenResponse() {
    return {
      accessToken: "mock_access_token",
      expiresOn: new Date(Date.now() + 3600 * 1000) // Token expires in 1 hour
    };
  }
}
