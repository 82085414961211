import React, { forwardRef, useState } from "react";
import clsx from "clsx";
import { TextareaAutosize } from "@material-ui/core";

import { ValidationError, Label } from "../index";

import "../styles.scss";

const TextareaWithCounter = forwardRef(({ onChange, onKeyDown, label, maxLength, ...props }, ref) => {
  const [focusCount, setFocusCount] = useState(0);
  const { value, ...rest } = props;
  const isRequired = props["data-__meta"] && props["data-__meta"].rules && Boolean(props["data-__meta"].rules.find(item => item.required));
  const counterClasses = clsx("input-with-counter__counter", props && props.value && props.value.length === maxLength && "input-with-counter__counter--red");
  const id = props && props._id ? props._id : props.id;

  const handleKeyDown = e => {
    e.stopPropagation();
    const { keyCode } = e;
    if (keyCode === 27) {
      e.target.value = "";
      onChange({ ...e });
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleFocus = () => setFocusCount(prev => prev + 1);

  const onChangeHandler = e => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <>
      {label && label.text && <Label htmlFor={id} useRequireOnLabel required={isRequired} id={`${id}-label`} {...label} />}
      <div className="input-with-counter">
        <TextareaAutosize
          ref={ref}
          minRows={2}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onChange={onChangeHandler}
          aria-labelledby={label && label.text ? `${id}-label` : undefined}
          aria-describedby={props["data-__field"] && props["data-__field"].errors && props["data-__field"].errors.map((_, i) => `${id}-error-${i}`).join(" ")}
          aria-invalid={props["data-__field"] && props["data-__field"].errors && Boolean(props["data-__field"].errors.length)}
          value={value || ""}
          {...rest}
          id={id}
        />
        <span className={counterClasses}>{props.value && props.value.length}</span>
      </div>
      {props["data-__field"] &&
        props["data-__field"].errors &&
        props["data-__field"].errors.map((err, i) => {
          return <ValidationError key={`${i}-${focusCount}`} id={`${id}-error-${i}`} errorText={err.message} />;
        })}
    </>
  );
});

export default TextareaWithCounter;
