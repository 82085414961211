import { observable, action } from "mobx";

import { urls } from "../../apiUrls";
import { PowerBIReport } from "../../models";

class PowerBIStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable reportId = null;
  @observable groupId = process.env.REACT_APP_POWER_BI_GROUP_ID;
  @observable report = {};
  @observable expireTimerId = null;
  @observable isLoading = false;

  @action.bound async getReport(reportId) {
    const { url, method } = urls.getPowerBIReport;
    const headers = { "group-id": this.groupId, "report-id": reportId };
    const onGetReportSuccess = data => {
      this.report = { [reportId]: new PowerBIReport({ store: this, ...data }), ...this.report };
    };

    this.isLoading = true;
    await this.rootStore.makeRequest(onGetReportSuccess, method, url, {}, {}, null, headers);
    this.isLoading = false;
  }

  @action.bound clearExpireTimer() {
    clearTimeout(this.expireTimerId);
  }

  @action.bound startInterval() {
    const interval = Math.abs(new Date() - new Date(this.report.tokenExpiryDate));

    this.expireTimerId = setTimeout(() => {
      this.clearExpireTimer();
      this.getReport();
    }, interval);
  }

  @action.bound downloadReport(format) {
    console.log(format);
  }

  @action.bound setGroupId(id) {
    this.groupId = id;
  }

  @action.bound setReportId(id) {
    this.reportId = id;
  }
}

export default PowerBIStore;
