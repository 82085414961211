import React from "react";
import { Avatar as AntAvatar } from "antd";
import "./styles.scss";

const Avatar = ({ src, size = 36, children, shape = "square", style = {}, alt }) => (
  <AntAvatar src={src} size={size} shape={shape} style={style} alt={alt}>
    {children}
  </AntAvatar>
);

export default Avatar;