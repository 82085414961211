import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import Button from "../../components/button";

import "./styles.scss";
import { Trans, useTranslation } from "react-i18next";

const InternalServerError = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const title = t("pages.internalServerError.title");
  const subTitle = t("pages.internalServerError.subTitle");

  const onGoBackHandler = () => {
    history.goBack();
  };

  return (
    <div className="page-wrapper">
      <div className="inner">
        <Typography variant="h1" className="title">
          {title}
        </Typography>
        <span className="subtitle bold block">{subTitle}</span>
        <span className="description bold block">
          <Trans
            i18nKey="pages.internalServerError.pleaseTryAgain"
            components={{
              a: <a href="#" />
            }}
          />
        </span>
        <Button onClick={onGoBackHandler} className="go-back-btn" type="primary">
          {t("pages.internalServerError.goBack")}
        </Button>
      </div>
    </div>
  );
};

export default InternalServerError;
