import { observable, action, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class HistoryFilter {
  @observable store = {};
  @observable title = "";
  @observable name = "";
  @observable type = "";
  @observable available_matchers = [{ available_values: [], name: "" }];
  @observable values = [];
  @observable selectedValues = [];
  @observable searchFilterKey = "";
  @observable isLocationFilter = false;
  @observable isSearching = false;

  @action.bound onChangeHandler(values) {
    this.selectedValues = values;
    this.store.pagination.current = 1;
    this.store.activeFilters = {
      ...this.store.activeFilters,
      [`${this.name}${this.available_matchers[0].name}`]: values,
    };
  }

  @action.bound async onSearchHandler(text) {
    const { method, url } = this.isLocationFilter ? urls.locationFilters : urls.orders.additionalFilters;
    this.values = [];
    this.isSearching = true;
    try {
      return await this.store.rootStore.makeRequestWithDebounce(
        this.updateFilterValues,
        method,
        url,
        {},
        {
          ...this.getAdditionalData(),
          q: {
            [this.searchFilterKey]: text,
          },
        }
      );
    } catch (e) {
      this.store.rootStore.errorService.handleError(e);
    }
  }

  @action.bound getAdditionalData() {
    return this.isLocationFilter
      ? {
          field_to_search: this.type,
        }
      : {
          ...this.addType(),
        };
  }

  @action.bound addType() {
    return this.type ? { [this.type]: true } : {};
  }

  @action.bound updateFilterValues({ filters }) {
    this.values = filters[0].available_matchers[0].available_values;
    this.isSearching = false;
  }

  @computed get hasActiveFilters() {
    return this.isIdFilter ? this.selectedValues : this.selectedValues.length;
  }

  @computed get isIdFilter() {
    return this.name === "id";
  }

  @action.bound setDefaultSelectedValues() {
    this.selectedValues = this.isIdFilter ? null : [];
  }

  constructor(props) {
    Object.assign(this, props);
    this.values = this.available_matchers[0].available_values;
    this.setDefaultSelectedValues();
  }
}
