import React, { forwardRef, useState } from "react";
import { isNumber, isString } from "lodash";
import withStyles from "@material-ui/core/styles/withStyles";
import { Select, MenuItem, InputBase, Chip } from "@material-ui/core";

import Label from "../label";
import ValidationError from "../validationError";

const MuiMenuItem = withStyles({
  root: {
    "&:hover, &:focus": {
      background: "rgba(212, 239, 252, 0.51)"
    }
  },
  selected: {
    backgroundColor: "#D4EFFC !important"
  }
})(MenuItem);

const StyledChip = withStyles({
  root: {
    height: 24,
    borderRadius: 0,
    border: "1px solid #EDEDED",
    background: "#F9F9F9",
    marginRight: 4
  }
})(Chip);

const MuiSelect = withStyles({
  root: props => {
    const overrides = props && props.styles && props.styles.root ? props.styles.root : {};
    return {
      paddingLeft: 10,
      fontSize: 14,
      background: "white",
      border: "1px solid #EDEDED",
      borderRadius: 4,
      color: "#595959",
      textTransform: "none",
      "& em": {
        fontStyle: "normal"
      },
      "&:focus": {
        background: "white",
        borderColor: "#0073cf",
        borderRadius: 4
      },
      "&:hover": {
        borderColor: "#0073CF80"
      },
      ...overrides
    };
  },
  disabled: {
    background: "hsl(0,0%,95%) !important",
    cursor: "not-allowed !important"
  },
  icon: {
    marginRight: 1,
    color: "#0073cf"
  }
})(Select);

const MuiInputBase = withStyles({
  root: props => {
    const overrides = props && props.styles && props.styles.root ? props.styles.root : {};
    return {
      width: "100%",
      ...overrides
    };
  }
})(InputBase);

const MaterialSelect = forwardRef(({ options = [], name, label, onChange, placeholder, value, valueKey, multiple, ...rest }, ref) => {
  const [focusCount, setFocusCount] = useState(0);
  const required =
    rest["data-__meta"] && rest["data-__meta"].rules && rest["data-__meta"].rules.length
      ? Boolean(rest["data-__meta"].rules.find(item => item.required))
      : false;
  let selectValue;
  if (multiple) {
    selectValue = value && value.length ? value.filter(option => option).map(option => (typeof option === "object" ? option.id : option)) : [];
  } else {
    selectValue = valueKey && value && typeof value === "object" ? value[valueKey] : value;
  }

  const handleFocus = () => setFocusCount(prev => prev + 1);

  const onChangeHandler = e => {
    const { value } = e.target;
    if (multiple) {
      onChange(value);
    } else {
      const selectedOption = options.find(option => option.value === value);
      onChange(selectedOption);
    }
  };

  const onRenderHandler = value => {
    if (multiple) {
      if (value.length === 0) {
        return <em>{placeholder}</em>;
      }

      return value.map(id => {
        if (isNumber(id) || isString(id)) {
          const option = options.find(option => option.id === id);
          return option && option.label ? option.label + " " : null;
        }

        return <StyledChip key={id} label={id} />;
      });
    } else {
      if (!value) {
        return <em>{placeholder}</em>;
      }
      const option = options.find(option => option.value === value);
      return option && option.label ? option.label : "";
    }
  };

  return (
    <>
      {label && <Label id={`select-${name}-label`} text={label.text} antLabel={label.antLabel} required={required} useRequireOnLabel />}
      <MuiSelect
        {...rest}
        ref={ref}
        autoWidth={false}
        MenuProps={{
          PaperProps: {
            style: { maxWidth: 500 }
          }
        }}
        multiple={multiple}
        displayEmpty
        id={`react-select-${name}-input`}
        labelId={label ? `select-${name}-label` : null}
        onChange={onChangeHandler}
        onFocus={handleFocus}
        input={<MuiInputBase styles={rest.styles} />}
        renderValue={onRenderHandler}
        value={multiple ? selectValue || [] : selectValue || ""}
        aria-describedby={rest["data-__field"] && rest["data-__field"].errors && rest["data-__field"].errors.map((_, i) => `${name}-error-${i}`).join(" ")}
      >
        {options.map(({ value, label }) => (
          <MuiMenuItem key={value} value={value}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{label}</div>
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {rest["data-__field"] &&
        rest["data-__field"].errors &&
        rest["data-__field"].errors.map((err, i) => (
          <ValidationError key={`${err.message}-${i}-${focusCount}`} id={`${name}-error-${i}`} errorText={err.message} />
        ))}
    </>
  );
});

export default MaterialSelect;
