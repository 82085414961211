import React, { useEffect, useCallback } from "react";
import { Form } from "antd";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Icon from "../../icon";
import Image from "../../image";
import { settings } from "./settings";
import Skeleton from "../../skeleton";
import { MaterialOrderForm } from "../../forms";
import { photoSizes } from "../../../constants";
import { proxy } from "../../../common/apiUrls";
import PrimaryButton from "../../formComponents/primaryButton";
import { materialOrderForm } from "../../forms/materialOrderForm/settings";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const MaterialCardModal = ({ form, material, onCloseModal, catalogStore, checkoutStore, visible }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { merch_name, code, photo } = material;
  const disableButtons = checkoutStore.draftOrder.pending;
  const alt = photo && photo.description ? photo.description : `${merch_name}-${code}`;
  const url =
    catalogStore.expandedMaterial.photo &&
    catalogStore.expandedMaterial.photo[photoSizes.medium] &&
    `${proxy}/${catalogStore.expandedMaterial.photo[photoSizes.medium].slice(1)}`;

  const onCloseModalCallback = useCallback(onCloseModal, []);

  useEffect(() => {
    catalogStore.getAdditionalMaterialData(material, onCloseModalCallback);
    return () => {
      catalogStore.clearExpandedMaterial();
    };
  }, [catalogStore, material.id, onCloseModalCallback]);

  const handleSubmit = async cb => {
    let formValid;
    try {
      formValid = await form.validateFields();
    } catch (e) {
      formValid = e;
    }
    if (formValid.errors) return;
    await checkoutStore.draftOrder.addMaterial(catalogStore.expandedMaterial, formValid);
    onCloseModal();
    if (cb) cb();
  };

  function withCallback() {
    history.push("/checkout");
  }

  const content = (
    <div className="material__content flex flex-start">
      <div className="material__image">
        <Skeleton loading={catalogStore.pending} avatar={{ shape: "square", size: 540 }}>
          <Image url={url} placeholderIconSize={200} alt={alt} />
        </Skeleton>
      </div>
      <div className="material__info">
        <Skeleton loading={catalogStore.pending} paragraph={{ rows: 7 }}>
          <MaterialOrderForm form={form} description={photo && photo.description ? photo.description : ""} />
        </Skeleton>
      </div>
    </div>
  );

  return (
    !catalogStore.materialDataIsLoading && (
      <Dialog open={visible} maxWidth="lg" onClose={onCloseModal} aria-labelledby={settings.titleId} fullWidth>
        <MuiDialogTitle disableTypography className={classes.root}>
          <h2 id={settings.titleId} className="dialog-title">
            {catalogStore.expandedMaterial.merch_name || catalogStore.expandedMaterial.cling_type_code}
          </h2>
          <IconButton aria-label={t("modals.closeIconLabel")} className={classes.closeButton} onClick={onCloseModal} autoFocus>
            <Icon type="cancel" />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button text="add to cart" color="secondary" variant="text" disabled={disableButtons} onClick={() => handleSubmit()}>
            {t("modals.addToCart")}
          </Button>
          <PrimaryButton disabled={disableButtons} onClick={() => handleSubmit(withCallback)}>
            {t("modals.orderItNow")}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    )
  );
};

export default Form.create({ name: materialOrderForm.formName })(
  inject(({ rootStore }) => ({
    checkoutStore: rootStore.checkoutStore,
    catalogStore: rootStore.catalogStore
  }))(observer(MaterialCardModal))
);
