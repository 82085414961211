import { observable } from "mobx";

export default class Feedback {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable additional_information = "";
  @observable additional_information_is_required = false;
  @observable date_end = "";
  @observable date_start = "";
  @observable id = 0;
  @observable questions = {};
  @observable survey_description = "";
  @observable survey_title = "";
}
