import { v4 as uuidv4 } from "uuid";

export const generateId = () => {
  return uuidv4();
};

export const getQueryString = str => {
  if (!str) return "";
  return decodeURIComponent(str.split("=")[1]);
};

export const getURLSearchParam = (param, str) => {
  if (!str || !param) return "";
  const params = new URLSearchParams(str);
  return params.get(param);
};

export const downloadFile = (data, fileName, fileExtension) => {
  const types = {
    pdf: "application/pdf",
    csv: "text/csv",
    xlsx: "application/vnd.openxmlformats- officedocument.spreadsheetml.sheet"
  };

  function s2ab(s) {
    let buf = new ArrayBuffer(s.length),
      view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  let blobData = data;

  if (fileExtension === "xlsx") {
    blobData = s2ab(atob(data));
  }

  const newBlob = new Blob([blobData], { type: types[fileExtension] });

  // IE browser
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  const url = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${fileName}.${fileExtension}`;
  link.click();
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(url);
  }, 100);
};

export const blobToBase64 = data => {
  const blob = new Blob([data], { type: "application/pdf" });

  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};
