import React, { useRef } from "react";

import Icon from "../icon";
import useOnScreen from "../../hooks/useOnScreen";

import "./styles.scss";

const Image = ({ size = "100%", width, height, placeholderIconSize = 96, alt, url, ...restProps }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const hasPhoto = !!url;

  return (
    <div ref={ref} className="image" style={{ minWidth: width || size, height: height || size }} {...restProps}>
      {hasPhoto && <img className="image__photo" src={url} alt={alt} loading={isVisible ? "eager" : "lazy"} />}
      {!hasPhoto && <Icon size={placeholderIconSize} ariaHidden={false} type="placeholder" />}
    </div>
  );
};

export default Image;
