import React from "react";

import ValidationForm from "./components";

const FormValidationGenerator = ({ fields, onSaveFormRef }) => (
  <div>
    {fields.map(field => (
      <ValidationForm wrappedComponentRef={onSaveFormRef} formId={field.name} fieldType={field.type} formIndex={field.index} key={field.name} field={field} />
    ))}
  </div>
);

export default FormValidationGenerator;
