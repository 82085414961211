import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    text: {
      fontSize: 14,
      [breakpoints.down("md")]: {
        fontSize: 9
      }
    }
  })
);
