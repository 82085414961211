import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/icons/Menu";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const MenuButton = ({ expanded, onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <IconButton className={classes.root} aria-expanded={expanded} aria-label={t("menuButton.toggleMenuLabel")} onClick={onClick}>
      <Menu className={classes.icon} fontSize="inherit" />
    </IconButton>
  );
};

export default MenuButton;
