import { observable, computed, action } from "mobx";

import { CheckoutMaterial } from "../index";
import { urls } from "../../apiUrls";
import { generatePdfTransformer, addMaterialTransformer, handleFormErrors } from "../../../utils";
import { has } from "lodash/object";
import { t } from "i18next";

export default class Order {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable store = null;
  @observable id = null;
  @observable parent_order_id = null;
  @observable location_fcid = null;
  @observable index = 0;
  @observable type = "";
  @observable status = "";
  @observable created_at = "";
  @observable updated_at = "";
  @observable finalization_time = "";
  @observable market = "";
  @observable orders = [];
  @observable ordered_items = [];
  @observable sentOrders = [];
  @observable fc_contact = { full_name: "" };
  @observable requestor = { full_name: "" };
  @observable isLoading = false;
  @observable active_feedback_exists = false;

  @computed get materialsQuantity() {
    return this.orderedItemsWithAdditionalMaterials.length;
  }

  @computed get orderedItems() {
    return this.ordered_items.map(material => new CheckoutMaterial({ store: this.store, ...material }));
  }

  @computed get orderedItemsWithAdditionalMaterials() {
    const order = [];
    this.ordered_items.forEach(item => {
      order.push(item);
      item.automatically_ordered_marketing_items.forEach(marketing_items => {
        order.push(marketing_items);
      });
    });
    return order;
  }

  @action.bound async updateOrderStatus(receiver, status) {
    const { method, url } = urls.orders.updateStatus;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(
      this.updateLocalOrder,
      method,
      `${url}/${this.id}`,
      {},
      {
        order_status: status,
        location_fcid: this.store.rootStore.locationStore.fullLocationData.fcid,
        ...receiver
      }
    );
    this.isLoading = false;
  }

  @action.bound async updateLocalOrder(order) {
    this.store.rootStore.feedbackStore.setActiveFeedbackExist(order.active_feedback_exists);
    this.store.rootStore.feedbackStore.setOrderId(order.id);
    Object.assign(this, order);
  }

  @action.bound async addMaterial(material, data) {
    const { method, url } = urls.orders.addMaterial;
    return await this.store.rootStore.makeRequest(this.addLocalMaterial, method, `${url}/${this.id}/items`, {}, addMaterialTransformer(material, data));
  }

  @action.bound async updateMaterial(material, form) {
    const { method, url } = urls.orders.updateMaterial;
    return await this.store.rootStore.makeRequest(null, method, `${url}/${this.id}/items/${material.item_id}`, {}, material, err =>
      this.onUpdateError(err, form)
    );
  }

  @action.bound async updateTemplateItem(material) {
    const { method, url } = urls.orders.updateMaterial;
    await this.store.rootStore.makeRequest(this.updateOrderItem, method, `${url}/${this.id}/items/${material.item_id}`, {}, material);
  }

  @action.bound updateOrderItem(data) {
    const item = this.ordered_items.find(({ id }) => id === data.id);
    if (!item) return;
    Object.keys(item.item).forEach(key => (item.item[key] = data.item[key]));
  }

  @action.bound async generatePdf(formValues, material) {
    const { method, url } = urls.templates.generatePdf;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(
      data => this.onGeneratePdfSuccess(data, material, formValues),
      method,
      url.replace("{id}", material.item.id),
      {},
      generatePdfTransformer(formValues)
    );
    this.isLoading = false;
  }

  @action.bound async onGeneratePdfSuccess(data, material, formValues) {
    await this.deleteGeneratedPdf(material.item.generatedPdfForm.id);
    await this.updateTemplateItem({
      item: { ...material.item, generatedPdfForm: data, pdfFormFields: this.setValuesToPdfForm(material.item.pdfFormFields, formValues) },
      item_id: material.id,
      item_type: material.item.item_type
    });
  }

  @action.bound async deleteGeneratedPdf(id) {
    const { method, url } = urls.templates.deleteGeneratedPdf;
    await this.store.rootStore.makeRequest(null, method, `${url}/${id}`, {});
  }

  @action.bound setValuesToPdfForm(formFields, formValues) {
    return formFields.map(field => {
      const value = formValues[`${field.name}---${field.index}`];
      return { ...field, value };
    });
  }

  onUpdateError(err, form) {
    if (err) {
      const { error } = err.response ? err.response.data : null;
      if (error) {
        handleFormErrors(error, form, {
          order_reason: "reason"
        });
      }
    }
  }

  @action.bound addLocalMaterial(material) {
    setTimeout(() => {
      this.store.rootStore.alertsStore.createAlert({
        severity: "success",
        message: t("alerts.materialAdded", material)
      });
    }, 0);

    this.ordered_items.push(material);
  }

  @action.bound async deleteMaterial(materialId) {
    const { method, url } = urls.orders.deleteMaterial;
    return await this.store.rootStore.makeRequest(() => this.deleteLocalMaterial(materialId), method, `${url}/${this.id}/items/${materialId}`);
  }

  @action.bound deleteLocalMaterial(materialId) {
    const material = this.ordered_items.find(item => item.id === materialId);
    if (material && has(material, "item.generatedPdfForm.id")) this.deleteGeneratedPdf(material.item.generatedPdfForm.id);
    this.ordered_items = this.ordered_items.reduce((filtered, item) => {
      if (item.id !== materialId) {
        const filteredAutomatically = item.automatically_ordered_marketing_items.filter(item => {
          return item.id !== materialId;
        });
        filtered.push({
          ...item,
          automatically_ordered_marketing_items: filteredAutomatically
        });
      }
      return filtered;
    }, []);
    this.store.rootStore.alertsStore.createAlert({
      severity: "success",
      message: t("alerts.materialDeleted", { materialId })
    });
  }

  @action.bound async clearOrder() {
    const { method, url } = urls.orders.clearDraft;
    return await this.store.rootStore.makeRequest(
      this.updateLocalOrder,
      method,
      `${url}/${this.id}/clear`,
      {},
      { location_fcid: this.store.rootStore.locationStore.activeLocation.fcid }
    );
  }
}
