import React from "react";
import { Collapse as AntCollapse } from "antd";

const Collapse = ({
  expandIconPosition = "right",
  expandIcon,
  panels,
  ...restProps
}) => {
  return (
    <AntCollapse
      expandIcon={expandIcon}
      expandIconPosition={expandIconPosition}
      {...restProps}
    >
      {panels.map(panel => {
        return (
          <AntCollapse.Panel header={panel.header} key={panel.id}>
            {panel.content}
          </AntCollapse.Panel>
        );
      })}
    </AntCollapse>
  );
};

export default Collapse;
