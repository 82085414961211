import React, { forwardRef } from "react";
import { InputNumber as AntInput } from "antd";

import "../styles.scss";
import ValidationError from "../validationError";
import Label from "../label";

const InputNumberAccessibility = forwardRef(({ label, antLabel, ...props }, ref) => {
  const isRequired = props['data-__meta'] && props['data-__meta'].rules && Boolean(props['data-__meta'].rules.find(item => item.required))
  const id = props && props._id ? props._id : props.id;
  return (
    <>
      {label && (
        <Label
          htmlFor={id}
          text={label}
          antLabel={antLabel}
          required={isRequired}
        />
      )}
      <AntInput
        {...props}
        ref={ref}
        id={id}
        aria-describedby={props['data-__field'] && props['data-__field'].errors && props['data-__field'].errors.map((_, i) => `${id}-error-${i}`).join(' ')}
        aria-required={isRequired}
        aria-invalid={props['data-__field'] && props['data-__field'].errors && Boolean(props['data-__field'].errors.length)}
        aria-label={label ? undefined : props['data-__field'] && props['data-__field'].name ? props['data-__field'].name : undefined}
      />
      {props['data-__field'] && props['data-__field'].errors && props['data-__field'].errors.map((err, i) => {
        return (
          <ValidationError
            key={i}
            id={`${id}-error-${i}`}
            errorText={err.message}
          />
        )
      })}
    </>
  )
});

export default InputNumberAccessibility;