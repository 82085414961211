import React from "react";
import Tag from "../../tag";
import MaterialsList from "../materialsList";

const SubZone = ({ zoneName, name, material }) => (
  <>
    <Tag className="tag--filter">
      {zoneName}: <span>{name}</span>
    </Tag>
    <MaterialsList material={material} zoneName={zoneName} subZoneName={name} />
  </>
);

export default SubZone;
