import React from "react";
import { proxy } from "../../common/apiUrls";

const AssociatedMaterialCarouselItem = ({ photo, code }) => (
  <div className="slide">
    <img className="slide__img" src={`${proxy}${photo.small_thumb}`} alt={`material-${code}`} />
    <span className="slide__code">{code}</span>
  </div>
);

export default AssociatedMaterialCarouselItem;
