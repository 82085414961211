import React, { useState, useEffect } from "react";
import { Form } from "antd";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";

import MenuButton from "./menuButton";
import GlobalSearch from "./globalSearch";
import { ChangeFCModal } from "../modals";
import LocationAutocomplete from "../../pages/pickLocation/locationAutocomplete";
import { ShoppingCartPopover, NotificationsPopover, UserPopover } from "../popovers";
import settings from "./settings";

import "./styles.scss";

const Header = ({ catalogStore, checkoutStore, locationStore, menuStore }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [visible, setVisible] = useState(false);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (locationStore.activeLocation) {
      catalogStore.initLocation();
    }
  }, [catalogStore, locationStore.activeLocation]);

  const handleSetLocation = location => {
    if (locationStore.activeLocation && locationStore.activeLocation.fcid === location.fcid) {
      return;
    }
    setLocation(location);
    if (checkoutStore.draftNotEmpty) {
      setVisible(true);
    } else {
      catalogStore.setActiveLocation(location);
    }
  };

  const onSearchEnterPressHandler = value => {
    const searchParams = new URLSearchParams({
      search: value
    });
    const url = search ? `${pathname}?${searchParams}` : `/search/all?${searchParams}`;
    history.push(url);
  };

  const onOkHandler = () => {
    setVisible(false);
    catalogStore.setActiveLocation(location);
    checkoutStore.draftOrder.clearOrder();
  };

  const onCancelHandler = () => {
    setVisible(false);
  };

  return (
    <>
      <Grid className="header-container" wrap="wrap" container justifyContent="space-between" alignItems="center">
        <Grid className="header-container__left-wrapper" item>
          <div role="navigation" className="flex flex-wrap">
            <MenuButton expanded={!menuStore.menuState.collapsed} onClick={menuStore.callbackCollapse} />
            <div className="header-wrapper__location-select">
              <LocationAutocomplete
                hideLabel
                locationicon="display"
                value={locationStore.activeLocation}
                setValue={handleSetLocation}
                endAdornmentClassName="location-autocomplete__end-adortment svg-white"
                styleOverrides={settings.styleOverrides}
              />
            </div>
            <div className="location-input-header">
              <GlobalSearch onPressEnter={onSearchEnterPressHandler} />
            </div>
          </div>
        </Grid>
        <Grid className="header__actions" item>
          <ShoppingCartPopover />
          <NotificationsPopover />
          <UserPopover />
        </Grid>
      </Grid>
      <ChangeFCModal visible={visible} onYes={onOkHandler} onNo={onCancelHandler} />
    </>
  );
};

export default Form.create({ name: "" })(
  inject(({ rootStore }) => ({
    catalogStore: rootStore.catalogStore,
    checkoutStore: rootStore.checkoutStore,
    locationStore: rootStore.locationStore,
    menuStore: rootStore.menuStore
  }))(observer(Header))
);
