const isDevEnv = process.env.REACT_APP_ENV === "development";

export const getBackendHostName = () => {
  return isDevEnv ? `http://localhost:3001` : `https://${process.env.REACT_APP_BACKEND_DOMAIN_NAME}`;
};

export const getWSHostName = () => {
  return isDevEnv ? `ws://localhost:3000` : `wss://${process.env.REACT_APP_BACKEND_DOMAIN_NAME}`;
};

export const getClientHostName = () => {
  return isDevEnv ? `http://localhost:3001` : `https://${process.env.REACT_APP_FRONTEND_DOMAIN_NAME}`;
};
