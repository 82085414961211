export const customAtmFormSettings = {
  atmAutocomplete: {
    optionProps: {
      id: "fcid",
      title: "site_name"
    },
    id: "custom-atm__value"
  },
  reasonSelect: {
    name: "reason"
  },
  comments: {
    name: "comments",
    _id: "custom-atm__comments",
    maxLength: 2000
  }
};
