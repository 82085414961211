import React, { forwardRef } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import ValidationError from "../validationError";

const MuiFormlabel = withStyles({
  root: {
    margin: "0 !important",
    paddingLeft: "10px !important",
    fontSize: "12px !important",
    lineHeight: "24px !important",
    color: "#545454 !important",
    border: "none !important",
    textTransform: "capitalize",
    fontFamily: `'Segoe UI', Arial, Helvetica, sans-serif`
  },
  asterisk: {
    position: "absolute",
    left: 0,
    color: "#DC1431"
  }
})(FormLabel);

const MuiRadio = withStyles({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    border: "1px solid #EDEDED !important",
    borderRight: "none !important",
    borderRadius: 0,
    background: "transparent",
    "&.first-child": {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    "&.last-child": {
      borderRight: "1px solid #EDEDED !important",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    "& svg": {
      display: "none"
    },
    "&.focus": {
      border: "2px solid red !important"
    }
  },
  checked: {
    backgroundColor: "#0073CF",
    "&:hover": {
      backgroundColor: "#0073CF !important"
    },
    "&+span": {
      color: "white"
    }
  }
})(Radio);

const MuiFormControlLabel = withStyles({
  root: {
    position: "relative",
    margin: 0,
    height: 36,
    padding: "0 20px",
    lineHeight: "34px",
    textTransform: "uppercase",
    background: "white",
    color: "#0073CF",
    fontWeight: "bold"
  },
  label: {
    position: "relative",
    fontSize: "14px !important",
    fontWeight: 900,
    fontFamily: `'Segoe UI', Arial, Helvetica, sans-serif`
  }
})(FormControlLabel);

const MaterialRadioGroup = forwardRef(
  ({ value, onChange, options, name, label, rules, ...rest }, ref) => {
    const required = Boolean(rules.find(item => item.required));
    const id = rest && rest._id ? rest._id : rest.id;
    return (
      <FormControl component="fieldset">
        <MuiFormlabel component="legend" required={required}>
          {label}
        </MuiFormlabel>
        <RadioGroup
          ref={ref}
          aria-describedby={
            rest["data-__field"].errors
              ? rest["data-__field"].errors
                  .map((_, i) => `${id}-error-${i}`)
                  .join(" ")
              : null
          }
          aria-label={label}
          name={name}
          value={value}
          onChange={onChange}
          row
        >
          {options.map((option, i) => (
            <MuiFormControlLabel
              key={i}
              value={option.id}
              control={
                <MuiRadio
                  focusVisibleClassName="focus"
                  className={`${i === 0 ? "first-child" : ""} ${
                    i === options.length - 1 ? "last-child" : ""
                  }`}
                  required={required}
                />
              }
              label={option.title}
            />
          ))}
        </RadioGroup>
        {rest["data-__field"] &&
          rest["data-__field"].errors &&
          rest["data-__field"].errors.map((err, i) => (
            <ValidationError
              key={`${err.message}-${i}`}
              id={`${id}-error-${i}`}
              errorText={err.message}
            />
          ))}
      </FormControl>
    );
  }
);

export default MaterialRadioGroup;
