export default {
  MuiButton: {
    root: {
      border: "1px solid transparent",
      "&:focus": {
        border: "1px solid red",
      },
      "&.focus-transparent-border:focus": {
        borderColor: "transparent",
      },
    },
  },

  MuiFormLabel: {
    root: {
      fontSize: 12,
      marginBottom: 5,
    },
  },
  MuiOutlinedInput: {
    multiline: {
      padding: "4px 11px 4px 11px",
    },
  },
  MuiDialogTitle: {
    root: {
      fontWeight: 700,
    },
  },
};
