import React from "react";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const Label = ({ id, htmlFor = "", text = "", hidden = false, required = false, useRequireOnLabel = false, antLabel }) => {
  const { t } = useTranslation();

  const showInLabel = useRequireOnLabel && required;

  return !antLabel ? (
    <label id={id} aria-required={required || undefined} className={`form-label ${hidden ? "hidden" : ""} ${required ? "required" : ""}`} htmlFor={htmlFor}>
      {text}
      <span className="required-text">{showInLabel ? t("required") : ""}</span>
    </label>
  ) : (
    <div className="ant-col ant-form-item-label">
      <label
        id={id}
        aria-required={required || undefined}
        className={`form-label ${hidden ? "hidden" : ""} ${required ? "required" : ""} regular`}
        htmlFor={htmlFor}
      >
        {text}
        <span className="required-text">{showInLabel ? t("required") : ""}</span>
      </label>
    </div>
  );
};

export default Label;
