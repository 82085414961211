import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";
import { Notification } from "../../models";
import { t } from "i18next";

export default class NotificationsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable notifications = [];
  @observable allLoaded = false;
  @observable isLoading = false;
  @observable unread = 0;
  @observable pagination = {
    page: 1,
    per_page: 50,
    total: 0
  };

  @action.bound async getNotifications() {
    const { url, method } = urls.getNotifications;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.saveNotifications, method, url, this.query);
    this.isLoading = false;
  }

  @action.bound saveNotifications({ data, total }) {
    if (!data.length || data.length < this.pagination.per_page) this.allLoaded = true;
    this.pagination.total = total;
    this.unread = total;
    this.notifications = [...this.notifications, ...data.map(this.createNotificationInstance.bind(this))];
  }

  createNotificationInstance(notification) {
    return new Notification({
      store: this,
      ...notification
    });
  }

  @action.bound async readNotifications() {
    const { url, method } = urls.readNotification;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onReadNotificationsSuccess, method, url, {}, { notification: { ids: this.alreadyReadNotifications } });
    this.isLoading = false;
  }

  @action.bound onReadNotificationsSuccess() {
    const readNotifications = this.alreadyReadNotifications;
    this.notifications = this.notifications.filter(({ id }) => !readNotifications.includes(id));
    this.unread -= readNotifications.length;
  }

  @action.bound onWsNotificationHandler(notificationData) {
    const notification = this.createNotificationInstance(notificationData);
    this.notifications = [notification, ...this.notifications];
    if (notification.isTemplateNotification) {
      this.rootStore.alertsStore.createAlert({
        severity: notification.data.status,
        message: notification.data.message,
        withAction: true,
        action: {
          onClickHandler: () => {
            this.rootStore.navigationStore.push(`/templates/${notification.data.id}/edit`);
          },
          actionText: t("alerts.open")
        }
      });
    }
    this.unread += 1;
  }

  @action.bound async markAllAsRead() {
    const { url, method } = urls.readAllNotifications;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onAllNotificationsRead, method, url);
    this.isLoading = false;
  }

  @action.bound onAllNotificationsRead() {
    this.notifications = [];
    this.pagination.page = 1;
    this.allLoaded = false;
    this.unread = 0;
  }

  @action resetPagination() {
    this.notifications = [];
    this.pagination.page = 1;
    this.allLoaded = false;
  }

  @action increasePage() {
    this.pagination.page += 1;
  }

  @computed get alreadyReadNotifications() {
    return this.notifications.filter(({ isRead }) => isRead).map(({ id }) => id);
  }

  @computed get hasReadNotifications() {
    return this.notifications.some(({ isRead }) => isRead);
  }

  @computed get query() {
    const { page, per_page } = this.pagination;
    return { page, per_page };
  }

  @computed get unreadNotifications() {
    return this.unread;
  }
}
