import React from "react";

import Collapse from "../collapse";
import List from "../list";
import AssociatedMaterialListItem from "./associatedMaterialListItem";
import "./styles.scss";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const AssociatedMaterials = ({ data, editable, setOrderFormRef, onDelete }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const wrappedComponentRef = form => {
    setOrderFormRef && setOrderFormRef(form);
  };

  const panels = [
    {
      id: 1,
      header: <span className={classes.text}>{t("associatedMaterials.materialsAddedAutomatically", { count: data.length })}</span>,
      content: (
        <List
          className="associated-material-list"
          dataSource={data}
          renderItem={data => (
            <AssociatedMaterialListItem onDelete={onDelete} wrappedComponentRef={wrappedComponentRef} formId={data.id} editable={editable} material={data} />
          )}
        />
      )
    }
  ];

  const getExpandIcon = ({ isActive }) => {
    return <span className="custom-collapse-expand bold">{isActive ? t("associatedMaterials.hideDetails") : t("associatedMaterials.showDetails")}</span>;
  };

  if (!data.length) return null;
  return <Collapse className="associated-materials-collapse" panels={panels} expandIcon={getExpandIcon} />;
};

export default AssociatedMaterials;
