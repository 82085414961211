import React from "react";

import MuiDialog from "../../muiDialog";
import PdfViewer from "../../pdfViewer";

import { settings } from "./settings";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const PreviewModal = ({ onCancel, visible, previewLink, base64, useFrame }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const content = base64 ? <PdfViewer useFrame={useFrame} url={base64} /> : <img className={classes.image} src={`${previewLink}`} alt="pdf" />;

  const actions = [
    {
      text: t("modals.cancel"),
      color: "secondary",
      onClick: onCancel
    },
    {
      text: t("modals.ok"),
      autoFocus: true,
      primary: true,
      onClick: onCancel
    }
  ];

  return <MuiDialog maxWidth="lg" open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.preview")} {...settings} />;
};

export default PreviewModal;
