import React from "react";
import Drawer from "@material-ui/core/Drawer";

import { Title, FiltersList } from "./components";

const FiltersMenu = ({ isOpen, onReset, onApply, onClose }) => (
  <Drawer
    classes={{
      paper: "drawer-paper",
    }}
    anchor="right"
    open={isOpen}
    onClose={onClose}
  >
    <Title onApply={onApply} onClose={onClose} onReset={onReset} />
    <FiltersList expandIconPosition="right" />
  </Drawer>
);

export default FiltersMenu;
