import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    marginRight: 20,
    color: "#fff",
    [breakpoints.down("sm")]: {
      marginRight: 0
    },
    ["@media (max-width:765px)"]: {
      marginLeft: 8
    }
  },
  icon: {
    fontSize: 40,
    [breakpoints.down("sm")]: {
      fontSize: 20
    }
  }
}));
