import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import { inject, observer } from "mobx-react";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { validateForm } from "../../../../utils";
import Button from "../../../../components/button";
import ProgressButton from "../../../../components/progressButton";
import { FormGenerator } from "../../../../components/formComponents";

import "./styles.scss";

@inject("rootStore")
@observer
class CheckoutTemplatePdfForm extends Component {
  constructor() {
    super();
    this.state = { open: true };
  }

  onGeneratePdfFormHandler = async () => {
    const formInstance = await validateForm(this.props.form);
    if (formInstance.errors) return;
    await this.props.rootStore.checkoutStore.draftOrder.generatePdf(formInstance, this.props.material);
  };

  onResetHandler = e => {
    e.stopPropagation();
    this.props.form.resetFields();
  };

  handleClick = e => {
    e.stopPropagation();
    this.setState({ open: !this.state.open });
  };

  render() {
    const { form, rootStore, material, t } = this.props;
    const { checkoutStore } = rootStore;

    if (!material.isTemplateItem || !material.hasPdfForm) return null;

    return (
      <>
        <ListItem button onClick={this.handleClick} style={{ background: "#F9F9F9" }}>
          <div className="collapse-title">
            <h2 className="title"> {t("pages.checkout.pdfForm")}</h2>
            <ListItemSecondaryAction style={{ right: 45 }}>
              <Box mr={2} component="span">
                <ProgressButton isLoading={checkoutStore.draftOrder.isLoading} variant="outlined" onClick={this.onGeneratePdfFormHandler}>
                  {t("pages.checkout.generatePdf")}
                </ProgressButton>
              </Box>
              <Button onClick={this.onResetHandler}>{t("pages.checkout.reset")}</Button>
            </ListItemSecondaryAction>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </div>
        </ListItem>
        <Collapse in={this.state.open}>
          <FormGenerator form={form} fields={material.item.pdfFormFields} />
        </Collapse>
      </>
    );
  }
}

export default CheckoutTemplatePdfForm;
