import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: "100%",
    backgroundColor: palette.background.paper,
  },
  firstLevelListItem: {
    padding: "13px 15px 13px 15px",
    "&:hover": {
      backgroundColor: palette.menu.hover,
    },
    "&:focus": {
      backgroundColor: palette.menu.focus,
    },
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#4a4a4a",
  },
  collapsed: {
    padding: "13px 15px 13px 20px",
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 700,
    color: "#545454",
  },
  iconWrapper: {
    minWidth: 36,
  },
  icon: {
    fontSize: 24,
    color: palette.primary.main,
  },
  nested: {
    paddingLeft: 14,
  },
  doubleNested: {
    paddingLeft: 50,
  },
}));
