import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class TagsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable tags = [];
  @observable isLoading = false;

  @action.bound async getTags() {
    const { method, url } = urls.tags.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetTagsSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onGetTagsSuccess({ data }) {
    this.tags = data;
  }

  @computed get dropdownValues() {
    return this.tags.map(({ id, name }) => ({ id, label: name, value: name }));
  }

  @computed get filters() {
    return this.tags.map(({ name }) => ({ supertitle: "", title: name, value: name }));
  }
}
