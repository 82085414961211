import React from "react";
import PaginatedStyledTypography from "../paginatedStyledTypography";

const PaginatedListOption = ({ optionProps, ...rest }) => (
    <div>
        <div className="flex">
            <PaginatedStyledTypography variant="subtitle1" noWrap>
                {rest[optionProps.id]}
            </PaginatedStyledTypography>
            <span>/</span>
            <PaginatedStyledTypography variant="subtitle2" noWrap>
                {rest[optionProps.title]}
            </PaginatedStyledTypography>
        </div>
    </div>
)

export default PaginatedListOption;