import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import Button from "../../../../components/button";

import { useStyles } from "../../styles";
import { useTranslation } from "react-i18next";

const Header = ({ onGoBack, onSave }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onGoBackHandler = () => {
    if (!onGoBack) return;
    onGoBack();
  };

  const onSaveHandler = () => {
    if (!onSave) return;
    onSave();
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Button icon="left" type="secondary" onClick={onGoBackHandler}>
          {t("pages.editTemplate.goBack")}
        </Button>
        <Box component="span" ml={3}>
          <h1 className={classes.title}>{t("pages.editTemplate.title")}</h1>
        </Box>
      </Grid>
      <Grid item>
        <Button type="primary" onClick={onSaveHandler}>
          {t("pages.editTemplate.saveChanges")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
