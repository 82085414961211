import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    createTemplateForm: {
      "& .ant-form-explain": {
        display: "none",
      },
    },
  })
);
