import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ zIndex }) =>
  createStyles({
    title: {
      display: "inline-block",
      fontWeight: "600 !important",
      fontSize: 20,
    },
    backdrop: {
      zIndex: zIndex.drawer + 1,
      color: "#fff",
    },
  })
);
