import { observable, action } from "mobx";

import { urls } from "../../common/apiUrls";

export default class InstructionsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable pages = [];
  @observable isLoading = false;

  @action.bound async getData() {
    const { method, url } = urls.textPages;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onSuccess({ texts }) {
    this.pages = texts;
  }
}
