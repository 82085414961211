import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import MuiDialog from "../../muiDialog";
import { settings } from "./settings";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const PlaceCustomOrderModal = ({ visible, onCancel, feedbackStore }) => {
  const { t } = useTranslation();
  const defaultActions = [
    {
      text: t("modals.openHistory"),
      color: "secondary",
      onClick: goToOrdersHistory
    },
    {
      text: t("modals.gotIt"),
      autoFocus: true,
      primary: true,
      onClick: goToCatalog
    }
  ];
  const history = useHistory();
  const [actions, setActions] = useState(defaultActions);

  useEffect(() => {
    if (feedbackStore.activeFeedbackExist) {
      setActions([
        {
          text: t("modals.survey"),
          color: "secondary",
          onClick: goToFeedback
        },
        ...defaultActions
      ]);
    }
  }, [feedbackStore.activeFeedbackExist]);

  function goToCatalog() {
    onCancel();
    history.push("/catalog");
  }

  function goToOrdersHistory() {
    onCancel();
    history.push("/orders-history");
  }

  function goToFeedback() {
    onCancel();
    history.push("/survey");
  }

  const content = (
    <div>
      <p>{t("modals.placeCustomOrderContent")}</p>
      {feedbackStore.activeFeedbackExist && <span>{t("pages.feedback.prompt")}</span>}
    </div>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.placeCustomOrderTitle")} {...settings} />;
};

export default inject(({ rootStore }) => ({
  feedbackStore: rootStore.feedbackStore
}))(observer(PlaceCustomOrderModal));
