import React from "react";
import { List } from "antd";
import MaterialCard from "../../materialCard";

export const grid = {
  gutter: 24,
  xs: 1,
  lg: 2,
  xl: 3
};

export const renderListItemHandler = (material, zoneName, subZoneName) => (
  <List.Item>
    <MaterialCard
      zoneName={zoneName}
      subZoneName={subZoneName}
      material={material}
    />
  </List.Item>
);
