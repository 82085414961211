import React from "react";
import Grid from "@material-ui/core/Grid";
import { Controller } from "react-hook-form";

import { ReasonsSelect } from "../../selects";
import { customAtmFormSettings } from "./settings";
import TextareaWithCounter from "../../formComponents/textareaWithCounter";
import PaginatedAutocomplete from "../../formComponents/paginatedAutocomplete";
import { useTranslation } from "react-i18next";

const { atmAutocomplete, reasonSelect, comments } = customAtmFormSettings;

const CustomAtmOrderFrom = ({ formId, control, errors, onSubmit, getAtms, setSelectedReason, commentsPlaceHolder }) => {
  const { t } = useTranslation();

  const atmAutocompleteRules = {
    required: {
      value: true,
      message: t("customAtmOrderForm.atmAutocomplete.rules.required")
    }
  };

  const reasonSelectRules = {
    required: {
      value: true,
      message: t("customAtmOrderForm.reasonSelect.rules.required")
    }
  };

  const commentsRules = {
    required: {
      value: true,
      message: t("customAtmOrderForm.comments.rules.required")
    }
  };

  const antTypeData = {
    reason: {
      "data-__meta": {
        rules: Object.keys(reasonSelectRules).map(key => ({
          message: reasonSelectRules[key].message,
          required: reasonSelectRules[key].value
        }))
      },
      "data-__field": {
        errors: errors && errors.reason && errors.reason.message ? [{ message: errors.reason.message }] : []
      }
    },
    comments: {
      "data-__meta": {
        rules: Object.keys(commentsRules).map(key => ({
          message: commentsRules[key].message,
          required: commentsRules[key].value
        }))
      },
      "data-__field": {
        errors: errors && errors.comments && errors.comments.message ? [{ message: errors.comments.message }] : []
      }
    }
  };

  return (
    <div className="material-details">
      <div className="flex flex-between">
        <h2>{t("customAtmOrderForm.atmDetails")}</h2>
      </div>
      <form onSubmit={onSubmit} id={formId}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12}>
            <Controller
              render={props => (
                <PaginatedAutocomplete
                  {...props}
                  {...atmAutocomplete}
                  placeholder={t("customAtmOrderForm.atmAutocomplete.placeholder")}
                  label={t("customAtmOrderForm.atmAutocomplete.label")}
                  rules={atmAutocompleteRules}
                  error={errors.atm}
                  getOptions={getAtms}
                />
              )}
              name="atm"
              control={control}
              defaultValue={""}
              rules={atmAutocompleteRules}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Controller
              render={props => (
                <ReasonsSelect
                  {...props}
                  {...antTypeData.reason}
                  placeholder={t("customAtmOrderForm.reasonSelect.placeholder")}
                  filters={["atm", "remote_atm"]}
                  onChange={val => {
                    props.onChange(val);
                    setSelectedReason(val);
                  }}
                  label={{
                    text: t("customAtmOrderForm.reasonSelect.label")
                  }}
                  name={`custom-atm_${reasonSelect.name}`}
                />
              )}
              name={reasonSelect.name}
              rules={reasonSelectRules}
              control={control}
              defaultValue={""}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              render={props => (
                <TextareaWithCounter
                  {...props}
                  {...comments}
                  {...antTypeData.comments}
                  placeholder={commentsPlaceHolder}
                  label={{
                    text: t("customAtmOrderForm.comments.label")
                  }}
                  rules={commentsRules}
                />
              )}
              name={comments.name}
              rules={commentsRules}
              control={control}
              defaultValue=""
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CustomAtmOrderFrom;
