export const fullKitSettings = {
  layout: "vertical",
  name: "customRequest",
  formId: "fullKitForm",
  campaign: {
    name: "campaign"
  },
  reason: {
    name: "reason"
  },
  comments: {
    name: "comments",
    maxLength: 2000
  }
};
