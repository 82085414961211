import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import { inject, observer } from "mobx-react";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory, useLocation, useParams } from "react-router-dom";

import CatalogWarning from "../../../../components/catalogWarning";
import { RequestSupportModal } from "../../../../components/modals";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { featureToggleFilter } from "../../../../utils/featureToggle/featureToggle";

const SearchSidebar = ({ searchStore, supportStore, locationStore, disableOtherFcCheckbox, withOtherFc, onOtherFcChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const { catalogName } = useParams();
  const currentCatalog = catalogName || "all";
  const [selectedValue, setSelectedValue] = useState(currentCatalog);
  const [showSupportRequest, setShowSupportRequest] = useState(false);

  const alertMessage = t("pages.searchResults.alertMessage");

  useEffect(() => {
    setSelectedValue(currentCatalog);
  }, [currentCatalog]);

  const onMaterialTypeChangeHandler = e => {
    history.push(`/search/${e.target.value}${search}`);
    setSelectedValue(e.target.value);
    onOtherFcChange(false);
  };

  const onCustomOrderHandler = () => {
    history.push("/custom-order");
  };

  const onHelpRequestHandler = () => {
    setShowSupportRequest(true);
  };

  const onSupportRequestSubmitHandler = async data => {
    await supportStore.sendSupportOrder({
      data,
      location_fcid: locationStore.fullLocationData.fcid,
      order_type: "general_support"
    });
    setShowSupportRequest(false);
  };

  return (
    <>
      <Box boxShadow={3} p={2} mb={2}>
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <div className="sidebar-card__header flex-wrap flex-between">
            <FormLabel component="legend">
              <span className={classes.title}>{t("pages.searchResults.searchFilters")}</span>
            </FormLabel>
          </div>
          <Box mb={1} paddingLeft={1}>
            <RadioGroup onChange={onMaterialTypeChangeHandler} aria-label={t("pages.searchResults.materialTypeLabel")} value={selectedValue}>
              {featureToggleFilter(searchStore.materialTypes || [], "value").map(({ value, name }) => (
                <div key={value} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <FormControlLabel value={value} control={<Radio size="small" color="primary" />} label={name} />
                  <span>{value === "all" ? searchStore.searchResultsAmount : searchStore.itemsFound[value]}</span>
                </div>
              ))}
            </RadioGroup>
            <Box mt={2}>
              <div className={classes.container}>
                <span className={classes.text}>{t("pages.searchResults.includeResultsFrom")}</span>
                <FormControlLabel
                  label={t("pages.searchResults.otherFinancialCenters")}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={disableOtherFcCheckbox}
                      checked={withOtherFc}
                      onChange={({ target }) => onOtherFcChange(target.checked)}
                    />
                  }
                />
              </div>
            </Box>
          </Box>
        </FormControl>
      </Box>
      <CatalogWarning text={alertMessage} onCustomOrder={onCustomOrderHandler} onHelpRequest={onHelpRequestHandler} />
      <RequestSupportModal visible={showSupportRequest} onCancel={() => setShowSupportRequest(false)} onSubmitCb={onSupportRequestSubmitHandler} />
    </>
  );
};

export default inject(({ rootStore }) => ({
  searchStore: rootStore.searchStore,
  supportStore: rootStore.supportStore,
  locationStore: rootStore.locationStore
}))(observer(SearchSidebar));
