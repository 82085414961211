import React from "react";
import { useEffect } from "react";
import List from "../list";
import { renderListItemHandler } from "./settings";
import Spin from "../spin";
import { useTranslation } from "react-i18next";

const CatalogList = ({ loading, dataSource }) => {
  const { t } = useTranslation();
  const handleSetNoneRoleListItems = () => {
    const catalogListItems = document.querySelectorAll(".list--campaigns ul, list--campaigns li");
    catalogListItems.forEach(listItem => listItem.setAttribute("role", "none"));
  };

  useEffect(() => {
    if (!loading) {
      handleSetNoneRoleListItems();
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />
      ) : (
        <List className="list--campaigns" loading={loading} dataSource={dataSource} renderItem={renderListItemHandler} />
      )}
    </>
  );
};

export default CatalogList;
