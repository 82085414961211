import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./styles.scss";
import LexicalWrapper from "../../components/lexicalWrapper";
import { PrimaryButton } from "../../components/formComponents";
import { OutlinedInput } from "@material-ui/core";
import RouteLeavingGuard from "../../components/routeLeavingGuard";
import { useTranslation } from "react-i18next";

const Feedback = ({ feedbackStore }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [radioValue, setRadioValue] = useState(feedbackStore.currentAnswer);
  const [additionalInfoValue, setAdditionalValue] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    feedbackStore.getActiveFeedback();
    return () => resetFeedback();
  }, [feedbackStore]);

  useEffect(() => {
    setRadioValue(feedbackStore.currentAnswer);
  }, [feedbackStore.currentQuestionIndex]);

  const onNextPress = () => {
    setIsUploading(true);
    feedbackStore.updateUserFeedback(onSuccessUpload, onErrorUpload);
  };

  const onSuccessUpload = () => {
    feedbackStore.incrementCurrentQuestionIndex();
    setIsUploading(false);
  };

  const onBackPress = () => {
    feedbackStore.decrementCurrentQuestionIndex();
  };

  const onChangeRadioGroup = e => {
    setRadioValue(e.target.value);
    feedbackStore.changeCurrentQuestionAnswer(e.target.value);
  };

  const onChangeAdditionalInfo = e => {
    setAdditionalValue(e.target.value);
    feedbackStore.changeAdditionalInfo(e.target.value);
  };

  const onSubmitFeedback = () => {
    setIsSubmitted(true);
    setIsUploading(true);
    feedbackStore.updateUserFeedback(onSuccessSubmit, onErrorUpload);
  };

  const onErrorUpload = () => {
    setIsUploading(false);
    setIsSubmitted(false);
  };

  const onSuccessSubmit = () => {
    feedbackStore.submitUserFeedback(() => setIsSubmitted(true), onErrorUpload);
  };

  const resetFeedback = () => {
    feedbackStore.isLoading = true;
    feedbackStore.resetFeedback();
  };

  const onExitFeedback = () => {
    history.push(`/`);
  };

  return (
    <>
      {!feedbackStore.isLoading && (
        <div className="feedback">
          <div>
            <div className="flex-between heading">
              <div>
                <Typography variant="h1">{feedbackStore.activeFeedback?.survey_title || "Feedback"}</Typography>
              </div>
              <Button size="large" variant="text" color="secondary" onClick={onExitFeedback}>
                {t("pages.feedback.exitSurvey")}
              </Button>
            </div>
            <div className="content">
              {!isSubmitted && feedbackStore.activeFeedback?.survey_description_json && (
                <LexicalWrapper body_json={feedbackStore.activeFeedback?.survey_description_json} />
              )}
              {!isSubmitted && !feedbackStore.isAdditionalInfo && (
                <>
                  <Typography variant="h1" style={{ textDecoration: "underline", textUnderlinePosition: "under", textAlign: "center", padding: "1em 0" }}>
                    {t("pages.feedback.questionOf", {
                      currentQuestionIndex: feedbackStore.currentQuestionIndex,
                      numberOfQuestion: feedbackStore.numberOfQuestion
                    })}
                  </Typography>
                  <Typography variant="h1" style={{ textAlign: "center" }}>
                    {feedbackStore.currentQuestion}
                  </Typography>
                  <FormControl component="fieldset" fullWidth="true">
                    <RadioGroup row name="options" onChange={onChangeRadioGroup} aria-label={t("pages.feedback.optionsLabel")} value={radioValue}>
                      {feedbackStore.currentOptions.map(([value, label]) => {
                        return (
                          <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio color="primary" size="medium" />}
                            label={<span style={{ fontSize: 20 }}>{label}</span>}
                            labelPlacement="bottom"
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </>
              )}
              {!isSubmitted && feedbackStore.isAdditionalInfo && (
                <>
                  <Typography variant="h1" style={{ textDecoration: "underline", textUnderlinePosition: "under", textAlign: "center", padding: "1em 0" }}>
                    {t("pages.feedback.additionalInformation")}
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    color="secondary"
                    minRows={10}
                    autoFocus
                    id="info"
                    multiline
                    placeholder={feedbackStore.activeFeedback?.additional_information}
                    onChange={onChangeAdditionalInfo}
                    value={additionalInfoValue}
                  />
                </>
              )}
              {isSubmitted && (
                <Typography variant="h1" style={{ padding: "1em 0" }}>
                  {t("pages.feedback.submittedMsg")}
                </Typography>
              )}
            </div>
          </div>
          {!isSubmitted && (
            <div className="box-buttons--right">
              {feedbackStore.isBackButtonAvailable && (
                <Button size="large" variant="text" color="secondary" onClick={onBackPress}>
                  {t("pages.feedback.back")}
                </Button>
              )}
              {feedbackStore.isNextButtonAvailable && (
                <Button size="large" variant="text" color="secondary" disabled={!radioValue} onClick={onNextPress}>
                  {t("pages.feedback.next")}
                </Button>
              )}
              {!feedbackStore.isNextButtonAvailable && (
                <PrimaryButton size="large" disabled={!(radioValue || feedbackStore.isAdditionalInfo) || isUploading} onClick={onSubmitFeedback}>
                  {t("pages.feedback.submit")}
                </PrimaryButton>
              )}
            </div>
          )}
        </div>
      )}
      <RouteLeavingGuard when={!isSubmitted} forward={true} />
    </>
  );
};

export default inject(({ rootStore }) => ({
  feedbackStore: rootStore.feedbackStore
}))(observer(Feedback));
