import { action, observable } from "mobx";

import { generateId } from "../../../utils";
import i18next, { t } from "i18next";
import { featureToggleFilter } from "../../../utils/featureToggle/featureToggle";

export default class CustomOrderForm {
  constructor(props) {
    const getMaterialTypes = () => {
      return [
        { id: "custom_marketing", title: t("customOrderForm.customMarketing"), value: "marketing" },
        { id: "custom_marketing_cling", title: t("customOrderForm.customMarketingCling"), value: "marketingcling" },
        { id: "custom_merchandising", title: t("customOrderForm.customMerchandising"), value: "fixtures & signage" },
        { id: "custom_other", title: t("customOrderForm.customOther"), value: "" }
      ];
    };
    const onInitialize = () => {
      i18next.off("initialized", onInitialize);
      this.activeMaterialType = { id: "custom_marketing", title: t("customOrderForm.customMarketing"), value: "marketing" };
      this.materialTypes = featureToggleFilter(getMaterialTypes(), "value");
    };

    Object.assign(this, props);
    if (!i18next.isInitialized) {
      i18next.on("initialized", onInitialize);
    } else {
      this.activeMaterialType = { id: "custom_marketing", title: t("customOrderForm.customMarketing"), value: "marketing" };
      this.materialTypes = featureToggleFilter(getMaterialTypes(), "value");
    }
  }

  @observable store = null;
  @observable id = generateId();
  @observable activeMaterialType = {};
  @observable zones = [];
  @observable subzones = [];
  @observable groups = [];
  @observable selectedReason = {};
  @observable selectedZoneId = null;
  @observable materialTypes = [];

  @action.bound onMaterialTypeChange({ target }) {
    this.activeMaterialType = this.materialTypes.find(materialType => materialType.id === target.value);
    this.selectedReason = {};
  }

  @action.bound setSelectedReason({ value }) {
    const { id } = JSON.parse(value);
    this.selectedReason = this.store.rootStore.reasonsStore.findReasonById(id);
  }

  @action.bound setSelectedZoneId(zoneId) {
    this.selectedZoneId = zoneId;
  }
}
