export const materialOrderForm = {
  formName: "materialOrder",
  layout: "vertical",
  subZone: {
    name: "subzone_id"
  },
  quantity: {
    name: "quantity",
    type: "number"
  },
  reason: {
    name: "reason",
    optionValue: "body"
  },
  comment: {
    name: "comments"
  },
  associatedMaterials: {
    name: "marketing_item_ids"
  }
};
