import React, { forwardRef, useState } from "react";
import { Upload, Icon } from "antd";
import ValidationError from "../validationError";
import { useTranslation } from "react-i18next";

const { Dragger } = Upload;

const UploadFile = forwardRef(({ ...props }, ref) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);
  const onBeforeUpload = file => {
    setFileList([file]);
    return false;
  };

  const onRemoveHandler = () => {
    setFileList([]);
  };

  return (
    <>
      <Dragger ref={ref} beforeUpload={onBeforeUpload} multiple={false} accept={".pdf"} onRemove={onRemoveHandler} {...props} fileList={fileList}>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">{t("uploadFile.uploadText")}</p>
      </Dragger>
      {props["data-__field"] &&
        props["data-__field"].errors &&
        props["data-__field"].errors.map((err, i) => <ValidationError key={i} id={`${i}-error-${i}`} errorText={err.message} />)}
    </>
  );
});

export default UploadFile;
