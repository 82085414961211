import React, { Component } from "react";
import { Form } from "antd";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { validationRulesOptions } from "../settings";
import { pdfFormValidationRules } from "../../../../constants";
import InputNumberAccessibility from "../../inputNumberAccessibility";
import { withTranslation } from "react-i18next";

class ValidationForm extends Component {
  getPlaceholder = key => {
    const { t } = this.props;
    return t("formValidationGenerator.getPlaceholder", { key: key });
  };

  getFieldByType = key => {
    switch (key) {
      case pdfFormValidationRules.max:
      case pdfFormValidationRules.min:
        return <InputNumberAccessibility label={key} _id={key} antLabel placeholder={this.getPlaceholder(key)} {...validationRulesOptions[key]} />;
      case pdfFormValidationRules.required:
        return <FormControlLabel label={key} control={<Checkbox color="primary" />} />;
      default:
        return null;
    }
  };

  render() {
    const { form, field, t } = this.props;
    return (
      <Form layout="vertical">
        <span style={{ display: "block", fontSize: 18, fontWeight: 600, marginBottom: 10 }}>
          {t("formValidationGenerator.validationRules", { name: field.name })}
        </span>
        <Grid container spacing={2} alignItems="flex-end">
          {Object.keys(field.constraints).map(key => {
            const initialValue = field.constraints[key];
            const options = { initialValue };
            if (key === pdfFormValidationRules.required) options.valuePropName = "checked";
            return (
              <Grid item>
                <Form.Item key={key}>{form.getFieldDecorator(key, options)(this.getFieldByType(key))}</Form.Item>
              </Grid>
            );
          })}
        </Grid>
      </Form>
    );
  }
}

export default withTranslation()(
  Form.create({
    name: "request"
  })(ValidationForm)
);
