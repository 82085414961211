import React, { Component } from "react";
import { Spin, Form, message } from "antd";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";

import Button from "../../components/button";
import Sidebar from "../../components/sidebar";
import { orderStatuses } from "../../constants";
import UserCard from "../../components/userCard";
import OrderDate from "../../components/orderDate";
import { CheckoutTemplatePdfForm } from "./components";
import { validateForm, validateForms } from "../../utils";
import { pdfFormError, userLeavesPage } from "./settings";
import { CheckoutOrderForm } from "../../components/forms";
import CheckoutMaterial from "../../common/models/checkoutMaterial";
import FinancialCenterCard from "../../components/financialCenterCard";
import { PlaceOrderModal, EmptyCheckoutModal } from "../../components/modals";
import PdfFormErrorsNotification from "./components/pdfFormErrorsNotification";
import DeliveryInformationTitle from "../../components/deliveryInformationTitle";

import "./styles.scss";
import { withTranslation } from "react-i18next";

@inject("rootStore")
@observer
class Checkout extends Component {
  constructor(props) {
    super(props);
    this.orderForms = {};
    this.pdfForms = {};
    this.customUserFormRef = null;
    this.locationListener = null;
    this.state = {
      placeOrderModalVisibility: false,
      cleanOrderModalVisibility: false,
      pdfFormErrorsModalVisibility: false,
      pdfFormsErrors: {}
    };
  }

  componentDidMount() {
    this.locationListener = this.props.history.subscribe(location => {
      if (location.pathname !== "/checkout" && this.props.rootStore.checkoutStore.draftOrder.ordered_items.length) {
        setTimeout(() => {
          this.props.rootStore.alertsStore.createAlert({
            severity: "success",
            message: userLeavesPage
          });
        }, 0);
        this.updateTouchedForms();
      }
    });
    setTimeout(() => {
      const skipToMainContent = document.getElementById("skipToMainContent");
      if (skipToMainContent) {
        skipToMainContent.focus();
      }
    }, 2);
  }

  componentWillUnmount() {
    this.locationListener();
  }

  saveFormRef = form => {
    if (!form) return;
    this.customUserFormRef = form;
  };

  setOrderFormRef = form => {
    if (!form || form === this.orderForms[form.props.formId]) return;
    this.orderForms[form.props.formId] = form;
  };

  setPdfForm = form => {
    if (!form) return;
    this.pdfForms[form.props.formId] = form;
  };

  onGenerateHandler = () => {
    Object.keys(this.pdfForms).forEach(key => {
      this.pdfForms[key].onGeneratePdfFormHandler();
    });
    message.destroy();
  };

  onCancelHandler = () => {
    Object.keys(this.pdfForms).forEach(key => {
      const { form } = this.pdfForms[key].props;
      form.resetFields();
    });
    message.destroy();
  };

  getPdfFormErrors = () => {
    const pdfFormsErrors = {};
    Object.keys(this.pdfForms).forEach(key => {
      const { form, material } = this.pdfForms[key].props;
      if (!form.isFieldsTouched()) return;
      pdfFormsErrors[material.id] = pdfFormError;
    });
    return pdfFormsErrors;
  };

  onPlaceOrderHandler = async () => {
    const t = this.props.t;
    const pdfFormsErrors = this.getPdfFormErrors();

    if (!isEmpty(pdfFormsErrors)) {
      const config = {
        content: (
          <PdfFormErrorsNotification pdfFormsErrors={pdfFormsErrors} onGenerateHandler={this.onGenerateHandler} onCancelHandler={this.onCancelHandler} />
        ),
        duration: 0
      };
      message.info(config);
      return;
    }

    const isFormsValid = await validateForms(this.orderForms);
    if (!isFormsValid) {
      this.props.rootStore.alertsStore.createAlert({
        severity: "error",
        message: t("pages.checkout.formValidationError")
      });
      return;
    }
    this.updateTouchedForms(this.onUpdateTouchedFormsSuccess);
  };

  getOrderReceiver = async () => {
    if (!this.customUserFormRef) return this.getSelectedUserId();
    let formResult = await validateForm(this.customUserFormRef);
    if (formResult.errors) return;
    return { custom_user: formResult };
  };

  getSelectedUserId = () => {
    const { rootStore } = this.props;
    const userId = rootStore.checkoutStore.activeSelectValue ? rootStore.checkoutStore.activeSelectValue.id : rootStore.authorizationStore.user.id;
    return { user_id: userId };
  };

  updateTouchedForms = async onSuccess => {
    let allowSuccess = true;
    for (let i = 0; i < Object.keys(this.orderForms).length; i++) {
      const key = Object.keys(this.orderForms)[i];
      const { material, form } = this.orderForms[key].props;
      if (!form.isFieldsTouched()) continue;
      const formValues = form.getFieldsValue(["quantity", "reason", "comments"]);
      if (!formValues.reason) {
        allowSuccess = false;
        return;
      }
      const { id, body } = JSON.parse(formValues.reason.value);
      formValues.reason = body;
      formValues.order_reason_id = id;
      const [, error] = await this.props.rootStore.checkoutStore.draftOrder.updateMaterial(
        {
          ...formValues,
          item_id: material.id,
          item_type: material.item.item_type
        },
        form
      );
      allowSuccess = error ? false : allowSuccess;
    }
    if (allowSuccess && onSuccess) {
      onSuccess();
    }
  };

  onUpdateTouchedFormsSuccess = async () => {
    const receiver = await this.getOrderReceiver();
    if (!receiver) return;
    await this.props.rootStore.checkoutStore.draftOrder.updateOrderStatus(receiver, orderStatuses.new);
    if (this.props.rootStore.checkoutStore.draftOrder.hasError) return;
    this.toggleModalVisibility("placeOrderModalVisibility");
  };

  onDeleteHandler = async id => {
    const { draftOrder } = this.props.rootStore.checkoutStore;
    await draftOrder.deleteMaterial(id);
    if (draftOrder.ordered_items.length > 0) return;
    this.toggleModalVisibility("cleanOrderModalVisibility");
  };

  toggleModalVisibility = modal => {
    this.setState({
      [modal]: !this.state[modal]
    });
  };

  renderCheckoutItem = material => {
    const reason = this.props.rootStore.reasonsStore.findReasonById(material.order_reason_id);
    const materialInstance = new CheckoutMaterial({
      store: this.props.rootStore.checkoutStore,
      filterKey: reason.ordering_type,
      selectedReason: reason,
      ...material
    });

    const WrapperCheckoutTemplatePdfForm = withTranslation()(Form.create({ name: `pdfForm-${material.item.id}` })(CheckoutTemplatePdfForm));

    return (
      <div key={material.id}>
        <CheckoutOrderForm
          material={materialInstance}
          formId={material.id}
          wrappedComponentRef={this.setOrderFormRef}
          setOrderFormRef={this.setOrderFormRef}
          onDelete={this.onDeleteHandler}
        />
        <Box mb={4}>
          <WrapperCheckoutTemplatePdfForm wrappedComponentRef={this.setPdfForm} formId={material.id} material={materialInstance} />
        </Box>
      </div>
    );
  };

  render() {
    const t = this.props.t;
    const { checkoutStore, reasonsStore, departmentsStore } = this.props.rootStore;
    const orderLoaded = !checkoutStore.draftOrder.pending;
    const isPlaceOrderDisable = !checkoutStore.draftOrder.ordered_items.length || checkoutStore.draftOrder.pending || checkoutStore.draftOrder.isLoading;

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={9}>
            <div className="checkout">
              <div className="heading flex-between">
                <div>
                  <Typography variant="h1">
                    {t("pages.checkout.title")} {checkoutStore.draftOrder.isLoading && <span>{t("pages.checkout.draftSaving")}</span>}
                  </Typography>
                  <p className="heading__subtitle">{t("pages.checkout.subTitle")}</p>
                </div>
                <Button size="large" type="primary" onClick={this.onPlaceOrderHandler} disabled={isPlaceOrderDisable}>
                  {t("pages.checkout.placeOrder")}
                </Button>
              </div>
              <div style={{ minHeight: 200 }} className="spinning-wrapper">
                {checkoutStore.draftOrder.status !== "new_order" && (
                  <>
                    {!orderLoaded || checkoutStore.pending || reasonsStore.isLoading || departmentsStore.isLoading ? (
                      <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />
                    ) : (
                      <div className="checkout-materials-list">
                        {!checkoutStore.pending && !reasonsStore.isLoading && checkoutStore.draftOrder.ordered_items.map(this.renderCheckoutItem)}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="box-buttons--right">
                <Button size="large" type="primary" onClick={this.onPlaceOrderHandler} disabled={isPlaceOrderDisable}>
                  {t("pages.checkout.placeOrder")}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Sidebar>
              <DeliveryInformationTitle />
              <OrderDate />
              <FinancialCenterCard editable={true} />
              <UserCard formRefHandler={this.saveFormRef} />
            </Sidebar>
          </Grid>
        </Grid>
        <PlaceOrderModal visible={this.state.placeOrderModalVisibility} onCancel={this.toggleModalVisibility} />
        <EmptyCheckoutModal open={this.state.cleanOrderModalVisibility} onCancel={this.toggleModalVisibility} />
      </>
    );
  }
}

export default withTranslation()(Checkout);
