import React from "react";
import Box from "@material-ui/core/Box";
import { inject, observer } from "mobx-react";

import Icon from "../../../icon";
import Image from "../../../image";
import Button from "../../../button";

import { useStyles } from "../../styles";
import { useTranslation } from "react-i18next";

const ImagePreview = ({ templateStore, onPreview }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onDeleteHandler = () => {
    templateStore.template.deletePdf();
  };

  const onUpdateHandler = () => {
    templateStore.template.content.sample.thumbnailPath = "";
    templateStore.template.content.sample.path = "";
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.actions}>
        <Box mr={1}>
          <Button variant="contained" aria-label={t("templatePreview.deletePdfLabel")} onClick={onDeleteHandler}>
            <Icon type="delete" />
          </Button>
        </Box>
        <Button variant="contained" aria-label={t("templatePreview.updatePdf")} onClick={onUpdateHandler}>
          t("templatePreview.updatePdf")
        </Button>
      </div>
      <Button className={classes.previewBtn} variant="contained" aria-label={t("templatePreview.zoomOutLabel")} onClick={onPreview}>
        <Icon type="arrows-alt" />
      </Button>
      <Image url={templateStore.templatePreview} placeholderIconSize={200} alt="" />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  templateStore: rootStore.templateStore
}))(observer(ImagePreview));
