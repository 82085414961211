export const userOrderForm = {
  layout: "vertical",
  formName: "customUserOrder",
  name: {
    name: "full_name"
  },
  phone: {
    name: "phone"
  },
  email: {
    name: "email"
  }
};
