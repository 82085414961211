import React, { useState, useEffect, useRef } from "react";
import Grow from "@material-ui/core/Grow";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Icon from "../../icon";
import List from "../../list";
import { useStyles } from "../styles";
import ShoppingCartListItem from "./shoppingCartListItem";
import PrimaryButton from "../../formComponents/primaryButton";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const ShoppingCartPopover = ({ checkoutStore }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  useEffect(() => {
    checkoutStore.getDraftOrder();
  }, [checkoutStore]);

  const onDeleteHandler = id => {
    checkoutStore.draftOrder.deleteMaterial(id);
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const goToCheckout = () => {
    history.push("/checkout");
    handleToggle();
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton
        className={classes.iconButton}
        ref={anchorRef}
        aria-controls={open ? "cart-menu" : undefined}
        aria-expanded={open}
        aria-label={t("shoppingCartPopover.cartItemsListLabel")}
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <Badge overlap="rectangular" badgeContent={checkoutStore.draftOrder.materialsQuantity} color="primary">
          <Icon style={{ color: "#fff" }} type="cart" />
        </Badge>
      </IconButton>
      <Popper open={open} style={{ zIndex: 1 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ padding: "5px 17px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div id="cart-menu" className="shopping-cart-popover" onKeyDown={handleListKeyDown}>
                  <div className="shopping-cart-popover__title">
                    <div className="shopping-cart-popover__title-left-wrapper">
                      <Icon type="cart" />
                      <span className="title-text uppercase bold">{t("shoppingCartPopover.cart")}</span>
                    </div>
                    <PrimaryButton onClick={goToCheckout}>{t("shoppingCartPopover.checkout")}</PrimaryButton>
                  </div>
                  <List
                    id="notifications menu"
                    className="popover--shopping-cart__list"
                    itemLayout="horizontal"
                    loading={checkoutStore.draftOrder.pending}
                    dataSource={checkoutStore.draftOrder.orderedItemsWithAdditionalMaterials}
                    renderItem={material => <ShoppingCartListItem onDelete={onDeleteHandler} material={material} />}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default inject(({ rootStore }) => ({
  checkoutStore: rootStore.checkoutStore
}))(observer(ShoppingCartPopover));
