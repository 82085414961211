import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import Icon from "../icon";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const WarningNotification = ({ warning_type, message, onDelete }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [visible, setVisible] = useState(true);
  const history = useHistory();

  const button = {
    expireCampaign: {
      text: t("warningNotification.selectDifferentItem"),
      onClickHandler: () => history.push("/catalog")
    },
    anotherFC: {
      text: t("warningNotification.delete"),
      onClickHandler: onDelete
    }
  };

  function closeHandler() {
    setVisible(false);
  }

  if (!visible) return null;

  return (
    <Grid className={classes.previousCampaignAlert} container justifyContent="space-between" alignItems="center">
      <Grid item>
        <div className={classes.leftBlock}>
          <Icon type="support" />
          <span className={classes.alertText}>{message}</span>
        </div>
      </Grid>
      <Grid item>
        <Box mr={1} component="span">
          <Button
            disableRipple
            aria-label={`${button[warning_type].text}`}
            style={{ fontWeight: 600 }}
            color="secondary"
            onClick={button[warning_type].onClickHandler}
          >
            {button[warning_type].text}
          </Button>
        </Box>

        <Button
          disableRipple
          aria-label={t("warningNotification.ok")}
          style={{ backgroundColor: "#fff", fontWeight: 600 }}
          color="secondary"
          type="contain"
          onClick={closeHandler}
        >
          {t("warningNotification.ok")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default WarningNotification;
