import React, { useEffect, useState } from "react";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const ChangeRequestSent = ({ visible, onCancel, feedbackStore }) => {
  const { t } = useTranslation();
  const defaultActions = [
    {
      text: t("modals.gotIt"),
      autoFocus: true,
      primary: true,
      onClick: onCancel
    }
  ];
  const history = useHistory();
  const [actions, setActions] = useState(defaultActions);

  useEffect(() => {
    if (feedbackStore.activeFeedbackExist) {
      setActions([
        {
          text: t("modals.survey"),
          color: "secondary",
          onClick: goToFeedback
        },
        ...defaultActions
      ]);
    }
  }, [feedbackStore.activeFeedbackExist]);

  function goToFeedback() {
    onCancel();
    history.push("/survey");
  }

  const content = (
    <div>
      <p>{t("modals.changeRequestSentContent")}</p>
      {feedbackStore.activeFeedbackExist && <span>{t("pages.feedback.prompt")}</span>}
    </div>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.changeRequestSentTitle")} {...settings} />;
};

export default inject(({ rootStore }) => ({
  feedbackStore: rootStore.feedbackStore
}))(observer(ChangeRequestSent));
