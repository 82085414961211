import React from "react";

import MuiDialog from "../../muiDialog";

import { useStyles } from "./styles";
import { settings } from "./settings";
import { proxy } from "../../../common/apiUrls";

import LexicalWrapper from "../../lexicalWrapper";
import { useTranslation } from "react-i18next";

const AlertModal = ({ visible, alert, onCancel }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { body_json, headline, image } = alert;

  const actions = [
    {
      text: t("modals.ok"),
      autoFocus: true,
      onClick: onCancel,
      primary: true
    }
  ];

  const content = (
    <div className={classes.content}>
      {image && <img className={classes.image} src={`${proxy}${image.medium_thumb}`} alt="" />}
      <LexicalWrapper body_json={body_json} />
    </div>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={headline} {...settings} />;
};

export default AlertModal;
