import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import Spin from "../spin";
import { useScript } from "../../hooks";

import { script } from "./settings";
import { useTranslation } from "react-i18next";

const Map = ({ financialCenterStore, locationStore }) => {
  const { t } = useTranslation();
  const [loaded, error] = useScript(script);

  useEffect(() => {
    loaded && !error && financialCenterStore.initMap(locationStore.fullLocationData.coordinates);
  }, [loaded, error, financialCenterStore, locationStore.fullLocationData.coordinates]);

  return (
    <Spin tip={t("loading")} spinning={!loaded} role="alert" aria-busy="true">
      <div id={financialCenterStore.mapID} className="map-wrapper" />
    </Spin>
  );
};

export default inject(({ rootStore }) => ({
  financialCenterStore: rootStore.financialCenterStore,
  locationStore: rootStore.locationStore
}))(observer(Map));
