import React from "react";
import { useForm, Controller } from "react-hook-form";
import { inject, observer } from "mobx-react";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import { Trans, useTranslation } from "react-i18next";
import { TextareaWithCounter } from "../../formComponents";

const useStyles = makeStyles({
  sendEmail: {
    "text-decoration": "underline",
    "text-transform": "lowercase !important",
    padding: 0,
    "&:hover": {
      "text-decoration": "underline"
    }
  }
});

const RequestSupportModal = ({ authorizationStore, visible, onCancel, onSubmitCb }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, handleSubmit, errors } = useForm();
  const { technical_support_email } = authorizationStore.config;
  const hasError = Boolean(errors.comments && errors.comments.message);

  const onSubmit = data => {
    onSubmitCb(data);
    onCancel();
  };

  const actions = [
    {
      text: t("modals.cancel"),
      color: "secondary",
      onClick: onCancel
    },
    {
      text: t("modals.send"),
      primary: true,
      onClick: handleSubmit(onSubmit)
    }
  ];

  const sendEmail = () => window.location.assign(`mailto:${technical_support_email}`);

  const content = (
    <>
      <p>
        <Trans
          i18nKey="modals.requestSupportContent"
          components={{
            br: <br />
          }}
        />{" "}
        <Button onClick={sendEmail} color="secondary" className={classes.sendEmail}>
          {technical_support_email}
        </Button>
      </p>
      <form>
        <InputLabel htmlFor="comments">
          <span style={{ color: "#DC1431" }}>* </span>
          {t("modals.comments")}
        </InputLabel>
        <Controller
          as={<TextareaWithCounter maxLength={2000} _id="comments" />}
          name="comments"
          control={control}
          defaultValue={""}
          rules={{
            required: {
              value: true,
              message: t("modals.commentsRequired")
            },
            maxLength: 2000
          }}
        />
        {hasError && (
          <FormHelperText error role="alert">
            {errors.comments.message}
          </FormHelperText>
        )}
      </form>
    </>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.requestSupport")} {...settings} />;
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(RequestSupportModal));
