import React from "react";
import { inject, observer } from "mobx-react";
import { Form } from "antd";

import { userOrderForm } from "./settings";
import "./styles.scss";
import InputAccessibility from "../../formComponents/inputAccessibility";
import { useTranslation } from "react-i18next";
import { onlyNumbersRegExpValidator } from "../../../utils";

const { layout, name, phone, email, formName } = userOrderForm;

const UserOrderForm = ({ form }) => {
  const { t } = useTranslation();

  const nameRules = [
    {
      min: 2,
      message: t("userOrderForm.name.rules.min")
    },
    { max: 100, message: t("userOrderForm.name.rules.max") },
    { required: true, message: t("userOrderForm.name.rules.required") }
  ];
  const phoneRules = [
    { required: true, message: t("userOrderForm.phone.rules.required") },
    {
      max: 16,
      message: t("userOrderForm.phone.rules.max")
    },
    {
      min: 7,
      message: t("userOrderForm.phone.rules.min")
    },
    {
      pattern: onlyNumbersRegExpValidator(),
      message: t("userOrderForm.phone.rules.pattern")
    }
  ];
  const emailRules = [
    {
      type: "email",
      message: t("userOrderForm.email.rules.type")
    },
    { required: true, message: t("userOrderForm.email.rules.required") }
  ];

  return (
    <Form className="custom-user-order" layout={layout}>
      <Form.Item help={false}>
        {form.getFieldDecorator(name.name, {
          rules: nameRules
        })(<InputAccessibility placeholder={t("userOrderForm.name.placeholder")} />)}
      </Form.Item>
      <Form.Item help={false}>
        {form.getFieldDecorator(phone.name, {
          rules: phoneRules
        })(<InputAccessibility label={t("userOrderForm.phone.label")} placeholder={t("userOrderForm.phone.placeholder")} />)}
      </Form.Item>
      <Form.Item help={false}>
        {form.getFieldDecorator(email.name, {
          rules: emailRules
        })(<InputAccessibility label={t("userOrderForm.email.label")} placeholder={t("userOrderForm.email.placeholder")} />)}
      </Form.Item>
    </Form>
  );
};

export default Form.create({ name: formName })(inject(({ rootStore }) => ({ checkoutStore: rootStore.checkoutStore }))(observer(UserOrderForm)));
