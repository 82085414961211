import React from "react";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const DeliveryInformationTitle = () => {
  const { t } = useTranslation();

  return <h2 className="info-title">{t("deliveryInformationTitle.deliveryInformation")}</h2>;
};

export default DeliveryInformationTitle;
