import { observable, action } from "mobx";

import { LocalStorageService } from "../../../services";
import i18next, { t } from "i18next";

export default class MenuStore {
  constructor(rootStore) {
    const onInitialize = () => {
      const myFinancialCenterKey = t("menuStore.myFinancialCenter") + " ";
      i18next.off("initialized", onInitialize);
      this.menuState.selectedKeys = [myFinancialCenterKey];
      this.menuState.lastActiveMenuKey = myFinancialCenterKey;
    };

    i18next.on("initialized", onInitialize);

    this.rootStore = rootStore;
    this.getMenuState();
  }

  @observable menuState = {
    rootSubmenuKeys: ["marketing", "marketing_cling", "merchandising", "regulatory", "healthSafety", "printOnDemand"],
    openKeys: ["marketing"],
    selectedKeys: [],
    collapsed: false,
    lastActiveMenuKey: ""
  };

  @action.bound onMenuOpenChange(openKeys) {
    const latestOpenKey = openKeys.find(key => this.menuState.openKeys.indexOf(key) === -1);
    let selectedKeys = this.selectedKeys;
    if (latestOpenKey) {
      selectedKeys =
        latestOpenKey === "marketing_cling" ||
        latestOpenKey === "merchandising" ||
        latestOpenKey === "regulatory" ||
        latestOpenKey === "healthSafety" ||
        latestOpenKey === "printOnDemand"
          ? [t("menuStore.myFinancialCenter")]
          : [t("menuStore.myFinancialCenter") + " "];
    }
    if (latestOpenKey) {
      this.rootStore.navigationStore.replace("/catalog");
    }
    if (this.menuState.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.updateMenuState({ openKeys, selectedKeys });
    } else {
      this.updateMenuState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
        selectedKeys
      });
    }
    if (latestOpenKey) {
      this.rootStore.catalogStore.setActiveCatalog(latestOpenKey);
      this.rootStore.catalogStore.activeCatalog.setFilters(selectedKeys[0]);
    }
  }

  @action.bound callbackCollapse() {
    this.updateMenuState({
      collapsed: !this.menuState.collapsed,
      openKeys: []
    });
  }

  @action.bound onSubMenuTitleClick({ key }) {
    if (!this.menuState.collapsed) return;
    this.onMenuOpenChange([key]);
  }

  @action.bound updateMenuState(newState) {
    this.menuState = { ...this.menuState, ...newState };
    LocalStorageService.save("menuState", this.menuState);
  }

  @action.bound resetMenuState() {
    this.menuState = { ...this.menuState, openKeys: [], selectedKeys: [] };
  }

  @action.bound async getMenuState() {
    this.menuState = (await LocalStorageService.get("menuState")) || this.menuState;
  }
}
