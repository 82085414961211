import React from "react";
import { inject, observer } from "mobx-react";

import CatalogSearchResults from "../CatalogSearchResults";

const FullResults = ({ searchStore }) => {
  return (
    <div>
      <div>{JSON.stringify(searchStore.results)}</div>
      {Object.keys(searchStore.fcSearchResults).map(key => (
        <CatalogSearchResults key={key} catalogName={key} />
      ))}
    </div>
  );
};

export default inject(({ rootStore }) => ({
  searchStore: rootStore.searchStore
}))(observer(FullResults));
