import React, { forwardRef } from "react";
import { Input as AntInput } from "antd";
import clsx from "clsx";

import Label from "../label";
import ValidationError from "../validationError";

import "../styles.scss";

const InputAccessibility = forwardRef(({ label, id, maxLength, showLength = true, onChange, onKeyDown, ...props }, ref) => {
  const isRequired = props["data-__meta"] && props["data-__meta"].rules && Boolean(props["data-__meta"].rules.find(item => item.required));
  const counterClasses = clsx("input-with-counter__counter", props && props.value && props.value.length === maxLength && "input-with-counter__counter--red");

  const handleKeyDown = e => {
    const { keyCode } = e;
    if (keyCode === 27) {
      e.target.value = "";
      onChange({ ...e });
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const onChangeHandler = e => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <>
      {label && <Label useRequireOnLabel required={isRequired} htmlFor={id} text={label} />}
      <div className="input-with-counter">
        <AntInput
          ref={ref}
          id={id}
          onKeyDown={handleKeyDown}
          onChange={onChangeHandler}
          aria-describedby={props["data-__field"] && props["data-__field"].errors && props["data-__field"].errors.map((_, i) => `${id}-error-${i}`).join(" ")}
          aria-required={isRequired}
          aria-invalid={props["data-__field"] && props["data-__field"].errors && Boolean(props["data-__field"].errors.length)}
          aria-label={label ? undefined : props["data-__field"] && props["data-__field"].name ? props["data-__field"].name : undefined}
          {...props}
        />
        {showLength && <span className={counterClasses}>{props.value && props.value.length}</span>}
      </div>
      {props["data-__field"] &&
        props["data-__field"].errors &&
        props["data-__field"].errors.map((err, i) => {
          return <ValidationError key={i} id={`${id}-error-${i}`} errorText={err.message} />;
        })}
    </>
  );
});

export default InputAccessibility;
