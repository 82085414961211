import React from "react";
import List from "../../list";
import { renderListItemHandler } from "./settings";

const Zone = ({ name, subzones }) => (
  <List
    dataSource={subzones}
    renderItem={subZone => renderListItemHandler(subZone, name)}
  />
);

export default Zone;
