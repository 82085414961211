import React from "react";
import Typography from "@material-ui/core/Typography";
import PowerBIDashboard from "./PowerBIDashboard";

const DashboardDigitalScreens = () => {
  return (
    <>
      <Typography style={{ marginTop: "12px", marginBottom: "12px" }} variant="h1">
        Digital Screens
      </Typography>
      <PowerBIDashboard reportId={process.env.REACT_APP_POWER_BI_DIGITAL_SCREENS_REPORT_ID} />
    </>
  );
};

export default DashboardDigitalScreens;
