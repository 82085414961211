import React from "react";
import startCase from "lodash/startCase";

import Tag from "../../components/tag";
import { DateService } from "../../services";
import { dateFormat } from "./settings";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const OrderDetails = ({ order }) => {
  const { t } = useTranslation();
  const orderTitles = {
    marketing: t("orderDetails.marketing"),
    marketing_cling: t("orderDetails.marketingClings"),
    merchandising: t("orderDetails.merchandising"),
    regulatory: t("orderDetails.regulatory"),
    healthSafety: t("orderDetails.healthSafety"),
    pod_template: t("orderDetails.printOnDemand")
  };

  return (
    <div className="order-details">
      <span className="order-details__order-title-item">{orderTitles[order.type] || "Print on Demand"}:</span>
      <ul className="flex order-details__list list">
        <li>
          <span>{t("orderDetails.orderId")}</span>
          <p className="semibold">{order.id}</p>
        </li>
        <li className="time">
          <span>{t("orderDetails.orderDateTime")}</span>
          <p>{DateService.getFormattedDate(order.time, dateFormat)}</p>
        </li>
        <li>
          <span className="block">{t("orderDetails.status")}</span>
          <Tag className="tag--yellow uppercase">{startCase(order.status)}</Tag>
        </li>
      </ul>
    </div>
  );
};

export default OrderDetails;
