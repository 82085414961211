import React from "react";
import { Spin } from "antd";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroller";

import List from "../list";
import { NotificationListItem } from "./notificationsListItems";
import { useTranslation } from "react-i18next";

const NotificationsList = ({ notificationsStore }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const hasMore = !notificationsStore.pending && !notificationsStore.allLoaded;
  const { isLoading } = notificationsStore;

  const onNotificationClickHandler = notification => {
    const url = notification.isTemplateNotification ? `/templates/${notification.data.id}/edit` : "/orders-history";
    history.push(url);
  };

  const onScrollHandler = () => {
    notificationsStore.increasePage();
    notificationsStore.getNotifications();
  };

  return (
    <div className="notifications-list-wrapper">
      <InfiniteScroll pageStart={0} useWindow={false} initialLoad={false} loadMore={onScrollHandler} hasMore={hasMore}>
        {isLoading ? (
          <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />
        ) : (
          <List
            rowKey={row => row.id}
            className="popover--notifications__list"
            itemLayout="horizontal"
            loading={notificationsStore.isLoading}
            dataSource={[...notificationsStore.notifications]}
            renderItem={notification => <NotificationListItem onClick={onNotificationClickHandler} notification={notification} />}
          />
        )}
      </InfiniteScroll>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  notificationsStore: rootStore.notificationsStore
}))(observer(NotificationsList));
