import React from "react";
import { List } from "antd";
import { inject, observer } from "mobx-react";

import { grid } from "./settings";
import PrintOnDemandMaterialCard from "../../components/printOnDemandMaterialCard";
import isEmpty from "lodash/isEmpty";

const PrintOnDemandCatalogList = ({ authorizationStore, catalogStore, templates, loading }) => {
  const renderItemHandler = item => (
    <List.Item>
      <PrintOnDemandMaterialCard template={item} />
    </List.Item>
  );

  if (isEmpty(catalogStore.activeCatalog.groupTemplatesByState(templates))) {
    return <List />;
  }

  if (authorizationStore.user.isAdmin) {
    return Object.keys(catalogStore.activeCatalog.groupTemplatesByState(templates)).map(key => (
      <List
        key={key}
        header={key}
        dataSource={key ? catalogStore.activeCatalog.groupTemplatesByState(templates)[key] : []}
        loading={loading}
        grid={grid}
        className="list--cards"
        renderItem={renderItemHandler}
      />
    ));
  }

  return <List dataSource={templates} loading={loading} grid={grid} className="list--cards" renderItem={renderItemHandler} />;
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  catalogStore: rootStore.catalogStore,
}))(observer(PrintOnDemandCatalogList));
