import { observable } from "mobx";

export default class Reason {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable store = null;
  @observable id = null;
  @observable body = "";
  @observable comments_placeholder = "";
  @observable comments_required = false;
  @observable material_type = "";
  @observable ordering_type = "";
}
