import React from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const CollapseIcon = ({ showIcon, collapsed }) => {
  if (!showIcon) return null;

  return collapsed ? <ExpandLess /> : <ExpandMore />;
};

export default CollapseIcon;
