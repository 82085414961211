import React, { forwardRef } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";

const StyledSelect = withStyles({
  root: {
    paddingLeft: 10,
    fontSize: 14,
    background: "white",
    border: "1px solid #EDEDED",
    borderRadius: 4,
    color: "#595959",
    textTransform: "none",
    "& em": {
      fontStyle: "normal"
    },
    "&:focus": {
      background: "white",
      borderColor: "#0073cf",
      borderRadius: 4
    },
    "&:hover": {
      borderColor: "#0073CF80"
    }
  },
  disabled: {
    background: "hsl(0,0%,95%) !important",
    cursor: "not-allowed !important"
  },
  icon: {
    marginRight: 1,
    color: "#0073cf"
  }
})(Select);

const MuiSelect = forwardRef(({ options, onChange, label, ...rest }, ref) => {
  const onChangeHandler = e => {
    if (!onChange) return;
    onChange(e.target.value);
  };

  return (
    <>
      {label && (
        <InputLabel id={`select-${rest.name}-label`}>{label}</InputLabel>
      )}
      <StyledSelect
        ref={ref}
        id={`select-${rest.name}-input`}
        labelId={label ? `select-${rest.name}-label` : null}
        onChange={onChangeHandler}
        disableUnderline
        {...rest}
      >
        {options.map(({ id, value }) => (
          <MenuItem key={id} value={id}>
            {value}
          </MenuItem>
        ))}
      </StyledSelect>
    </>
  );
});

export default MuiSelect;
