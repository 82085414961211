import React from "react";
import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { inject, observer } from "mobx-react";
import { Label } from "../../../components/formComponents";
import uniqBy from "lodash/uniqBy";

import "./styles.scss";
import ListOption from "./listOption";
import AutocompleteInput from "./autocompleteInput";
import { useTranslation } from "react-i18next";

const LocationAutocomplete = ({ locationStore, value, setValue, disabled, hideLabel, styleOverrides, endAdornmentClassName, locationicon }) => {
  const { t } = useTranslation();
  const { defaultLocations } = locationStore;
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(3);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState(defaultLocations);
  const [loading, setLoading] = useState(false);
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [searchTouched, setSearchTouched] = useState(false);
  const isIe = !!window.document.documentMode;

  useEffect(() => {
    if (!dropdownVisibility || locations.length >= total) return;
    setLoading(true);
    locationStore.getLocations({ page, search: searchTouched ? search : "" }, onSuccess);
  }, [dropdownVisibility, search, total, searchTouched]);

  useEffect(() => {
    setAllDataLoaded(false);
    setTotal(locations.length + 3);
  }, [search]);

  useEffect(() => {
    if (isIe) {
      const combobox = document.querySelector(`.location-autocomplete div[role="combobox"]`);
      if (combobox) {
        combobox.removeAttribute("role");
      }
    }
  }, [isIe]);

  const onSuccess = ({ data, total }) => {
    if (locations.length >= total) setAllDataLoaded(true);
    const newLocations = uniqBy([...locations, ...data.filter(deleteDefaultLocations)], "fcid").filter(s => s);
    setLocations(newLocations);
    setTotal(total);
    setPage(page + 1);
    setLoading(false);
  };

  const handleScrollListBox = event => {
    const listboxNode = event.currentTarget;
    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
      onScrollToBottomHandler();
    }
  };

  const onScrollToBottomHandler = () => {
    if (allDataLoaded) return;
    setLoading(true);
    locationStore.getLocations({ page, search: searchTouched ? search : "" }, onSuccess);
  };

  const onInputKeyDown = e => {
    const { keyCode } = e;
    if (keyCode === 40 || keyCode === 38) {
      const listboxNode = document.querySelector(".location-autocomplete__listBox");
      if (listboxNode) {
        if (listboxNode.scrollTop + listboxNode.clientHeight + 58 >= listboxNode.scrollHeight) {
          onScrollToBottomHandler();
        }
      }
    } else if (keyCode === 13 && !dropdownVisibility) {
      setDropdownVisibility(true);
    }
  };

  const handleChange = (_, newValue) => {
    setValue(newValue);
    setSearchTouched(false);
  };

  const handleInputChange = (e, newInputValue) => {
    setSearch(newInputValue);
    if (e) {
      setPage(1);
      setSearchTouched(true);
    }
  };

  const deleteDefaultLocations = location => {
    return !locationStore.defaultLocationsIds.includes(location.id);
  };

  return (
    <div className="location-autocomplete">
      {!hideLabel && <Label htmlFor="location-autocomplete-input" id={`location-autocomplete-label`} text="FC Name" />}
      {isIe && (
        <p id="ie-combobox-description" style={{ display: "none" }}>
          {t("pages.pickLocation.combobox")}
        </p>
      )}
      <Autocomplete
        disabled={disabled}
        open={dropdownVisibility}
        onOpen={() => setDropdownVisibility(true)}
        onClose={() => setDropdownVisibility(false)}
        value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={({ fcid, site_name, name }) => `${String(fcid)} ${site_name || name}`}
        options={locations}
        loading={loading}
        disableClearable
        inputValue={search}
        renderOption={ListOption}
        ListboxProps={{
          onScroll: handleScrollListBox,
          className: "location-autocomplete__listBox"
        }}
        renderInput={params => (
          <AutocompleteInput
            {...params}
            endAdornmentClassName={endAdornmentClassName}
            dropdownVisibility={dropdownVisibility}
            locationicon={locationicon}
            styleOverrides={styleOverrides}
            value={value}
            onInputKeyDown={onInputKeyDown}
            loading={loading}
            ariaDescribedBy="ie-combobox-description"
          />
        )}
      />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  locationStore: rootStore.locationStore
}))(observer(LocationAutocomplete));
