import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Button from "../button";

const MuiSnackbar = ({ visible, severity, message, withAction, action, onClose, duration }) => {
  const [open, setOpen] = useState(visible);

  useEffect(() => {
    const id = setTimeout(() => {
      setOpen(!open);
    }, duration);
    return () => {
      clearTimeout(id);
    };
  }, [duration, open]);

  const alertAction = withAction ? (
    <Button type="link" onClick={action.onClickHandler}>
      {action.actionText}
    </Button>
  ) : null;

  return (
    <Snackbar open={open} onClose={onClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={duration}>
      <Alert severity={severity} action={alertAction}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default MuiSnackbar;
