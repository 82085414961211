import React from "react";
import { Col, List, Row } from "antd";
import { inject, observer } from "mobx-react";
import startCase from "lodash/startCase";

import Tag from "../tag";
import Image from "../image";
import Button from "../button";
import TextBlock from "../textBlock";
import { photoSizes } from "../../constants";
import { proxy } from "../../common/apiUrls";
import { useCommentState } from "../../hooks";
import AssociatedMaterials from "../associatedMaterials";
import { useTranslation } from "react-i18next";

const OrdersHistoryItem = ({ departmentsStore, material }) => {
  const { t } = useTranslation();
  const {
    id,
    zone,
    subzone,
    quantity,
    reason,
    comments,
    automatically_ordered_marketing_items,
    item: { photo, code, merch_name, name, content, departmentId }
  } = material;
  const imageAlt = (photo && photo.description) || `${merch_name}-${code}`;
  const { commentState, toggleComment } = useCommentState({
    commentElId: id
  });
  const department = departmentsStore.findById(departmentId);
  const url = (photo && photo[photoSizes.medium] && `${proxy}/${photo[photoSizes.medium].slice(1)}`) || (content && content.sample.path);

  function checkIfZoneAndSubZoneExist() {
    return zone && zone.name && subzone && subzone.name;
  }

  function commentWrapperClassName() {
    return commentState.showFull ? "comment-wrapper" : "comment-wrapper additional-margin";
  }

  return (
    <List.Item className="orders-history-list-item">
      <div className="flex-start">
        <Image alt={imageAlt} url={url} size={184} />
        <div style={{ marginLeft: 24, width: "100%" }}>
          <div className="top-block">
            <div className="flex-between">
              <span className="orders-history-list-item__category block bold">{merch_name || name}</span>
              <Tag className={`order-table order-table--${material.status}`}>{startCase(material.status)}</Tag>
            </div>
            <span className="orders-history-list-item__code block">{code || (department && department.name)}</span>
            <span className="orders-history-list-item__zone block bold">{checkIfZoneAndSubZoneExist() && <span>{`${zone.name}: ${subzone.name}`}</span>}</span>
          </div>
          <Row gutter={16}>
            <Col className="gutter-row" span={5}>
              <TextBlock title={t("ordersHistoryItem.quantity")} content={quantity} />
            </Col>
            <Col className="gutter-row" span={12}>
              <TextBlock title={t("ordersHistoryItem.reason")} content={reason} />
            </Col>
          </Row>
          {!!comments && (
            <div className="bottom-block">
              <span className="label block">{t("ordersHistoryItem.comments")}</span>
              <div className={commentWrapperClassName()}>
                <p id={`${id}`} className={commentState.showFull ? "value " : "value comment"}>
                  {comments}
                </p>
              </div>
              {commentState.isOverflowing && (
                <Button className="show-all-comment bold" onClick={toggleComment} type="link">
                  {commentState.showFull ? t("ordersHistoryItem.showLess") : t("ordersHistoryCustomItem.showAll")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <AssociatedMaterials data={automatically_ordered_marketing_items} />
    </List.Item>
  );
};

export default inject(({ rootStore }) => ({
  departmentsStore: rootStore.departmentsStore
}))(observer(OrdersHistoryItem));
