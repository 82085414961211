import React from "react";
import { List } from "antd";
import Zone from "../zone";

export const renderListItemHandler = zone => (
  <List.Item>
    <Zone {...zone} />
  </List.Item>
);

export const dateFormat = "MM/DD/YYYY";
