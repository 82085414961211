import { observable, action, computed } from "mobx";

import { urls } from "../../common/apiUrls";
import { LocalStorageService } from "../../services";
import { Catalog, DetailedMaterial } from "../../common/models";
import i18next, { t } from "i18next";
import { profileAssignments } from "../../common/models/catalog/settings";
import { featureToggleFilter } from "../../utils/featureToggle/featureToggle";

export default class CatalogStore {
  constructor(rootStore) {
    const onInitialize = () => {
      const catalogDefault = [
        {
          topCatalogMenuKey: t("menuStore.myFinancialCenter") + " ",
          bottomCatalogMenuKey: t("menuStore.allMarketingMaterials"),
          catalogName: "marketing",
          catalogShowedName: t("menuStore.marketing")
        },
        {
          topCatalogMenuKey: t("menuStore.myFinancialCenter"),
          bottomCatalogMenuKey: t("menuStore.allMarketingClings"),
          catalogName: "marketing_cling",
          catalogShowedName: t("menuStore.marketingClings")
        },
        {
          topCatalogMenuKey: t("menuStore.myFinancialCenter"),
          bottomCatalogMenuKey: t("menuStore.allAvailableFixtures"),
          catalogName: "merchandising",
          catalogShowedName: t("menuStore.merchandising")
        },
        {
          topCatalogMenuKey: t("menuStore.myFinancialCenter"),
          bottomCatalogMenuKey: t("menuStore.allAvailableFixtures"),
          catalogName: "regulatory",
          catalogShowedName: t("menuStore.regulatory")
        },
        {
          topCatalogMenuKey: t("menuStore.myFinancialCenter"),
          bottomCatalogMenuKey: t("menuStore.allAvailableFixtures"),
          catalogName: "healthSafety",
          catalogShowedName: t("menuStore.healthSafety")
        },
        {
          catalogName: "printOnDemand",
          catalogShowedName: t("menuStore.printOnDemand")
        }
      ];
      i18next.off("initialized", onInitialize);
      this.catalogs = featureToggleFilter(catalogDefault || [], "catalogName").map(catalog => new Catalog({ store: this, ...catalog }));
      this.getActiveCatalog();
    };

    i18next.on("initialized", onInitialize);

    this.rootStore = rootStore;
    // this.getActiveCatalog();
  }

  @observable filters = [];
  @observable catalogs = [];
  @observable activeCatalog = {};
  @observable searchStore = {};
  @observable expandedMaterial = {};
  @observable activeCatalogFilters = {};
  @observable materialDataIsLoading = false;

  @action.bound initLocation() {
    const location = this.rootStore.locationStore.activeLocation;
    if (location) {
      this.updateLocationInCatalogs(location);
    }
  }

  @action.bound setActiveLocation(location) {
    this.updateLocationInCatalogs(location);
    this.rootStore.locationStore.setActiveLocation(location);
  }

  @action.bound updateLocationInCatalogs(location) {
    this.catalogs.forEach(catalog => catalog.setLocation(location));
  }

  @action.bound setActiveCatalog(key) {
    this.activeCatalog = this.getCatalogByName(key);
    LocalStorageService.save("catalogState", key);
  }

  @action.bound async getAdditionalMaterialData({ id, item_type, fixture_category }, errorCb) {
    let requestUrl;

    switch (item_type) {
      case "MarketingAssignment":
        requestUrl = urls.orderDetails.marketing;
        break;
      case "MarketingCling":
        requestUrl = urls.orderDetails.marketing_cling;
        break;
      case "MerchItemOccurence":
        switch (fixture_category) {
          case "regulatory":
            requestUrl = urls.orderDetails.regulatory;
            break;
          case "health_safety":
            requestUrl = urls.orderDetails.healthSafety;
            break;
          default:
            requestUrl = urls.orderDetails.merchandising;
        }
        break;
      default:
        requestUrl = urls.orderDetails.marketing;
    }
    const { method, url } = requestUrl;
    this.materialDataIsLoading = true;
    await this.rootStore.makeRequest(
      this.saveAdditionalMaterialData,
      method,
      url,
      {
        id,
        location_fcid: this.rootStore.locationStore.activeLocation.fcid
      },
      {},
      errorCb
    );
    this.materialDataIsLoading = false;
  }

  @action.bound saveAdditionalMaterialData(data) {
    const { inProfile, notInProfile } = profileAssignments;
    this.expandedMaterial = new DetailedMaterial({ store: this, ...data });
    this.expandedMaterial.updateProfileAssignment(this.expandedMaterial.total_quantity >= 1 ? inProfile : notInProfile);
  }

  @action.bound clearExpandedMaterial() {
    this.expandedMaterial = {};
  }

  @action.bound async getActiveCatalog() {
    const activeCatalogName = (await LocalStorageService.get("catalogState")) || "marketing";
    this.activeCatalog = this.getCatalogByName(activeCatalogName) || this.catalogs[0];
    this.filters = this.activeCatalog.filters;
  }

  @action.bound async getCatalogMaterials() {
    await this.activeCatalog.getCatalogMaterials();
  }

  @action.bound async getFilters() {
    await this.activeCatalog.getFilters();
  }

  @computed get activeCatalogZoneFilters() {
    return this.activeCatalog.zoneFilters;
  }

  @action.bound setZoneFilter(filter) {
    this.activeCatalog.setZoneActiveFilter(filter);
  }

  @action.bound resetZoneFilter() {
    this.catalogs.forEach(catalog => catalog.resetZoneFilter());
  }

  @action.bound findActiveCatalog() {
    return this.catalogs.find(catalog => catalog.searchResults.length) || this.catalogs[0];
  }

  @action.bound clearFilters() {
    this.activeCatalog.clearFilters();
    this.applyFilters();
  }

  @action.bound applyFilters() {
    this.activeCatalogFilters = this.activeCatalog.applyFilters();
  }

  @action.bound materialDataLoadingSet(loading) {
    this.materialDataIsLoading = loading;
  }

  getCatalogByName = name => {
    return this.catalogs.find(({ catalogName }) => catalogName === name);
  };
}
