export const validateForm = async form => {
  let res;
  try {
    res = await form.validateFields();
  } catch (e) {
    res = e;
  }
  return res;
};

export const validateForms = async forms => {
  let res;
  try {
    res = await Promise.all(
      Object.keys(forms).map(async key => {
        const form = forms[key];
        return form.props.form.validateFields(err => Boolean(err));
      })
    );
  } catch (e) {
    res = e;
  }
  return !res.errors;
};

export const handleArrayFormsErrors = (formErrors, forms, fieldSchema) => {
  Object.keys(formErrors).forEach(formIndex => {
    const formId = Object.keys(forms).find((_, i) => Number(i) === Number(formIndex));
    const form = forms[formId] && forms[formId].props ? forms[formId].props.form : null;
    const errorFields = formErrors[formIndex];

    handleFormErrors(errorFields, form, fieldSchema);
  });
};

export const handleFormErrors = (errorFields, form, fieldSchema) => {
  if (form) {
    const newFields = {};
    Object.keys(errorFields).forEach(field => {
      const formField = fieldSchema && fieldSchema[field] ? fieldSchema[field] : field;
      const errorMsgs = errorFields[field];
      const value = form.getFieldValue(formField);
      newFields[formField] = {
        value,
        errors: errorMsgs.map(err => new Error(err)),
      };
    });
    form.setFields(newFields);
  }
};
