import { observable, action, computed } from "mobx";
import { generateId } from "../../../utils";
import { t } from "i18next";

export default class Filter {
  @observable title = "";
  @observable name = "";
  @observable available_matchers = [{ available_values: [] }];
  @observable searchBar = { placeholder: t("filter.searchBarPlaceholder"), value: "" };
  @observable customTextField = { placeholder: t("filter.customTextFieldPlaceholder"), value: "" };
  @observable useSearch = true;
  @observable quantityToDisplay = 10;
  @observable customText = false;
  @observable showAllFilters = false;
  @observable values = [];
  @observable multiply = true;

  @action.bound filterClickHandler({ target }) {
    const activeByDefault = this.activeByDefault;

    const el = this.values.find(({ id }) => id === target.name);
    el.checked = target.checked;
    if (!target.checked) {
      const allValuesUnchecked = this.values.every(val => !val.checked);
      if (allValuesUnchecked && activeByDefault.length) activeByDefault.forEach(val => (val.checked = true));
    }
  }

  @action.bound inputChangeHandler({ target }) {
    this.searchBar.value = target.value;
  }

  @action.bound customSearchHandler({ target }) {
    this.customTextField.value = target.value;
  }

  @action.bound clearValues() {
    this.values.forEach(val => (val.checked = Boolean(val.default)));
    this.customTextField.value = "";
  }

  @action.bound toggleShowAllFilters() {
    this.showAllFilters = !this.showAllFilters;
  }

  @computed get checkedValues() {
    return {
      category: `${this.name}${this.available_matchers[0].name || ""}`,
      values: !this.multiply && this.filterValues.length > 1 ? [] : this.filterValues
    };
  }

  @computed get filterValues() {
    return this.customText
      ? this.customTextField.value
        ? [`*${this.customTextField.value}*`]
        : []
      : this.values.reduce((acc, val) => {
          return !val.checked ? [...acc] : [...acc, val.value];
        }, []);
  }

  @computed get activeByDefault() {
    return this.values.filter(val => val.default);
  }

  @computed get filteredValues() {
    const matchesFilter = new RegExp(this.searchBar.value, "i");
    return this.values.filter(({ title, supertitle }) => !this.searchBar.value || matchesFilter.test(`${supertitle} ${title}`));
  }

  @computed get amountCheckedValues() {
    return this.values.filter(({ checked }) => checked).length;
  }

  addCheckedValue = filter => {
    const filterId = typeof filter.value === "string" ? generateId() : filter.value;
    return { ...filter, checked: Boolean(filter.checked), id: filterId.toString() };
  };

  constructor(props) {
    Object.assign(this, props);
    this.values = this.available_matchers[0].available_values.map(this.addCheckedValue);
  }
}
