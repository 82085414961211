import { observable, action, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class FullKitCampaignsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable campaigns = [];
  @observable isLoading = false;

  @action.bound async getFullKitCampaigns() {
    const { method, url } = urls.campaigns.fullKit;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.saveCampaigns, method, url, { location_fcid: this.rootStore.locationStore.activeLocation.fcid });
    this.isLoading = false;
  }

  @action.bound async saveCampaigns(data) {
    this.campaigns = data;
  }

  @computed get isFullKitAvailable() {
    return !!this.campaigns.length;
  }
}
