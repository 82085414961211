import { useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

const useLocationChange = cb => {
  const location = useLocation();

  const callBack = useCallback(cb, []);

  useEffect(() => {
    if (location.pathname === "/") return;
    callBack();
  }, [location, callBack]);
};

export default useLocationChange;
