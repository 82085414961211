import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { List } from "antd";

import TextBlock from "../textBlock";
import { UserOrderForm } from "../forms";
import { useTranslation } from "react-i18next";

const UserCard = ({ formRefHandler, authorizationStore, locationStore, checkoutStore }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isCheckout = history.location.pathname === "/checkout";
  const userData = checkoutStore.activeSelectValue ? checkoutStore.activeSelectValue : authorizationStore.user;

  useEffect(() => {
    if (isCheckout && locationStore.activeLocation) checkoutStore.getUserForFC(locationStore.activeLocation.fcid);

    return checkoutStore.hideUserForm;
  }, [isCheckout, checkoutStore, locationStore.activeLocation]);

  return (
    <div className="sidebar-card">
      <div className="sidebar-card__header flex-wrap flex-between">
        <span className="sidebar-card__fin-title">{t("userCard.contactInformation")}</span>
      </div>
      <List className="sidebar-card__list">
        {authorizationStore.user.full_name && (
          <div>
            <div className="text-block">
              <span>{t("userCard.fullName")}</span>
              <p>{authorizationStore.user.full_name}</p>
            </div>
          </div>
        )}
        {checkoutStore.showUserForm && (
          <div style={{ marginTop: 16 }}>
            <UserOrderForm ref={formRefHandler} />
          </div>
        )}
        {!checkoutStore.showUserForm && (
          <>
            <div className="flex-between flex-wrap">
              <TextBlock title={t("userCard.role")} content={userData.userRole} />
            </div>
            <div>
              <TextBlock title={t("userCard.phone")} content={userData.phone} />
            </div>
            <div>
              <div className="text-block">
                <span>{t("userCard.email")}</span>
                <Grid container alignItems="center">
                  {userData.contact_email.split(".").map((val, index) => (
                    <Grid key={index} item>
                      <p style={{ fontSize: 15 }}>
                        {index ? "." : ""}
                        {val}
                      </p>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </>
        )}
      </List>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  locationStore: rootStore.locationStore,
  checkoutStore: rootStore.checkoutStore
}))(observer(UserCard));
