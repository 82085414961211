import React from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";

import Button from "../../components/button";

import "../noMatch/styles.scss";
import { useTranslation } from "react-i18next";

const NoPrimaryLocationError = ({ authorizationStore }) => {
  const { t } = useTranslation();
  const handleLogout = () => {
    authorizationStore.logout();
  };

  const title = t("pages.noPrimaryLocationError.title");
  const subTitle = t("pages.noPrimaryLocationError.subTitle");
  const description = t("pages.noPrimaryLocationError.description");

  return (
    <div className="page-wrapper">
      <div className="inner">
        <Typography variant="h1" className="title">
          {title}
        </Typography>
        <span className="subtitle bold block">{subTitle}</span>
        <span className="description bold block">{description}</span>
        <Button onClick={handleLogout}>{t("pages.noPrimaryLocationError.logout")}</Button>
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(NoPrimaryLocationError));
