import React from "react";
import Typography from "@material-ui/core/Typography";
import PowerBIDashboard from "./PowerBIDashboard";

const DashboardExteriorAttributes = () => {
  return (
    <>
      <Typography style={{ marginTop: "12px", marginBottom: "12px" }} variant="h1">
        Exterior Attributes
      </Typography>
      <PowerBIDashboard reportId={process.env.REACT_APP_POWER_BI_EXTERIORS_REPORT_ID} />
    </>
  );
};

export default DashboardExteriorAttributes;
