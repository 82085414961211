import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 20,
    color: "rgba(0, 0, 0, 0.85)",
  },
  text: {
    fontSize: 12,
    color: "#757575",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
}));
