import React from "react";
import Box from "@material-ui/core/Box";
import startCase from "lodash/startCase";
import { inject, observer } from "mobx-react";

import { catalogs } from "../../../../constants";
import Button from "../../../../components/button";
import CatalogList from "../../../../components/catalogList";
import PrintOnDemandCatalogList from "../../../../components/printOnDemandCatalogList/PrintOnDemandCatalogList";

import { useStyles } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CatalogSearchResults = ({ searchStore, departmentsStore, catalogName }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const results = searchStore.fcSearchResults[catalogName];
  const hasResults = results.length;
  const shouldShowShowAllBtn = searchStore.itemsFound[catalogName] > searchStore.resultsLimit;

  const onShowAllResultHandler = () => {
    history.push(`/search/${catalogName}${search}`);
  };

  if (!hasResults) return null;

  return (
    <Box mb={4}>
      <Box mb={2}>
        <h2 className={classes.title}>{startCase(catalogName)}</h2>
      </Box>
      {catalogName === catalogs.printOnDemand ? (
        <PrintOnDemandCatalogList loading={searchStore.isLoading || departmentsStore.isLoading} templates={results} />
      ) : (
        <CatalogList loading={searchStore.isLoading} dataSource={results} />
      )}
      {shouldShowShowAllBtn && (
        <Button type="primary" onClick={onShowAllResultHandler}>
          {t("pages.searchResults.showAllResults", { catalogName })}
        </Button>
      )}
    </Box>
  );
};

export default inject(({ rootStore }) => ({
  searchStore: rootStore.searchStore,
  departmentsStore: rootStore.departmentsStore
}))(observer(CatalogSearchResults));
