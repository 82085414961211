import { templateFileSize } from "../../../constants";

export const createTemplateForm = {
  formName: "createTemplate",
  layout: "vertical",
  file: {
    name: "file",
    options: {
      valuePropName: "file",
      rules: [
        { required: true, message: "This field is required" },
        {
          validator: (rule, value, callback) => {
            if (!value) {
              callback();
              return;
            }
            if (value.type !== "application/pdf") {
              callback("Only pdf allowed");
              return;
            }
            const isValid = value.size < templateFileSize;
            callback(!isValid ? "The document must be less than 20 MB" : undefined);
          },
        },
      ],
    },
  },
  name: {
    name: "name",
    label: "Name",
    placeholder: "Enter name",
    options: {
      rules: [
        { required: true, message: "This field is required" },
        { min: 3, message: "Value must be 3 to 50 characters" },
        { whitespace: true, message: "Value can't be empty" },
        {
          validator: function(rule, value, callback) {
            const regex = new RegExp(/^[a-z\d\-_\s]+$/i);
            const isValid = regex.test(value);
            callback(!isValid ? "Value must contain alphanumeric characters" : undefined);
          },
        },
      ],
    },
  },
  description: {
    name: "description",
    label: { text: "Description" },
    placeholder: "Enter description",
    options: { rules: [{ required: true, message: "This field is required" }, { min: 3, message: "Value must be 3 to 500 characters" }] },
  },
  department: {
    name: "departmentId",
    label: { text: "Department" },
    placeholder: "Select department",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  language: {
    name: "languageId",
    label: { text: "Language" },
    placeholder: "Select language",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  tags: {
    name: "tags",
    label: { text: "Tags" },
    placeholder: "Select tags",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  downloadable: {
    name: "downloadable",
    label: { text: "Downloadable" },
    placeholder: "",
    defaultValue: "YES",
    values: [{ id: 0, label: "YES", value: "YES" }, { id: 1, label: "NO", value: "NO" }],
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  orderable: {
    name: "orderable",
    label: { text: "Orderable" },
    placeholder: "",
    defaultValue: "YES",
    values: [{ id: 0, label: "YES", value: "YES" }, { id: 1, label: "NO", value: "NO" }],
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  editable: {
    name: "editable",
    label: { text: "Editable" },
    placeholder: "",
    defaultValue: "YES",
    values: [{ id: 0, label: "YES", value: "YES" }, { id: 1, label: "NO", value: "NO" }],
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  vendor: {
    name: "vendors",
    label: { text: "Vendor" },
    placeholder: "Select vendor",
  },
  cost: {
    name: "cost",
    label: "Cost ($)",
    placeholder: "Enter Cost",
  },
  quantityMin: {
    name: "quantityMin",
    label: "Qty (Min)",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  quantityMax: {
    name: "quantityMax",
    label: "Qty (Max)",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
  color: {
    name: "colorProfileId",
    label: { text: "Color Profile" },
    placeholder: "Select color",
    options: { rules: [{ required: true, message: "This field is required" }] },
  },
};
