import React, { useState } from "react";
import clsx from "clsx";
import { Form } from "antd";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import GetAppIcon from "@material-ui/icons/GetApp";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";

import Tag from "../../../../tag";
import Icon from "../../../../icon";
import Image from "../../../../image";
import { ReasonsSelect } from "../../../../selects";
import ProgressButton from "../../../../progressButton";
import { printOnDemandOrderForm } from "../../settings";
import { InputNumberAccessibility, PrimaryButton, InputAccessibility, TextareaWithCounter, FormGenerator } from "../../../../formComponents";

import { useStyles } from "../../styles";

const { layout, quantity, reason, color, language, comment } = printOnDemandOrderForm;

const DialogContent = ({ form, template, showLoader, onAddToCard, onOrderNow, onDownload, onDownloadEmpty, onRestore, isAdmin, isEditor, onZoomOut }) => {
  const classes = useStyles();
  const [commentRequired, setCommentRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [commentPlaceholder, setCommentPlaceholder] = useState("");

  const onReasonChangeHandler = ({ value }) => {
    const { comments_required, comments_placeholder } = JSON.parse(value);
    setCommentPlaceholder(comments_placeholder);
    setCommentRequired(comments_required);
  };

  const onZoomOutHandler = async () => {
    await setIsLoading(true);
    await onZoomOut();
    await setIsLoading(false);
  };

  return (
    <MuiDialogContent>
      <div className="material__content flex flex-start">
        <div className="material__image">
          <Image url={template.bigPhoto} placeholderIconSize={200} alt="" />
          <div className={classes.zoomOutBtnWrapper}>
            <ProgressButton
              isLoading={isLoading}
              variant="contained"
              aria-label="zoom out"
              className={clsx("btn--zoom-out", classes.blueIconBtn)}
              onClick={onZoomOutHandler}
            >
              <Icon type="arrows-alt" />
            </ProgressButton>
          </div>
          {template.isDownloadable && isEditor && template.hasPdfForm && (
            <Button className={classes.downloadBtn} variant="contained" onClick={onDownload}>
              <GetAppIcon />
            </Button>
          )}
        </div>
        <div className={clsx("material__info", classes.content)}>
          <Form className="material-order-form" layout={layout}>
            <Box mb={2}>
              <span>{template.departmentName}</span>
            </Box>
            <Box mb={2}>
              {template.tags.map(({ value }) => (
                <Tag key={value} className="tag--template">
                  {value}
                </Tag>
              ))}
            </Box>
            <Box mb={3}>
              <p className={classes.description}>{template.description}</p>
            </Box>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Form.Item>
                  {form.getFieldDecorator(color.name, { initialValue: template.colorProfileName })(
                    <InputAccessibility showLength={false} disabled label={color.label} />
                  )}
                </Form.Item>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Form.Item>
                  {form.getFieldDecorator(language.name, { initialValue: template.languageName })(
                    <InputAccessibility showLength={false} disabled label={language.label} />
                  )}
                </Form.Item>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Form.Item>
                  {form.getFieldDecorator(quantity.name, {
                    initialValue: 1,
                    rules: quantity.rules,
                  })(<InputNumberAccessibility label={quantity.label} min={1} max={999} />)}
                </Form.Item>
              </Grid>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Form.Item>
                  {form.getFieldDecorator(reason.name, {
                    rules: reason.rules,
                  })(
                    <ReasonsSelect
                      placeholder={reason.placeholder}
                      onChange={onReasonChangeHandler}
                      filters={["podtemplate", "pod_template"]}
                      label={reason.label}
                      name={reason.name}
                    />
                  )}
                </Form.Item>
              </Grid>
            </Grid>
            <Grid>
              <Form.Item help={false}>
                {form.getFieldDecorator(comment.name, {
                  rules: [
                    {
                      required: commentRequired,
                      message: "Comment is required for this reason",
                    },
                  ],
                })(<TextareaWithCounter label={comment.label} maxLength={2000} placeholder={commentPlaceholder} />)}
              </Form.Item>
            </Grid>
            {template.hasPdfForm && (
              <>
                <Divider light style={{ marginBottom: 20 }} />
                <h2>PDF form</h2>
                <FormGenerator form={form} fields={template.pdfFormFields} />
              </>
            )}
          </Form>
          <DialogActions
            className={clsx({
              [classes.actions]: template.isDownloadable,
            })}
          >
            {template.isDownloadable && (
              <Button text="download" color="secondary" onClick={onDownloadEmpty}>
                download
              </Button>
            )}
            {template.isDeleted && isAdmin && <PrimaryButton onClick={onRestore}>restore</PrimaryButton>}
            {!template.isDeleted && (
              <div style={{ display: "flex" }}>
                <ProgressButton isLoading={showLoader} style={{ marginRight: 16 }} color="secondary" aria-label="add to cart" onClick={onAddToCard}>
                  add to cart
                </ProgressButton>
                <ProgressButton
                  className={classes.orderNowBtn}
                  isLoading={showLoader}
                  disableRipple
                  color="primary "
                  aria-label="order it now"
                  onClick={onOrderNow}
                >
                  order it now
                </ProgressButton>
              </div>
            )}
          </DialogActions>
        </div>
      </div>
    </MuiDialogContent>
  );
};

export default DialogContent;
