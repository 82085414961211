import React from "react";
import clsx from "clsx";

import LogoImage from "./../../../assets/images/logo.svg";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const Logo = ({ full }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, { [classes.collapsedWrapper]: !full })}>
      <LogoImage className={clsx(classes.icon, { [classes.collapsedIcon]: !full })} />
      <span style={{ display: full ? `block` : "none" }} className={classes.title}>
        {t("logo.title")}
      </span>
    </div>
  );
};

export default Logo;
