import React from "react";
import { Carousel as AntCarousel } from "antd";

import { carouselSettings } from "./settings";
import "./styles.scss";

const Carousel = ({ slides }) => (
  <AntCarousel {...carouselSettings} className="csp-carousel">
    {slides}
  </AntCarousel>
);

export default Carousel;
