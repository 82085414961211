import React from "react";
import clsx from "clsx";
import { inject, observer } from "mobx-react";

import Icon from "../../../icon";
import Image from "../../../image";
import { useTranslation } from "react-i18next";

const Cover = ({ authorizationStore, id, alt, url, deleted, onRestore }) => {
  const { t } = useTranslation();
  const onRestoreHandler = e => {
    e.stopPropagation();
    if (!onRestore) return;
    onRestore(id);
  };

  return (
    <>
      {deleted && <span className="deleted-label deleted-label--left">{t("printOnDemandMaterialCard.deleted")}</span>}
      <div className="card__options">
        <button
          tabIndex={0}
          type="button"
          aria-labelledby={`material-card-title-${id}`}
          aria-describedby={`material-card-description-${id}`}
          className="card__options__btn"
          style={{ width: deleted && authorizationStore.user.isAdmin ? "50%" : "100%" }}
        >
          <Icon type={deleted ? "exclamation-circle" : "options"} /> {deleted ? "details" : "select options"}
        </button>
        {deleted && authorizationStore.user.isAdmin && (
          <button
            tabIndex={0}
            type="button"
            aria-labelledby={`material-card-title-${id}`}
            aria-describedby={`material-card-description-${id}`}
            className={clsx("card__options__btn", deleted && "card__options__btn--deleted")}
            style={{ width: "50%" }}
            onClick={onRestoreHandler}
          >
            <Icon type="undo" rotate={90} /> {t("printOnDemandMaterialCard.restore")}
          </button>
        )}
      </div>
      <Image alt={alt} url={url} />
    </>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(Cover));
