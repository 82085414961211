import React, { forwardRef, useEffect } from "react";
import { Tabs as AntTabs } from "antd";

import "./styles.scss";

const { TabPane } = AntTabs;

const Tabs = forwardRef(({ tabs, ...restProps }, ref) => {

  useEffect(() => {
    const tabs = document.querySelectorAll('.csp-tabs');
    if(tabs && tabs.length){
      tabs.forEach(tab => {
        const presentationComponents = tab.querySelectorAll('div[role=presentation]');
        if(presentationComponents && presentationComponents.length){
          presentationComponents.forEach(comp => {
            if(comp){
              comp.setAttribute('tabIndex', -1)
            }
          })
        }
      })
    }
  }, [restProps.activeKey])

  return (
    <AntTabs ref={ref} className="csp-tabs" {...restProps}>
      {tabs.map(({ name, id, content }) => (
        <TabPane tab={name} key={id}>
          {content}
        </TabPane>
      ))}
    </AntTabs>
  )
});

export default Tabs;