import { observable, action, computed } from "mobx";
import groupBy from "lodash/groupBy";

import { proxy } from "../../apiUrls";
import { profileAssignments } from "../catalog/settings";

export default class DetailedMaterial {
  @observable store = null;
  @observable id = null;
  @observable item_id = null;
  @observable code = "";
  @observable merch_name = "";
  @observable subzone_id = 0;
  @observable subzone_name = "";
  @observable subzone_items = [];
  @observable subzone_variants = [];
  @observable activeSubZone = null;
  @observable item_type = "";
  @observable fixture_category = null;
  @observable zone_name = "";
  @observable total_quantity = 0;
  @observable quantity = 0;
  @observable description = "";
  @observable fixture_type_description = "";
  @observable position = [];
  @observable language = "";
  @observable profileAssignment = "";
  @observable filterKey = "in_profile";
  @observable filterKeys = {
    notInProfile: "not_in_profile",
    inProfile: "in_profile",
    hideAll: "hideAll"
  };
  @observable photo = {
    original: "",
    medium_thumb: "",
    small_thumb: "",
    thumb: ""
  };

  @action.bound setProfileAssignment() {
    const { inProfile, notInProfile } = profileAssignments;
    const valueToCheck = this.store.activeCatalog.locationFilter.active;
    return valueToCheck ? inProfile : notInProfile;
  }

  @action.bound updateProfileAssignment(profileAssignment) {
    this.profileAssignment = profileAssignment;
  }

  @action.bound setActiveSubZone(subZoneId) {
    this.activeSubZone = subZoneId;
  }

  @action.bound setAssociatedMaterialsFilterKey(key) {
    this.filterKey = key;
  }

  @action.bound updateAssociatedMaterialsFilterKey(newQuantity, prevQuantity) {
    const maxQuantity = this.quantity;
    const totalQuantity = this.total_quantity;
    const { inProfile, notInProfile, hideAll } = this.filterKeys;
    const counterIncrease = newQuantity > prevQuantity;
    let filterKey = "";

    if (newQuantity > totalQuantity) {
      this.setAssociatedMaterialsFilterKey(hideAll);
      return;
    }

    if (counterIncrease) {
      filterKey = newQuantity >= maxQuantity && newQuantity <= totalQuantity ? notInProfile : inProfile;
    } else {
      filterKey = newQuantity <= totalQuantity && newQuantity > maxQuantity ? notInProfile : inProfile;
    }

    this.setAssociatedMaterialsFilterKey(filterKey);
  }

  @computed get associatedMaterials() {
    if (this.filterKey === this.filterKeys.hideAll || !this.hasSubZoneItems) return [];
    return this.filterKey === this.filterKeys.inProfile ? [groupBy(this.subzone_items[this.activeSubZone], "set_number")] : this.allSubZoneItems;
  }

  @computed get hasSubZoneItems() {
    return Object.keys(this.subzone_items).length;
  }

  @computed get allSubZoneItems() {
    return Object.keys(this.subzone_items).map(key => {
      return groupBy(this.subzone_items[key], "set_number");
    });
  }

  @computed get selectSubZoneOptions() {
    return this.subzone_variants.map(({ subzone_id, zone_name, subzone_name }) => ({
      id: subzone_id,
      value: `${zone_name}: ${subzone_name}`
    }));
  }

  @action.bound getJSONValue(val) {
    return JSON.stringify(val.map(el => el.id));
  }

  @computed get mediumPhoto() {
    return this.photo ? `${proxy}/${this.photo.medium_thumb}` : "";
  }

  @computed get smallPhoto() {
    return this.photo ? `${proxy}/${this.photo.small_thumb}` : "";
  }

  @computed get extraSmallPhoto() {
    return this.photo ? `${proxy}/${this.photo.thumb}` : "";
  }

  @computed get shouldShowAssociatedMaterials() {
    return !!(this.item_type !== "MarketingAssignment" && this.associatedMaterials.length);
  }

  constructor(props) {
    Object.assign(this, props);
    this.profileAssignment = this.setProfileAssignment();
    this.activeSubZone = this.subzone_id || (this.subzone_variants[0] && this.subzone_variants[0].subzone_id);
  }
}
