import React, { useState } from "react";
import { Form, Checkbox } from "antd";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";

import Carousel from "../../carousel";
import { ReasonsSelect } from "../../selects";
import { materialOrderForm } from "./settings";
import { MuiSelect } from "../../formComponents";
import { profileAssignments, getMaterialNameByType } from "../../../common/models/catalog/settings";
import TextareaWithCounter from "../../formComponents/textareaWithCounter";
import AssociatedMaterialCarouselItem from "../../associatedMaterialCarouselItem";
import InputNumberAccessibility from "../../formComponents/inputNumberAccessibility";

import "./styles.scss";
import { numberMinPositiveValuePromise } from "../../../utils";
import { Trans, useTranslation } from "react-i18next";

const { layout, subZone, quantity, reason, comment, associatedMaterials, formName } = materialOrderForm;

const MaterialOrderForm = ({ form, catalogStore, description }) => {
  const { t } = useTranslation();
  const { expandedMaterial } = catalogStore;
  const { inProfile, notInProfile } = profileAssignments;

  const materialDescription = expandedMaterial.description || expandedMaterial.fixture_type_description;

  const [formState, setFormState] = useState({
    selectedReason: {},
    quantity: 1
  });

  const quantityRules = [{ required: true, message: t("materialOrderForm.quantity.rules.required") }, numberMinPositiveValuePromise];
  const reasonRules = [{ required: true, message: t("materialOrderForm.reason.rules.required") }];

  async function onQuantityChangeHandler(quantity) {
    updateProfileAssignmentIfNeed(quantity);
    expandedMaterial.updateAssociatedMaterialsFilterKey(quantity, formState.quantity);
    await updateFormState({ quantity });
    form.validateFields([associatedMaterials.name]);
  }

  function updateProfileAssignmentIfNeed(newQuantity) {
    const maxQuantity = expandedMaterial.total_quantity;

    if (formState.quantity <= maxQuantity && newQuantity > maxQuantity) {
      updateProfileAssignment(notInProfile);
    }

    if (formState.quantity > maxQuantity && newQuantity <= maxQuantity) {
      updateProfileAssignment(inProfile);
    }
  }

  function updateProfileAssignment(profileAssignment) {
    form.resetFields([reason.name, comment.name, associatedMaterials.name]);
    expandedMaterial.updateProfileAssignment(profileAssignment);
  }

  function updateFormState(newState) {
    setFormState({ ...formState, ...newState });
  }

  function onReasonChange({ value }) {
    const selectedReason = JSON.parse(value);
    form.resetFields([comment.name]);
    updateFormState({ selectedReason });
  }

  function onSubZoneChange(id) {
    form.resetFields([associatedMaterials.marketing_item_ids]);
    expandedMaterial.setActiveSubZone(id);
  }

  function carouselSlides(slides) {
    return slides.map(material => <AssociatedMaterialCarouselItem key={material.id} {...material} />);
  }

  function associatedMaterialsValidation(rule, value, cb) {
    if (value.length > formState.quantity) cb(t("materialOrderForm.associatedMaterials.validationErrorMessage", { count: formState.quantity }));
    cb();
  }

  return (
    <Form className="material-order-form" layout={layout}>
      {description && <p>{description}</p>}
      <span className="block semibold material-order-form__code">{expandedMaterial.code}</span>
      {!!expandedMaterial.total_quantity && (
        <Form.Item>
          {form.getFieldDecorator(subZone.name, {
            initialValue: expandedMaterial.subzone_id
          })(
            <MuiSelect
              onChange={onSubZoneChange}
              disabled={expandedMaterial.subzone_variants.length <= 1}
              name={subZone.name}
              options={expandedMaterial.selectSubZoneOptions}
            />
          )}
        </Form.Item>
      )}
      {materialDescription && <p>{materialDescription}</p>}
      <p className="alert-message">
        <Trans
          i18nKey="materialOrderForm.quantityAssignedToThisFinancialCenter"
          values={{
            total_quantity: expandedMaterial.total_quantity
          }}
          components={{
            b: <span className="bold" />
          }}
        />
      </p>
      <Grid container spacing={2}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <Form.Item help={false}>
            {form.getFieldDecorator(quantity.name, {
              initialValue: 1,
              rules: quantityRules
            })(
              <InputNumberAccessibility
                _id={`${formName}_${quantity.name}`}
                label={t("materialOrderForm.quantity.label")}
                min={1}
                max={999}
                onChange={onQuantityChangeHandler}
              />
            )}
          </Form.Item>
        </Grid>
        <Grid item lg={9} md={6} sm={12} xs={12}>
          <Form.Item help={false}>
            {form.getFieldDecorator(reason.name, {
              rules: reasonRules
            })(
              <ReasonsSelect
                placeholder={t("materialOrderForm.reason.placeholder")}
                onChange={onReasonChange}
                filters={[getMaterialNameByType(expandedMaterial.fixture_category || expandedMaterial.item_type), expandedMaterial.profileAssignment]}
                label={{
                  text: t("materialOrderForm.reason.label")
                }}
                name={reason.name}
              />
            )}
          </Form.Item>
        </Grid>
      </Grid>
      <Form.Item help={false}>
        {form.getFieldDecorator(comment.name, {
          rules: [
            {
              required: formState.selectedReason.comments_required,
              message: t("materialOrderForm.comment.rules.required")
            }
          ]
        })(
          <TextareaWithCounter
            label={{
              antLabel: true,
              text: t("materialOrderForm.comment.label")
            }}
            maxLength={2000}
            placeholder={formState.selectedReason.comments_placeholder}
          />
        )}
      </Form.Item>
      {expandedMaterial.shouldShowAssociatedMaterials && (
        <>
          <p className="bold">
            <Trans
              i18nKey="materialOrderForm.correspondingMarketingMaterial"
              values={{
                error: form.getFieldError(associatedMaterials.name)
              }}
              components={{
                error: <span className="custom-validation-message block" />
              }}
            />
          </p>
          <Form.Item className="associated-materials">
            {form.getFieldDecorator(associatedMaterials.name, {
              initialValue: [],
              rules: [
                {
                  validator: associatedMaterialsValidation
                }
              ]
            })(
              <Checkbox.Group>
                {expandedMaterial.associatedMaterials.map((materialSet, i) => (
                  <div key={i} className="marketing-group" role="group">
                    {Object.keys(materialSet).map((key, i) => (
                      <Checkbox className="marketing-list" key={key} value={`${i}-${expandedMaterial.getJSONValue(materialSet[key])}`}>
                        <Carousel slides={carouselSlides(materialSet[key])} />
                      </Checkbox>
                    ))}
                  </div>
                ))}
              </Checkbox.Group>
            )}
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore
}))(observer(MaterialOrderForm));
