import React, { forwardRef, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import MaterialSelect from "../../formComponents/materialSelect";

const ReasonsSelect = forwardRef(({ reasonsStore, filters, ...props }, ref) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const filteredReasons = reasonsStore.filterReasons(filters).map(reason => {
      return {
        value: JSON.stringify(reason),
        label: reason.body,
        id: reason.id
      };
    });
    setData(filteredReasons);
  }, [reasonsStore.reasons, filters]);

  return <MaterialSelect ref={ref} options={data} valueKey="value" {...props} />;
});

export default inject(({ rootStore }) => ({
  reasonsStore: rootStore.reasonsStore
}))(observer(ReasonsSelect));
