import React, { forwardRef } from "react";
import { inject, observer } from "mobx-react";

import MaterialSelect from "../../formComponents/materialSelect";

const FullKitCampaignsSelect = forwardRef(({ fullKitCampaignsStore, ...props }, ref) => {
  const data = fullKitCampaignsStore.campaigns?.map(campaign => {
    return {
      value: JSON.stringify(campaign),
      label: campaign.name,
      id: campaign.id
    };
  });

  return <MaterialSelect ref={ref} options={data} valueKey="value" disabled={!data.length} {...props} />;
});

export default inject(({ rootStore }) => ({
  fullKitCampaignsStore: rootStore.fullKitCampaignsStore
}))(observer(FullKitCampaignsSelect));
