import { observable, action } from "mobx";
import ActionCable from "./action-cable-react-jwt";

import { urls } from "../../apiUrls";
import { LocalStorageService } from "../../../services";

export default class WebSocketStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable cable = {};

  @action.bound subscribe() {
    this.cable.subscriptions.create("CustomerServicePortal::NotificationChannel", {
      received: ({ notification }) => {
        this.rootStore.notificationsStore.onWsNotificationHandler(JSON.parse(notification));
      }
    });
  }

  @action.bound async createConsumer() {
    const { idToken } = await this.rootStore.authorizationStore.acquireTokenResponse();
    const { url } = urls.webSocket;
    this.cable = ActionCable.createConsumer(url, idToken);
    this.subscribe();
  }

  getHeaders = async () => {
    return await LocalStorageService.get("defaultHeaders");
  };
}
