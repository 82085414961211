import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaginatedStyledInput from "../paginatedStyledInput";
import PaginatedStyledTypography from "../paginatedStyledTypography";

const PaginatedAutocompleteInput = ({
    disabled,
    dropdownVisibility,
    value,
    InputProps,
    inputProps,
    onInputKeyDown,
    loading,
    placeholder,
    optionProps,
}) => (
    <div style={{ position: 'relative' }}>  
        {!dropdownVisibility && (
            <div className={`paginated-autocomplete__value-wrapper ${disabled ? "disabled" : ""}`}>
                {value && value[optionProps.id] ? (
                    <div className="flex">
                        <PaginatedStyledTypography variant="subtitle1" noWrap>
                            {value[optionProps.id]}
                        </PaginatedStyledTypography>
                        <span>/</span>
                        <PaginatedStyledTypography variant="subtitle2" noWrap>
                            {value[optionProps.title]}
                        </PaginatedStyledTypography>
                    </div>
                ) : (
                    <PaginatedStyledTypography variant="body1" noWrap>
                        {placeholder}
                    </PaginatedStyledTypography>
                )}
            </div>
        )}
        <PaginatedStyledInput
            ref={InputProps.ref}
            inputProps={{
                ...inputProps,
                placeholder,
                className: !dropdownVisibility ? "transparent" : "",
                onKeyDown: onInputKeyDown,
            }}
            endAdornment={(
                <div className="paginated-autocomplete__end-adornment">  
                    {loading ? <CircularProgress color="inherit" size={14} /> : null}
                    <div>{InputProps.endAdornment}</div>
                </div>
            )}
        />
    </div>
)

export default PaginatedAutocompleteInput;