import { action, observable, computed } from "mobx";

import { urls } from "../../common/apiUrls";
import { Order, User } from "../../common/models";
import { addMaterialTransformer } from "../../utils";

export default class CheckoutStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable draftOrder = new Order({ store: this });
  @observable draftOrderIsPending = false;
  @observable fcUsers = [];
  @observable activeSelectValue = null;
  @observable customName = { id: "customName", full_name: "Guest Name" };
  @observable showUserForm = false;

  @action.bound hideUserForm() {
    this.showUserForm = false;
  }

  @action.bound async getDraftOrder() {
    this.draftOrderIsPending = true;
    const { method, url } = urls.orders.getOrder;
    const result = await this.rootStore.makeRequest(this.setDraftOrder, method, url);
    this.draftOrderIsPending = false;

    return result;
  }

  @action.bound async createOrder(material, data) {
    const { method, url } = urls.orders.create;
    const receiver = { user_id: this.rootStore.authorizationStore.user.id };

    return await this.rootStore.makeRequest(
      this.onCreateOrderSuccess,
      method,
      url,
      {},
      { ...addMaterialTransformer(material, data), ...receiver, location_fcid: this.rootStore.locationStore.fullLocationData.fcid }
    );
  }

  @action.bound onCreateOrderSuccess() {
    this.rootStore.alertsStore.createAlert({
      severity: "success",
      message: `New order was created`,
    });
  }

  @action.bound async getUserForFC(fcid) {
    const { method, url } = urls.users;
    return await this.rootStore.makeRequest(this.saveUsers, method, url, {
      fcid,
    });
  }

  @action.bound saveUsers(data) {
    this.fcUsers = data.map(user => new User({ store: this, ...user }));
  }

  @action.bound onFcUserChange({ id: userId }) {
    this.shouldShowUserForm(userId);
    this.activeSelectValue = this.fcUsers.find(({ id }) => id === userId);
  }

  @action.bound shouldShowUserForm(userId) {
    this.showUserForm = userId === this.customName.id;
  }

  @action.bound setDraftOrder({ order }) {
    this.draftOrder = new Order({ store: this, ...order });
  }

  @computed get draftNotEmpty() {
    const { ordered_items } = this.draftOrder;
    return Boolean(ordered_items.length);
  }
}
