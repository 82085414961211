import React, { useState } from "react";
import { List } from "antd";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import MuiButton from "@material-ui/core/Button";

import Icon from "../icon";
import Button from "../button";
import TextBlock from "../textBlock";
import { ChangeRequestModal, ChangeRequestSent } from "../modals";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const FinancialCenterCard = ({ supportStore, locationStore, editable = false, showDownloadButtons = false }) => {
  const { t } = useTranslation();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [changeRequestSentVisible, setChangeRequestSentVisible] = useState(false);

  function editClickHandler() {
    setModalVisibility(true);
  }

  function cancelClickHandler() {
    setModalVisibility(false);
  }

  const onChangeRequestSubmitHandler = async data => {
    supportStore.sendSupportOrder({
      data,
      location_fcid: locationStore.fullLocationData.fcid,
      order_type: "change_fc_info"
    });
    setChangeRequestSentVisible(true);
  };

  return (
    <div className="sidebar-card">
      <div className={"sidebar-card__header flex-wrap flex-between"}>
        <span className="sidebar-card__fin-title">{t("financialCenterCard.financialCenter")}</span>
        {editable && (
          <Button onClick={editClickHandler} style={{ padding: 0 }} type="link">
            {t("financialCenterCard.changeRequest")}
          </Button>
        )}
      </div>
      <Box mb={1}>
        <List loading={locationStore.pending} className="sidebar-card__list">
          <div>
            <TextBlock title={t("financialCenterCard.locationName")} content={locationStore.fullLocationData.name} />
          </div>
          <div className="flex-between flex-wrap">
            <TextBlock title={t("financialCenterCard.mailCode")} content={locationStore.fullLocationData.mail_code} />
            <TextBlock title={t("financialCenterCard.fcid")} content={locationStore.fullLocationData.fcid} />
          </div>
          <div>
            <TextBlock title={t("financialCenterCard.locationAddress")} content={locationStore.fullLocationData.address} />
          </div>
          <div>
            <TextBlock title={t("financialCenterCard.phone")} content={locationStore.fullLocationData.phone} />
          </div>
          <div>
            <TextBlock title={t("financialCenterCard.costCenter")} content={locationStore.fullLocationData.cost_center} />
          </div>
          <div>
            <TextBlock title={t("financialCenterCard.language")} content={locationStore.fullLocationData.language} />
          </div>
        </List>
      </Box>
      {showDownloadButtons && (
        <Grid container alignItems="center" justifyContent="space-between">
          {locationStore.fullLocationData.hasCig && (
            <Grid item>
              <MuiButton
                className="btn--download"
                color="secondary"
                onClick={locationStore.fullLocationData.downloadCIG}
                startIcon={<Icon size={18} type="download" />}
              >
                {t("financialCenterCard.currentCig")}
              </MuiButton>
            </Grid>
          )}
          {locationStore.fullLocationData.hasFloorPlans && (
            <Grid item>
              <MuiButton
                className="btn--download"
                color="secondary"
                onClick={locationStore.fullLocationData.downloadFloorPlan}
                startIcon={<Icon size={18} type="download" />}
              >
                {t("financialCenterCard.floorplan")}
              </MuiButton>
            </Grid>
          )}
        </Grid>
      )}
      {modalVisibility && <ChangeRequestModal visible={modalVisibility} onCancel={cancelClickHandler} onSubmitCb={onChangeRequestSubmitHandler} />}
      <ChangeRequestSent visible={changeRequestSentVisible} onCancel={() => setChangeRequestSentVisible(false)} />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  supportStore: rootStore.supportStore,
  locationStore: rootStore.locationStore
}))(observer(FinancialCenterCard));
