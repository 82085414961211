import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { inject, observer } from "mobx-react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Logo from "./logo";
import MenuItem from "./MenuItem";
import { CallUsModal } from "../modals";
import ListSubHeader from "./ListSubHeader";
import { proxy } from "../../common/apiUrls";
import CollapsibleMenuItem from "./CollapsibleMenuItem";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { featureToggleFilter } from "../../utils/featureToggle/featureToggle";

import Collapse from "@material-ui/core/Collapse";

const MaterialMenu = ({ catalogStore, menuStore, alertsStore, locationStore, authorizationStore }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const isCatalog = useRouteMatch("/catalog");
  const [modalVisibility, setModalVisibility] = useState(false);
  const [collapseCatalog, setCollapseCatalog] = useState(isCatalog?.isExact);
  const [collapseDashboard, setCollapseDashboard] = useState(true);
  const menuOpened = !menuStore.menuState.collapsed;

  useEffect(() => {
    locationStore.getLocationById();
  }, [locationStore, locationStore.activeLocation]);

  const emptyCigError = {
    severity: "error",
    message: t("menu.emptyCigError")
  };

  const menu = [
    {
      key: "dashboard",
      title: "INSIGHTLENS",
      subMenus: false,
      items: [
        { key: "dashboard_financial_center", title: "Locations", iconType: "accountBalanceRounded", isLink: true, href: "/locations-dashboard" },
        { key: "dashboard_fixtures", title: "Fixtures", iconType: "webAssetRounded", isLink: true, href: "/fixtures" },
        { key: "dashboard_digital_screens", title: "Digital Screens", iconType: "queuePlayNextRounded", isLink: true, href: "/digital-screens" },
        { key: "dashboard_exterior_attributes", title: "Exterior Attributes", iconType: "autoAwesomeMosaic", isLink: true, href: "/exterior-attributes" }
      ]
    },
    {
      key: "materialCatalog",
      title: t("menu.materialsCatalog"),
      href: "/catalog",
      subMenus: [
        {
          key: "marketing",
          collapsed: false,
          collapsible: true,
          title: { iconType: "marketing", text: t("menu.marketing") }
        },
        {
          key: "marketing_cling",
          collapsed: false,
          collapsible: true,
          title: { iconType: "clings", text: t("menu.marketingCling") }
        },
        {
          key: "merchandising",
          collapsed: true,
          collapsible: true,
          title: { iconType: "fixtures", text: t("menu.merchandising") }
        },
        {
          key: "regulatory",
          collapsed: true,
          collapsible: true,
          title: { iconType: "regulatory", text: t("menu.regulatory") }
        },
        {
          key: "healthSafety",
          collapsed: true,
          collapsible: true,
          title: { iconType: "healthSafety", text: t("menu.healthSafety") }
        },
        {
          key: "printOnDemand",
          collapsed: true,
          collapsible: false,
          title: { iconType: "print", text: t("menu.printOnDemand") }
        }
      ]
    },
    {
      key: "help",
      title: t("menu.help"),
      subMenus: false,
      items: [
        {
          key: "support",
          title: t("menu.support"),
          iconType: "support",
          isLink: true,
          href: "/support"
        },
        {
          key: "vault",
          title: t("menu.vault"),
          iconType: "vault",
          isLink: true,
          href: "#"
        },
        {
          key: "phone",
          title: t("menu.phone"),
          iconType: "phone",
          isLink: false,
          href: "#"
        },
        {
          key: "instructions",
          title: t("menu.instructions"),
          iconType: "instructions",
          isLink: true,
          href: "/other-resources"
        }
      ]
    },
    {
      key: "aboutUs",
      title: t("menu.aboutUs"),
      subMenus: false,
      items: [
        {
          key: "location",
          title: t("menuStore.myFinancialCenter"),
          iconType: "location",
          isLink: true,
          href: "/location"
        },
        {
          key: "cig",
          title: t("menu.cig"),
          iconType: "download",
          isLink: false,
          href: "#"
        }
      ]
    }
  ];

  const onMenuItemClickHandler = ({ href, key }) => {
    switch (key) {
      case "phone":
        setModalVisibility(true);
        break;
      case "cig":
        downloadCig();
        break;
      case "vault":
        if (authorizationStore.vaultLink) {
          window.open(authorizationStore.vaultLink, authorizationStore.vaultLinkSettings);
        }
        break;
      default:
        history.push(href);
    }
  };

  const downloadCig = () => {
    if (!locationStore.fullLocationData.cig) {
      alertsStore.createAlert(emptyCigError);
      return;
    }
    window.location.href = `${proxy}/${locationStore.fullLocationData.cig}`;
  };

  const onModalCancelHandler = () => {
    setModalVisibility(false);
  };

  const onCollapsedMenuSelect = ({ key }, label) => {
    catalogStore.activeCatalog.setFilters(label);
    menuStore.updateMenuState(getMenuStateForUpdate(label));
    catalogStore.setActiveCatalog(key);
  };

  const getMenuStateForUpdate = key => {
    return catalogStore.activeCatalog.checkIfBottomOrTopMenuKey(key) ? { lastActiveMenuKey: key, selectedKeys: [key] } : { selectedKeys: [key] };
  };

  return (
    <>
      <Logo full={!menuStore.menuState.collapsed} />
      {menu
        .filter(({ key }) => key !== "dashboard" || authorizationStore.user.isILDashboardAccess)
        .map(({ key, title, items, subMenus }) => {
          const list = (
            <List
              key={key}
              component="nav"
              aria-labelledby={key}
              subheader={<ListSubHeader isShown={menuOpened} id={key} label={title} />}
              className={classes.root}
            >
              {featureToggleFilter(subMenus || [], "key").map(item => (
                <CollapsibleMenuItem key={item.key} item={item} onClick={onCollapsedMenuSelect} />
              ))}
              {(items || []).map(item => {
                const newItem = { ...item };
                const selected = pathname === newItem.href;
                return <MenuItem key={newItem.key} selected={selected} item={newItem} onClick={onMenuItemClickHandler} />;
              })}
            </List>
          );
          if (authorizationStore.user.isILDashboardAccess && key === "materialCatalog") {
            return (
              <List
                key={key}
                component="nav"
                aria-labelledby={key}
                subheader={
                  <ListSubHeader
                    isShown={menuOpened}
                    id={key}
                    label={title}
                    collapsed={collapseCatalog}
                    onClick={() => {
                      setCollapseCatalog(!collapseCatalog);
                    }}
                  />
                }
                className={classes.root}
              >
                <Collapse in={collapseCatalog}>
                  {featureToggleFilter(subMenus || [], "key").map(item => (
                    <CollapsibleMenuItem key={item.key} item={item} onClick={onCollapsedMenuSelect} />
                  ))}
                  {(items || []).map(item => {
                    const newItem = { ...item };
                    const selected = pathname === newItem.href;
                    return <MenuItem key={newItem.key} selected={selected} item={newItem} onClick={onMenuItemClickHandler} />;
                  })}
                </Collapse>
              </List>
            );
          }
          if (key === "dashboard") {
            return (
              <List
                key={key}
                component="nav"
                aria-labelledby={key}
                subheader={
                  <ListSubHeader
                    isShown={menuOpened}
                    id={key}
                    label={title}
                    collapsed={collapseDashboard}
                    onClick={() => {
                      setCollapseDashboard(!collapseDashboard);
                    }}
                  />
                }
                className={classes.root}
              >
                <Collapse in={collapseDashboard}>
                  {(items || []).map(item => {
                    const newItem = { ...item };
                    const selected = pathname === newItem.href;
                    return <MenuItem key={newItem.key} selected={selected} item={newItem} onClick={onMenuItemClickHandler} />;
                  })}
                </Collapse>
              </List>
            );
          }

          return list;
        })}
      {modalVisibility && <CallUsModal visible={modalVisibility} onCancel={onModalCancelHandler} />}
    </>
  );
};

export default inject(({ rootStore }) => ({
  menuStore: rootStore.menuStore,
  catalogStore: rootStore.catalogStore,
  alertsStore: rootStore.alertsStore,
  locationStore: rootStore.locationStore,
  authorizationStore: rootStore.authorizationStore
}))(observer(MaterialMenu));
