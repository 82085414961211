import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";
import { generateId } from "../../../utils";

export default class AlertsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable alerts = [];
  @observable AIMAlerts = [];
  @observable isLoading = false;

  @action.bound createAlert(data) {
    this.alerts.push({ id: generateId(), ...data });
  }

  @action.bound deleteAlert(alertId) {
    this.alerts = this.alerts.filter(({ id }) => id !== alertId);
  }

  @action.bound deleteAIMAlert(alertId) {
    this.AIMAlerts = this.AIMAlerts.filter(({ id }) => id !== alertId);
  }

  @action.bound async getAIMAlerts(fcid) {
    const { method, url } = urls.getAlerts;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetAlertsSuccess, method, url, {
      q: { location_fcid_eq: fcid },
    });
    this.isLoading = false;
  }

  @action.bound onGetAlertsSuccess({ data }) {
    this.AIMAlerts = data;
  }

  @computed get hasAIMAlerts() {
    return Boolean(this.AIMAlerts.length);
  }

  @computed get AIMAlert() {
    return this.AIMAlerts[0];
  }
}
