import { action, computed, observable } from "mobx";

import { urls } from "../../common/apiUrls";
import Feedback from "../../common/models/feedback/feedbackModels";

export default class FeedbacksStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable activeFeedback = {};
  @observable currentQuestionIndex = 1;
  @observable answers = {};
  @observable additionalInformation = "";
  @observable isLoading = true;
  @observable orderId = 0;
  @observable activeFeedbackExist = false;
  @observable userFeedbackId = 0;

  @action.bound async getActiveFeedback() {
    const { method, url } = urls.feedbacks.activeFeedback;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.saveActiveFeedback, method, url, {});
    await this.createUserFeedback();
    this.isLoading = false;
  }

  @action.bound async createUserFeedback() {
    const { method, url } = urls.userFeedback.create;
    const body = {
      feedback_id: this.activeFeedback.id
    };
    if (this.orderId) {
      body.order_id = this.orderId;
    }
    await this.rootStore.makeRequest(this.saveUserFeedbackId, method, url, {}, body);
  }

  @action.bound saveUserFeedbackId(data) {
    this.userFeedbackId = data.user_feedback_id;
  }

  @action.bound async updateUserFeedback(cb, errorCb) {
    const { method, url } = urls.userFeedback.update;
    const body = {
      answers: this.answers
    };
    if (this.isAdditionalInfo && this.additionalInformation) {
      body.additional_information = this.additionalInformation;
    }
    await this.rootStore.makeRequest(cb, method, url.replace("{id}", this.userFeedbackId.toString()), {}, body, errorCb);
  }

  @action.bound async submitUserFeedback(cb, errorCb) {
    const { method, url } = urls.userFeedback.submit;
    await this.rootStore.makeRequest(cb, method, url.replace("{id}", this.userFeedbackId.toString()), {}, {}, errorCb);
  }

  @action.bound resetFeedback() {
    this.activeFeedback = {};
    this.currentQuestionIndex = 1;
    this.answers = {};
    this.additionalInformation = "";
    this.orderId = 0;
    this.activeFeedbackExist = false;
    this.userFeedbackId = 0;
  }

  @action.bound saveActiveFeedback(data) {
    this.activeFeedback = new Feedback({ ...data.feedback });
  }

  @action.bound changeCurrentQuestionAnswer(value) {
    this.answers[this.currentQuestionIndex] = {
      survey_type: this.currentQuestionObject.survey_type,
      answer: value
    };
  }

  @action.bound changeAdditionalInfo(value) {
    this.additionalInformation = value;
  }

  @action.bound incrementCurrentQuestionIndex() {
    this.currentQuestionIndex = this.currentQuestionIndex + 1;
  }

  @action.bound decrementCurrentQuestionIndex() {
    this.currentQuestionIndex = this.currentQuestionIndex - 1;
  }

  @action.bound setOrderId(orderId) {
    this.orderId = orderId;
  }

  @action.bound setActiveFeedbackExist(activeFeedbackExist) {
    this.activeFeedbackExist = activeFeedbackExist;
  }

  @computed get currentQuestionObject() {
    return this.activeFeedback?.questions && this.activeFeedback?.questions[this.currentQuestionIndex];
  }

  @computed get currentQuestion() {
    return this.currentQuestionObject ? this.currentQuestionObject.survey_question : "";
  }

  @computed get numberOfQuestion() {
    return Object.keys(this.activeFeedback?.questions || {}).length;
  }

  @computed get currentOptions() {
    const options = this.currentQuestionObject ? this.currentQuestionObject.options : "";

    return Object.entries(options);
  }

  @computed get currentAnswer() {
    return (this.answers[this.currentQuestionIndex] && this.answers[this.currentQuestionIndex].answer) || null;
  }

  @computed get questionsEntries() {
    return Object.entries(this.activeFeedback?.questions || {});
  }

  @computed get isBackButtonAvailable() {
    return this.currentQuestionIndex !== 1;
  }

  @computed get isNextButtonAvailable() {
    return this.currentQuestionIndex !== this.numberOfQuestion + this.activeFeedback?.additional_information_is_required;
  }

  @computed get isAdditionalInfo() {
    return this.activeFeedback?.additional_information_is_required && this.currentQuestionIndex > this.numberOfQuestion;
  }
}
