import React from "react";
import { useForm, Controller } from "react-hook-form";

import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import FinancialCenterDetails from "../../financialCenterDetails";
import { useTranslation } from "react-i18next";
import { TextareaWithCounter } from "../../formComponents";

const ChangeRequestModal = ({ visible, onCancel, onSubmitCb }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, errors } = useForm();
  const hasError = Boolean(errors.comments && errors.comments.message);

  const onSubmit = data => {
    onSubmitCb(data);
    onCancel();
  };

  const actions = [
    {
      text: t("modals.cancel"),
      color: "secondary",
      onClick: onCancel
    },
    {
      text: t("modals.send"),
      primary: true,
      onClick: handleSubmit(onSubmit)
    }
  ];

  const content = (
    <>
      <FinancialCenterDetails />
      <form>
        <InputLabel htmlFor="comments">
          <span style={{ color: "#DC1431" }}>* </span>
          {t("modals.comments")}
        </InputLabel>
        <Controller
          as={<TextareaWithCounter maxLength={2000} _id="comments" />}
          name="comments"
          control={control}
          defaultValue={""}
          rules={{
            required: {
              value: true,
              message: t("modals.commentsRequired")
            },
            maxLength: 2000
          }}
        />
        {hasError && (
          <FormHelperText error role="alert">
            {errors.comments.message}
          </FormHelperText>
        )}
      </form>
    </>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.changeRequestTitle")} {...settings} />;
};

export default ChangeRequestModal;
