import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    reportWrapper: {
      height: "calc(100vh - 176px)",
      ["@media (max-width:765px)"]: {
        height: "calc(100vh - 238px)"
      },
      ["@media (max-width:665px)"]: {
        height: "calc(100vh - 290px)"
      }
    }
  })
);
