export const addMaterialTransformer = (material, data) => {
  const { id, item_type } = material;
  const { marketing_item_ids, reason, ...rest } = data;
  const { id: reasonId, body } = JSON.parse(reason.value);

  rest.reason = body;
  rest.order_reason_id = reasonId;

  return {
    item_id: id,
    item_type,
    ...rest,
    marketing_item_ids: getIds(marketing_item_ids),
  };
};

const getIds = data => {
  return ((data || []).map(s => s.split("-")[1]) || []).reduce((ids, val) => [...ids, ...JSON.parse(val)], []);
};
