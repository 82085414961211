import { action, observable, computed } from "mobx";

import { User } from "../index";
import { t } from "i18next";

export default class Notification {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable store = null;
  @observable id = null;
  @observable key = "";
  @observable created_at = "";
  @observable isRead = false;
  @observable data = {
    code: "",
    id: null,
    message: "",
    user_id: null,
    order_id: null,
    ordered_item_id: null,
    order_status: "",
    status: ""
  };
  @observable recipient = { ...new User() };

  @action.bound markAsRead() {
    this.isRead = true;
  }

  @computed get isTemplateNotification() {
    return this.key === "print_on_demand_template.updated";
  }

  @computed get title() {
    return this.isTemplateNotification
      ? t("notification.template", this.data)
      : this.data.ordered_item_id
      ? t("notification.itemId", this.data)
      : t("notification.orderId", this.data);
  }
}
