import React, { useEffect, useState } from "react";

import { EditorComposer, Editor } from "verbum";

import "verbum/dist/verbum.esm.css";

const LexicalWrapper = ({ body_json }) => {
    const [editor, setEditor] = useState(null);

    useEffect(() => {
        if (editor) {
            editor.setEditorState(editor.parseEditorState(body_json))
        }
    }, [body_json]);


    const onChange = (editorState, editorInstance) => {
        if (!editor) {
            setEditor(editorInstance);
        }
    }

    return (
        <EditorComposer initialEditorState={body_json}>
            <Editor isEditable={false} onChange={onChange} locale="en"></Editor>
        </EditorComposer>
    );
};

export default LexicalWrapper;