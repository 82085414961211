import React from "react";
import MaterialPagination from "@material-ui/lab/Pagination";
import { PaginationItem } from "@material-ui/lab";
import withStyles from "@material-ui/core/styles/withStyles";

const MuiPaginationItem = withStyles({
  root: {
    border: "1px solid #d9d9d9",
    borderRadius: 4,
    "&:hover": {
      color: "#DC1431",
      borderColor: "#DC1431",
      backgroundColor: "white !important"
    }
  },
  selected: {
    color: "#DC1431",
    borderColor: "#DC1431",
    backgroundColor: "white !important"
  },
  focusVisible: {
    borderColor: "#DC1431",
    backgroundColor: "rgba(212, 239, 252, 0.51) !important",
  },
  disabled: {
    cursor: "not-allowed !important",
    opacity: ".2 !important",
    pointerEvents: "all !important"
  },
  ellipsis: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32
  }
})(PaginationItem);

const MuiPagination = withStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10
  }
})(MaterialPagination);

const Pagination = ({ current: page, total: count, onChange, pageSize }) => (
  <MuiPagination
    page={page}
    count={Math.ceil(count / pageSize)}
    onChange={onChange}
    renderItem={item => (
      <MuiPaginationItem {...item} />
    )}
  />
);

export default Pagination;
