import { roles } from "./constants";
import Support from "./pages/support";
import Catalog from "./pages/catalog";
import Checkout from "./pages/checkout";
import CustomAtm from "./pages/customAtm";
import CustomOrder from "./pages/customOrder";
import Instructions from "./pages/instructions";
import EditTemplate from "./pages/editTemplate";
import SearchResults from "./pages/searchResults";
import OrdersHistory from "./pages/ordersHistory";
import CreateTemplate from "./pages/createTemplate";
import FinancialCenter from "./pages/financialCenter";
import InternalServerError from "./pages/internalServerError";
import Feedback from "./pages/feedback/feedback";
import FullKitRequest from "./pages/fullKitRequest";
import DashboardFinancialCenter from "./pages/dashboard/financialCenter";
import DashboardFixtures from "./pages/dashboard/fixtures";
import DashboardDigitalScreens from "./pages/dashboard/digitalScreens";
import DashboardExteriorAttributes from "./pages/dashboard/exteriorAttributes";

export default [
  {
    path: `/catalog`,
    exact: true,
    component: Catalog
  },
  {
    path: `/search/all`,
    exact: true,
    component: SearchResults
  },
  {
    path: `/search/:catalogName`,
    exact: true,
    component: SearchResults
  },
  {
    path: `/orders-history`,
    component: OrdersHistory
  },
  {
    path: `/checkout`,
    component: Checkout
  },
  {
    path: `/custom-order`,
    component: CustomOrder
  },
  {
    path: `/custom-atm`,
    component: CustomAtm
  },
  {
    path: `/full-kit`,
    component: FullKitRequest
  },
  {
    path: `/support`,
    component: Support
  },
  {
    path: `/location`,
    component: FinancialCenter
  },
  {
    path: `/other-resources`,
    component: Instructions
  },
  {
    path: `/templates/create`,
    component: CreateTemplate,
    roles: [roles.podAdmin, roles.podEditor]
  },
  {
    path: `/templates/:id/edit`,
    exact: true,
    component: EditTemplate,
    roles: [roles.podAdmin, roles.podEditor]
  },
  {
    path: `/server-error`,
    component: InternalServerError
  },
  {
    path: `/survey`,
    component: Feedback
  }
];

export const dashboardsRoutes = [
  {
    path: `/locations-dashboard`,
    exact: true,
    component: DashboardFinancialCenter,
    roles: [roles.ILDashboardAccess]
  },
  {
    path: `/fixtures`,
    exact: true,
    component: DashboardFixtures,
    roles: [roles.ILDashboardAccess]
  },
  {
    path: `/digital-screens`,
    exact: true,
    component: DashboardDigitalScreens,
    roles: [roles.ILDashboardAccess]
  },
  {
    path: `/exterior-attributes`,
    exact: true,
    component: DashboardExteriorAttributes,
    roles: [roles.ILDashboardAccess]
  }
];
