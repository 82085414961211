import React, { forwardRef } from "react";
import { Input as AntInput } from "antd";
import "./styles.scss";

const Input = forwardRef(
  (
    {
      placeholder = "",
      prefix,
      onChange,
      onKeyDown,
      onPressEnter,
      value = "",
      ...restProps
    },
    ref
  ) => {

    const handleKeyDown = e => {
      const { keyCode } = e;
      if(keyCode === 27){
        e.target.value = '';
        onChange({ ...e })
      }
      if(onKeyDown){
        onKeyDown(e);
      }
    }

    return (
      <AntInput
        placeholder={placeholder}
        ref={ref}
        prefix={prefix}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onPressEnter={onPressEnter}
        value={value}
        {...restProps}
      />
    )
  }
);
export default Input;
