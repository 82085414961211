import React from "react";

import MuiDialog from "../../muiDialog";
import { settings } from "./settings";
import { useTranslation } from "react-i18next";

const DeleteTemplateModal = ({ visible, materialName, onCancel, onSubmit }) => {
  const { t } = useTranslation();
  const actions = [
    {
      text: t("modals.cancel"),
      autoFocus: true,
      onClick: onCancel,
      size: "small",
      color: "secondary"
    },
    {
      text: t("modals.submit"),
      onClick: onSubmit,
      primary: true,
      size: "small"
    }
  ];

  const content = <div>{t("modals.deleteTemplateContent", { materialName })}</div>;

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} title={t("modals.deleteTemplateTitle")} {...settings} />;
};

export default DeleteTemplateModal;
