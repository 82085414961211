import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const MuiTypography = withStyles({
    subtitle1: {
        fontSize: 14,
        fontWeight: "bold",
        color: "rgb(89, 89, 89)",
    },
    subtitle2: {
        fontSize: 12,
        color: "rgb(89, 89, 89)",
    },
    body1: {
        display: "block",
        fontSize: 14,
        color: "rgb(89, 89, 89)",
    }
})(Typography);

const PaginatedStyledTypography = props => <MuiTypography { ...props } />

export default PaginatedStyledTypography;