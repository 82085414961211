import { observable, action, computed } from "mobx";
import Template from "../../models/template";
import { catalogs } from "../../../constants";
import { t } from "i18next";

export default class SearchStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable resultsLimit = 20;
  @observable isLoading = false;
  @observable allCatalogsLoaded = false;
  @observable fcSearchResults = {
    marketing: [],
    marketing_cling: [],
    merchandising: [],
    healthSafety: [],
    regulatory: [],
    printOnDemand: []
  };
  @observable outFcSearchResults = {
    marketing: [],
    marketing_cling: [],
    merchandising: [],
    healthSafety: [],
    regulatory: [],
    printOnDemand: []
  };
  @observable itemsFound = {
    marketing: 0,
    marketing_cling: 0,
    merchandising: 0,
    healthSafety: 0,
    regulatory: 0,
    printOnDemand: 0
  };
  @observable materialTypes = [
    { value: "all", name: t("searchStore.all") },
    { value: "marketing", name: t("searchStore.marketing") },
    { value: "marketing_cling", name: t("searchStore.marketingClings") },
    { value: "merchandising", name: t("searchStore.merchandising") },
    { value: "regulatory", name: t("searchStore.regulatory") },
    { value: "healthSafety", name: t("searchStore.healthSafety") },
    { value: "printOnDemand", name: t("searchStore.printOnDemand") }
  ];

  @action.bound async getMaterials(searchStr, catalogName) {
    if (!searchStr) return;
    this.isLoading = true;
    if (catalogName && this.allCatalogsLoaded) {
      await this.getSelectedCatalogMaterials(catalogName, searchStr);
      this.isLoading = false;
      return;
    }
    await Promise.all(this.rootStore.catalogStore.catalogs.map(catalog => catalog.searchMaterials(searchStr, this.resultsLimit)));
    this.allCatalogsLoaded = true;
    this.isLoading = false;
  }

  @action.bound async resetAllCatalogsLoaded() {
    this.allCatalogsLoaded = false;
  }

  @action.bound async getSelectedCatalogMaterials(catalogName, searchStr) {
    const catalog = this.rootStore.catalogStore.catalogs.find(catalog => catalog.catalogName === catalogName);
    if (!catalog) return;
    await catalog.searchMaterials(searchStr);
  }

  @action.bound saveSearchResults(catalogName, { groups, campaigns, templates, total, itemsFound }) {
    this.itemsFound[catalogName] = catalogName === catalogs.printOnDemand ? itemsFound : total;
    this.fcSearchResults[catalogName] = groups || campaigns || templates.map(t => new Template({ store: this.rootStore.catalogStore, ...t }));
  }

  @action.bound saveOutFcSearchResults(catalogName, { groups, campaigns, total }) {
    this.itemsFound[catalogName] = this.itemsFound[catalogName] + total;
    this.outFcSearchResults[catalogName] = groups || campaigns;
  }

  @computed get searchResultsAmount() {
    return Object.keys(this.itemsFound).reduce((sum, key) => sum + this.itemsFound[key] || 0, 0);
  }
}
