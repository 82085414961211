import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class LanguagesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable languages = [];
  @observable isLoading = false;

  @action.bound async getLanguages() {
    const { method, url } = urls.languages.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetLanguagesSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onGetLanguagesSuccess({ data }) {
    this.languages = data;
  }

  @action.bound findById(languagesId) {
    return this.languages.find(({ id }) => id === parseInt(languagesId));
  }

  @computed get dropdownValues() {
    return this.languages.map(({ id, name }) => ({ id, label: name, value: id }));
  }

  @computed get filters() {
    return this.languages.map(({ id, name }) => ({ supertitle: "", title: name, value: id }));
  }
}
