import React from "react";
import MuiListSubheader from "@material-ui/core/ListSubheader";

import { useStyles } from "./styles";
import CollapseIcon from "./CollapseIcon";

const ListSubHeader = ({ label, id, isShown, onClick, collapsed }) => {
  const classes = useStyles();
  if (!isShown) return null;

  return (
    <MuiListSubheader className={classes.subHeader} component="div" id={id} onClick={onClick}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {label}
        {onClick && <CollapseIcon showIcon={true} collapsed={collapsed} />}
      </div>
    </MuiListSubheader>
  );
};

export default ListSubHeader;
