import React from "react";

import MuiDialog from "../../muiDialog";
import { useTranslation } from "react-i18next";

const ChangeFCModal = ({ visible, onNo, onYes }) => {
  const { t } = useTranslation();
  const actions = [
    {
      text: t("modals.cancel"),
      color: "secondary",
      onClick: onNo
    },
    {
      text: t("modals.yesLeave"),
      primary: true,
      autoFocus: true,
      onClick: onYes
    }
  ];

  const settings = {
    title: t("modals.confirm"),
    content: t("modals.changeFCModalContent"),
    titleId: "change-financial-center-dialog-title",
    contentId: "change-financial-center-dialog-content"
  };

  return <MuiDialog open={visible} actions={actions} {...settings} />;
};

export default ChangeFCModal;
