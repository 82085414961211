import React from "react";
import { Skeleton as AntSkeleton } from "antd";

const Skeleton = ({
  active = true,
  paragraph = null,
  title = null,
  avatar = null,
  children,
  ...restProps
}) => (
  <AntSkeleton
    active={active}
    paragraph={paragraph}
    title={title}
    avatar={avatar}
    {...restProps}
  >
    {children}
  </AntSkeleton>
);

export default Skeleton;
