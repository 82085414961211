export default {
  common: {
    white: "#fff",
    black: "#000",
  },
  menu: {
    focus: "#D4EFFC",
    hover: "rgba(212, 239, 252, 0.51)",
  },
  primary: { main: "#DC1431" },
  secondary: { main: "#0073CF" },
};
