import React from "react";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import ArrowRight from "@material-ui/icons/ArrowRight";
import TripOrigin from "@material-ui/icons/TripOrigin";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { useStyles } from "./styles";

const StyledInnerListItem = withStyles(({ palette }) => ({
  selected: {
    backgroundColor: "transparent !important",
    "& span": {
      color: palette.primary.main,
    },
    "& svg": {
      color: palette.primary.main,
    },
  },
  focusVisible: {
    backgroundColor: "#D4EFFC !important",
  },
}))(ListItem);

const StyledInnerListItemText = withStyles({
  primary: {
    fontSize: 15,
    fontWeight: 700,
    color: "#4a4a4a",
  },
})(ListItemText);

const SubMenuListItemText = withStyles({
  primary: {
    fontSize: 14,
    color: "#4a4a4a",
  },
})(ListItemText);

const StyledArrow = withStyles(({ palette }) => ({
  root: {
    color: palette.primary.main,
    fontSize: 30,
  },
}))(ArrowRight);

const StyledTripOrigin = withStyles(() => ({
  root: {
    color: "#4a4a4a",
    fontSize: 8,
  },
}))(TripOrigin);

const SubMenuItem = ({ selected, isSubMenu, label, onClick }) => {
  const classes = useStyles();

  const onCLickHandler = () => {
    onClick(label);
  };

  return (
    <StyledInnerListItem selected={selected} onClick={onCLickHandler} button className={clsx(classes.nested, { [classes.doubleNested]: !isSubMenu })}>
      <ListItemIcon className={classes.icon}>{isSubMenu ? <StyledArrow /> : <StyledTripOrigin />}</ListItemIcon>
      {isSubMenu ? <StyledInnerListItemText primary={label} /> : <SubMenuListItemText primary={label} />}
    </StyledInnerListItem>
  );
};

export default SubMenuItem;
