import React from "react";
import StyledTypography from "../styledTypography";
import Icon from "../../../../components/icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import StyledInput from "../styledInput";
import { useTranslation } from "react-i18next";

const AutocompleteInput = ({
  endAdornmentClassName,
  disabled,
  dropdownVisibility,
  locationicon,
  styleOverrides,
  value,
  InputProps,
  inputProps,
  onInputKeyDown,
  loading,
  ariaDescribedBy
}) => {
  const { t } = useTranslation();
  return (
    <div className={`location-autocomplete__renderInput-wrapper ${disabled ? "disabled" : ""}`} style={{ position: "relative" }}>
      {locationicon && (
        <Icon
          type="location"
          style={{
            position: "absolute",
            left: 5,
            top: "50%",
            fontSize: 24,
            transform: "translateY(-50%)"
          }}
        />
      )}
      {!dropdownVisibility && (
        <div className={`location-autocomplete__value-wrapper ${locationicon ? "l-32" : ""}`}>
          {value && value.fcid ? (
            <>
              <StyledTypography styleoverrides={styleOverrides && styleOverrides.typography ? styleOverrides.typography : null} variant="subtitle1" noWrap>
                {value.fcid}
              </StyledTypography>
              <StyledTypography styleoverrides={styleOverrides && styleOverrides.typography ? styleOverrides.typography : null} variant="subtitle2" noWrap>
                {value.name || value.site_name}
              </StyledTypography>
            </>
          ) : (
            <StyledTypography variant="body1" noWrap>
              {t("pages.pickLocation.searchByLocationNameOrFcid")}
            </StyledTypography>
          )}
        </div>
      )}
      <StyledInput
        ref={InputProps.ref}
        inputProps={{
          ...inputProps,
          placeholder: t("pages.pickLocation.searchByLocationNameOrFcid"),
          id: "location-autocomplete-input",
          className: !dropdownVisibility ? "transparent" : "",
          onKeyDown: onInputKeyDown,
          ["aria-describedby"]: ariaDescribedBy
        }}
        styleoverrides={styleOverrides && styleOverrides.input ? styleOverrides.input : null}
        locationicon={locationicon}
        endAdornment={
          <div className={endAdornmentClassName}>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            <div className="location-autocomplete__end-adortment-wrap">{InputProps.endAdornment}</div>
          </div>
        }
      />
    </div>
  );
};

export default AutocompleteInput;
