import React, { useEffect, useState } from "react";
import { Form } from "antd";
import Grid from "@material-ui/core/Grid";
import Button from "../../components/button";
import { inject, observer } from "mobx-react";
import { fullKitSettings } from "./settings";
import Typography from "@material-ui/core/Typography";

import { orderTypes } from "../../constants";
import Sidebar from "../../components/sidebar";
import UserCard from "../../components/userCard";
import OrderDate from "../../components/orderDate";
import { FullKitCampaignsSelect, ReasonsSelect } from "../../components/selects";
import { PlaceCustomOrderModal } from "../../components/modals";
import FinancialCenterCard from "../../components/financialCenterCard";
import DeliveryInformationTitle from "../../components/deliveryInformationTitle";
import { TextareaWithCounter } from "../../components/formComponents";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const { name, formId, reason, layout, comments, campaign } = fullKitSettings;

const FullKitRequest = ({ form, customOrderStore, locationStore, fullKitCampaignsStore }) => {
  const { t } = useTranslation();
  const [customOrderModalVisible, setCustomOrderModalVisible] = useState(false);
  const [isFullKitAvailable, setIsFullKitAvailable] = useState(false);

  const reasonRules = [{ required: true, message: t("pages.fullKitRequest.reason.rules.required") }];
  const commentsRules = [{ required: true, message: t("pages.fullKitRequest.comments.rules.required") }];
  const campaignRules = [{ required: true, message: t("pages.fullKitRequest.campaign.rules.required") }];

  const submitButton = {
    type: "primary",
    htmlType: "submit",
    title: t("pages.fullKitRequest.placeOrder")
  };

  useEffect(() => {
    setIsFullKitAvailable(fullKitCampaignsStore.isFullKitAvailable);
    form.setFieldsValue({ campaign: null });
  }, [fullKitCampaignsStore.campaigns]);

  const onSubmitHandler = async () => {
    let formState;
    try {
      formState = await form.validateFields();
    } catch (e) {
      formState = e;
    }
    if (formState.errors) return;

    const { reason, comments, campaign } = formState;
    const { id: order_reason_id, body: reasonBody } = JSON.parse(reason.value);
    const { id: campaign_id, code: mrch_code } = JSON.parse(campaign.value);
    const data = {
      order_type: orderTypes.fullKit,
      location_fcid: locationStore.activeLocation.fcid,
      reason: reasonBody,
      quantity: 1,
      order_reason_id,
      comments,
      campaign_id,
      mrch_code
    };

    await customOrderStore.sendCustomOrder(data, onSendFullKitOrderSuccess);
  };

  const onSendFullKitOrderSuccess = () => {
    toggleCustomOrderModal();
  };

  const toggleCustomOrderModal = () => setCustomOrderModalVisible(prev => !prev);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={9}>
          <div className="heading flex-between">
            <div>
              <Typography variant="h1">{t("pages.fullKitRequest.title")}</Typography>
              <p className="heading__subtitle">
                {isFullKitAvailable ? t("pages.fullKitRequest.subTitleAvailable") : t("pages.fullKitRequest.subTitleUnavailable")}
              </p>
            </div>
            <Button
              size="large"
              type={submitButton.type}
              htmlType={submitButton.htmlType}
              form={formId}
              disabled={customOrderStore.isLoading || !isFullKitAvailable}
              onClick={onSubmitHandler}
            >
              {submitButton.title}
            </Button>
          </div>
          <div className="spinning-wrapper">
            <div className="list--materials">
              <div className="material-details">
                <div className="flex flex-between">
                  <h2>{t("pages.fullKitRequest.fullKitDetails")}</h2>
                </div>
                <Form layout={layout}>
                  <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Form.Item>
                        {form.getFieldDecorator(campaign.name, { rules: campaignRules })(
                          <FullKitCampaignsSelect
                            placeholder={t("pages.fullKitRequest.campaign.placeholder")}
                            label={{ text: t("pages.fullKitRequest.campaign.label") }}
                            name={campaign.name}
                            disabled={!isFullKitAvailable}
                          />
                        )}
                      </Form.Item>
                    </Grid>
                    <Grid item lg={8} md={8} sm={6} xs={12}>
                      <Form.Item>
                        {form.getFieldDecorator(reason.name, { rules: reasonRules })(
                          <ReasonsSelect
                            placeholder={t("pages.fullKitRequest.reason.placeholder")}
                            filters={["fullkit", "full_kit"]}
                            label={{ text: t("pages.fullKitRequest.reason.label") }}
                            name={reason.name}
                            disabled={!isFullKitAvailable}
                          />
                        )}
                      </Form.Item>
                    </Grid>
                  </Grid>
                  <Form.Item>
                    {form.getFieldDecorator(comments.name, { rules: commentsRules })(
                      <TextareaWithCounter
                        label={{ text: t("pages.fullKitRequest.comments.label") }}
                        maxLength={comments.maxLength}
                        placeholder={t("pages.fullKitRequest.comments.placeholder")}
                        disabled={!isFullKitAvailable}
                      />
                    )}
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <Button
            size="large"
            type={submitButton.type}
            htmlType={submitButton.htmlType}
            form={formId}
            disabled={customOrderStore.isLoading || !isFullKitAvailable}
            style={{ float: "right" }}
            onClick={onSubmitHandler}
          >
            {submitButton.title}
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Sidebar>
            <DeliveryInformationTitle />
            <OrderDate />
            <FinancialCenterCard editable={true} />
            <UserCard />
          </Sidebar>
        </Grid>
      </Grid>
      <PlaceCustomOrderModal visible={customOrderModalVisible} onCancel={toggleCustomOrderModal} />
    </>
  );
};

export default Form.create({ name: name })(
  inject(({ rootStore }) => ({
    customOrderStore: rootStore.customOrderStore,
    locationStore: rootStore.locationStore,
    fullKitCampaignsStore: rootStore.fullKitCampaignsStore
  }))(observer(FullKitRequest))
);
