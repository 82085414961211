import React, { useState } from "react";
import { Form } from "antd";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";

import { createTemplateForm } from "./settings";
import PreviewModal from "../../modals/previewModal";
import { ImagePreview } from "../../TemplatePreview/components";
import { MaterialSelect, InputAccessibility, InputNumberAccessibility, TextareaWithCounter, UploadFile } from "../../formComponents";

import { useStyles } from "./styles";
import "./styles.scss";

const {
  layout,
  formName,
  file,
  name,
  description,
  department,
  language,
  tags,
  downloadable,
  orderable,
  editable,
  vendor,
  cost,
  quantityMax,
  quantityMin,
  color,
} = createTemplateForm;

const CreateTemplateForm = ({ departmentsStore, colorProfilesStore, languagesStore, tagsStore, vendorsStore, form, initialValues, previewLink, isDeleted }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [vendorRequired, setVendorRequired] = useState(() => initialValues && initialValues[orderable.name]);
  const vendorInitialValue = initialValues && Number(Object.keys(initialValues[vendor.name]).join(""));
  const tagsInitialValue = initialValues && initialValues[tags.name].map(({ value }) => value);

  const normFile = ({ file }) => {
    return file;
  };

  const onPreviewHandler = () => {
    setVisible(true);
  };

  const onPreviewCancelHandler = () => {
    setVisible(false);
  };

  const onOrderableChangeHandler = async ({ value }) => {
    await setVendorRequired(() => value === "YES");
    form.validateFields([vendor.name]);
  };

  return (
    <Form className={classes.createTemplateForm} layout={layout}>
      <Grid container spacing={3} alignItems="flex-start" justifyContent="space-between">
        <Grid item lg={6} md={12} sm={12}>
          <div style={{ position: "relative" }}>
            {isDeleted && <span className="deleted-label deleted-label--right">deleted</span>}
            {previewLink && <ImagePreview onPreview={onPreviewHandler} />}
            {!previewLink && (
              <Form.Item>
                {form.getFieldDecorator(file.name, {
                  getValueFromEvent: normFile,
                  ...file.options,
                })(<UploadFile />)}
              </Form.Item>
            )}
          </div>
          <PreviewModal visible={visible} onCancel={onPreviewCancelHandler} previewLink={previewLink} />
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <Form.Item>
            {form.getFieldDecorator(name.name, {
              initialValue: initialValues && initialValues[name.name],
              ...name.options,
            })(<InputAccessibility maxLength={50} label={name.label} placeholder={name.placeholder} />)}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator(description.name, {
              initialValue: initialValues && initialValues[description.name],
              ...description.options,
            })(<TextareaWithCounter label={description.label} maxLength={500} placeholder={description.placeholder} />)}
          </Form.Item>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(department.name, {
                  initialValue: initialValues && initialValues[department.name],
                  ...department.options,
                })(
                  <MaterialSelect
                    name={department.name}
                    valueKey="value"
                    label={department.label}
                    options={departmentsStore.dropdownValues}
                    placeholder={department.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(language.name, {
                  initialValue: (initialValues && parseInt(initialValues[language.name])) || 1,
                  ...language.options,
                })(
                  <MaterialSelect
                    name={language.name}
                    valueKey="value"
                    label={language.label}
                    options={languagesStore.dropdownValues}
                    placeholder={language.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
          </Grid>
          <Form.Item>
            {form.getFieldDecorator(tags.name, {
              initialValue: tagsInitialValue,
              ...tags.options,
            })(
              <MaterialSelect multiple name={tags.name} valueKey="value" label={tags.label} options={tagsStore.dropdownValues} placeholder={tags.placeholder} />
            )}
          </Form.Item>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={2} md={3}>
              <Form.Item>
                {form.getFieldDecorator(downloadable.name, {
                  initialValue: initialValues && initialValues[downloadable.name] === true ? "YES" : "NO" || downloadable.defaultValue,
                  ...downloadable.options,
                })(
                  <MaterialSelect
                    name={downloadable.name}
                    valueKey="value"
                    label={downloadable.label}
                    options={downloadable.values}
                    placeholder={downloadable.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item sm={2} md={3}>
              <Form.Item>
                {form.getFieldDecorator(editable.name, {
                  initialValue: initialValues && initialValues[editable.name] === true ? "YES" : "NO" || editable.defaultValue,
                  ...editable.options,
                })(
                  <MaterialSelect name={editable.name} valueKey="value" label={editable.label} options={editable.values} placeholder={editable.placeholder} />
                )}
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(orderable.name, {
                  initialValue: initialValues && initialValues[orderable.name] === true ? "YES" : "NO" || orderable.defaultValue,
                  ...orderable.options,
                })(
                  <MaterialSelect
                    name={orderable.name}
                    valueKey="value"
                    label={orderable.label}
                    options={orderable.values}
                    onChange={onOrderableChangeHandler}
                    placeholder={orderable.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(vendor.name, { initialValue: vendorInitialValue, rules: [{ required: vendorRequired }] })(
                  <MaterialSelect
                    name={vendor.name}
                    valueKey="value"
                    label={vendor.label}
                    options={vendorsStore.dropdownValues}
                    placeholder={vendor.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(cost.name, { initialValue: (initialValues && initialValues[cost.name]) || 1 })(
                  <InputNumberAccessibility label={cost.label} min={1} max={999} _id={`${formName}_${cost.name}`} />
                )}
              </Form.Item>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(quantityMin.name, {
                  initialValue: (initialValues && initialValues[quantityMin.name]) || 1,
                  ...quantityMin.options,
                })(<InputNumberAccessibility disabled={!vendorRequired} label={quantityMin.label} min={1} max={999} _id={`${formName}_${quantityMin.name}`} />)}
              </Form.Item>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Form.Item>
                {form.getFieldDecorator(quantityMax.name, {
                  initialValue: (initialValues && initialValues[quantityMax.name]) || 1,
                  ...quantityMax.options,
                })(<InputNumberAccessibility disabled={!vendorRequired} label={quantityMax.label} min={1} max={999} _id={`${formName}_${quantityMax.name}`} />)}
              </Form.Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={6}>
              <Form.Item>
                {form.getFieldDecorator(color.name, { initialValue: (initialValues && parseInt(initialValues[color.name])) || 1, ...color.options })(
                  <MaterialSelect
                    name={color.name}
                    valueKey="value"
                    label={color.label}
                    options={colorProfilesStore.dropdownValues}
                    placeholder={color.placeholder}
                  />
                )}
              </Form.Item>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default inject(({ rootStore }) => ({
  tagsStore: rootStore.tagsStore,
  vendorsStore: rootStore.vendorsStore,
  languagesStore: rootStore.languagesStore,
  departmentsStore: rootStore.departmentsStore,
  colorProfilesStore: rootStore.colorProfilesStore,
}))(observer(CreateTemplateForm));
