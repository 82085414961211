import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import Button from "../button";

import { useStyles } from "./styles";

const ProgressButton = ({ children, isLoading, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button disabled={isLoading} onClick={onClick} {...props}>
        {children}
      </Button>
      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

export default ProgressButton;
