import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ breakpoints }) => ({
  iconButton: {
    [breakpoints.down("sm")]: {
      padding: 5
    },
    ["@media (max-width:765px)"]: {
      marginRight: 10
    }
  }
}));
