import React from "react";
import { List } from "antd";
import { inject, observer } from "mobx-react";

import Icon from "../../icon";
import Avatar from "../../avatar";
import Button from "../../button";
import { proxy } from "../../../common/apiUrls";
import { useTranslation } from "react-i18next";

const ShoppingCartListItem = ({ departmentsStore, material, onDelete }) => {
  const { t } = useTranslation();
  const { id, quantity, item } = material;
  const { code, merch_name, photo, name, content } = item;
  const department = departmentsStore.findById(material.item.departmentId);
  const alt = photo && photo.description ? photo.description : `${merch_name}-${code}`;
  const imagePreviewUrl = (photo && `${proxy}${photo.medium_thumb}`) || (content && content.sample.optimizedPath);

  function onDeleteHandler() {
    onDelete(id);
  }

  return (
    <List.Item
      actions={[
        <Button aria-label={t("shoppingCartPopover.deleteItemLabel")} onClick={onDeleteHandler} className="btn-delete" type="link">
          <Icon type="cancel" />
        </Button>
      ]}
    >
      <Avatar alt={alt} size={64} src={imagePreviewUrl} />
      <div style={{ marginLeft: 16 }}>
        <span className="category block semibold">{merch_name || name}</span>
        <span className="code block grey-light">{code || department.name}</span>
        <span className="quantity block">{t("shoppingCartPopover.quantity", { quantity })}</span>
      </div>
    </List.Item>
  );
};

export default inject(({ rootStore }) => ({
  departmentsStore: rootStore.departmentsStore
}))(observer(ShoppingCartListItem));
