const settings = {
  styleOverrides: {
    input: {
      root: {
        height: 44,
        borderColor: "rgba(255, 255, 255, 0.4)",
        transition: ".3s",
        color: "white",
        "&:hover": {
          boxShadow: "0 0 6px #D4EFFC !important",
          backgroundColor: "rgba(212, 239, 252, 0.2)"
        }
      }
    },
    typography: {
      subtitle1: {
        color: "white"
      },
      subtitle2: {
        color: "white"
      }
    }
  }
};

export default settings;
