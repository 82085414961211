import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import { inject, observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Icon from "../../icon";
import Avatar from "../../avatar";
import { useStyles } from "../styles";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const UserPopover = ({ authorizationStore }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);
  const history = useHistory();

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const goToOrdersHistory = () => {
    history.push("/orders-history");
    handleToggle();
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton
        className={classes.iconButton}
        ref={anchorRef}
        aria-controls={open ? "account-menu" : undefined}
        aria-expanded={open}
        aria-label={t("userPopover.userAccountLabel")}
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <Icon style={{ color: "#fff", fontSize: 32 }} type="account" />
      </IconButton>
      <Popper open={open} style={{ zIndex: 1 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div id="account-menu" className="account-popover" onKeyDown={handleListKeyDown}>
                  <div className="flex-between title-block">
                    <div className="title-block__left">
                      <Avatar style={{ backgroundColor: "#DC1431" }} size={40}>
                        <Icon style={{ color: "#fff", fontSize: 32 }} type="account" />
                      </Avatar>
                    </div>
                    <div className="title-block__right">
                      <span className="title-block__name semibold">{authorizationStore.user.full_name}</span>
                      <span className="title-block__role grey-light">
                        {authorizationStore.user.userRole} | {authorizationStore.user.primaryLocationFCID}
                      </span>
                    </div>
                  </div>
                  <div className="content-block">
                    <Button
                      className="content-block__btn"
                      onClick={goToOrdersHistory}
                      aria-label={t("userPopover.goToOrdersHistoryLabel")}
                      fullWidth
                      startIcon={<Icon className="content-block__icon" type="history" />}
                      disableRipple
                    >
                      {t("userPopover.orderHistory")}
                    </Button>
                    <Button
                      className="content-block__btn"
                      fullWidth
                      aria-label={t("userPopover.logOutLabel")}
                      onClick={authorizationStore.logout}
                      startIcon={<Icon className="content-block__icon" type="logout" />}
                      disableRipple
                    >
                      {t("userPopover.logOut")}
                    </Button>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(UserPopover));
