import { observable, action } from "mobx";
import { createBrowserHistory } from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";

export default class NavigationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable location = null;
  history = syncHistoryWithStore(createBrowserHistory({ basename: "/csp#/" }), new RouterStore());

  @action push(location) {
    this.history.push(location);
  }
  @action replace(location) {
    this.history.replace(location);
  }
  @action go(n) {
    this.history.go(n);
  }
  @action goBack() {
    this.history.goBack();
  }
  @action goForward() {
    this.history.goForward();
  }
}
