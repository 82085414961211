import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { List as AntList, Tooltip } from "antd";
import Typography from "@material-ui/core/Typography";

import List from "../../components/list";
import Icon from "../../components/icon";
import { ChangeRequestSent, SupportRequestSent, ChangeRequestModal, RequestSupportModal } from "../../components/modals";
import { grid } from "./settings";
import PrimaryButton from "../../components/formComponents/primaryButton";

import "./styles.scss";
import { Trans, useTranslation } from "react-i18next";

const Support = ({ authorizationStore, supportStore, locationStore, fullKitCampaignsStore }) => {
  const { t } = useTranslation();
  const { technical_support_email } = authorizationStore.config;
  const history = useHistory();
  const [activeModal, setActiveModal] = useState("");
  const [supportRequestSentVisible, setSupportRequestSentVisible] = useState(false);
  const [changeRequestSentVisible, setChangeRequestSentVisible] = useState(false);
  const [isFullKitAvailable, setIsFullKitAvailable] = useState(false);

  const showSupportRequestModal = activeModal === "requestSupport";
  const showChangeRequestModal = activeModal === "changeRequest";

  const questionsList = [
    {
      id: 1,
      request: "customOrder",
      title: t("pages.support.questions.customOrder.title"),
      description: t("pages.support.questions.customOrder.description"),
      buttonText: t("pages.support.questions.customOrder.buttonText")
    },
    {
      id: 2,
      request: "requestSupport",
      title: t("pages.support.questions.requestSupport.title"),
      description: t("pages.support.questions.requestSupport.description"),
      buttonText: t("pages.support.questions.requestSupport.buttonText")
    },
    {
      id: 3,
      request: "changeRequest",
      title: t("pages.support.questions.changeRequest.title"),
      description: t("pages.support.questions.changeRequest.description"),
      buttonText: t("pages.support.questions.changeRequest.buttonText")
    },
    {
      id: 4,
      request: "customAtmOrder",
      title: t("pages.support.questions.customAtmOrder.title"),
      description: t("pages.support.questions.customAtmOrder.description"),
      buttonText: t("pages.support.questions.customAtmOrder.buttonText")
    },
    {
      id: 5,
      request: "fullKit",
      title: t("pages.support.questions.fullKit.title"),
      description: t("pages.support.questions.fullKit.description"),
      buttonText: t("pages.support.questions.fullKit.buttonText")
    }
  ];

  useEffect(() => {
    locationStore.getLocationById();
  }, [locationStore, locationStore.activeLocation]);

  useEffect(() => {
    setIsFullKitAvailable(fullKitCampaignsStore.isFullKitAvailable);
  }, [fullKitCampaignsStore.campaigns]);

  const onRequestClick = request => {
    switch (request) {
      case "customOrder": {
        history.push("/custom-order");
        break;
      }
      case "customAtmOrder": {
        history.push("/custom-atm");
        break;
      }
      case "fullKit": {
        history.push("/full-kit");
        break;
      }
      default: {
        setActiveModal(request);
      }
    }
  };

  const onCancelClick = () => {
    setActiveModal("");
  };

  const sendSupportOrder = async (data, orderType) => {
    await supportStore.sendSupportOrder({
      data,
      location_fcid: locationStore.fullLocationData.fcid,
      order_type: orderType
    });
  };

  const onSupportRequestSubmitHandler = async data => {
    sendSupportOrder(data, "general_support");
    setSupportRequestSentVisible(true);
  };

  const onChangeRequestSubmitHandler = async data => {
    sendSupportOrder(data, "change_fc_info");
    setChangeRequestSentVisible(true);
  };

  const renderQuestionsListItem = ({ id, title, description, buttonText, request }) => (
    <AntList.Item key={id}>
      <div className="box--shadow question">
        <div className="question__content">
          <h2>
            <span>#{id}</span>
            <p>{title}</p>
          </h2>
          <p>{description}</p>
        </div>
        {request === "fullKit" && !isFullKitAvailable ? (
          <Tooltip title={"There are currently no active campaigns associated with this FC"} placement="right">
            <div>
              <PrimaryButton type="primary" onClick={() => onRequestClick(request)} disabled={true}>
                {buttonText}
              </PrimaryButton>
            </div>
          </Tooltip>
        ) : (
          <PrimaryButton type="primary" onClick={() => onRequestClick(request)}>
            {buttonText}
          </PrimaryButton>
        )}
      </div>
    </AntList.Item>
  );

  return (
    <div>
      <div className="heading">
        <Typography variant="h1">{t("pages.support.title")}</Typography>
        <p className="heading__subtitle">{t("pages.support.subTitle")}</p>
      </div>
      <List className="list--questions" itemLayout="horizontal" grid={grid} dataSource={questionsList} renderItem={renderQuestionsListItem} />
      <div className="support__info">
        <div className="flex">
          <Icon type="mail" />
          <p>
            <Trans
              i18nKey="pages.support.mail"
              components={{
                a: <a href={`mailto:${technical_support_email}`} />
              }}
            />
          </p>
        </div>
      </div>
      {showSupportRequestModal && <RequestSupportModal visible={showSupportRequestModal} onCancel={onCancelClick} onSubmitCb={onSupportRequestSubmitHandler} />}
      {showChangeRequestModal && <ChangeRequestModal visible={showChangeRequestModal} onCancel={onCancelClick} onSubmitCb={onChangeRequestSubmitHandler} />}
      <SupportRequestSent visible={supportRequestSentVisible} onCancel={() => setSupportRequestSentVisible(false)} />
      <ChangeRequestSent visible={changeRequestSentVisible} onCancel={() => setChangeRequestSentVisible(false)} />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  locationStore: rootStore.locationStore,
  supportStore: rootStore.supportStore,
  fullKitCampaignsStore: rootStore.fullKitCampaignsStore
}))(observer(Support));
