import { observable, action, computed } from "mobx";

import { urls } from "../../apiUrls";
import { Template } from "../../models";

export default class TemplateStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable isLoading = false;
  @observable dropzone = null;
  @observable template = new Template({ store: { rootStore: this.rootStore } });

  @action.bound async getTemplate(id) {
    const { method, url } = urls.templates.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onSuccess, method, `${url}/${id}`);
    this.isLoading = false;
  }

  @action.bound onSuccess(data) {
    this.template = new Template({ store: { rootStore: this.rootStore }, ...data });
  }

  @action.bound onPdfUploadSuccess(file) {
    const { templateId, previewBigImagePath, previewMediumImagePath, previewSmallImagePath } = JSON.parse(file.xhr.response);

    this.template.id = templateId;
    this.template.content.sample.optimizedPath = previewSmallImagePath;
    this.template.content.sample.thumbnailPath = previewMediumImagePath;
    this.template.content.sample.path = previewBigImagePath;
  }

  @action.bound deleteTemplate() {
    this.template = new Template({ store: { rootStore: this.rootStore } });
  }

  @action.bound setDropzone(dropzone) {
    this.dropzone = dropzone;
  }

  @action.bound setupOptions() {
    this.dropzone.options.method = !this.template ? "POST" : "PUT";
  }

  @computed get templatePreview() {
    return this.template && this.template.content.sample.path;
  }

  @computed get hasTemplate() {
    return Boolean(this.template.id);
  }

  @computed get formData() {
    const { cost, departmentId, description, downloadable, editable, tags, languageId, name, orderable, vendors, colorProfileId } = this.template;

    const vendorId = Number(Object.keys(vendors).join(""));
    const vendor = vendors[vendorId];

    return {
      cost,
      departmentId,
      description,
      downloadable,
      editable,
      tags,
      languageId,
      name,
      orderable,
      quantityMax: vendor && vendor.quantity.max,
      quantityMin: vendor && vendor.quantity.min,
      vendors,
      colorProfileId,
    };
  }
}
