import { observable, action } from "mobx";
import debounce from "lodash/debounce";
import { t } from "i18next";

export default class ErrorService {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  errorMessage = t("services.errorMessage", "Sorry, an error occurred. Please try again.");

  @observable hasError = false;
  @observable unauthorized = false;
  @observable noPrimaryLocation = false;

  @action.bound setNoPrimaryLocation(val) {
    this.noPrimaryLocation = val;
  }

  @action.bound getServerStatusDebounce = debounce(this.rootStore.serverStatusStore.getServerStatus, 1000, { leading: true, trailing: false });

  @action handleError(error, errorCb) {
    if (errorCb) errorCb(error);

    this.hasError = true;
    const status = error.response && error.response.status;

    switch (status) {
      case 400:
        this.showValidationError(error);
        break;
      case 401:
        this.unauthorized = true;
        break;
      case 404:
        this.rootStore.navigationStore.replace("/");
        break;
      case 422:
        break;
      case 500:
        this.rootStore.navigationStore.replace("/server-error");
        break;
      default:
        this.rootStore.alertsStore.createAlert({
          severity: "error",
          message: this.errorMessage
        });
        this.getServerStatusDebounce();
    }
  }

  @action resetErrors() {
    this.hasError = false;
    this.unauthorized = false;
  }

  @action.bound showValidationError(error) {
    const message = error && error.response && error.response.data;

    this.rootStore.alertsStore.createAlert({
      severity: "error",
      message: message
    });
  }
}
