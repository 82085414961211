import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import Layout from "../../layout";
import routes, { dashboardsRoutes } from "../../../routes";
import { roles } from "../../../constants";

const DashboardRouteWrapper = ({ path, children }) => {
  const match = useRouteMatch(path);

  return <div style={{ display: match?.isExact ? "block" : "none" }}>{children}</div>;
};

const ProtectedRoute = ({
  authorizationStore,
  reasonsStore,
  webSocketStore,
  notificationsStore,
  departmentsStore,
  languagesStore,
  tagsStore,
  colorProfilesStore,
  vendorsStore,
  locationStore,
  fullKitCampaignsStore
}) => {
  useEffect(() => {
    authorizationStore.getInfo();
  }, [authorizationStore]);

  useEffect(() => {
    tagsStore.getTags();
  }, [tagsStore]);

  useEffect(() => {
    reasonsStore.getReasons();
  }, [reasonsStore]);

  useEffect(() => {
    vendorsStore.getVendors();
  }, [vendorsStore]);

  useEffect(() => {
    languagesStore.getLanguages();
  }, [languagesStore]);

  useEffect(() => {
    departmentsStore.getDepartments();
  }, [departmentsStore]);

  useEffect(() => {
    colorProfilesStore.getColorProfiles();
  }, [colorProfilesStore]);

  useEffect(() => {
    webSocketStore.createConsumer();
  }, [webSocketStore]);

  useEffect(() => {
    notificationsStore.getNotifications();
  }, [notificationsStore]);

  useEffect(() => {
    fullKitCampaignsStore.getFullKitCampaigns();
  }, [locationStore.activeLocation.fcid]);

  const history = useHistory();
  const matchSlash = useRouteMatch("/");
  const matchCsp = useRouteMatch("/csp");

  if (matchSlash?.isExact || matchCsp?.isExact) {
    history.replace(authorizationStore.user.isILDashboardAccess ? "/locations-dashboard" : "/catalog");
  }

  if (!authorizationStore.user.id || !authorizationStore.config) return null;
  return (
    <Layout>
      <Switch>
        {routes
          .filter(route => authorizationStore.user.role.some(role => (route.roles ? route.roles.includes(role) : true)))
          .map(route => {
            const { component, ...restProps } = route;
            return <Route key={route.path} {...restProps} render={props => <route.component {...props} />} />;
          })}
      </Switch>
      <Route>
        {authorizationStore.user.role.some(role => role === roles.ILDashboardAccess) &&
          dashboardsRoutes.map(route => {
            const { component, ...restProps } = route;
            return (
              <DashboardRouteWrapper key={route.path} path={route.path}>
                <route.component {...restProps} />
              </DashboardRouteWrapper>
            );
          })}
      </Route>
    </Layout>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore,
  tagsStore: rootStore.tagsStore,
  reasonsStore: rootStore.reasonsStore,
  vendorsStore: rootStore.vendorsStore,
  webSocketStore: rootStore.webSocketStore,
  languagesStore: rootStore.languagesStore,
  departmentsStore: rootStore.departmentsStore,
  notificationsStore: rootStore.notificationsStore,
  colorProfilesStore: rootStore.colorProfilesStore,
  locationStore: rootStore.locationStore,
  fullKitCampaignsStore: rootStore.fullKitCampaignsStore
}))(observer(ProtectedRoute));
