import React from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";

import Button from "../../components/button";

import "../noMatch/styles.scss";
import { useTranslation } from "react-i18next";

const UnauthorizedError = ({ authorizationStore }) => {
  const { t } = useTranslation();
  const handleLogout = () => {
    authorizationStore.logout();
  };

  if (authorizationStore.isTokenExpired) {
    authorizationStore.logout();
    return null;
  }

  return (
    <div className="page-wrapper">
      <div className="inner">
        <Typography variant="h1" className="title">
          {t("pages.unauthorizedError.title")}
        </Typography>
        <span className="subtitle bold block">{t("pages.unauthorizedError.subTitle")}</span>
        <Button onClick={handleLogout}>{t("pages.unauthorizedError.logout")}</Button>
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(UnauthorizedError));
