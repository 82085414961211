import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";

import Tabs from "../../components/tabs";

import { tabPosition } from "./settings";
import "./styles.scss";

import LexicalWrapper from "../../components/lexicalWrapper";
import { useTranslation } from "react-i18next";

const Instructions = ({ instructionsStore }) => {
  const { t } = useTranslation();
  useEffect(() => {
    instructionsStore.getData();
  }, [instructionsStore]);

  const getTabs = () => {
    return instructionsStore.pages.map(({ id, title, body_json }) => ({
      id,
      name: title,
      content: <LexicalWrapper body_json={body_json} />
    }));
  };

  return (
    <div className="instructions-page">
      <Typography variant="h1" className="instructions-page__title">
        {t("pages.instructions.title")}
      </Typography>
      <Tabs tabPosition={tabPosition} tabs={getTabs()} />
    </div>
  );
};

export default inject(({ rootStore }) => ({
  instructionsStore: rootStore.instructionsStore
}))(observer(Instructions));
