import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { PowerBIEmbed } from "powerbi-client-react";

import { useStyles } from "./styles";
import "./styles.scss";

const PowerBIDashboard = ({ powerBIDashboardStore, reportId }) => {
  const classes = useStyles();

  const [report, setReport] = useState(null);

  useEffect(() => {
    powerBIDashboardStore.getReport(reportId);

    return () => {
      powerBIDashboardStore.clearExpireTimer();
    };
  }, []);

  useEffect(() => {
    if (!report) return;
    report.reload(); //TODO reload report when get new one
  }, [powerBIDashboardStore.report.tokenExpiryDate]);

  console.log("component", powerBIDashboardStore.report);

  return (
    <div className={"power-bi"}>
      {powerBIDashboardStore.report[reportId] && (
        <PowerBIEmbed
          embedConfig={powerBIDashboardStore.report[reportId]?.config}
          cssClassName={classes.reportWrapper}
          pageView="fitToWidth"
          getEmbeddedComponent={embeddedReport => setReport(embeddedReport)}
        />
      )}
    </div>
  );
};

export default inject(({ rootStore }) => ({
  powerBIDashboardStore: rootStore.powerBIDashboardStore
}))(observer(PowerBIDashboard));
