import React from "react";

import "./styles.scss";

const TextBlock = ({ title, content, contentSize = 15, ...restProps }) => (
  <div className="text-block" {...restProps}>
    <span>{title}:</span>
    <p style={{ fontSize: contentSize }}>{content}</p>
  </div>
);

export default TextBlock;
