import { observable, computed } from "mobx";
import { models } from "powerbi-client";

export default class PowerBIReport {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable id = null;
  @observable visualizationType = "report";
  @observable name = "";
  @observable url = "";
  @observable token = "";
  @observable tokenExpiryDate = "";

  @computed get config() {
    return {
      type: this.visualizationType,
      id: this.id,
      embedUrl: this.url,
      accessToken: this.token,
      tokenType: models.TokenType.Embed,
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false
          },
          pageNavigation: {
            visible: false
          }
        },
        background: models.BackgroundType.Transparent
      }
    };
  }
}
