import React from "react";
import Button from "@material-ui/core/Button";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const SkipToMainContentBtn = ({ onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      tabIndex="0"
      disableRipple
      className={classes.skipLink}
      aria-label={t("skipToMainContent")}
      variant="contained"
      color="secondary"
      onClick={onClick}
      id="skipToMainContent"
      autoFocus
    >
      {t("skipToMainContent")}
    </Button>
  );
};

export default SkipToMainContentBtn;
