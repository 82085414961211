import { getBackendHostName, getWSHostName } from "../../utils";

// const v1 = "api/v1/";
const v1 = "";
const v2 = "api/v2/";
const p_o_d = "p_o_d/v1/";
const host = getBackendHostName();
export const proxy = getBackendHostName();

export const urls = {
  user: {
    getProfile: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/profile`
    },
    updateProfile: {
      method: "patch",
      url: `${host}/${v1}customer_service_portal/profile`
    },
    getActiveLocation: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/profile/active_location`
    },
    setActiveLocation: {
      method: "put",
      url: `${host}/${v1}customer_service_portal/profile/active_location`
    }
  },
  getLocations: {
    method: "get",
    url: `${host}/${v2}locations`
  },
  getAtms: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/atms`
  },
  getLocationById: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/locations/`
  },
  locationFilters: {
    method: "post",
    url: `${host}/${v1}customer_service_portal/locations/filters`
  },
  users: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/users`
  },
  catalog: {
    marketing: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/catalog_search/marketing`
    },
    marketing_cling: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/catalog_search/marketing_cling`
    },
    merchandising: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/catalog_search/merchandising`
    },
    regulatory: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/catalog_search/regulatory`
    },
    healthSafety: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/catalog_search/health_safety`
    },
    printOnDemand: {
      method: "get",
      url: `${host}/${p_o_d}documents/templates`
    }
  },
  filters: {
    marketing: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/info/marketing`
    },
    marketing_cling: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/info/marketing_cling`
    },
    merchandising: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/info/merchandising`
    },
    regulatory: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/info/regulatory`
    },
    healthSafety: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/info/health_safety`
    },
    printOnDemand: {
      method: "get",
      url: `${host}/${p_o_d}documents/templates`
    }
  },
  orders: {
    history: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/orders/cart/history`
    },
    filters: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/orders/info`
    },
    additionalFilters: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/filters`
    },
    getOrder: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/orders/cart`
    },
    updateStatus: {
      method: "patch",
      url: `${host}/${v1}customer_service_portal/orders/cart`
    },
    addMaterial: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/orders`
    },
    updateMaterial: {
      method: "patch",
      url: `${host}/${v1}customer_service_portal/orders`
    },
    deleteMaterial: {
      method: "delete",
      url: `${host}/${v1}customer_service_portal/orders`
    },
    clearDraft: {
      method: "patch",
      url: `${host}/${v1}customer_service_portal/orders/cart`
    },
    getCustomDetails: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/orders/custom/details`
    },
    sendCustomOrders: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/orders/custom`
    },
    sendSupportOrder: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/orders/support`
    },
    create: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/orders/completed_pod`
    }
  },
  reasons: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/order_reasons`
  },
  campaigns: {
    fullKit: {
      method: "get",
      url: `${host}/${v2}campaigns/running_with_full_kits`
    }
  },
  textPages: {
    method: "post",
    url: `${host}/${v1}customer_service_portal/text_items`
  },
  orderDetails: {
    marketing: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/marketing/order_details`
    },
    marketing_cling: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/marketing_cling/order_details`
    },
    merchandising: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/merchandising/order_details`
    },
    regulatory: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/regulatory/order_details`
    },
    healthSafety: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/health_safety/order_details`
    },
    printOnDemand: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/health_safety/order_details`
    }
  },
  config: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/general_configs`
  },
  getNotifications: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/notifications`
  },
  readNotification: {
    method: "put",
    url: `${host}/${v1}customer_service_portal/notifications/read`
  },
  readAllNotifications: {
    method: "put",
    url: `${host}/${v1}customer_service_portal/notifications/read_all`
  },
  uploadPdf: {
    method: "post",
    url: `${host}/${p_o_d}pdfs`
  },
  downloadPdf: {
    method: "get",
    url: `${host}/${p_o_d}documents/templates`
  },
  templates: {
    get: {
      method: "get",
      url: `${host}/${p_o_d}documents/templates`
    },
    post: {
      method: "post",
      url: `${host}/${p_o_d}documents/templates`
    },
    put: {
      method: "put",
      url: `${host}/${p_o_d}documents/templates`
    },
    delete: {
      method: "delete",
      url: `${host}/${p_o_d}documents/templates`
    },
    formFields: {
      get: { method: "get", url: `${host}/${p_o_d}documents/templates/{id}/form-fields` },
      put: { method: "put", url: `${host}/${p_o_d}documents/templates/{id}/form-fields` }
    },
    generatePdf: { method: "post", url: `${host}/${p_o_d}documents/templates/{id}/generate` },
    deleteGeneratedPdf: { method: "delete", url: `${host}/${p_o_d}documents/templates/document` }
  },
  departments: {
    get: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/departments`
    }
  },
  colors: {
    get: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/color_profiles`
    }
  },
  languages: {
    get: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/languages`
    }
  },
  tags: {
    get: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/tags`
    }
  },
  vendors: {
    get: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/vendors`
    }
  },
  getAlerts: {
    method: "get",
    url: `${host}/${v1}customer_service_portal/alerts`
  },
  webSocket: {
    url: `${getWSHostName()}/cable`
  },
  feedbacks: {
    activeFeedback: {
      method: "get",
      url: `${host}/${v1}customer_service_portal/feedbacks/active_feedback`
    }
  },
  userFeedback: {
    create: {
      method: "post",
      url: `${host}/${v1}customer_service_portal/user_feedbacks`
    },
    update: {
      method: "put",
      url: `${host}/${v1}customer_service_portal/user_feedbacks/{id}`
    },
    submit: {
      method: "put",
      url: `${host}/${v1}customer_service_portal/user_feedbacks/{id}/submit`
    }
  },
  status: {
    method: "get",
    url: `${host}/status`
  },
  getPowerBIReport: {
    method: "get",
    url: `${host}/${v2}get_power_bi_dashboard`
  }
};
