import React from "react";
import { inject, observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { catalogs } from "../../../../constants";
import CatalogList from "../../../../components/catalogList";
import PrintOnDemandCatalogList from "../../../../components/printOnDemandCatalogList/PrintOnDemandCatalogList";
import { useTranslation } from "react-i18next";

const CatalogResults = ({ catalogStore, searchStore, departmentsStore, includeOtherFc }) => {
  const { t } = useTranslation();
  const { catalogName } = useParams();
  const isPodCatalog = catalogName === catalogs.printOnDemand;
  const catalog = catalogStore.catalogs.find(catalog => catalog.catalogName === catalogName);

  return isPodCatalog ? (
    <PrintOnDemandCatalogList loading={searchStore.isLoading || departmentsStore.isLoading} templates={searchStore.fcSearchResults[catalogName]} />
  ) : (
    <div>
      <CatalogList loading={searchStore.isLoading} dataSource={searchStore.fcSearchResults[catalogName]} />
      {includeOtherFc && searchStore.outFcSearchResults[catalogName] && (
        <div>
          <h1>{t("pages.searchResults.outOfFcMaterials")}</h1>
          <CatalogList loading={catalog.isLoading || searchStore.isLoading} dataSource={searchStore.outFcSearchResults[catalogName]} />
        </div>
      )}
    </div>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore,
  searchStore: rootStore.searchStore,
  departmentsStore: rootStore.departmentsStore
}))(observer(CatalogResults));
