import React, { useEffect } from "react";
import { Spin } from "antd";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";

import Affix from "../../components/affix";
import Table from "../../components/table";
import Button from "../../components/button";
import MuiTabs from "../../components/muiTabs";
import ColumnTitle from "./components/columnTitle";
import { DateService } from "../../services";
import { alphabeticSorter } from "../../utils";
import DownloadReport from "../../components/downloadReport";
import RangeDatePickerAda from "../../components/rangeDatePickerAda";
import { expandedRowRender, getRowClassName, renderStatusColumn, renderDateColumn, getTransformedOrderType } from "./settings";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const OrdersHistory = ({ ordersHistoryStore, locationStore }) => {
  const { t } = useTranslation();
  const loading = ordersHistoryStore.isLoading || ordersHistoryStore.pending || !Boolean(ordersHistoryStore.tableFilters.length);

  useEffect(() => {
    if (locationStore.activeLocation && locationStore.activeLocation.fcid) {
      ordersHistoryStore.getOrdersHistory();
    }
  }, [
    ordersHistoryStore,
    ordersHistoryStore.activeFilters,
    ordersHistoryStore.activeFilters.finalization_time_lt,
    ordersHistoryStore.activeFilters.finalization_time_gt,
    ordersHistoryStore.activeFilters.order_status_in,
    ordersHistoryStore.pagination.current,
    locationStore.activeLocation
  ]);

  useEffect(() => {
    ordersHistoryStore.getOrdersHistoryFilters();
  }, [ordersHistoryStore, locationStore.activeLocation]);

  useEffect(() => {
    const skipToMainContent = document.getElementById("skipToMainContent");
    if (skipToMainContent) {
      skipToMainContent.focus();
    }
  }, [ordersHistoryStore]);

  useEffect(() => {
    return () => {
      ordersHistoryStore.clearFilters();
    };
  }, []);

  const tabs = [
    { id: "all", name: t("pages.ordersHistory.tabs.allOrders") },
    { id: 0, name: t("pages.ordersHistory.tabs.draft") },
    { id: 1, name: t("pages.ordersHistory.tabs.newOrder") },
    { id: 2, name: t("pages.ordersHistory.tabs.inProgress") },
    { id: 3, name: t("pages.ordersHistory.tabs.shipped") },
    { id: 4, name: t("pages.ordersHistory.tabs.complete") },
    { id: [5, 6], name: t("pages.ordersHistory.tabs.declined") }
  ];

  const timePeriods = {
    months: "months",
    year: "year"
  };

  const defaultDateRanges = {
    [t("pages.ordersHistory.ranges.lastMonth")]: [DateService.subtractFromDate(1, timePeriods.months), DateService.getEndOfDay()],
    [t("pages.ordersHistory.ranges.last3Months")]: [DateService.subtractFromDate(3, timePeriods.months), DateService.getEndOfDay()],
    [t("pages.ordersHistory.ranges.lastYear")]: [DateService.subtractFromDate(1, timePeriods.year), DateService.getEndOfDay()]
  };

  function renderColumnTitle({ filterName }) {
    const filter = ordersHistoryStore.getFilterByName(filterName);
    return filter && <ColumnTitle filter={filter} filterName={filterName} />;
  }

  function withReset(component) {
    if (!component) return null;
    return (
      <div>
        <Button className="btn--reset" disabled={!ordersHistoryStore.hasActiveFilters()} onClick={ordersHistoryStore.clearFilters}>
          {t("pages.ordersHistory.reset")}
        </Button>
        {component}
      </div>
    );
  }

  const columns = [
    {
      title: t("pages.ordersHistory.columnsTitles.type"),
      key: "type",
      className: "order-type-column",
      sorter: (a, b) => alphabeticSorter(a.type, b.type),
      children: [
        {
          title: withReset(renderColumnTitle({ filterName: "order_type" })),
          dataIndex: "type",
          width: 150,
          key: "type",
          render: text => <span className="semibold custom-column-value">{getTransformedOrderType(text, t)}</span>
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.id"),
      key: "id",
      className: "order-id-column",
      sorter: (a, b) => a.id - b.id,
      children: [
        {
          title: renderColumnTitle({ filterName: "id" }),
          width: 50,
          key: "id",
          dataIndex: "id",
          render: text => <span className="bold custom-column-value">{text}</span>
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.finalization_time"),
      key: "finalization_time",
      className: "order-date-column",
      sorter: (a, b) => DateService.getWrappedDate(a.finalization_time) - DateService.getWrappedDate(b.finalization_time),
      children: [
        {
          title: renderColumnTitle({ filterName: "finalization_time" }),
          dataIndex: "finalization_time",
          key: "finalization_time",
          render: renderDateColumn
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.location_market"),
      key: "location_market",
      className: "market-column",
      sorter: (a, b) => alphabeticSorter(a.market, b.market),
      children: [
        {
          title: renderColumnTitle({}),
          dataIndex: "market_name",
          key: "location_market",
          render: text => <span className="custom-column-value">{text}</span>
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.location_fcid"),
      key: "location_fcid",
      className: "location-fcid-column",
      sorter: (a, b) => a.location_fcid - b.location_fcid,
      children: [
        {
          title: renderColumnTitle({}),
          dataIndex: "location_fcid",
          key: "location_fcid",
          render: (text, order) => {
            return (
              <div>
                <span className="custom-column-value block">{order.financial_center_name}</span>
                <span className="custom-column-value">{text}</span>
              </div>
            );
          }
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.requestor"),
      key: "requestor",
      className: "requestor-column",
      sorter: (a, b) => alphabeticSorter(a.requestor.full_name, b.requestor.full_name),
      children: [
        {
          title: renderColumnTitle({
            filterName: "user_id"
          }),
          dataIndex: "requestor",
          key: "requestor",
          render: user => <span className="custom-column-value">{user ? user.full_name : ""}</span>
        }
      ]
    },
    {
      title: t("pages.ordersHistory.columnsTitles.status"),
      key: "status",
      className: "status-column",
      sorter: (a, b) => a.status.length - b.status.length,
      children: [
        {
          title: renderColumnTitle({ filterName: "order_status" }),
          dataIndex: "status",
          key: "status",
          render: renderStatusColumn
        }
      ]
    }
  ];

  return (
    <div className="orders-history">
      <div className="orders__header">
        <div className="flex-between orders__heading">
          <Typography variant="h1">{t("pages.ordersHistory.title")}</Typography>
        </div>
        <Affix offsetTop={64}>
          <Grid style={{ marginBottom: 16 }} container justifyContent="space-between" spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <MuiTabs ariaLabel="status filter" activeKey="all" onChange={ordersHistoryStore.setOrderStatusFilter} options={tabs} />
            </Grid>
            <Grid item>
              <RangeDatePickerAda
                onChange={ordersHistoryStore.onChangeHandler}
                defaultEndDate={DateService.getEndOfDay()}
                defaultStartDate={DateService.subtractFromDate(1, "year")}
                defaultDateRanges={defaultDateRanges}
              />
            </Grid>
            <Grid item>
              <DownloadReport />
            </Grid>
          </Grid>
        </Affix>
      </div>
      <div className="orders-history__content">
        {loading ? (
          <Spin tip={t("loading")} spinning={true} role="alert" aria-busy="true" />
        ) : (
          <Table
            className="orders-history__table"
            rowClassName={getRowClassName}
            onChange={ordersHistoryStore.onTableChangeHandler}
            pagination={{ ...ordersHistoryStore.pagination, size: "default" }}
            columns={columns}
            loading={ordersHistoryStore.isLoading || ordersHistoryStore.pending || !Boolean(ordersHistoryStore.tableFilters.length)}
            dataSource={[...ordersHistoryStore.orders]}
            rowKey={record => record.id}
            expandedRowRender={expandedRowRender}
          />
        )}
      </div>
    </div>
  );
};

export default inject(({ rootStore }) => ({
  ordersHistoryStore: rootStore.ordersHistoryStore,
  locationStore: rootStore.locationStore
}))(observer(OrdersHistory));
