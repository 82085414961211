import React from "react";

import List from "../../list";
import MaterialsList from "../materialsList";
import { DateService } from "../../../services";
import { renderListItemHandler, dateFormat } from "./settings";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const CatalogListItem = ({ name, campaign_end_date, zones, material }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex-between campaign-title">
        <h2 className="campaign-title__text">{name}</h2>
        <span className="campaign-title__date semibold">
          {campaign_end_date && t("catalogListItem.validUntil", { date: DateService.getFormattedDate(campaign_end_date, dateFormat) })}
        </span>
      </div>
      {material ? <MaterialsList material={material} subZoneName={name} /> : <List dataSource={zones} renderItem={renderListItemHandler} />}
    </>
  );
};

export default CatalogListItem;
