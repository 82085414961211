import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class ColorProfilesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable colorProfiles = [];
  @observable isLoading = false;

  @action.bound async getColorProfiles() {
    const { method, url } = urls.colors.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetColorProfilesSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onGetColorProfilesSuccess({ data }) {
    this.colorProfiles = data;
  }

  @action.bound findById(colorProfileId) {
    return this.colorProfiles.find(({ id }) => id === parseInt(colorProfileId));
  }

  @computed get dropdownValues() {
    return this.colorProfiles.map(({ id, name }) => ({ id, label: name, value: id }));
  }
}
