import React, { useState } from "react";
import { Form } from "antd";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Dialog from "@material-ui/core/Dialog";

import PreviewModal from "../previewModal";
import { DialogTitle, DialogContent } from "./components";
import { settings, printOnDemandOrderForm } from "./settings";

const { formName } = printOnDemandOrderForm;

const PrintOnDemandOrderModal = ({ checkoutStore, authorizationStore, form, template, onCloseModal, onDelete, onEdit, onRestore, visible }) => {
  const history = useHistory();
  const [preview, setPreview] = useState(false);

  const validateForm = async () => {
    let formData;
    try {
      formData = await form.validateFields();
    } catch (e) {
      formData = e;
    }

    return formData;
  };

  const submitHandler = async cb => {
    const formData = await validateForm();

    if (formData.errors) return;
    const { color, comments, language, reason, quantity, ...pdfForm } = formData;

    if (template.hasPdfForm) {
      await template.generatePdf(pdfForm);
    }

    await checkoutStore.draftOrder.addMaterial(template, {
      color,
      comments,
      language,
      reason,
      quantity,
      item: { ...template.dataForOrder, pdfFormFields: template.getPdfFormWithValues(pdfForm) },
    });
    onCloseHandler();
    if (cb) cb();
  };

  const onAddToCardHandler = async () => {
    await submitHandler();
  };

  const onOrderNowHandler = async () => {
    const cb = () => {
      history.push("/checkout");
    };
    await submitHandler(cb);
  };

  const onCloseHandler = () => {
    if (!onCloseModal) return;
    onCloseModal();
  };

  const onDeleteHandler = () => {
    if (!onDelete) return;
    onDelete();
  };

  const onEditHandler = () => {
    if (!onEdit) return;
    onEdit();
  };

  const onDownloadHandler = async () => {
    const formData = await validateForm();

    if (formData.errors) return;

    const { color, comments, language, reason, quantity, ...pdfForm } = formData;

    if (!isEmpty(pdfForm)) {
      await template.generatePdf(pdfForm);
    }

    await checkoutStore.createOrder(template, {
      color,
      comments,
      language,
      reason,
      quantity,
      item: { ...template.dataForOrder, pdfFormFields: template.getPdfFormWithValues(pdfForm) },
    });
    await template.downloadGeneratedPdf();
  };

  const onDownloadEmptyHandler = async () => {
    const formData = await validateForm();

    if (formData.errors) return;

    const { color, comments, language, reason, quantity, ...pdfForm } = formData;

    await checkoutStore.createOrder(template, {
      color,
      comments,
      language,
      reason,
      quantity,
      item: { ...template.dataForOrder, pdfFormFields: template.getPdfFormWithValues(pdfForm) },
    });
    await template.downloadPdf();
  };

  const onZoomOutHandler = async () => {
    await template.loadPdf();
    setPreview(true);
  };

  const onRestoreHandler = () => {
    if (!onRestore) return;
    onRestore();
  };

  const onPreviewCancelHandler = () => {
    setPreview(false);
  };

  return (
    <>
      <Dialog open={visible} maxWidth="lg" onClose={onCloseHandler} aria-labelledby={settings.titleId} fullWidth>
        <DialogTitle
          title={template.name}
          onEdit={onEditHandler}
          onCloseModal={onCloseHandler}
          onDelete={onDeleteHandler}
          deleted={template.isDeleted}
          isAdmin={authorizationStore.user.isAdmin}
        />
        <DialogContent
          form={form}
          template={template}
          showLoader={template.isLoading}
          isEditor={authorizationStore.user.isPodEditor}
          onAddToCard={onAddToCardHandler}
          onOrderNow={onOrderNowHandler}
          onDownload={onDownloadHandler}
          onDownloadEmpty={onDownloadEmptyHandler}
          onZoomOut={onZoomOutHandler}
          onRestore={onRestoreHandler}
          isAdmin={authorizationStore.user.isAdmin}
        />
      </Dialog>
      <PreviewModal visible={preview} onCancel={onPreviewCancelHandler} previewLink={template.bigPhoto} base64={template.base64} />
    </>
  );
};

export default Form.create({ name: formName })(
  inject(({ rootStore }) => ({
    authorizationStore: rootStore.authorizationStore,
    checkoutStore: rootStore.checkoutStore,
    catalogStore: rootStore.catalogStore,
    templateStore: rootStore.templateStore,
  }))(observer(PrintOnDemandOrderModal))
);
