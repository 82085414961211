import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "react-pdf/node_modules/pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/Page/AnnotationLayer.css";

import Button from "../button";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const PdfViewer = ({ url, useFrame }) => {
  const { t } = useTranslation();
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onNextHandler = () => {
    if (page >= numPages) return;
    setPage(page + 1);
  };

  const onPrevHandler = () => {
    if (page <= 1) return;
    setPage(page - 1);
  };

  if (useFrame) return <iframe width="100%" height="500px" title={t("pdfViewer.title")} src={url} />;

  return (
    <div>
      <div className="pagination-wrapper">
        <span>{t("pdfViewer.pageNumber", { page, numPages })}</span>
        <div>
          <Button text="prev" color="primary" variant="text" onClick={onPrevHandler}>
            {t("pdfViewer.prev")}
          </Button>
          <Button text="next" color="primary" variant="text" onClick={onNextHandler}>
            {t("pdfViewer.next")}
          </Button>
        </div>
      </div>
      <Document className="pdf-document" file={{ url, httpHeaders: { "Access-Control-Allow-Origin": "*" } }} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={page} />
      </Document>
    </div>
  );
};

export default PdfViewer;
