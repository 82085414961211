import React from "react";
import Icon from "../icon";

export const carouselSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  accessibility: true,
  arrows: true,
  prevArrow: <Arrow type="arrowPrev" />,
  nextArrow: <Arrow type="arrowNext" />
};

function Arrow({ type, className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <Icon type={type} ariaHidden={false} />
    </div>
  );
}
