export const errorMessage = "Request error, please try again.";

export const locationFilterKey = {
  merchandising: "location_fcid_in",
  regulatory: "location_fcid_in",
  healthSafety: "location_fcid_in",
  printOnDemand: "location_fcid_in",
  marketing: "location_id_in",
  marketing_cling: "location_fcid_in"
};

const materialTypes = {
  MarketingAssignment: "marketing",
  MarketingCling: "marketingcling",
  MerchItemOccurence: "fixtures & signage",
  regulatory: "regulatory",
  health_safety: "health & safety",
  PodTemplate: "podtemplate"
};

export const profileAssignments = {
  notInProfile: "not_in_profile",
  inProfile: "in_profile"
};

export const getMaterialNameByType = type => {
  return materialTypes[type];
};
