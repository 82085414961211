import React from "react";
import { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { Tag } from "antd";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const RangeDatePickerAda = ({ defaultStartDate, defaultEndDate, defaultDateRanges, onChange }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [focusedInput, setFocusedInput] = useState(null);

  const dateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const focusChange = focusedInput => {
    setFocusedInput(focusedInput);
  };

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 27) setFocusedInput(null);
  };

  const handleMoveToMoth = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      onChange([startDate, endDate]);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId="start_date_id"
      endDate={endDate}
      initialVisibleMonth={() => endDate}
      screenReaderInputMessage={t("rangeDatePickerAda.screenReaderInputMessage")}
      endDateId="end_date_id"
      onDatesChange={dateChange}
      focusedInput={focusedInput}
      onFocusChange={focusChange}
      isOutsideRange={() => false}
      displayFormat={() => "YYYY/MM/DD"}
      numberOfMonths={1}
      hideKeyboardShortcutsPanel={true}
      renderCalendarInfo={() => (
        <div className="daterangepicker__action-container">
          {Object.keys(defaultDateRanges).map(key => {
            const [startDate, endDate] = defaultDateRanges[key];
            return (
              <Tag
                key={key}
                onClick={() => {
                  handleMoveToMoth(startDate, endDate);
                }}
                onKeyDown={({ key }) => {
                  if (key === "Enter") {
                    handleMoveToMoth(startDate, endDate);
                  }
                }}
                tabIndex={0}
                role="button"
                color="blue"
                className="daterangepicker__btn-tag"
              >
                {key}
              </Tag>
            );
          })}
        </div>
      )}
    />
  );
};

export default RangeDatePickerAda;
