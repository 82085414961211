import * as localForage from "localforage";

class LocalStorageService {
  async save(key, value) {
    return await localForage.setItem(key, JSON.stringify(value));
  }

  async get(key) {
    return JSON.parse(await localForage.getItem(key));
  }

  async remove(key) {
    return await localForage.removeItem(key);
  }

  async clear() {
    return await localForage.clear();
  }
}

export default new LocalStorageService();
