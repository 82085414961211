export const orderTypes = {
  atm: "remote_atm",
  fullKit: "full_kit"
};

export const photoSizes = {
  original: "original",
  medium: "medium_thumb",
  small: "small_thumb",
  thumb: "thumb"
};

export const templateStates = {
  draft: "DRAFT",
  active: "ACTIVE",
  deleted: "DELETED"
};

export const roles = {
  podUser: "POD User",
  podEditor: "POD Editor",
  podAdmin: "CSP Administrator",
  ILDashboardAccess: "IL-dbs-access"
};

export const templateCreated = {
  severity: "success",
  message: "Template has been created and file has been uploaded and is being processed"
};

export const templateUpdateMessage = "Template was successfully updated";

export const orderStatuses = {
  new: "new_order",
  complete: "complete"
};

export const catalogs = {
  marketing: "marketing",
  merchandising: "merchandising",
  regulatory: "regulatory",
  healthSafety: "healthSafety",
  printOnDemand: "printOnDemand"
};

export const pdfFormFieldsTypes = {
  text: "text"
};

export const pdfFormValidationRules = {
  min: "min",
  max: "max",
  required: "required"
};

export const defaultPdfFormValidation = {
  text: { min: 2, max: 20, required: true }
};

export const templateFileSize = 20971520; // 20mb
