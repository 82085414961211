import { observable, action } from "mobx";
import debounce from "lodash/debounce";

import CatalogStore from "../pages/catalog/Store";
import CheckoutStore from "../pages/checkout/Store";
import CustomOrderStore from "../pages/customOrder/Store";
import CustomAtmStore from "../pages/customAtm/Store";
import FinancialCenterStore from "../pages/financialCenter/Store";
import InstructionsStore from "../pages/instructions/Store";
import OrdersHistoryStore from "../pages/ordersHistory/Store";
import AuthorizationStore from "./stores/AuthorizationStore";
import AlertsStore from "./stores/AlertsStore";
import ColorProfilesStore from "./stores/ColorProfilesStore";
import DepartmentsStore from "./stores/DepartmentsStore";
import LanguagesStore from "./stores/LanguagesStore";
import LocationStore from "./stores/LocationStore";
import MenuStore from "./stores/MenuStore";
import NavigationStore from "./stores/NavigationStore";
import NotificationsStore from "./stores/NotificationsStore";
import ReasonsStore from "./stores/ReasonsStore";
import SearchStore from "./stores/SearchStore";
import SupportStore from "./stores/SupportStore";
import TagsStore from "./stores/TagsStore";
import VendorsStore from "./stores/VendorsStore";
import TemplateStore from "./stores/TemplateStore";
import WebSocketStore from "./stores/WebSocketStore";
import FeedbacksStore from "../pages/feedback/feedbacksStore";
import { HttpService, ErrorService, InterceptorsService } from "../services";
import ServerStatusStore from "./stores/ServerStatusStore";
import FullKitCampaignsStore from "./stores/FullKitCampaignsStore";
import PowerBIDashboardStore from "./stores/PowerBIDashboardStore";

class RootStore {
  constructor(settings) {
    this.settings = settings;
    this.menuStore = new MenuStore(this);
    this.tagsStore = new TagsStore(this);
    this.searchStore = new SearchStore(this);
    this.alertsStore = new AlertsStore(this);
    this.errorService = new ErrorService(this);
    this.reasonsStore = new ReasonsStore(this);
    this.catalogStore = new CatalogStore(this);
    this.supportStore = new SupportStore(this);
    this.vendorsStore = new VendorsStore(this);
    this.locationStore = new LocationStore(this);
    this.checkoutStore = new CheckoutStore(this);
    this.templateStore = new TemplateStore(this);
    this.webSocketStore = new WebSocketStore(this);
    this.customAtmStore = new CustomAtmStore(this);
    this.languagesStore = new LanguagesStore(this);
    this.navigationStore = new NavigationStore(this);
    this.customOrderStore = new CustomOrderStore(this);
    this.departmentsStore = new DepartmentsStore(this);
    this.instructionsStore = new InstructionsStore(this);
    this.notificationsStore = new NotificationsStore(this);
    this.authorizationStore = new AuthorizationStore(this);
    this.ordersHistoryStore = new OrdersHistoryStore(this);
    this.colorProfilesStore = new ColorProfilesStore(this);
    this.interceptorsService = new InterceptorsService(this);
    this.financialCenterStore = new FinancialCenterStore(this);
    this.feedbackStore = new FeedbacksStore(this);
    this.serverStatusStore = new ServerStatusStore(this);
    this.fullKitCampaignsStore = new FullKitCampaignsStore(this);
    this.powerBIDashboardStore = new PowerBIDashboardStore(this);
  }

  settings = {};
  @observable pending = false;

  @action.bound makeRequestWithDebounce = debounce(this.makeRequest, 1000);

  @action async makeRequest(cb, method, url, params, body, errorCb, headers = {}) {
    this.pending = true;
    this.errorService.resetErrors();

    const req = await HttpService.makeRequest({
      method,
      url,
      params,
      data: body,
      headers
    });

    const [response, error] = req;

    if (error) {
      this.errorService.handleError(error, errorCb);
    } else {
      const { data } = response;
      if (cb) {
        await cb(data, body);
      }
    }

    this.pending = false;
    return req;
  }

  @action resetErrors() {
    this.errorService.resetErrors();
  }
}

export default new RootStore();
