import React from "react";
import List from "../../list";
import { renderListItemHandler, grid } from "./settings";
import "./styles.scss";

const MaterialsList = ({ zoneName = "", subZoneName = "", material }) => (
  <List
    className="list--cards"
    itemLayout="horizontal"
    grid={grid}
    dataSource={material}
    renderItem={material =>
      renderListItemHandler(material, zoneName, subZoneName)
    }
  />
);

export default MaterialsList;
