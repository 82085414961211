import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Icon from "../../../icon";
import Input from "../../../input";
import Button from "../../../button";

export function getIcon({ isActive }) {
  return <Icon type={isActive ? "minus" : "plus"} size={12} />;
}

export const customPanelStyle = {
  background: "#F7F7F7",
  borderRadius: 0,
  overflow: "hidden"
};

export const panelHeader = ({ title, amountCheckedValues }) => {
  return (
    <span>
      {title} <span style={{ color: "red" }}>{amountCheckedValues ? amountCheckedValues : ""}</span>
    </span>
  );
};

export const getPanelContent = (
  {
    searchBar,
    customTextField,
    filteredValues,
    filterClickHandler,
    inputChangeHandler,
    customSearchHandler,
    quantityToDisplay,
    showAllFilters,
    customText,
    useSearch,
    toggleShowAllFilters,
    title
  },
  t
) => {
  const buttonText = showAllFilters ? t("filtersMenu.hide") : t("filtersMenu.showAll");
  return (
    <>
      {useSearch && (
        <Input
          aria-label={t("filtersMenu.searchLabel", { title })}
          allowClear={true}
          placeholder={searchBar.placeholder}
          value={searchBar.value}
          onChange={inputChangeHandler}
        />
      )}
      {customText && (
        <Input
          aria-label={t("filtersMenu.searchLabel", { title })}
          allowClear={true}
          placeholder={customTextField.placeholder}
          value={customTextField.value}
          onChange={customSearchHandler}
        />
      )}
      {filteredValues.map(({ id, value, title, supertitle, checked }, i) => {
        if (!showAllFilters && i + 1 > quantityToDisplay) return null;
        return (
          <div key={id}>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={filterClickHandler} name={id} color="primary" />}
              label={supertitle ? `${supertitle} ${title}` : `${title}`}
            />
          </div>
        );
      })}
      {filteredValues.length > quantityToDisplay && (
        <Button onClick={toggleShowAllFilters} type="link">
          {buttonText}
        </Button>
      )}
    </>
  );
};
