import React from "react";
import { Icon as AntIcon } from "antd";
import healthSafety from "@material-ui/icons/LocalHospitalOutlined";

import account from "../../assets/images/icons/account.svg";
import arrowDown from "../../assets/images/icons/arrow-down.svg";
import arrowPrev from "../../assets/images/icons/arrow-prev.svg";
import arrowNext from "../../assets/images/icons/arrow-next.svg";
import cancel from "../../assets/images/icons/cancel.svg";
import cart from "../../assets/images/icons/cart.svg";
import clings from "../../assets/images/icons/clings.svg";
import download from "../../assets/images/icons/download.svg";
import filter from "../../assets/images/icons/filter.svg";
import fixtures from "../../assets/images/icons/fixtures.svg";
import instructions from "../../assets/images/icons/instructions.svg";
import location from "../../assets/images/icons/location.svg";
import logout from "../../assets/images/icons/logout.svg";
import mail from "../../assets/images/icons/mail.svg";
import marketing from "../../assets/images/icons/marketing.svg";
import menu from "../../assets/images/icons/menu.svg";
import notifications from "../../assets/images/icons/notifications.svg";
import options from "../../assets/images/icons/options.svg";
import phone from "../../assets/images/icons/phone.svg";
import search from "../../assets/images/icons/search.svg";
import support from "../../assets/images/icons/support.svg";
import zoomOut from "../../assets/images/icons/zoom-out.svg";
import history from "../../assets/images/icons/history.svg";
import sent from "../../assets/images/icons/sent.svg";
import calendar from "../../assets/images/icons/calendar.svg";
import placeholder from "../../assets/images/icons/placeholder.svg";
import regulatory from "../../assets/images/icons/regulatory.svg";
import print from "../../assets/images/icons/print.svg";
import vault from "../../assets/images/icons/vault.svg";
import accountBalanceRounded from "../../assets/images/icons/account-balance-rounded.svg";
import webAssetRounded from "../../assets/images/icons/web-asset-rounded.svg";
import queuePlayNextRounded from "../../assets/images/icons/queue-play-next-rounded.svg";
import autoAwesomeMosaic from "../../assets/images/icons/auto_awesome_mosaic.svg";

const icons = {
  account,
  arrowDown,
  arrowPrev,
  arrowNext,
  cancel,
  cart,
  clings,
  download,
  filter,
  fixtures,
  instructions,
  location,
  logout,
  mail,
  marketing,
  menu,
  notifications,
  options,
  phone,
  search,
  support,
  zoomOut,
  history,
  sent,
  calendar,
  placeholder,
  regulatory,
  healthSafety,
  print,
  vault,
  accountBalanceRounded,
  webAssetRounded,
  queuePlayNextRounded,
  autoAwesomeMosaic
};

const iconSize = 24;

const Icon = ({ type, size = iconSize, ariaHidden = true, ...restProps }) => (
  <AntIcon aria-hidden={ariaHidden} type={type} component={icons[type]} style={{ fontSize: `${size}px` }} {...restProps} />
);

export default Icon;
