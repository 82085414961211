import React, { useState, useEffect, useRef } from "react";
import Grow from "@material-ui/core/Grow";
import Badge from "@material-ui/core/Badge";
import Paper from "@material-ui/core/Paper";
import { inject, observer } from "mobx-react";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Icon from "../../icon";
import NotificationsList from "../../notificationsList";
import PrimaryButton from "../../formComponents/primaryButton";
import { useStyles } from "../styles";

import "./styles.scss";
import { useTranslation } from "react-i18next";

const NotificationsPopover = ({ notificationsStore }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const onReadAllHandler = () => {
    notificationsStore.markAllAsRead();
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = event => {
    if (notificationsStore.hasReadNotifications) {
      notificationsStore.readNotifications();
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton
        className={classes.iconButton}
        ref={anchorRef}
        aria-controls={open ? "notifications-menu" : undefined}
        aria-expanded={open}
        aria-label={t("notificationsPopover.notificationsListLabel")}
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <Badge overlap="rectangular" badgeContent={notificationsStore.unreadNotifications} color="primary">
          <Icon style={{ color: "#fff" }} type="notifications" />
        </Badge>
      </IconButton>
      <Popper open={open} style={{ zIndex: 1 }} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper style={{ padding: "5px 17px" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div id="notifications-menu" className="shopping-cart-popover" onKeyDown={handleListKeyDown}>
                  <div className="shopping-cart-popover__title">
                    <div className="shopping-cart-popover__title-left-wrapper">
                      <Icon type="notifications" />
                      <span className="title-text uppercase bold">{t("notificationsPopover.notifications")}</span>
                    </div>
                    <PrimaryButton onClick={onReadAllHandler}>{t("notificationsPopover.readAll")}</PrimaryButton>
                  </div>
                  <NotificationsList />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default inject(({ rootStore }) => ({
  notificationsStore: rootStore.notificationsStore
}))(observer(NotificationsPopover));
