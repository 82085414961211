import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PrimaryButton from "../formComponents/primaryButton";

import "./styles.scss";

const MuiDialog = ({ titleId, contentId, open, onClose, title, content, actions, ...props }) => (
  <Dialog open={open} role="alert" onClose={onClose} maxWidth={"sm"} fullWidth {...props}>
    <span className="dialog-title">{title}</span>
    <DialogContent>
      <div id={contentId}>{content}</div>
    </DialogContent>
    <DialogActions>
      {actions.map(({ primary, text, ...rest }) =>
        primary ? (
          <PrimaryButton key={text} {...rest}>
            {text}
          </PrimaryButton>
        ) : (
          <Button disableRipple key={text} {...rest}>
            {text}
          </Button>
        )
      )}
    </DialogActions>
  </Dialog>
);

export default MuiDialog;
