export const customRequestForm = {
  layout: "vertical",
  materialType: {
    name: "order_type"
  },
  reasonSelect: {
    name: "reason",
    optionValue: "body"
  },
  quantityNumber: {
    name: "quantity",
    rules: [{ required: false }]
  },
  commentField: {
    name: "comments"
  },
  categorySelect: {
    name: "group_description"
  },
  zoneSelect: {
    name: "zone_id"
  },
  subZoneSelect: {
    name: "subzone_id"
  }
};
