import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    margin: 0,
    padding: spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflow: "hidden",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 460,
    height: 460,
    overflowY: "auto",
  },
  description: {
    color: "#4A4A4A",
    fontSize: 12,
    fontWeight: 600,
  },
  greyIconBtn: {
    color: palette.grey[500],
  },
  blueIconBtn: {
    color: palette.secondary.main,
  },
  actions: {
    justifyContent: "space-between",
  },
  downloadBtn: {
    position: "absolute",
    bottom: 20,
    right: 10,
    background: palette.common.white,
    color: palette.secondary.main,
  },
  deleted: {
    position: "absolute",
    top: 0,
    left: 0,
    background: "#757575",
    color: palette.common.white,
    textTransform: "uppercase",
    padding: "4px 12px",
    fontSize: 12,
    borderRadius: "0 0 4px 0",
  },
  zoomOutBtnWrapper: {
    position: "absolute",
    bottom: 10,
    left: 10,
  },
  orderNowBtn: {
    border: `1px solid ${palette.primary.main}`,
    background: "#DC1431",
    boxShadow: "none",
    color: palette.common.white,
    "&:focus": {
      border: `1px solid ${palette.primary.main}`,
      background: "white !important",
      color: palette.primary.main,
      boxShadow: "none",
    },
    "&:hover": {
      boxShadow: "none",
      background: "rgb(154, 14, 34)",
    },
  },
}));
