import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "focus-within-polyfill";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ConfigProvider } from "antd";
import { Helmet } from "react-helmet";
import { createRoot } from "react-dom/client";
import "./i18n";

import App from "./App";
import { LocalStorageService } from "./services";
import stores from "./stores";

import "./styles.scss";

const nonce = uuidv4();

const start = async () => {
  try {
    // Initialize the stores
    await stores.rootStore.authorizationStore.login();
    await stores.rootStore.locationStore.setLocationInitialized(await LocalStorageService.get("locationInitialized"));

    const noscript = document.querySelector("noscript");
    if (noscript) {
      noscript.parentNode.removeChild(noscript);
    }

    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
      <ConfigProvider csp={{ nonce }}>
        <>
          <Helmet>
            <meta httpEquiv="Content-Security-Policy" content={`${decodeURIComponent(process.env.REACT_APP_CSP_RULES)} 'nonce-${nonce}';`} />
            <meta property="csp-nonce" content={nonce} />
          </Helmet>
          <App />
        </>
      </ConfigProvider>
    );
  } catch (e) {
    console.error("Application initialization error => ", e);
  }
};

start();
