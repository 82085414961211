import React from "react";
import { inject, observer } from "mobx-react";

import { settings } from "./settings";
import MuiDialog from "../../muiDialog";
import { Trans, useTranslation } from "react-i18next";

const CallUsModal = ({ authorizationStore, visible, onCancel }) => {
  const { t } = useTranslation();

  const { support_phone_number, customer_support_email } = authorizationStore.config;
  const actions = [
    {
      text: t("modals.gotIt"),
      autoFocus: true,
      onClick: onCancel,
      primary: true
    }
  ];

  const content = (
    <div>
      <Trans
        i18nKey="modals.callUs"
        values={{
          customer_support_email,
          support_phone_number
        }}
        components={{
          b: <b />
        }}
      />
    </div>
  );

  return <MuiDialog open={visible} onClose={onCancel} actions={actions} content={content} {...settings} />;
};

export default inject(({ rootStore }) => ({
  authorizationStore: rootStore.authorizationStore
}))(observer(CallUsModal));
