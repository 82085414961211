import React, { useState, forwardRef } from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import withStyles from "@material-ui/core/styles/withStyles";

const MuiCusTabs = withStyles({
  root: {
    backgroundColor: "#fff"
  },
  indicator: {
    backgroundColor: "#e21037",
    height: "3px"
  }
})(Tabs);

const MuiTab = withStyles(({ breakpoints }) => ({
  root: {
    width: "auto",
    textTransform: "none",
    fontSize: "17px",
    letterSpacing: "0.3px",
    opacity: 1,
    color: "#4c4c4c",
    minWidth: "115px",
    marginRight: "20px",
    [breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  selected: {
    fontWeight: 900,
    opacity: 1,
    color: "#4c4c4c"
  }
}))(props => <Tab {...props} />);

const MuiTabs = forwardRef(
  ({ ariaLabel, options, activeKey, onChange }, ref) => {
    const [value, setValue] = useState(() => activeKey);
    const handleChange = (event, newValue) => {
      setValue(newValue);
      onChange(String(newValue));
    };

    return (
      <MuiCusTabs
        ref={ref}
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel}
        selectionFollowsFocus
      >
        {options.map(({ name, id }) => (
          <MuiTab key={id} label={name} value={id} />
        ))}
      </MuiCusTabs>
    );
  }
);

export default MuiTabs;
