import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: "relative",
    height: 623,
  },
  actions: {
    position: "absolute",
    top: 10,
    left: 10,
    display: "flex",
    alignItems: "center",
  },
  preview: {
    display: "block",
    height: "100%",
    margin: "0 auto",
  },
  previewBtn: {
    position: "absolute",
    bottom: 10,
    left: 10,
  },
}));
