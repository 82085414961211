import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ palette, breakpoints }) =>
  createStyles({
    previousCampaignAlert: {
      background: "rgba(196, 18, 48, 0.05)",
      border: "1px solid rgba(196, 18, 48, 0.05)",
      borderRadius: 4,
      padding: "7px 16px"
    },
    leftBlock: {
      display: "flex",
      alignItems: "center",
      color: palette.primary.main
    },
    alertText: {
      [breakpoints.down("md")]: {
        fontSize: 9
      },
      fontSize: 12,
      fontWeight: 600,
      textTransform: "uppercase",
      marginLeft: 12
    }
  })
);
