import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class DepartmentsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable departments = [];
  @observable isLoading = false;

  @action.bound async getDepartments() {
    const { method, url } = urls.departments.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetDepartmentsSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onGetDepartmentsSuccess({ data }) {
    this.departments = data;
  }

  @action.bound findById(departmentId) {
    return this.departments.find(({ id }) => id === departmentId);
  }

  @computed get dropdownValues() {
    return this.departments.map(({ id, name }) => ({ id, label: name, value: id }));
  }

  @computed get filters() {
    return this.departments.map(({ id, name }) => ({ supertitle: "", title: name, value: id }));
  }
}
