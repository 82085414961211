import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";

import Affix from "../../components/affix";
import { catalogs } from "../../constants";
import { getURLSearchParam } from "../../utils";
import FullResults from "./components/FullResults";
import SearchSidebar from "./components/SearchSidebar";
import CatalogResults from "./components/CatalogResults";
import { useTranslation } from "react-i18next";

const SearchResults = ({ locationStore, searchStore, catalogStore }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { catalogName } = useParams();
  const searchStr = getURLSearchParam("search", search);
  const [withOtherFc, setWithOtherFc] = useState(false);

  const shouldDisableOtherFcCheckbox = !catalogName || catalogName === catalogs.printOnDemand;

  useEffect(() => {
    if (!withOtherFc) {
      searchStore.getMaterials(searchStr, catalogName);
    } else {
      const catalog = catalogStore.catalogs.find(catalog => catalog.catalogName === catalogName);
      catalog.getOutFcMaterials(searchStr);
    }
  }, [searchStore, searchStr, withOtherFc, catalogName]);

  const onOtherFcChangeHandler = value => {
    if (!value) {
      searchStore.resetAllCatalogsLoaded();
    }
    setWithOtherFc(value);
  };

  if (!locationStore.activeLocation) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={9}>
        <h1>{t("pages.searchResults.searchResultFor", { searchStr })}</h1>
        {!catalogName && <FullResults />}
        {catalogName && <CatalogResults includeOtherFc={withOtherFc} />}
      </Grid>
      <Grid item xs={12} lg={3}>
        <Affix offsetTop={64} style={{ zIndex: 11 }}>
          <SearchSidebar disableOtherFcCheckbox={shouldDisableOtherFcCheckbox} withOtherFc={withOtherFc} onOtherFcChange={onOtherFcChangeHandler} />
        </Affix>
      </Grid>
    </Grid>
  );
};

export default inject(({ rootStore }) => ({
  locationStore: rootStore.locationStore,
  searchStore: rootStore.searchStore,
  catalogStore: rootStore.catalogStore
}))(observer(SearchResults));
