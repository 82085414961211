import React from "react";

const Description = ({ language, department }) => (
  <>
    <span id={`material-card-description-${department}`} className="block card__code semibold">
      {language}
    </span>
    <span className="bold card__description">{department}</span>
  </>
);

export default Description;
