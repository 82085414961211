import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "25px 0px 45px 0",
    marginBottom: 20,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  collapsedWrapper: {
    padding: 0,
    marginBottom: 18
  },
  icon: {
    display: "inline-block",
    transform: "scale(1)",
    width: 110,
    height: 60,
    transition: "0.1s"
  },
  collapsedIcon: {
    transform: "scale(.45)"
  },
  title: {
    marginTop: 20,
    fontSize: 15,
    display: "block",
    fontWeight: 700,
    color: "#012169",
    textTransform: "uppercase",
    opacity: 1,
    visibility: "visible",
    transform: "scaleY(1)",
    transformOrigin: "center top",
    transition: " transform .3s"
  }
}));
