import React, { Component } from "react";
import { Form, List } from "antd";
import Avatar from "../../avatar";
import "./styles.scss";
import { proxy } from "../../../common/apiUrls";
import Grid from "@material-ui/core/Grid";
import Icon from "../../icon";
import Button from "../../button";
import { numberMinPositiveValuePromise } from "../../../utils";
import InputNumberAccessibility from "../../formComponents/inputNumberAccessibility";
import { ReasonsSelect } from "../../selects";
import { getMaterialNameByType } from "../../../common/models/catalog/settings";
import TextareaWithCounter from "../../formComponents/textareaWithCounter";
import { materialOrderForm } from "../../forms/checkoutOrderForm/settings";
import { inject, observer } from "mobx-react";
import Tag from "../../tag";
import startCase from "lodash/startCase";
import { withTranslation } from "react-i18next";

const { formName, layout, quantity: quantityField, reason, comment } = materialOrderForm;

@inject("rootStore")
@observer
class AssociatedMaterialListItem extends Component {
  constructor() {
    super();
  }

  onDeleteHandler = () => {
    this.props.onDelete(this.props.material.id);
  };

  onQuantityChangeHandler = quantity => {
    this.props.material.resetReason();
    this.props.form.setFieldsValue({ [reason.name]: null });
    this.props.material.onQuantityChange(quantity);
  };

  render() {
    const { material, form, formId, editable, t } = this.props;
    const {
      item: { merch_name, photo, code, item_type, fixture_category }
    } = material;

    return (
      <List.Item className="associated-material-list-item">
        <Grid container spacing={2}>
          <Grid item xs={2} lg={2}>
            <div className="left-block">
              {editable && (
                <Button onClick={this.onDeleteHandler} className="btn-delete" type="link" aria-label={t("shoppingCartPopover.deleteItemLabel")}>
                  <Icon type="cancel" />
                </Button>
              )}
              <Avatar size={80} src={photo && `${proxy}${photo.small_thumb}`} />
            </div>
          </Grid>
          <Grid item xs={10} lg={10}>
            <div>
              <div className="flex-between">
                <span className="associated-material-list-item__category block bold">{merch_name}</span>
                <Tag className={`order-table order-table--${material.status}`}>{startCase(material.status)}</Tag>
              </div>

              <span className="associated-material-list-item__code block">{code}</span>
              <span className="associated-material-list-item__zone block bold">
                <span> {`${material.zone.name}: ${material.subzone.name}`}</span>
              </span>
            </div>
            {editable && (
              <Form layout={layout} className="right-block">
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Form.Item help={false}>
                      {form.getFieldDecorator(quantityField.name, {
                        initialValue: material.currentQuantity,
                        rules: [{ required: true, message: t("checkoutOrderForm.quantity.rules.required") }, numberMinPositiveValuePromise]
                      })(
                        <InputNumberAccessibility
                          className="quantity"
                          label={t("checkoutOrderForm.quantity.label")}
                          onChange={this.onQuantityChangeHandler}
                          min={1}
                          max={999}
                          _id={`${formName}_${quantityField.name}_${formId}`}
                          antLabel
                        />
                      )}
                    </Form.Item>
                  </Grid>
                  <Grid xs={12} sm={6} md={8} lg={9} xl={10} item>
                    <Form.Item help={false}>
                      {form.getFieldDecorator(reason.name, {
                        rules: [{ required: true, message: t("checkoutOrderForm.reason.rules.required") }],
                        initialValue: {
                          value: JSON.stringify(material.selectedReason),
                          label: material.selectedReason?.body,
                          id: material.selectedReason?.id
                        }
                      })(
                        <ReasonsSelect
                          placeholder={t("checkoutOrderForm.reason.placeholder")}
                          onChange={material.setSelectedReason}
                          filters={[getMaterialNameByType(fixture_category || item_type), material.filterKey]}
                          label={{
                            text: t("checkoutOrderForm.reason.label"),
                            antLabel: true
                          }}
                          name={`${formId}-${reason.name}`}
                        />
                      )}
                    </Form.Item>
                  </Grid>
                </Grid>
                <Form.Item help={false}>
                  {form.getFieldDecorator(comment.name, {
                    initialValue: material.comments,
                    rules: [
                      {
                        required: material.selectedReason.comments_required,
                        message: t("checkoutOrderForm.comment.rules.required")
                      }
                    ]
                  })(
                    <TextareaWithCounter
                      maxLength={2000}
                      placeholder={material.selectedReason.comments_placeholder}
                      _id={`${formName}_${comment.name}_${formId}`}
                      label={{ text: t("checkoutOrderForm.comment.label") }}
                    />
                  )}
                </Form.Item>
              </Form>
            )}
          </Grid>
        </Grid>
      </List.Item>
    );
  }
}

export default withTranslation()(
  Form.create({
    name: formName
  })(AssociatedMaterialListItem)
);
