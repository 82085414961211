import React from "react";
import { Col, Row } from "antd";
import Button from "@material-ui/core/Button";

import Icon from "../../../icon";
import PrimaryButton from "../../../formComponents/primaryButton";
import { useTranslation } from "react-i18next";

const Title = ({ onReset, onApply, onClose }) => {
  const { t } = useTranslation();

  return (
    <Row type="flex" className="drawer-filters-title" align="middle" justify="space-between">
      <Col>
        <Button
          aria-label={t("filtersMenu.closeFiltersLabel")}
          onClick={onClose}
          autoFocus
          disableRipple
          startIcon={<Icon type="cancel" style={{ color: "#dc1431" }} />}
        >
          {t("filtersMenu.filtersBy")}
        </Button>
      </Col>
      <Col>
        <Button disableRipple color="secondary" aria-label={t("filtersMenu.resetFiltersLabel")} onClick={onReset}>
          {t("filtersMenu.reset")}
        </Button>
        <PrimaryButton aria-label={t("filtersMenu.applyFiltersLabel")} onClick={onApply} style={{ marginLeft: 5 }}>
          {t("filtersMenu.apply")}
        </PrimaryButton>
      </Col>
    </Row>
  );
};

export default Title;
