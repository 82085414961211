import { useState, useEffect } from "react";

const useCommentState = initialState => {
  const [commentState, setCommentState] = useState(() => ({
    showFull: false,
    isOverflowing: false,
    ...initialState
  }));

  useEffect(() => {
    const domElement = document.getElementById(`${commentState.commentElId}`);
    const isOverflowing = domElement
      ? domElement.scrollWidth > domElement.clientWidth
      : false;
    setCommentState({ ...commentState, isOverflowing });
  }, []);

  function toggleComment() {
    setCommentState({ ...commentState, showFull: !commentState.showFull });
  }

  return { commentState, toggleComment };
};

export default useCommentState;
