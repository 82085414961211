import React, { useEffect } from "react";
import { Form } from "antd";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { inject, observer } from "mobx-react";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import { useHistory, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import Header from "./components/Header";
import { validateForm } from "../../utils";
import { transformData } from "./settings";
import { CreateTemplateForm } from "../../components/forms";
import RouteLeavingGuard from "../../components/routeLeavingGuard";
import { FormValidationGenerator } from "../../components/formComponents";
import { createTemplateForm } from "../../components/forms/createTemplateForm/settings";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const EditTemplate = ({ form, alertsStore, templateStore, departmentsStore, languagesStore, colorProfilesStore, tagsStore, vendorsStore }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const formRefs = {};

  const pdfAlert = { severity: "error", message: t("pages.editTemplate.pdfAlert") };

  useEffect(() => {
    tagsStore.getTags();
  }, [tagsStore]);

  useEffect(() => {
    return templateStore.deleteTemplate;
  }, [templateStore]);

  useEffect(() => {
    vendorsStore.getVendors();
  }, [vendorsStore]);

  useEffect(() => {
    templateStore.getTemplate(id);
  }, [templateStore, id]);

  useEffect(() => {
    languagesStore.getLanguages();
  }, [languagesStore]);

  useEffect(() => {
    departmentsStore.getDepartments();
  }, [departmentsStore]);

  useEffect(() => {
    colorProfilesStore.getColorProfiles();
  }, [colorProfilesStore]);

  useEffect(() => {
    if (!templateStore.template.isEditable || !templateStore.hasTemplate) return;
    templateStore.template.getPdfFields();
  }, [templateStore.template, templateStore.hasTemplate, templateStore.template.isEditable]);

  const onGoBackHandler = () => {
    history.goBack();
  };

  const onSaveHandler = async () => {
    await updatePdfForm();
    await updateTemplate();
  };

  const updateTemplate = async () => {
    let formData;
    try {
      formData = await form.validateFields();
    } catch (e) {
      formData = e;
    }

    if (formData.errors) return;
    const { file, ...rest } = formData;

    if (templateStore.template.templateWithoutPdf && !file) {
      alertsStore.createAlert(pdfAlert);
      return;
    }

    const data = new FormData();
    if (file) {
      data.append("file", file);
    }
    data.append(
      "metadata",
      new Blob([JSON.stringify(transformData(rest))], {
        type: "application/json"
      })
    );

    await templateStore.template.update(data);
  };

  const updatePdfForm = async () => {
    const validForms = [];

    await Promise.all(
      Object.keys(formRefs).map(async key => {
        const formData = await validateForm(formRefs[key].form);
        if (formData.errors) return;
        validForms.push({ name: key, index: formRefs[key].formIndex, type: formRefs[key].fieldType, constraints: formData });
      })
    );

    await templateStore.template.updatePdfFields(validForms);
  };

  const onSaveFormRefHandler = formRef => {
    if (!formRef || !formRef.props.formId) return;
    const { formId, form, formIndex, fieldType } = formRef.props;
    formRefs[formId] = { form, formIndex, fieldType };
  };

  return (
    <div>
      <RouteLeavingGuard when={templateStore.template.templateWithoutPdf} />
      <Box mb={3}>
        <Header onSave={onSaveHandler} onGoBack={onGoBackHandler} />
      </Box>
      <Backdrop className={classes.backdrop} open={templateStore.isLoading || templateStore.template.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {templateStore.hasTemplate && (
        <CreateTemplateForm
          form={form}
          initialValues={templateStore.formData}
          previewLink={templateStore.templatePreview}
          isDeleted={templateStore.template.isDeleted}
        />
      )}
      {templateStore.template.hasPdfFormConstraints && templateStore.template.isEditable && (
        <Grid container spacing={3} alignItems="flex-start" justifyContent="space-between">
          <Grid item lg={6} md={12} sm={12} />
          <Grid item lg={6} md={12} sm={12}>
            <Divider light style={{ marginBottom: 20 }} />
            <h2>{t("pages.editTemplate.pdfFormValidationRules")}</h2>
            <FormValidationGenerator onSaveFormRef={onSaveFormRefHandler} fields={templateStore.template.pdfFormFields} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Form.create({ name: createTemplateForm.formName })(
  inject(({ rootStore }) => ({
    tagsStore: rootStore.tagsStore,
    alertsStore: rootStore.alertsStore,
    vendorsStore: rootStore.vendorsStore,
    templateStore: rootStore.templateStore,
    languagesStore: rootStore.languagesStore,
    departmentsStore: rootStore.departmentsStore,
    colorProfilesStore: rootStore.colorProfilesStore
  }))(observer(EditTemplate))
);
