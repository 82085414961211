import { observable, action } from "mobx";
import uniqBy from "lodash/uniqBy";

import { urls } from "../../apiUrls";

export default class ReasonsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable reasons = [];
  @observable isLoading = false;

  @action.bound async getReasons() {
    if (this.reasons.length) return;
    const { method, url } = urls.reasons;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.saveReasons, method, url);
    this.isLoading = false;
  }

  @action.bound async saveReasons(data) {
    this.reasons = data;
  }

  @action.bound filterReasons([materialType, profileAssignment]) {
    return !materialType
      ? uniqBy(this.reasons, "body")
      : this.reasons.filter(reason => reason.material_type === materialType && reason.ordering_type === profileAssignment);
  }

  findReasonById(reasonId) {
    return this.reasons.find(({ id }) => id === reasonId);
  }
}
