import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  skipLink: {
    position: "absolute",
    left: -9999,
    "&:focus": {
      position: "static",
      zIndex: 13
    }
  }
}));
