export const tagNameTransformer = (oldEl, tagName) => {
  const newEl = document.createElement(tagName);
  for (let attr of oldEl.attributes) {
    newEl.setAttribute(attr.name, attr.value);
  }
  newEl.innerHTML = oldEl.innerHTML;

  newEl.classList.add("table-header__td");
  oldEl.parentNode.insertBefore(newEl, oldEl);
  oldEl.parentNode.removeChild(oldEl);
};
