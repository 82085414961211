import axios from "axios";
import of from "await-of";
import isObject from "lodash/isObject";
import isString from "lodash/isString";

import { QueryStringService } from "./index";

class HttpService {
  request = null;
  allowedMethods = ["get", "post", "put", "delete", "patch"];

  validateUrl = url => {
    if (!isString(url)) {
      throw new Error(`{url} parameter must be a string, got a ${typeof url}`);
    }
  };

  validateHeaders = headers => {
    if (!isObject(headers)) {
      throw new Error(`{headers} parameter must be a valid object, got a ${typeof headers}`);
    }
  };

  validateMethod = method => {
    if (!this.allowedMethods.includes(method)) {
      throw new Error(`Method ${method} is not allowed. Please, use one of ${this.allowedMethods}.`);
    }
  };

  makeRequest = async ({ method, url, params = {}, data = {}, headers = {} }) => {
    this.validateMethod(method);
    this.validateUrl(url);
    this.validateHeaders(headers);

    this.request = axios({
      method,
      headers,
      params,
      data,
      url,
      paramsSerializer: () => QueryStringService.stringify(params, { encode: false })
    });
    const [response, error] = await of(this.request);
    // const body = response ? response.data : null; //TODO: return when server responses will have same structure
    const body = response ? (response.data?.data ? response.data : response) : null;

    return [body, error];
  };

  abort = () => {
    if (this.request) {
      return this.request.abort();
    }
    return null;
  };
}

export default new HttpService();
