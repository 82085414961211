import React from "react";
import { inject, observer } from "mobx-react";
import { List } from "antd";

import Tag from "../../tag";
import Icon from "../../icon";
import Avatar from "../../avatar";
import { DateService } from "../../../services";

const NotificationListItem = ({ notification, onClick }) => {
  const status = notification.data.order_status || notification.data.status;

  const onClickHandler = () => {
    if (!onClick) return;
    onClick(notification);
  };

  return (
    <List.Item onMouseEnter={notification.markAsRead} onClick={onClickHandler} className="flex-between">
      <List.Item.Meta
        avatar={
          <Avatar size={40} className="avatar">
            <Icon type="sent" />
          </Avatar>
        }
        title={notification.title}
        description={DateService.getFormattedDate(notification.created_at, "MM/DD/YYYY hh:mm A") + " " + DateService.getTimeZone()}
      />
      {!notification.isRead && <span className="unread-dot" />}
      <Tag className={`order-table order-table--${status}`}>{status}</Tag>
    </List.Item>
  );
};

export default inject(({ rootStore }) => ({
  notificationsStore: rootStore.notificationsStore
}))(observer(NotificationListItem));
