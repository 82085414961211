import React from "react";

import "../styles.scss";

const ValidationError = ({ id, errorText }) => (
  <p
    id={id}
    aria-invalid="true"
    role='alert'
    aria-live="polite"
    className="validation-error-message"
  >
    {errorText}
  </p>
);

export default ValidationError;