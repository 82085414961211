import { action, observable, computed } from "mobx";
import uniqBy from "lodash/uniqBy";

import { urls } from "../../apiUrls";
import { Location } from "../../models";
import { LocalStorageService } from "../../../services";

const mockActiveLocation = {
  data: {
    id: 11948,
    fcid: 14414,
    name: "Harvard Square"
  }
};

export default class LocationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable activeLocation = null;
  @observable fullLocationData = {};
  @observable locations = [];
  @observable locationInitialized = false;
  @observable isLoading = false;

  @action.bound async getLocations({ page, search }, onSuccess) {
    const { method, url } = urls.getLocations;
    this.isLoading = true;
    await this.rootStore.makeRequestWithDebounce(onSuccess, method, url, {
      page,
      q: {
        ...this.query(search)
      }
    });
    this.isLoading = false;
  }

  @action.bound query(search) {
    const result = {};
    const words = search.split(" ");
    words.forEach(word => {
      const isString = isNaN(parseInt(word));
      if (isString) {
        result.site_name_cont = result.site_name_cont ? `${result.site_name_cont} ${word}` : word;
      } else {
        result.fcid_cont = word;
      }
    });
    return result;
  }

  @action.bound async setLocationInitialized(val) {
    this.locationInitialized = val;
    await LocalStorageService.save("locationInitialized", val);
  }

  @action.bound async getActiveLocation() {
    // Mock the API call
    return this.saveActiveLocation(mockActiveLocation.data);
  }

  @action.bound async setActiveLocation(location) {
    if (!location) return;
    // Mock the API call
    return this.saveActiveLocation(location);
  }

  @action.bound saveActiveLocation(location) {
    this.activeLocation = new Location(location);
  }

  @action.bound async getLocationById() {
    const { method, url } = urls.getLocationById;
    if (this.activeLocation && this.activeLocation.fcid) {
      return await this.rootStore.makeRequest(this.saveLocation, method, `${url}${this.activeLocation.fcid}`);
    }
  }

  @action.bound saveLocation(location) {
    this.fullLocationData = new Location(location);
  }

  @computed get defaultLocations() {
    return uniqBy([this.rootStore.authorizationStore.user.primaryLocationForSelect, this.activeLocation], "id").filter(s => s);
  }

  @computed get defaultLocationsIds() {
    return this.defaultLocations.map(({ id }) => id);
  }
}
