import React from "react";
import { Form } from "antd";

import { InputAccessibility } from "../index";
import { pdfFormFieldsTypes, defaultPdfFormValidation } from "../../../constants";
import { useTranslation } from "react-i18next";

const FormGenerator = ({ form, fields }) => {
  const { t } = useTranslation();
  const getValidationMessages = (key, value) => {
    const messages = {
      min: t("formGenerator.min", { value }),
      max: t("formGenerator.max", { value }),
      required: t("formGenerator.required")
    };
    return messages[key];
  };
  const getValidationRules = field => {
    const rules = field.constraints || defaultPdfFormValidation[field.type];
    return Object.keys(rules).map(key => ({ [key]: rules[key], message: getValidationMessages(key, rules[key]) }));
  };
  const getPlaceholder = name => `Enter ${name}`;

  const getFieldByType = ({ type, name, constraints }) => {
    switch (type) {
      case pdfFormFieldsTypes.text:
        return (
          <InputAccessibility maxLength={constraints ? constraints.max : defaultPdfFormValidation[type].max} label={name} placeholder={getPlaceholder(name)} />
        );
      default:
        return null;
    }
  };

  return fields.map(field => {
    const validation = getValidationRules(field);
    const initialValue = field.value || undefined;
    return (
      <Form.Item key={field.index}>
        {form.getFieldDecorator(`${field.name}---${field.index}`, { rules: validation, initialValue })(getFieldByType(field))}
      </Form.Item>
    );
  });
};

export default FormGenerator;
