import React, { useEffect } from "react";
import { Form } from "antd";
import Box from "@material-ui/core/Box";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Header from "./components/Header";
import { transformData } from "../editTemplate/settings";
import { CreateTemplateForm } from "../../components/forms";
import RouteLeavingGuard from "../../components/routeLeavingGuard";
import { createTemplateForm } from "../../components/forms/createTemplateForm/settings";

import { useStyles } from "./styles";

const CreateTemplate = ({ form, templateStore, departmentsStore, colorProfilesStore, languagesStore, vendorsStore, tagsStore }) => {
  const history = useHistory();
  const classes = useStyles();

  const onGoBackHandler = () => {
    history.goBack();
  };

  useEffect(() => {
    return templateStore.deleteTemplate;
  }, [templateStore]);

  useEffect(() => {
    tagsStore.getTags();
  }, [tagsStore]);

  useEffect(() => {
    vendorsStore.getVendors();
  }, [vendorsStore]);

  useEffect(() => {
    languagesStore.getLanguages();
  }, [languagesStore]);

  useEffect(() => {
    departmentsStore.getDepartments();
  }, [departmentsStore]);

  useEffect(() => {
    colorProfilesStore.getColorProfiles();
  }, [colorProfilesStore]);

  const onCreateHandler = async () => {
    let formData;
    try {
      formData = await form.validateFields();
    } catch (e) {
      formData = e;
    }

    if (formData.errors) return;

    const { file, ...rest } = formData;

    const data = new FormData();
    data.append("file", file);
    data.append("metadata", new Blob([JSON.stringify(transformData(rest))], { type: "application/json" }));

    await templateStore.template.create(data);
  };

  const onLeaveHandler = () => {
    templateStore.template.delete();
    templateStore.deleteTemplate();
  };

  return (
    <div>
      <RouteLeavingGuard when={templateStore.template.templateNotFinished} onLeave={onLeaveHandler} />
      <Box mb={3}>
        <Header onCreate={onCreateHandler} onGoBack={onGoBackHandler} />
      </Box>
      <Backdrop className={classes.backdrop} open={templateStore.isLoading || templateStore.template.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CreateTemplateForm form={form} previewLink={templateStore.templatePreview} />
    </div>
  );
};

export default Form.create({ name: createTemplateForm.formName })(
  inject(({ rootStore }) => ({
    tagsStore: rootStore.tagsStore,
    vendorsStore: rootStore.vendorsStore,
    templateStore: rootStore.templateStore,
    languagesStore: rootStore.languagesStore,
    departmentsStore: rootStore.departmentsStore,
    colorProfilesStore: rootStore.colorProfilesStore,
  }))(observer(CreateTemplate))
);
