import React from "react";
import { useState } from "react";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "../../components/button";
import { useForm } from "react-hook-form";

import Sidebar from "../../components/sidebar";
import UserCard from "../../components/userCard";
import OrderDate from "../../components/orderDate";
import FinancialCenterCard from "../../components/financialCenterCard";
import DeliveryInformationTitle from "../../components/deliveryInformationTitle";
import CustomAtmOrderFrom from "../../components/forms/customAtmOrderForm";
import { PlaceCustomOrderModal } from "../../components/modals";
import RouteLeavingGuard from "../../components/routeLeavingGuard";
import { orderTypes } from "../../constants";
import { useTranslation } from "react-i18next";

const CustomAtm = ({ customAtmStore, locationStore }) => {
  const { t } = useTranslation();
  const { getAtms, setSelectedReason, commentsPlaceHolder } = customAtmStore;
  const { control, handleSubmit, errors } = useForm({ mode: "onChange" });
  const [customOrderModalVisible, setCustomOrderModalVisible] = useState(false);
  const [orderWasSent, setOrderWasSent] = useState(false);

  const formId = "customAtmForm";
  const submitButton = {
    type: "primary",
    htmlType: "submit",
    title: t("pages.customAtm.placeOrder")
  };

  const onSubmit = handleSubmit(data => {
    const custom_order = {
      order_type: orderTypes.atm,
      location_fcid: locationStore.activeLocation.fcid,
      reason: data.reason.label,
      order_reason_id: data.reason.id,
      quantity: 1,
      comments: t("pages.customAtm.comments", { comments: data.comments, siteName: data.atm.site_name, fcid: data.atm.fcid })
    };
    customAtmStore.sendCustomAtmOrder([custom_order], onSendCustomAtmOrderSuccess);
  });

  const handleClickPlaceOrder = e => {
    if (!!window.document.documentMode) {
      const { target } = e;
      const formId = target.getAttribute("form");
      const form = document.querySelector(`#${formId}`);
      if (form) {
        const btn = document.createElement("button");
        form.appendChild(btn);
        btn.click();
        form.removeChild(btn);
      }
    }
  };

  const onSendCustomAtmOrderSuccess = () => {
    setOrderWasSent(true);
    toggleCustomOrderModal();
  };

  const toggleCustomOrderModal = () => setCustomOrderModalVisible(prev => !prev);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={9}>
          <div className="heading flex-between">
            <div>
              <Typography variant="h1">{t("pages.customAtm.title")}</Typography>
              <p className="heading__subtitle">{t("pages.customAtm.subTitle")}</p>
            </div>
            <Button
              size="large"
              type={submitButton.type}
              htmlType={submitButton.htmlType}
              form={formId}
              disabled={customAtmStore.pending}
              onClick={handleClickPlaceOrder}
            >
              {submitButton.title}
            </Button>
          </div>
          <div className="spinning-wrapper">
            <div className="list--materials">
              <CustomAtmOrderFrom
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                formId={formId}
                getAtms={getAtms}
                setSelectedReason={setSelectedReason}
                commentsPlaceHolder={commentsPlaceHolder}
              />
            </div>
          </div>
          <Button
            size="large"
            type={submitButton.type}
            htmlType={submitButton.htmlType}
            form={formId}
            disabled={customAtmStore.pending}
            style={{ float: "right" }}
            onClick={handleClickPlaceOrder}
          >
            {submitButton.title}
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Sidebar>
            <DeliveryInformationTitle />
            <OrderDate />
            <FinancialCenterCard editable={true} />
            <UserCard />
          </Sidebar>
        </Grid>
      </Grid>
      <PlaceCustomOrderModal visible={customOrderModalVisible} onCancel={toggleCustomOrderModal} />
      <RouteLeavingGuard when={!orderWasSent} />
    </>
  );
};

export default inject(({ rootStore }) => ({
  customAtmStore: rootStore.customAtmStore,
  locationStore: rootStore.locationStore
}))(observer(CustomAtm));
