import React from "react";
import { Col, List, Row } from "antd";
import startCase from "lodash/startCase";

import Image from "../image";
import Button from "../button";
import TextBlock from "../textBlock";
import { useCommentState } from "../../hooks";
import Tag from "../tag";
import { useTranslation } from "react-i18next";

const OrdersHistoryCustomItem = ({ orderType, material }) => {
  const { t } = useTranslation();
  const { id, reason, quantity, comments, group_description, zone, subzone, item: { photo, code, merch_name } = {} } = material;
  const imageAlt = (photo && photo.description) || `${merch_name}-${code}`;
  const { commentState, toggleComment } = useCommentState({ commentElId: id });

  const orderTypes = {
    custom_marketing: t("ordersHistoryCustomItem.customMarketing"),
    custom_marketing_cling: t("ordersHistoryCustomItem.customMarketingCling"),
    custom_merchandising: t("ordersHistoryCustomItem.customMerchandising"),
    custom_other: t("ordersHistoryCustomItem.customOther")
  };

  function commentWrapperClassName() {
    return commentState.showFull ? "comment-wrapper" : "comment-wrapper additional-margin";
  }

  return (
    <List.Item className="orders-history-list-item">
      <div className="flex-start">
        <Image alt={imageAlt} size={184} />
        <div style={{ marginLeft: 24, width: "100%" }}>
          <div className="top-block">
            <div className="flex-between">
              <span className="orders-history-list-item__category block bold">{orderTypes[orderType]}</span>
              <Tag className={`order-table order-table--${material.status}`}>{startCase(material.status)}</Tag>
            </div>
          </div>
          <Row gutter={16}>
            <Col className="gutter-row" span={7}>
              <TextBlock title={t("ordersHistoryCustomItem.materialType")} content={orderTypes[orderType]} />
            </Col>
            <Col className="gutter-row" span={5}>
              <TextBlock title={t("ordersHistoryCustomItem.quantity")} content={quantity} />
            </Col>
            <Col className="gutter-row" span={12}>
              <TextBlock title={t("ordersHistoryCustomItem.reason")} content={reason} />
            </Col>
          </Row>
          {!!comments && (
            <Row className="bottom-block">
              <Col>
                <span className="label block">{t("ordersHistoryCustomItem.comments")}</span>
                <div className={commentWrapperClassName()}>
                  <p id={`${id}`} className={commentState.showFull ? "value " : "value comment"}>
                    {comments}
                  </p>
                </div>
                {commentState.isOverflowing && (
                  <Button className="show-all-comment bold" onClick={toggleComment} type="link">
                    {commentState.showFull ? t("ordersHistoryCustomItem.showLess") : t("ordersHistoryCustomItem.showAll")}
                  </Button>
                )}
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            {group_description && (
              <Col className="gutter-row" span={12}>
                <TextBlock title={t("ordersHistoryCustomItem.materialCategory")} content={group_description} />
              </Col>
            )}
            {zone && zone.name && (
              <Col className="gutter-row" span={6}>
                <TextBlock title={t("ordersHistoryCustomItem.zone")} content={zone.name} />
              </Col>
            )}
            {subzone && subzone.name && (
              <Col className="gutter-row" span={6}>
                <TextBlock title={t("ordersHistoryCustomItem.subzone")} content={subzone.name} />
              </Col>
            )}
          </Row>
        </div>
      </div>
    </List.Item>
  );
};

export default OrdersHistoryCustomItem;
