import { action, observable, computed } from "mobx";

import { urls } from "../../apiUrls";

export default class VendorsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable vendors = [];
  @observable isLoading = false;

  @action.bound async getVendors() {
    const { method, url } = urls.vendors.get;
    this.isLoading = true;
    await this.rootStore.makeRequest(this.onGetVendorsSuccess, method, url);
    this.isLoading = false;
  }

  @action.bound onGetVendorsSuccess({ data }) {
    this.vendors = data;
  }

  @action.bound findById(vendorId) {
    return this.vendors.find(({ id }) => id === vendorId);
  }

  @computed get dropdownValues() {
    return this.vendors.map(({ id, name }) => ({ id, label: name, value: id }));
  }
}
