import React from "react";
import clsx from "clsx";
import { inject, observer } from "mobx-react";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "../icon";

import { useStyles } from "./styles";

const StyledListItemText = withStyles({
  primary: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#4a4a4a",
  },
})(ListItemText);

const StyledListItem = withStyles({
  selected: {
    backgroundColor: "#D4EFFC !important",
  },
  focusVisible: {
    backgroundColor: "#D4EFFC !important",
  },
})(ListItem);

const MenuItem = ({ menuStore, selected, item, onClick }) => {
  const classes = useStyles();

  const onMenuItemClickHandler = () => {
    onClick(item);
  };

  return (
    <StyledListItem
      className={clsx(classes.firstLevelListItem, {
        [classes.collapsed]: menuStore.menuState.collapsed,
      })}
      selected={selected}
      onClick={onMenuItemClickHandler}
      button
    >
      <ListItemIcon className={classes.icon}>
        <Icon style={{ fontSize: 24, color: "#DC1431" }} type={item.iconType} />
      </ListItemIcon>
      {!menuStore.menuState.collapsed && <StyledListItemText primary={item.title} />}
    </StyledListItem>
  );
};

export default inject(({ rootStore }) => ({
  menuStore: rootStore.menuStore,
}))(observer(MenuItem));
