import React from "react";
import { List as AntList } from "antd";

const List = ({
  dataSource,
  className,
  renderItemHandler,
  itemLayout = "vertical",
  size = "large",
  ...restProps
}) => (
  <AntList
    className={className}
    itemLayout={itemLayout}
    size={size}
    dataSource={dataSource}
    renderItem={renderItemHandler}
    {...restProps}
  />
);

export default List;
