import React, { forwardRef } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const MuiButton = withStyles(({ palette }) => ({
  root: {
    border: `1px solid ${palette.primary.main}`,
    background: "#DC1431",
    boxShadow: "none",
    "&:focus": {
      border: `1px solid ${palette.primary.main}`,
      background: "white",
      color: palette.primary.main,
      boxShadow: "none"
    },
    "&:hover": {
      boxShadow: "none"
    }
  },
  disabled: {
    backgroundColor: "#DC1431 !important",
    color: "white !important",
    pointerEvents: 'unset !important',
    cursor: 'not-allowed !important',
    opacity: 0.5,
  }
}))(Button);

const PrimaryButton = forwardRef(
  (
    {
      size = "small",
      variant = "contained",
      color = "primary",
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiButton
        ref={ref}
        size={size}
        variant={variant}
        color={color}
        disableRipple
        {...rest}
      >
        {children}
      </MuiButton>
    );
  }
);

export default PrimaryButton;
