
import React, { forwardRef } from "react";
import { Input as AntInput } from "antd";

import "../styles.scss";

const InputPassword = forwardRef(({ ...restProps }, ref) => (
  <AntInput.Password ref={ref} {...restProps} />
));

export default InputPassword;