import { observable, action, computed } from "mobx";
import pick from "lodash/pick";

import { urls } from "../../apiUrls";
import { generatePdfTransformer, downloadFile, blobToBase64 } from "../../../utils";
import { templateCreated, templateStates, templateUpdateMessage } from "../../../constants";
import { t } from "i18next";

export default class Template {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable store = null;
  @observable id = null;
  @observable cost = 0;
  @observable name = "";
  @observable state = "";
  @observable orderable = true;
  @observable downloadable = true;
  @observable editable = true;
  @observable description = "";
  @observable reasons = "";
  @observable item_type = "PodTemplate";
  @observable generatedPdfForm = {
    id: null,
    pdf: {
      relativePath: "",
      originalFileName: "",
      url: ""
    },
    previewImageSmall: {
      relativePath: "",
      originalFileName: "",
      url: ""
    },
    previewImageMedium: {
      relativePath: "",
      originalFileName: "",
      url: ""
    },
    previewImageBig: {
      relativePath: "",
      originalFileName: "",
      url: ""
    }
  };
  @observable departmentId = null;
  @observable languageId = "";
  @observable colorProfileId = "";
  @observable tags = [];
  @observable vendors = {};
  @observable pdfFormFields = [];
  @observable content = {
    main: { mimeType: "", origFileName: "", path: "" },
    sample: { mimeType: "", optimizedPath: "", path: "", thumbnailPath: "" }
  };
  @observable isLoading = false;
  @observable base64 = "";

  @action.bound async update(data, message) {
    const { method, url } = urls.templates.put;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(data => this.onUpdateSuccess(data, message), method, `${url}/${this.id}`, {}, data);
    this.isLoading = false;
  }

  @action.bound onUpdateSuccess(data, message) {
    this.updateLocalState(data);
    const alertMessage = message || templateUpdateMessage;
    this.store.rootStore.catalogStore.activeCatalog.getCatalogMaterials();
    this.store.rootStore.alertsStore.createAlert({
      severity: "success",
      message: alertMessage,
      withAction: Boolean(message),
      action: {
        onClickHandler: () => {
          this.store.rootStore.navigationStore.push(`/templates/${this.id}/edit`);
        },
        actionText: t("alerts.open")
      }
    });
  }

  @action.bound updateLocalState(data) {
    this.editable = data.editable;
  }

  @action.bound async create(data) {
    const { method, url } = urls.templates.post;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(this.onCreateSuccess, method, url, {}, data);
    this.isLoading = false;
  }

  @action.bound onCreateSuccess() {
    this.store.rootStore.alertsStore.createAlert(templateCreated);
    this.store.rootStore.navigationStore.goBack();
  }

  @action.bound async delete() {
    const { method, url } = urls.templates.delete;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(null, method, `${url}/${this.id}`, {});
    this.isLoading = false;
  }

  @action.bound async downloadPdf() {
    const { url } = urls.downloadPdf;
    const { idToken } = await this.rootStore.authorizationStore.acquireTokenResponse();
    this.isLoading = true;
    fetch(`${url}/${this.id}?fileName=${this.fileName}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(res => res.blob())
      .then(this.onDownloadPdfSuccess)
      .catch(e => this.store.rootStore.errorService.handleError(e));
    this.isLoading = false;
  }

  @action.bound async downloadGeneratedPdf() {
    this.isLoading = true;
    fetch(this.generatedPdfForm.url)
      .then(res => res.blob())
      .then(this.onDownloadPdfSuccess)
      .catch(e => this.store.rootStore.errorService.handleError(e));
    this.isLoading = false;
  }

  @action.bound async loadPdf() {
    const { url } = urls.downloadPdf;
    const { idToken } = await this.rootStore.authorizationStore.acquireTokenResponse();

    return fetch(`${url}/${this.id}?fileName=${this.fileName}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
      .then(res => res.blob())
      .then(this.onLoadSuccess)
      .catch(e => this.store.rootStore.errorService.handleError(e));
  }

  @action.bound async onLoadSuccess(data) {
    this.base64 = await blobToBase64(data);
  }

  @action.bound async onDownloadPdfSuccess(data) {
    downloadFile(data, "template", "pdf");
  }

  @action.bound async updateState(state) {
    const formData = new FormData();
    const message = state === templateStates.deleted ? t("alerts.templateDeleted", this) : t("alerts.templateRestored", this);

    formData.append(
      "metadata",
      new Blob(
        [
          JSON.stringify({
            state
          })
        ],
        { type: "application/json" }
      )
    );
    await this.update(formData, message);
  }

  @action.bound deletePdf() {
    this.content.sample = {
      mimeType: "",
      optimizedPath: "",
      path: "",
      thumbnailPath: ""
    };
  }

  @action.bound async getPdfFields() {
    const { method, url } = urls.templates.formFields.get;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(this.onGetPdfFields, method, url.replace("{id}", this.id), {});
    this.isLoading = false;
  }

  @action.bound onGetPdfFields(data) {
    this.pdfFormFields = Object.values(data);
  }

  @action.bound async updatePdfFields(body) {
    if (!body || !body.length) return;
    const { method, url } = urls.templates.formFields.put;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(this.onGetPdfFields, method, url.replace("{id}", this.id), {}, body);
    this.isLoading = false;
  }

  @action.bound async generatePdf(body) {
    const { method, url } = urls.templates.generatePdf;
    this.isLoading = true;
    await this.store.rootStore.makeRequest(this.onGeneratePdfSuccess, method, url.replace("{id}", this.id), {}, generatePdfTransformer(body));
    this.isLoading = false;
  }

  @action.bound onGeneratePdfSuccess(data) {
    this.generatedPdfForm = data;
  }

  @action.bound getPdfFormWithValues(formValues) {
    return this.pdfFormFields.map(field => {
      const value = formValues[`${field.name}---${field.index}`];
      return { ...field, value };
    });
  }

  @computed get templateWithoutPdf() {
    return !this.content.sample.path && !this.content.sample.optimizedPath && !this.content.sample.thumbnailPath;
  }

  @computed get templateNotFinished() {
    return this.id && (!this.name && !this.description);
  }

  @computed get smallPhoto() {
    return this.content.sample.optimizedPath;
  }

  @computed get mediumPhoto() {
    return this.content.sample.thumbnailPath;
  }

  @computed get bigPhoto() {
    return this.content.sample.path;
  }

  @computed get isDeleted() {
    return this.state === templateStates.deleted;
  }

  @computed get colorProfileName() {
    const colorProfile = this.store.rootStore.colorProfilesStore.findById(this.colorProfileId);
    return colorProfile && colorProfile.name;
  }

  @computed get languageName() {
    const language = this.store.rootStore.languagesStore.findById(this.languageId);
    return language && language.name;
  }

  @computed get departmentName() {
    const department = this.store.rootStore.departmentsStore.findById(this.departmentId);
    return department && department.name;
  }

  @computed get fileName() {
    return this.content.main.origFileName;
  }

  @computed get isDownloadable() {
    return this.downloadable;
  }

  @computed get hasPdfForm() {
    return Boolean(this.pdfFormFields.length);
  }

  @computed get isEditable() {
    return this.editable;
  }

  @computed get hasPdfFormConstraints() {
    return this.pdfFormFields.some(field => field.constraints);
  }

  @computed get dataForOrder() {
    return pick(
      this,
      "id",
      "cost",
      "name",
      "state",
      "orderable",
      "editable",
      "downloadable",
      "description",
      "reasons",
      "item_type",
      "content",
      "departmentId",
      "language",
      "color",
      "tags",
      "vendors",
      "generatedPdfForm"
    );
  }
}
