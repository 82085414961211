import moment from "moment";

class DateService {
  constructor() {
    this.service = moment;
  }

  getDate() {
    return this.service();
  }

  getWrappedDate(date) {
    return this.service(date);
  }

  toLocalDate(date, format) {
    const meridian = date.slice(-2);

    return this.service(`${date.replace(meridian, ` ${meridian}`)} UTC`).format(format);
  }

  getUnixStringFromDate(date) {
    return this.service(date)
      .unix()
      .toString();
  }

  getEndOfDay() {
    return this.service().endOf("day");
  }

  subtractFromDate(amount, val) {
    return this.service().subtract(amount, val);
  }

  isBefore(a, b) {
    return this.service(a).isBefore(this.service(b));
  }

  getFormattedDate(date, format) {
    return this.service(date).format(format);
  }

  getTimeZone() {
    return new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
  }
}

export default new DateService();
