import React, { useState, useEffect } from "react";
import clsx from "clsx";
import List from "@material-ui/core/List";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Icon from "../icon";
import SubMenuItem from "./SubMenuItem";
import CollapseIcon from "./CollapseIcon";

import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";

const CollapsibleMenuItem = ({ catalogStore, menuStore, item, onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(() => menuStore.menuState.openKeys.includes(item.key));

  const excludedCompanyFilterValues = [
    t("menuStore.myFinancialCenter") + " ",
    t("menuStore.allMarketingMaterials"),
    t("menuStore.myFinancialCenter"),
    t("menuStore.allAvailableFixtures"),
    t("menuStore.allMarketingClings")
  ];
  const {
    key,
    collapsible,
    title: { text, iconType }
  } = item;

  useEffect(() => {
    setCollapsed(menuStore.menuState.openKeys.includes(item.key));
  }, [item.key, menuStore.menuState.openKeys]);

  const onCollapseClickHandler = () => {
    history.push("/catalog");
    setCollapsed(!collapsed);
    menuStore.onMenuOpenChange([item.key]);
    menuStore.updateMenuState({
      collapsed: false
    });
  };

  const renderSubMenuItems = key => {
    const catalog = catalogStore.catalogs.find(({ catalogName }) => catalogName === key);
    return catalog.materialsNamesList;
  };

  const onMenuClickHandler = label => {
    history.push("/catalog");
    onClick(item, label);
  };

  return (
    <>
      <ListItem
        aria-expanded={collapsed}
        button
        className={clsx(classes.firstLevelListItem, {
          [classes.collapsed]: menuStore.menuState.collapsed
        })}
        onClick={onCollapseClickHandler}
      >
        <ListItemIcon className={classes.iconWrapper}>
          <Icon className={classes.icon} type={iconType} />
        </ListItemIcon>
        {!menuStore.menuState.collapsed && <ListItemText classes={{ primary: classes.listItemText }} primary={text} />}
        {collapsible && <CollapseIcon showIcon={!menuStore.menuState.collapsed} collapsed={collapsed} />}
      </ListItem>
      {collapsible && (
        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {renderSubMenuItems(key).map(item => (
              <SubMenuItem
                selected={(menuStore.menuState.selectedKeys || []).includes(item)}
                key={item}
                label={item}
                isSubMenu={excludedCompanyFilterValues.includes(item)}
                onClick={onMenuClickHandler}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default inject(({ rootStore }) => ({
  catalogStore: rootStore.catalogStore,
  menuStore: rootStore.menuStore
}))(observer(CollapsibleMenuItem));
