import React from "react";
import Box from "@material-ui/core/Box";

import Icon from "../icon";
import Button from "../button";

import "../warningNotification/styles.scss";
import { useTranslation } from "react-i18next";

const CatalogWarning = ({ text, onCustomOrder, onHelpRequest, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <div className="previous-campaign-alert" {...restProps}>
      <div className="previous-campaign-alert__left">
        <Icon type="support" />
      </div>
      <div className="">
        <span className="text">{text}</span>
        <Box mr={1} component="span">
          <Box mr={1} component="span">
            <Button className="uppercase" type="default" onClick={onCustomOrder}>
              {t("catalogWarning.customOrderButton")}
            </Button>
          </Box>
          <Button className="uppercase" type="default" onClick={onHelpRequest}>
            {t("catalogWarning.helpRequestButton")}
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default CatalogWarning;
