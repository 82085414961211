import { numberMinPositiveValuePromise } from "../../../utils";

export const settings = {
  titleId: "print-on-demand-card-dialog-title",
};

export const printOnDemandOrderForm = {
  formName: "printOnDemand",
  layout: "vertical",
  quantity: {
    name: "quantity",
    type: "number",
    label: "Quantity to order",
    rules: [{ required: true, message: "quantity is required" }, numberMinPositiveValuePromise],
  },
  reason: {
    name: "reason",
    label: { text: "Reason" },
    optionValue: "body",
    placeholder: "Select Reason",
    rules: [{ required: true, message: "you need to choose reason" }],
  },
  comment: {
    name: "comments",
    label: { text: "Comments" },
  },
  color: {
    name: "color",
    label: "Color profile",
  },
  language: {
    name: "language",
    label: "Language",
  },
};
