import { observable, computed } from "mobx";

import { roles } from "../../../constants";

export default class User {
  constructor(props) {
    Object.assign(this, props);
  }

  @observable store = null;
  @observable id = null;
  @observable role = [];
  @observable full_name = "";
  @observable language = "";
  @observable phone = "";
  @observable email = "";
  @observable contact_email = "";
  @observable assigned_locations = [];

  @computed get primaryLocation() {
    return this.assigned_locations.find(location => location.primary) || this.assigned_locations[0];
  }

  @computed get primaryLocationFCID() {
    if (!this.primaryLocation) return 0;
    return this.primaryLocation.fcid;
  }

  @computed get primaryLocationForSelect() {
    if (!this.primaryLocation) return {};
    const { fcid, id, name, site_name } = this.primaryLocation;
    return { fcid, id, name: name || site_name };
  }

  @computed get userRole() {
    return this.role.length ? this.role[0] : "";
  }

  @computed get isAdmin() {
    return this.role.includes(roles.podAdmin) || this.role.includes(roles.podEditor);
  }

  @computed get isPodEditor() {
    return this.role.includes(roles.podEditor);
  }

  @computed get isILDashboardAccess() {
    return this.role.includes(roles.ILDashboardAccess);
  }
}
