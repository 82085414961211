import { observable, action, computed } from "mobx";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

import { Filter } from "../index";
import Template from "../template";
import { urls } from "../../apiUrls";
import { generateId } from "../../../utils";
import { locationFilterKey } from "./settings";
import { templateStates } from "../../../constants";
import { LocalStorageService } from "../../../services";
import { t } from "i18next";

export default class Catalog {
  constructor(props) {
    Object.assign(this, props);
    this.getMenuState();
  }

  @observable store = null;
  @observable id = generateId();
  @observable catalogName = "";
  @observable materials = [];
  @observable filters = [];
  @observable activeFilters = {};
  @observable materialsNames = [];
  @observable isLoading = false;
  @observable zoneFilter = "0";
  @observable defaultZoneId = "0";
  @observable zoneFilterValues = [];
  @observable companyNameFilter = "";
  @observable topCatalogMenuKey = "";
  @observable bottomCatalogMenuKey = "";
  @observable locationFilter = { active: false, value: {} };
  @observable location = {};
  @observable allMaterialsZone = { id: 0, name: t("catalog.allZones") };
  @observable frontFilters = {
    zoneFilterFn: null,
    companyFilterFn: null
  };

  @computed get locationFilterValue() {
    const filterKey = locationFilterKey[this.store.rootStore.menuStore.menuState.openKeys[0]];

    return this.store.rootStore.menuStore.menuState.selectedKeys[0] === this.bottomCatalogMenuKey
      ? {}
      : {
          [filterKey]: this.store.rootStore.locationStore.activeLocation.fcid
        };
  }

  @action.bound async getCatalogMaterials() {
    const { method, url } = urls.catalog[this.catalogName];
    this.isLoading = true;
    this.materials = [];
    await this.store.rootStore.makeRequest(this.saveMaterials, method, url, this.getFilterParams, this.materialsFilters);
    this.isLoading = false;
  }

  @computed get materialsFilters() {
    return { q: { ...this.activeFilters, ...this.locationFilter.value } };
  }

  @computed get getFilterParams() {
    if (this.catalogName !== "printOnDemand") return {};

    const filters = Object.keys(this.activeFilters).reduce((acc, key) => {
      if (this.activeFilters[key].length) {
        acc[key] = this.activeFilters[key].join(",");
      }
      return acc;
    }, {});

    const defaultFilters = {
      states: `${templateStates.active}${this.store.rootStore.authorizationStore.user.isAdmin ? `,${templateStates.deleted},${templateStates.draft}` : ""}`
    };

    return isEmpty(filters) ? defaultFilters : { ...filters };
  }

  @action.bound saveMaterials({ groups, campaigns, templates }) {
    this.materials = groups || campaigns || templates.map(template => new Template({ store: this.store, ...template }));
  }

  @action.bound groupTemplatesByState(templates) {
    return groupBy(templates, "state");
  }

  @action.bound async searchMaterials(searchString, limit) {
    if (this.catalogName === "printOnDemand") {
      this.searchTemplates(searchString, limit);
      return;
    }
    return this.getFcMaterials(searchString, limit);
  }

  @action.bound async getFcMaterials(search_query, limit) {
    const { method, url } = urls.catalog[this.catalogName];
    const query = { search_query, q: this.location };
    if (limit) query.per_page = limit;
    return await this.store.rootStore.makeRequest(data => this.store.rootStore.searchStore.saveSearchResults(this.catalogName, data), method, url, {}, query);
  }

  @action.bound async getOutFcMaterials(search_query) {
    const { method, url } = urls.catalog[this.catalogName];

    return await this.store.rootStore.makeRequest(
      data => this.store.rootStore.searchStore.saveOutFcSearchResults(this.catalogName, data),
      method,
      url,
      {},
      { search_query }
    );
  }

  @action.bound async searchTemplates(search_query, limit) {
    const { method, url } = urls.catalog[this.catalogName];
    this.isLoading = true;
    await this.store.rootStore.makeRequest(data => this.store.rootStore.searchStore.saveSearchResults(this.catalogName, data), method, url, {
      operator: "OR",
      name: `*${search_query}*`,
      description: `*${search_query}*`,
      size: limit ? limit : -1
    });
    this.isLoading = false;
  }

  @computed get filtersWithoutLocation() {
    const { [locationFilterKey[this.catalogName]]: excludedValue, ...values } = this.activeFilters;
    return values;
  }

  @computed get filteredMaterials() {
    return Object.values(this.frontFilters).reduce((materials, filterFn) => {
      return filterFn ? filterFn(materials) : materials;
    }, this.materials);
  }

  companyFilterFn = campaigns => {
    return campaigns.filter(campaign => campaign.name === this.companyNameFilter);
  };

  zoneFilterFn = campaigns => {
    return campaigns.reduce(this.filterByZoneNameAndNoNEmpty, []);
  };

  filterByZoneNameAndNoNEmpty = (acc, campaign) => {
    const filteredCampaign = this.filterByZoneName(campaign);
    return filteredCampaign.zones.length ? [...acc, filteredCampaign] : [...acc];
  };

  filterByZoneName = campaign => {
    return {
      ...campaign,
      zones: campaign.zones && campaign.zones.length ? campaign.zones.filter(({ zone_id }) => zone_id === parseInt(this.zoneFilter)) : []
    };
  };

  @computed get materialsNamesList() {
    const showCampaigns =
      this.catalogName === "marketing" || this.catalogName === "marketing_cling" || this.catalogName === "regulatory" || this.catalogName === "healthSafety";
    if (showCampaigns) return [this.topCatalogMenuKey, this.bottomCatalogMenuKey];
    const materialsNames = this.materials.map(({ name }) => name);
    return this.locationFilter.active
      ? [this.topCatalogMenuKey, ...materialsNames, this.bottomCatalogMenuKey]
      : [this.topCatalogMenuKey, this.bottomCatalogMenuKey, ...materialsNames];
  }

  @computed get materialsNames() {
    return this.materials.map(({ name }) => name);
  }

  @action.bound async getFilters() {
    if (this.catalogName === "printOnDemand") {
      this.saveFilters(this.getPodFilters);
      return;
    }
    const { method, url } = urls.filters[this.catalogName];
    return await this.store.rootStore.makeRequest(this.saveFilters, method, url, {
      location_fcid: this.locationFilter.value[locationFilterKey[this.catalogName]]
    });
  }

  @action.bound saveFilters({ tabs, filters }) {
    //TODO zone should be deleted on the server
    if (!tabs || !filters) return;
    this.zoneFilterValues = tabs.zones.filter(({ name }) => name !== "Special Materials");
    this.filters = filters.map(filter => new Filter(filter));
  }

  @action.bound setZoneActiveFilter(filter) {
    this.frontFilters.zoneFilterFn = !parseInt(filter) ? null : this.zoneFilterFn;
    this.zoneFilter = filter;
  }

  @action.bound resetZoneFilter() {
    this.setZoneActiveFilter(this.defaultZoneId);
  }

  @action.bound setFilters(key) {
    const shouldResetZoneFilter = this.checkIfBottomOrTopMenuKey(key);
    this.setCompanyFilter(key);
    this.setLocationFilter(key);
    if (shouldResetZoneFilter) this.resetZoneFilter();
  }

  @action.bound setCompanyFilter(companyName) {
    this.frontFilters.companyFilterFn = this.checkIfBottomOrTopMenuKey(companyName) ? null : this.companyFilterFn;
    this.companyNameFilter = companyName;
  }

  @computed get anotherFCIncluded() {
    return !this.locationFilter.active;
  }

  @action.bound setLocationFilter(key) {
    if (!this.checkIfBottomOrTopMenuKey(key)) return;
    const value = key === this.bottomCatalogMenuKey ? { active: false, value: {} } : { active: true, value: this.location };
    this.setLocationFilterState(value);
  }

  @action.bound checkIfBottomOrTopMenuKey(key) {
    return key === this.bottomCatalogMenuKey || key === this.topCatalogMenuKey;
  }

  @action.bound setLocation(location) {
    this.location[locationFilterKey[this.catalogName]] = location.fcid;
  }

  @computed get zoneFilters() {
    const zoneIds = this.activeZonesIds;
    const filteredZones = zoneIds ? this.zoneFilterValues.filter(({ id }) => zoneIds.includes(id)) : this.zoneFilterValues;
    return [this.allMaterialsZone, ...filteredZones];
  }

  @computed get activeZonesIds() {
    return this.materials
      ?.map(material => {
        return material?.zones?.map(({ zone_id }) => {
          return zone_id;
        });
      })
      .flat()
      .filter((id, index, filteredArray) => filteredArray.indexOf(id) === index);
  }

  @action.bound amountAllActiveFilters() {
    return Object.keys(this.filtersWithoutLocation).reduce((acc, key) => {
      return acc + this.activeFilters[key].length;
    }, 0);
  }

  @action.bound async getMenuState() {
    const menu = (await LocalStorageService.get("menuState")) || this.store.rootStore.menuStore.menuState;
    if (menu && menu.selectedKeys) {
      this.setLocationFilter(this.getActiveMenuKey(menu));
      this.setCompanyFilter(menu.selectedKeys[0]);
    }
  }

  @action.bound getActiveMenuKey(menu) {
    return menu && menu.selectedKeys && this.checkIfBottomOrTopMenuKey(menu.selectedKeys[0]) ? menu.selectedKeys[0] : menu.lastActiveMenuKey;
  }

  @action.bound applyFilters() {
    return this.filters.map(filter => {
      const { category, values } = filter.checkedValues;
      return (this.activeFilters[category] = values);
    });
  }

  @action.bound clearFilters() {
    this.filters.forEach(filter => filter.clearValues());
  }

  @action.bound setLocationFilterState(state) {
    this.locationFilter = state;
  }

  @computed get getPodFilters() {
    return {
      tabs: { groups: [], subzones: [], zones: [] },
      filters: [
        { name: "name", title: "Name", customText: true, available_matchers: [{ available_values: [] }], useSearch: false, multiply: true },
        { name: "description", title: "Description", customText: true, available_matchers: [{ available_values: [] }], useSearch: false, multiply: true },
        {
          name: "departmentIds",
          title: "Department",
          available_matchers: [{ available_values: this.store.rootStore.departmentsStore.filters }],
          multiply: true
        },
        { name: "tags", title: "Tags", available_matchers: [{ available_values: this.store.rootStore.tagsStore.filters }] },
        { name: "languageIds", title: "Language", available_matchers: [{ available_values: this.store.rootStore.languagesStore.filters }], multiply: true },
        {
          name: "downloadable",
          title: "Downloadable",
          available_matchers: [{ available_values: [{ supertitle: "", title: "Yes", value: true }, { supertitle: "", title: "No", value: false }] }],
          useSearch: false,
          multiply: false
        },
        {
          name: "orderable",
          title: "Orderable",
          available_matchers: [{ available_values: [{ supertitle: "", title: "Yes", value: true }, { supertitle: "", title: "No", value: false }] }],
          useSearch: false,
          multiply: false
        },
        {
          name: "editable",
          title: "Editable",
          available_matchers: [{ available_values: [{ supertitle: "", title: "Yes", value: true }, { supertitle: "", title: "No", value: false }] }],
          useSearch: false,
          multiply: false
        },
        {
          name: "states",
          title: "State",
          available_matchers: [
            {
              available_values: [
                { supertitle: "", title: "Active", checked: true, default: true, value: templateStates.active },
                {
                  supertitle: "",
                  title: "Deleted",
                  checked: this.store.rootStore.authorizationStore.user.isAdmin,
                  default: this.store.rootStore.authorizationStore.user.isAdmin,
                  value: templateStates.deleted
                },
                {
                  supertitle: "",
                  title: "Draft",
                  checked: this.store.rootStore.authorizationStore.user.isAdmin,
                  default: this.store.rootStore.authorizationStore.user.isAdmin,
                  value: templateStates.draft
                }
              ]
            }
          ],
          useSearch: false,
          multiply: true
        }
      ]
    };
  }
}
